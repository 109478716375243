import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { Form, HAControlButtons } from 'shared';
import { Input } from '@tuunetech/tuune-components';
import { TextInputValue } from 'modules/Questionnaire/types';

interface TextAnswerProps {
  handleRequestNext: () => void;
  initialValues: TextInputValue;
  handleSubmit: (values: TextInputValue) => void;
  validate: (values: TextInputValue) => void;
}

const TextAnswer: React.FC<TextAnswerProps> = ({
  handleRequestNext,
  initialValues,
  handleSubmit,
  validate,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount
      enableReinitialize
    >
      {({
        values,
        dirty,
        isValid,
        errors,
        touched,
        submitForm,
        handleChange,
        handleBlur,
        setFieldTouched,
      }): ReactNode => {
        const submit = (): void | Promise<void> => {
          if (isValid) {
            return dirty ? submitForm() : handleRequestNext();
          } else {
            Object.keys(values).forEach((key) => setFieldTouched(key));
          }
        };

        return (
          <>
            <Form>
              <Input
                autoComplete="off"
                name="answer"
                type="text"
                autoFocus
                required
                placeholder="Give your answer here"
                value={values.answer}
                error={touched.answer && !!errors.answer}
                helperText={touched.answer && errors.answer}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <HAControlButtons onClick={submit} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default TextAnswer;
