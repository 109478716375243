import { Reducer } from 'redux';
import { CheckoutReducerState } from '../types';
import {
  REPORT_UNLOCK_SUCCESS,
  SUBMIT_PRESCRIPTION_ORDER_SUCCESS,
  GET_PHARMACY_DETAILS_SUCCESS,
  RESEARCH_AUTO_APPROVE_SUCCESS,
  SET_SIGNUP_ORDER_UUID,
} from '../constants';
import { REGISTER_HORMONE_TEST_ADDRESS_SUCCESS } from 'modules/Account/constants';

const defaultState: CheckoutReducerState = {
  stripeRedirectUrl: '',
  paid: false,
  prescriptionOrderUuid: undefined,
  pharmacyDetails: undefined,
  isResearchAutoApproved: undefined,
  signupOrderUuid: undefined,
};

const checkoutReducer: Reducer<CheckoutReducerState> = (
  state = defaultState,
  { type, payload, response: { data = {} } = {} },
) => {
  switch (type) {
    case REPORT_UNLOCK_SUCCESS:
      return {
        ...state,
        stripeRedirectUrl: data.redirect_url,
        paid: data.paid || false,
      };
    case RESEARCH_AUTO_APPROVE_SUCCESS:
      return {
        ...state,
        isResearchAutoApproved: true,
      };
    case SUBMIT_PRESCRIPTION_ORDER_SUCCESS:
      return {
        ...state,
        prescriptionOrderUuid: data.uuid,
        stripeRedirectUrl: data.redirect_url,
      };
    case GET_PHARMACY_DETAILS_SUCCESS:
      return {
        ...state,
        pharmacyDetails: data.address,
      };
    case REGISTER_HORMONE_TEST_ADDRESS_SUCCESS:
      return {
        ...state,
        stripeRedirectUrl: data.redirect_url,
      };
    case SET_SIGNUP_ORDER_UUID:
      return {
        ...state,
        signupOrderUuid: payload.orderUuid,
      };
    default:
      return state;
  }
};

export { checkoutReducer as checkout };
