//import { FacebookLogo } from 'assets/icons/FacebookLogo';
import { GoogleLogo } from 'assets/icons/GoogleLogo';
import { OAuthConfig } from '../types/socialAuth';

export enum OAuthProvider {
  Google = 'Google',
  //Facebook = 'Facebook',
}

export const OAUTH_CONFIGS: Record<OAuthProvider, OAuthConfig> = {
  [OAuthProvider.Google]: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    redirectUri: '/oauth-redirect/google/',
    responseType: 'code',
    scope: 'email profile',
    authServerUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    authProviderLogo: GoogleLogo,
  },
  // [OAuthProvider.Facebook]: {
  //   clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID || '',
  //   redirectUri: '/oauth-redirect/facebook/',
  //   responseType: 'code',
  //   scope: 'email public_profile',
  //   authServerUrl: 'https://www.facebook.com/v13.0/dialog/oauth',
  //   authProviderLogo: FacebookLogo,
  // },
};
