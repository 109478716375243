import { MARKETING } from 'modules/Questionnaire/constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMarketingPreference } from '../actions';
import { MarketingPreference } from '../components/MarketingPreference';
import { getMarketingPreference } from '../selectors';

export const MarketingPreferenceContainer: React.FC = () => {
  const marketingPreference = useSelector(getMarketingPreference);
  const dispatch = useDispatch();

  const handleSubmit = (options: Set<MARKETING>) => {
    dispatch(updateMarketingPreference(options));
  };

  return (
    <MarketingPreference
      marketingPreference={marketingPreference}
      onSubmit={handleSubmit}
    />
  );
};
