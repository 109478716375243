import { GET_RECOMMENDATION_RESULTS_REQUEST } from 'modules/Results/constants';
import { ResultsRequestActionTypes } from 'modules/Results/types';
import { ApiHost } from 'utils/axios';
import { ApiReturnFunc } from './index';

const resultsApi: Record<ResultsRequestActionTypes, ApiReturnFunc> = {
  [GET_RECOMMENDATION_RESULTS_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/recommendation`,
      method: 'get',
      headers: {},
    };
  },
};

export default resultsApi;
