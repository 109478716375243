import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, SwipeableDrawerProps } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BODY1 } from '@tuunetech/tuune-components';
import { getEntries } from 'modules/Contentful/actions';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { getIsLoading } from 'modules/Loading/selectors';
import { DrawerModal } from 'shared';
import {
  getResultsContentfulOptions,
  HOW_TO_START_SUMMARY_SLUG,
} from '../constants';

export type SwitchingPillInfoProps = {
  isSwitchingPillInfoOpen: boolean;
  handleSwitchingPillInfoOpen: (
    event: React.SyntheticEvent<HTMLButtonElement>,
  ) => void;
  handleSwitchingPillInfoClose: SwipeableDrawerProps['onClose'];
};

const CK = {
  [CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC,
  ),
};

export const SwitchingPillInfoContainer: React.FC<SwitchingPillInfoProps> = ({
  handleSwitchingPillInfoOpen,
  handleSwitchingPillInfoClose,
  isSwitchingPillInfoOpen,
}) => {
  const dispatch = useDispatch();

  // get all variant for switching contraception
  const switchingContraception = useSelector(
    getArrayContentMapped(CK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC].reqId),
  );
  const isSwitchingContraceptionLoading = useSelector(
    getIsLoading(CK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC].loadingId),
  );

  useEffect(() => {
    // request all options for switching contraception
    if (
      isSwitchingPillInfoOpen &&
      isSwitchingContraceptionLoading === undefined
    ) {
      dispatch(
        getEntries({
          ...CK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC],
          slugs: [HOW_TO_START_SUMMARY_SLUG],
          ne: true,
        }),
      );
    }
  }, [dispatch, isSwitchingContraceptionLoading, isSwitchingPillInfoOpen]);

  const switchingPillInfoContent = useMemo(() => {
    return Object.keys(switchingContraception).map((key) => ({
      content: documentToReactComponents(
        switchingContraception[key]?.content,
        getResultsContentfulOptions(),
      ),
      name: switchingContraception[key]?.name,
    }));
  }, [switchingContraception]);

  return (
    <DrawerModal
      onClose={handleSwitchingPillInfoClose}
      onOpen={handleSwitchingPillInfoOpen}
      open={isSwitchingPillInfoOpen}
    >
      {isSwitchingContraceptionLoading ? (
        <CircularProgress color="primary" />
      ) : (
        switchingPillInfoContent.map(({ name, content }) => (
          <React.Fragment key={name}>
            <BODY1 align="left" color="textPrimary" paragraph>
              {name}
            </BODY1>
            {content}
          </React.Fragment>
        ))
      )}
    </DrawerModal>
  );
};
