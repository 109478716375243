import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Button, Container, H3, H5 } from '@tuunetech/tuune-components';
import {
  feedbackQuestions,
  lastQuestionNum,
} from '../../containers/HAFeedbackModule/questions';
import type { FeedbackDataType } from '../../containers/HAFeedbackModule/state';

type HAFeedbackModuleProps = {
  feedbackQnNum: number;
  handleGoBack: () => void;
  handleNext: () => void;
  handleUpdateFeedbackQnData: (data: FeedbackDataType) => void;
  feedbackData: FeedbackDataType;
};

export const HAFeedbackModule: React.FC<HAFeedbackModuleProps> = ({
  feedbackQnNum,
  handleGoBack,
  handleNext,
  handleUpdateFeedbackQnData,
  feedbackData,
}) => {
  const QuestionComponent = feedbackQuestions[feedbackQnNum].component;

  const handleUpdateData = useCallback(
    (data: FeedbackDataType) => {
      handleUpdateFeedbackQnData(data);
      if (feedbackQnNum === 0) {
        handleNext();
      }
    },
    [feedbackQnNum, handleNext, handleUpdateFeedbackQnData],
  );

  return (
    <Container $marginBottom={16}>
      {feedbackQnNum === 0 && (
        <H3 $marginBottom={40}>
          Congrats, you made it!
          <br />
          Please take some time to tell us more about you.
        </H3>
      )}
      <H5 $marginBottom={24}>{feedbackQuestions[feedbackQnNum].title}</H5>
      <Box display="flex" justifyContent="center">
        <QuestionComponent
          handleUpdateData={handleUpdateData}
          currentData={feedbackData[feedbackQuestions[feedbackQnNum].key]}
        />
      </Box>
      {feedbackQnNum !== 0 && (
        <Box display="flex" justifyContent="center" marginTop="40px">
          <Button color="white" variant="outlined" onClick={handleGoBack}>
            back
          </Button>
          <Box width="8px" />

          <Button color="white" onClick={handleNext}>
            {feedbackQnNum === lastQuestionNum ? 'submit' : 'next'}
          </Button>
        </Box>
      )}
    </Container>
  );
};
