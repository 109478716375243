import React from 'react';
import { BODY1, Button } from '@tuunetech/tuune-components';
import Routes from 'routes';
import { Box, CircularProgress } from '@material-ui/core';
import { ErrorBox, TuuneInput } from 'shared';
import { Control, FieldErrors } from 'react-hook-form';
import AuthLayout from '../AuthLayout';
import { ForgotPasswordFormData } from '../../pages/ForgotPasswordPage';

interface ForgotPasswordProps {
  control: Control<ForgotPasswordFormData>;
  handleSubmit: () => void;
  errors: FieldErrors<ForgotPasswordFormData>;
  recoveryStage: number;
  isLinkValid: boolean;
  email: string;
  isResendLoading?: 0 | 1;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  control,
  handleSubmit,
  errors,
  recoveryStage,
  isLinkValid,
  email,
  isResendLoading,
}) => {
  const subTitle =
    recoveryStage === 0
      ? 'Enter your email below and we’ll send a link to reset your password.'
      : undefined;

  const content = (
    <>
      <form onSubmit={handleSubmit}>
        {!isLinkValid && recoveryStage === 0 && (
          <ErrorBox text="The link you clicked has expired." />
        )}
        {recoveryStage === 0 ? (
          <TuuneInput
            name="email"
            required
            autoFocus
            control={control}
            autoComplete="email"
            placeholder="Email"
            type="email"
            inputProps={{
              inputMode: 'email',
            }}
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        ) : (
          <>
            <BODY1>
              We’ve sent an email to{' '}
              <span style={{ fontWeight: 'bold' }}>{email}</span> with a link to
              reset your password.
            </BODY1>
            <BODY1>
              If you don’t receive it in 1 minute click the button below to
              resend it.
            </BODY1>
          </>
        )}
        <Box marginTop="40px">
          <Button type="submit" fullWidth disabled={isResendLoading === 1}>
            {isResendLoading === 1 && (
              <CircularProgress color="inherit" size={12} />
            )}
            {recoveryStage === 0 ? 'Reset password' : 'Resend email'}
          </Button>
        </Box>
        <Box marginTop="16px" display="flex" justifyContent="center">
          <Button href={Routes.LOGIN} variant="text">
            back to login
          </Button>
        </Box>
      </form>
    </>
  );
  return (
    <AuthLayout title="Forgot password" subTitle={subTitle} content={content} />
  );
};

export default ForgotPassword;
