import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import Routes from 'routes';
import {
  BODY1,
  Button,
  Container,
  GridContainer,
  GridItem,
  H1,
} from '@tuunetech/tuune-components';
import { Page } from 'shared';
import { ClinicsConfigContext } from 'utils/clinics/context';
import { ClinicLandingContainer } from 'modules/Questionnaire/containers/ClinicGetStarted/ClinicLandingContainer';
import { getObjValueIgnoreCase } from 'utils/helpers/objectUtils';
import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';

export const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clinicSlug } = useParams<{ clinicSlug: string }>();

  const [clinicAuthConfig, setClinicAuthConfig] = useState<ClinicAuthConfig>();

  const { isLoading: isClinicsConfigLoading, clinicsConfig } =
    useContext(ClinicsConfigContext);

  useEffect(() => {
    if (clinicSlug && clinicsConfig && isClinicsConfigLoading === false) {
      const config = getObjValueIgnoreCase(clinicsConfig, clinicSlug);
      setClinicAuthConfig(config as ClinicAuthConfig);
    }
  }, [history, dispatch, isClinicsConfigLoading, clinicsConfig, clinicSlug]);

  const handleClick = useCallback(() => {
    history.replace(Routes.HOME);
  }, [history]);

  const pageNotFound = (
    <Page>
      <Container>
        <GridContainer justify="center">
          <GridItem>
            <H1 paragraph>Oops!</H1>
            <BODY1 paragraph>
              We couldn’t find the page you’re looking for. Let’s try something
              different.
            </BODY1>
            <Button onClick={handleClick}>Go back home</Button>
          </GridItem>
        </GridContainer>
      </Container>
    </Page>
  );

  if (isClinicsConfigLoading != false || clinicAuthConfig === undefined) {
    return <></>;
  }

  return clinicAuthConfig ? (
    <ClinicLandingContainer clinicAuthConfig={clinicAuthConfig} />
  ) : (
    pageNotFound
  );
};
