import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ForgotPassword from '../components/ForgotPassword';
import { resetPasswordRequest } from '../actions';
import { getResetPasswordStage } from '../selectors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordSchema } from '../constants/validation';
import { getIsLoading } from '../../Loading/selectors';
import { RESET_PASSWORD } from '../constants';

export type ForgotPasswordFormData = {
  email: string;
};

const ForgotPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const recoveryStage = useSelector(getResetPasswordStage);
  const {
    location: { state: { isLinkValid = true } = {} },
  } = useHistory<{ isLinkValid?: boolean }>();

  const isResendLoading = useSelector(getIsLoading(RESET_PASSWORD));

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(ForgotPasswordSchema),
    mode: 'onSubmit',
  });

  const email = watch('email');

  const submit = useCallback(
    ({ email }) => {
      dispatch(resetPasswordRequest({ email }));
    },
    [dispatch],
  );

  return (
    <ForgotPassword
      recoveryStage={recoveryStage}
      control={control}
      handleSubmit={handleSubmit(submit)}
      isLinkValid={isLinkValid}
      errors={errors}
      email={email}
      isResendLoading={isResendLoading}
    />
  );
};

export default ForgotPasswordPage;
