import { MARKETING } from 'modules/Questionnaire/constants';
import { BaseRegisterPayload } from 'modules/Questionnaire/types';

export const registerPayloadToAPIPayload = (
  registerPayload: BaseRegisterPayload,
): Record<string, string | boolean> => {
  const { country, state, phone, marketingOptions, signupType } =
    registerPayload;

  return {
    ...(country && { country }),
    ...(state && { state }),
    ...(phone && { phone_number: phone, accept_sms_consent: true }),
    [MARKETING.marketingPreference]: marketingOptions.has(
      MARKETING.marketingPreference,
    ),
    [MARKETING.researchPreference]: marketingOptions.has(
      MARKETING.researchPreference,
    ),
    ...(signupType && { signup_type: signupType }),
  };
};
