import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const FunnelFilter: React.FC<SvgIconProps> = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M7.00067 6H17.0007L11.9907 12.3L7.00067 6ZM4.25067 5.61C6.27067 8.2 10.0007 13 10.0007 13V19C10.0007 19.55 10.4507 20 11.0007 20H13.0007C13.5507 20 14.0007 19.55 14.0007 19V13C14.0007 13 17.7207 8.2 19.7407 5.61C20.2507 4.95 19.7807 4 18.9507 4H5.04067C4.21067 4 3.74067 4.95 4.25067 5.61Z" />
    </SvgIcon>
  );
};
