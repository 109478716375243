import { ActionCreator, AnyAction } from 'redux';
import {
  CLEAR_NOTIFICATION_POPUP,
  CLEAR_ERRORS,
  DISPLAY_DEFAULT_ERROR_POPUP,
  DISPLAY_SESSION_EXPIRED_ERROR_POPUP,
  DISPLAY_ERROR_POPUP,
  DISPLAY_ERROR_NON_FIELD,
  DISPLAY_FIELD_ERRORS,
  DISPLAY_INFO_POPUP,
} from '../constants';

export const clearNotificationPopup: ActionCreator<AnyAction> = () => ({
  type: CLEAR_NOTIFICATION_POPUP,
});

export const clearErrors: ActionCreator<AnyAction> = () => ({
  type: CLEAR_ERRORS,
});

export const displayDefaultErrorPopup: ActionCreator<AnyAction> = () => ({
  type: DISPLAY_DEFAULT_ERROR_POPUP,
});

export const displaySessionExpiredErrorPopup: ActionCreator<
  AnyAction
> = () => ({
  type: DISPLAY_SESSION_EXPIRED_ERROR_POPUP,
});

export const displayErrorPopup: ActionCreator<AnyAction> = (
  message: string,
) => ({
  type: DISPLAY_ERROR_POPUP,
  payload: { message: message },
});

export const displayNonFieldError: ActionCreator<AnyAction> = (
  message: string,
) => ({
  type: DISPLAY_ERROR_NON_FIELD,
  payload: { message: message },
});

export const displayFieldErrors: ActionCreator<AnyAction> = (
  messages: Record<string, []>,
) => ({
  type: DISPLAY_FIELD_ERRORS,
  payload: { messages: messages },
});

export const displayInfoPopup: ActionCreator<AnyAction> = (
  message: string,
) => ({
  type: DISPLAY_INFO_POPUP,
  payload: { message: message },
});
