import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes';
import { getIsLoading } from 'modules/Loading/selectors';
import { logout } from 'modules/Auth/actions';
import { getHasAnyErrorMessages } from 'modules/NotificationsHandler/selectors';
import { displayErrorPopup } from 'modules/NotificationsHandler/actions';
import { DeleteAccount as DeleteAccountLayout } from '../components/DeleteAccount';
import { deactivateUserRequest, getDeletionReasonsRequest } from '../actions';
import { getDeletionReasons } from '../selectors';
import { DEACTIVATE_USER } from '../constants';

export const FREE_TEXT_REASON_ID = 'something_else';

export const DeleteAccount: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const hasServerErrors = useSelector(getHasAnyErrorMessages);

  const isDeactivateLoading = useSelector(getIsLoading(DEACTIVATE_USER));

  const deletionReasons = useSelector(getDeletionReasons);

  const [activeReason, setActiveReason] = useState<string | undefined>();
  const [freeTextReason, setFreeTextReason] = useState();

  const handleFreeTextReasonChange = useCallback((e) => {
    setFreeTextReason(e.target.value);
  }, []);

  useEffect(() => {
    dispatch(getDeletionReasonsRequest());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    dispatch(
      deactivateUserRequest({
        reason: activeReason,
        freeText: freeTextReason,
      }),
    );
  }, [dispatch, activeReason, freeTextReason]);

  const [deactivateFailed, setDeactivateFailed] = useState(false);

  useEffect(() => {
    // if during request any server error occurred, remember that deactivateAccount failed
    // it is required, because I trigger ErrorPopup and when it clears, all errors are cleared from state
    if (hasServerErrors && isDeactivateLoading === 1) {
      setDeactivateFailed(true);
    }

    if (!hasServerErrors && isDeactivateLoading === 1) {
      setDeactivateFailed(false);
    }
  }, [isDeactivateLoading, hasServerErrors]);

  useEffect(() => {
    if (!deactivateFailed && isDeactivateLoading === 0) {
      history.replace(Routes.DELETE_CONFIRM);
      dispatch(logout());
    }
  }, [isDeactivateLoading, history, dispatch, deactivateFailed]);

  useEffect(() => {
    if (hasServerErrors) {
      dispatch(
        displayErrorPopup(
          'Error when trying to delete the account. Please try again later',
        ),
      );
    }
  }, [hasServerErrors, dispatch]);

  return (
    <DeleteAccountLayout
      reasons={deletionReasons}
      activeReason={activeReason}
      handleSelectReason={setActiveReason}
      handleFreeTextReasonChange={handleFreeTextReasonChange}
      freeTextReason={freeTextReason}
      handleDelete={handleDelete}
      isLoading={isDeactivateLoading === 1}
    />
  );
};
