import React, { useCallback, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { AppHeader, BODY2, Container, H3 } from '@tuunetech/tuune-components';
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerContainer } from 'shared/Spinner/styles';

import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { useAnalytics } from 'utils/analytics';
import { getAccountDataRequest } from 'modules/Account/actions';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { getDealbreakerIssues, setDealbreakerIssuesRank } from '../actions';
import {
  getDealbreakerIssues as selectDealbreakerIssues,
  getDealbreakerSubmitted,
  getPageNum,
} from '../selectors';
import { Symptom } from '../types';
import { DealbreakerContainer } from '../containers/DealbreakerContainer';
import { SymptomsRankingContainer } from '../containers/SymptomsRankingContainer';
import { Page } from 'shared';

const Title = [
  'Is there one thing above all else you’d like to solve for?',
  'Rank the remaining symptoms from most important at the top to least important at the bottom.',
];

const SubTitle = [
  'Select one. If there are no dealbreakers for you, please continue.',
  'Hold and drag to rearrange.',
];

export type DealbreakerAnalyticsMethods = {
  trackDealbreakerSkipped: (whoSkipped: string) => void;
  trackDealbreakerSelected: (dealbreaker: string | null) => void;
  trackRankingSkipped: (whoSkipped: string) => void;
  trackIssuesReordered: (
    issuesRank: Symptom[],
    originalOrder: Symptom[],
  ) => void;
};

export const DealbreakerPage: React.FC = () => {
  useReportStatusRouter();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const pageNum = useSelector(getPageNum);
  const symptoms = useSelector(selectDealbreakerIssues);
  const isSubmitted = useSelector(getDealbreakerSubmitted);

  const headerButtons = useBaseNavButtons();

  useEffect(() => {
    if (isSubmitted) {
      dispatch(getAccountDataRequest());
    }
  }, [isSubmitted, dispatch]);

  useEffect(() => {
    dispatch(getDealbreakerIssues());
  }, [dispatch]);

  const trackDealbreakerSkipped = useCallback(
    (whoSkipped) => {
      analytics.track(ANALYTICS_EVENT.SKIP_DEALBREAKER, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
        whoSkipped,
      });
    },
    [analytics],
  );

  const trackDealbreakerSelected = useCallback(
    (dealbreaker) => {
      analytics.track(ANALYTICS_EVENT.SELECT_DEALBREAKER, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
        dealbreaker,
      });
    },
    [analytics],
  );

  const trackRankingSkipped = useCallback(
    (whoSkipped) => {
      analytics.track(ANALYTICS_EVENT.SKIP_ISSUES_RANK, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
        whoSkipped,
      });
    },
    [analytics],
  );

  const trackIssuesReordered = useCallback(
    (issuesRank: Symptom[], originalOrder: Symptom[]) => {
      analytics.track(ANALYTICS_EVENT.DEALBREAKER_ISSUES_REORDER, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
        originalOrder: originalOrder.map(({ slug }) => slug),
        issuesRank: issuesRank.map(({ slug }) => slug),
      });
    },
    [analytics],
  );

  const analyticsMethods = {
    trackDealbreakerSkipped,
    trackDealbreakerSelected,
    trackRankingSkipped,
    trackIssuesReordered,
  };

  useEffect(() => {
    // skip the dealbreaker and ranking
    if (symptoms && symptoms.length <= 1) {
      dispatch(
        setDealbreakerIssuesRank({
          dealbreakerIssue: {
            slug: null,
          },
          issuesRank: symptoms,
        }),
      );
    }
  }, [symptoms, dispatch]);

  return (
    <Page>
      <AppHeader buttons={[headerButtons.contactUs, headerButtons.logout]} />
      {!!symptoms && symptoms.length > 1 ? (
        <Container maxWidth="lg">
          <H3>{Title[pageNum]}</H3>
          <BODY2 $verticalMargin={32} color="textSecondary">
            {SubTitle[pageNum]}
          </BODY2>
          {pageNum === 0 ? (
            <DealbreakerContainer analytics={analyticsMethods} />
          ) : (
            <SymptomsRankingContainer analytics={analyticsMethods} />
          )}
        </Container>
      ) : (
        <Container $marginTop={100}>
          <SpinnerContainer>
            <CircularProgress color="primary" />
          </SpinnerContainer>
        </Container>
      )}
    </Page>
  );
};
