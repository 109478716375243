import { postcodeValidator } from 'postcode-validator';
import { getDefaultCountry, validatePhoneNumber } from 'utils/helpers';
import * as yup from 'yup';

export const onlinePharmacySchema = yup.object().shape({
  name: yup.string().required('Pharmacy name is a required field').max(255),
  type: yup.string().required('Pharmacy type is a required field'),
});

export const physicalPharmacySchema = yup.object().shape({
  addressLine1: yup.string().required('Address line 1 is a required field'),
  city: yup.string().required('City is a required field'),
  state: yup.string().required('State is a required field'),
  zipCode: yup.string().required('ZIP Code is a required field'),
  pharmacyPhone: yup.string().required('Pharmacy phone is a required field'),
});

export const shippingDetailsSchema = yup.object().shape({
  addressLine1: yup.string().required('Address line 1 is a required field'),
  addressLine2: yup.string(),
  city: yup.string().required('City is a required field'),
  state: yup.string().required('State is a required field'),
  country: yup.string().nullable(),
  phone: yup
    .string()
    .required('Phone number is a required field')
    .test('mobile', 'Phone number format incorrect', function (value = '') {
      return validatePhoneNumber(value);
    }),
  zipCode: yup
    .string()
    .required('ZIP Code is a required field')
    .test('postcode', 'Incorrect ZIP code', function (value = '') {
      const countryCode = getDefaultCountry().code;
      return postcodeValidator(value, countryCode);
    }),
});
