import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Page, TuuneInput } from 'shared';
import { AppHeader, Button, H3 } from '@tuunetech/tuune-components';
import Routes from 'routes';
import { StartContainer } from './styles';
import { resendActivationRequest } from 'modules/Auth/actions';
import * as yup from 'yup';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const ResendActivationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
});

export const ResendCustomEmailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = useCallback(
    ({ email }) => {
      dispatch(resendActivationRequest({ email }));
      history.push(Routes.PUBLIC_VERIFY_EMAIL);
    },
    [dispatch, history],
  );
  const { contactUs } = useBaseNavButtons();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(ResendActivationSchema),
    mode: 'onTouched',
  });

  return (
    <Page>
      <AppHeader buttons={[contactUs]} />
      <StartContainer fixed={false} maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <H3 paragraph>There was a problem with your activation link.</H3>
          <H3 paragraph>Enter your email to get a new one.</H3>
          <TuuneInput
            name="email"
            required
            placeholder="Email"
            type="email"
            inputMode="email"
            inputProps={{
              inputMode: 'email',
            }}
            control={control}
            error={!!errors.email}
            helperText={errors.email?.message}
            autoFocus
            fullWidth
          />
          <Button type="submit">send activation link</Button>
        </form>
      </StartContainer>
    </Page>
  );
};
