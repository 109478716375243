import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import {
  CLEAR_ERRORS,
  CLEAR_NOTIFICATION_POPUP,
  DISPLAY_DEFAULT_ERROR_POPUP,
  DEFAULT_ERROR_MESSAGE,
  SESSION_EXPIRED_MESSAGE,
  DISPLAY_SESSION_EXPIRED_ERROR_POPUP,
  DISPLAY_ERROR_POPUP,
  DISPLAY_ERROR_NON_FIELD,
  DISPLAY_FIELD_ERRORS,
  DISPLAY_INFO_POPUP,
} from '../constants';
import { NotificationsReducerState } from '../types';

const defaultState: NotificationsReducerState = {
  errors: {
    message: undefined,
    nonFieldBasedValidationMessage: undefined,
    fieldBasedValidationMessages: undefined,
  },
  infoMessage: undefined,
};

const notificationsReducer: Reducer<NotificationsReducerState> = (
  state = defaultState,
  { type, payload },
) => {
  switch (type) {
    case CLEAR_NOTIFICATION_POPUP:
      return {
        ...state,
        errors: undefined,
        infoMessage: undefined,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: undefined,
      };
    case DISPLAY_DEFAULT_ERROR_POPUP:
      return {
        ...state,
        errors: {
          message: DEFAULT_ERROR_MESSAGE,
        },
      };
    case DISPLAY_SESSION_EXPIRED_ERROR_POPUP:
      return {
        ...state,
        errors: {
          message: SESSION_EXPIRED_MESSAGE,
        },
      };
    case DISPLAY_ERROR_POPUP:
      return {
        ...state,
        errors: {
          message: payload.message,
        },
      };
    case DISPLAY_ERROR_NON_FIELD:
      return {
        ...state,
        errors: {
          nonFieldBasedValidationMessage: payload.message,
        },
      };
    case DISPLAY_FIELD_ERRORS:
      return {
        ...state,
        errors: {
          fieldBasedValidationMessages: camelcaseKeys(payload.messages, {
            deep: true,
          }),
        },
      };
    case DISPLAY_INFO_POPUP:
      return {
        ...state,
        infoMessage: payload.message,
      };
    default:
      return state;
  }
};

export { notificationsReducer as notifications };
