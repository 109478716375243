import React from 'react';
import { Box } from '@material-ui/core';
import { Control, FieldErrors } from 'react-hook-form';
import {
  Container,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { HAControlButtons, ErrorBox, TuuneInput } from 'shared';
import { AccountInfoFormData } from '../../../types';

export type CreateAccountProps = {
  control: Control<Partial<AccountInfoFormData>>;
  handleSubmit: () => void;
  errors: FieldErrors<Partial<AccountInfoFormData>>;
  nonFieldErrorMessage?: string;
  fieldErrorMessages?: Record<string, string>;
  isLoading?: boolean;
};

export const CreateAccount: React.FC<CreateAccountProps> = ({
  control,
  handleSubmit,
  errors,
  nonFieldErrorMessage,
  fieldErrorMessages,
  isLoading,
}) => {
  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <GridContainer justify="center">
          <GridItem xs={12} md={6}>
            <H3 paragraph>Set a password for your account:</H3>
            {!!nonFieldErrorMessage && <ErrorBox text={nonFieldErrorMessage} />}
            <TuuneInput
              name="password"
              placeholder="Password"
              control={control}
              fullWidth
              autoComplete="new-password"
              type="password"
              error={!!fieldErrorMessages?.password || !!errors.password}
              helperText={
                fieldErrorMessages?.password || errors?.password?.message
              }
            />
            <Box height="16px" />
            <TuuneInput
              name="rePassword"
              placeholder="Confirm password"
              control={control}
              fullWidth
              type="password"
              helperText={
                fieldErrorMessages?.rePassword ||
                errors?.rePassword?.message ||
                'Your password needs to be unique, contain letters and numbers, and be at least 8 characters long. Also please refrain from using words like ‘password’, your name, or any part of your email address.'
              }
              error={!!fieldErrorMessages?.rePassword || !!errors.rePassword}
            />
            <HAControlButtons
              onClick={handleSubmit}
              align="left"
              text="create account"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </GridItem>
        </GridContainer>
      </form>
    </Container>
  );
};
