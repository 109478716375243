import React, { useCallback, useEffect, useState } from 'react';
import { getIsLoading } from 'modules/Loading/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import ResultsLayout from '../components/ResultsLayout';
import { getRecommendationResultsRequest } from '../actions';
import {
  GET_RECOMMENDATION_RESULTS,
  HOW_TO_START_SUMMARY_SLUG,
  OC_SUITABILITY_EXPLANATOIN_SLUGS,
  OC_SUITABILITY_SLUGS,
  RECOMMENDATION_INTRO_SLUGS,
  REPORT_STATIC_DATA_SLUGS,
  ReportContentfulKeys as RCK,
  ReportStatus,
} from '../constants';
import {
  getAllAssessmentData,
  getAssessmentData,
  getRecommendations,
  getRecommendationType,
  getReportStatus,
} from '../selectors';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getEntries } from 'modules/Contentful/actions';
import { getInitialAuthMethod } from 'modules/Auth/selectors';
import { AuthMethod } from 'modules/Auth/constants/authMethod';
import { useResultsLoading } from '../hooks/useResultsLoading';
import { LoadingPage } from 'shared/LoadingPage';
import { useMandatoryChange } from '../hooks/useMandatoryChange';
import { useLoadSymptoms } from '../hooks/useLoadSymptoms';
import { getHasAnyErrorMessages } from 'modules/NotificationsHandler/selectors';

export const ResultsPage: React.FC = () => {
  useReportStatusRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = useAnalytics();
  const initialAuthMethod = useSelector(getInitialAuthMethod);
  const reportStatus = useSelector(getReportStatus);

  const isRecommendationLoading = useSelector(
    getIsLoading(GET_RECOMMENDATION_RESULTS),
  );
  const assessmentData = useSelector(getAssessmentData);
  const allAssessmentData = useSelector(getAllAssessmentData);
  const isSymptomsLoaded = useLoadSymptoms(
    assessmentData,
    isRecommendationLoading,
  );
  const isServerErrors = useSelector(getHasAnyErrorMessages);
  const recommendations = useSelector(getRecommendations);
  const isRecommendedRegimenSummaryLoading = useSelector(
    getIsLoading(RCK[CTN.RECOMMENDED_REGIMEN_SUMMARY].loadingId),
  );
  const recommendationGroup = useSelector(getRecommendationType);
  const isRecommendedConsiderationsSummaryLoading = useSelector(
    getIsLoading(RCK[CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY].loadingId),
  );
  const isSwitchingContraceptionPreviewLoading = useSelector(
    getIsLoading(RCK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC].loadingId),
  );
  const isStaticDataLoading = useSelector(
    getIsLoading(RCK[CTN.STATIC_CONTENT].loadingId),
  );
  const isEligibilityExplainLoading = useSelector(
    getIsLoading(RCK[CTN.CHC_UNSUITABILITY_EXPLANATION].loadingId),
  );
  const isSymptomsIssueSnippetSpecificLoading = useSelector(
    getIsLoading(RCK[CTN.ISSUE_SNIPPET_SPECIFIC].loadingId),
  );
  const isOcSuitabilityDataLoading = useSelector(
    getIsLoading(RCK[CTN.OC_SUITABILITY].loadingId),
  );
  const isOcSuitabilityExplanationDataLoading = useSelector(
    getIsLoading(RCK[CTN.OC_SUITABILITY_EXPLANATION].loadingId),
  );
  const isRecommendationIntroDataLoading = useSelector(
    getIsLoading(RCK[CTN.RECOMMENDATION_INTRO].loadingId),
  );
  const isIssueSnippetGenericLoading = useSelector(
    getIsLoading(RCK[CTN.ISSUE_SNIPPET_GENERIC].loadingId),
  );
  const isImpactScoreLoading = useSelector(
    getIsLoading(RCK[CTN.IMPACT_SCORE].loadingId),
  );
  const isImpactScoreExplainedLoading = useSelector(
    getIsLoading(RCK[CTN.IMPACT_SCORE_EXPLAINED].loadingId),
  );
  const isIssueIconsLoading = useSelector(
    getIsLoading(RCK[CTN.ISSUE_ICON].loadingId),
  );
  const isBenefitDetailsLoading = useSelector(
    getIsLoading(RCK[CTN.CONTRACEPTION_DETAIL_PERSONALISED].loadingId),
  );
  const isAdditionalInfoLoading = useSelector(
    getIsLoading(RCK[CTN.CONTRACEPTION_ADDITIONAL_INFORMATION].loadingId),
  );

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT.RECOMMENDATION_REPORT_LOADING_ERROR, {
      category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
    });
  }, [isServerErrors, history, , analytics]);

  useEffect(() => {
    if (initialAuthMethod === AuthMethod.JwtCreate) {
      analytics.track(ANALYTICS_EVENT.RECOMMENDATION_RETURNED_TO_VIEW_REPORT, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      });
    }
  }, [initialAuthMethod, analytics]);

  const [HAMandatoryChange, setHAMandatoryChange] = useState(false);

  useEffect(() => {
    if (isRecommendationLoading === 0) {
      if (reportStatus === ReportStatus.MANDATORY_CHANGE) {
        setHAMandatoryChange(true);
      }
    }
  }, [reportStatus, isRecommendationLoading]);

  useEffect(() => {
    // fetch data from backend
    if (isRecommendationLoading === undefined) {
      dispatch(getRecommendationResultsRequest());
    }

    if (isOcSuitabilityDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.OC_SUITABILITY],
          slugs: Object.values(OC_SUITABILITY_SLUGS),
        }),
      );
    }

    if (isRecommendationIntroDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.RECOMMENDATION_INTRO],
          slugs: Object.values(RECOMMENDATION_INTRO_SLUGS),
        }),
      );
    }

    // request static layout data
    if (isStaticDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.STATIC_CONTENT],
          slugs: Object.values(REPORT_STATIC_DATA_SLUGS),
        }),
      );
    }

    // request static layout data
    if (isOcSuitabilityExplanationDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.OC_SUITABILITY_EXPLANATION],
          slugs: Object.values(OC_SUITABILITY_EXPLANATOIN_SLUGS),
        }),
      );
    }

    if (isRecommendationLoading === 0) {
      const vmpSlugs = recommendations.map(({ slug }) => slug);
      const additionalInfoSlugs = recommendations.length
        ? recommendations[0].additionalInformation.map(({ slug }) => slug)
        : undefined;
      if (isAdditionalInfoLoading === undefined && additionalInfoSlugs) {
        const slugs = recommendations
          .map((recommendation) =>
            additionalInfoSlugs.map((slug) => `${slug}-${recommendation.slug}`),
          )
          .flat();
        dispatch(
          getEntries({
            ...RCK[CTN.CONTRACEPTION_ADDITIONAL_INFORMATION],
            slugs: slugs,
          }),
        );
      }
      if (isBenefitDetailsLoading === undefined) {
        const issuesData = [
          ...assessmentData.conditions,
          ...assessmentData.symptomsHormonal,
          ...assessmentData.symptomsUnknown,
        ];

        const slugs: string[] = [];

        issuesData.forEach(({ slug }) => {
          slugs.push(slug);
        });

        const benefitDetailSlugs = recommendations
          .map((recommendation) =>
            slugs.map((issueSlug) => `${issueSlug}-${recommendation.slug}`),
          )
          .flat();

        dispatch(
          getEntries({
            ...RCK[CTN.CONTRACEPTION_DETAIL_PERSONALISED],
            slugs: benefitDetailSlugs,
          }),
        );
      }

      if (isEligibilityExplainLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.CHC_UNSUITABILITY_EXPLANATION],
            slugs: [recommendationGroup],
          }),
        );
      }

      // request recommended regimen summary
      if (isRecommendedRegimenSummaryLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.RECOMMENDED_REGIMEN_SUMMARY],
            slugs: vmpSlugs,
          }),
        );
      }

      // request considerations summary
      if (isRecommendedConsiderationsSummaryLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY],
            slugs: vmpSlugs,
          }),
        );
      }

      // request switching contraception summary
      if (isSwitchingContraceptionPreviewLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC],
            slugs: [HOW_TO_START_SUMMARY_SLUG],
          }),
        );
      }

      if (isIssueIconsLoading === undefined) {
        const issueSlugs = allAssessmentData.map((issue) => issue.slug);
        dispatch(
          getEntries({
            ...RCK[CTN.ISSUE_ICON],
            slugs: issueSlugs,
          }),
        );
      }
    }
  }, [
    dispatch,
    isRecommendationLoading,
    recommendations,
    isRecommendedRegimenSummaryLoading,
    isRecommendedConsiderationsSummaryLoading,
    recommendationGroup,
    isSwitchingContraceptionPreviewLoading,
    isStaticDataLoading,
    assessmentData,
    isEligibilityExplainLoading,
    isSymptomsIssueSnippetSpecificLoading,
    isOcSuitabilityDataLoading,
    isOcSuitabilityExplanationDataLoading,
    isRecommendationIntroDataLoading,
    isImpactScoreExplainedLoading,
    isImpactScoreLoading,
    isIssueSnippetGenericLoading,
    isIssueIconsLoading,
    isBenefitDetailsLoading,
    allAssessmentData,
    isAdditionalInfoLoading,
  ]);

  const isDataLoading = !(
    isRecommendationLoading === 0 &&
    isStaticDataLoading === 0 &&
    isEligibilityExplainLoading === 0 &&
    isRecommendedRegimenSummaryLoading === 0 &&
    isRecommendedConsiderationsSummaryLoading === 0 &&
    isSwitchingContraceptionPreviewLoading === 0 &&
    isOcSuitabilityDataLoading === 0 &&
    isOcSuitabilityExplanationDataLoading === 0 &&
    isRecommendationIntroDataLoading === 0 &&
    isBenefitDetailsLoading === 0 &&
    isSymptomsLoaded
  );

  const [isReady, setIsReady] = useState<boolean>(false);
  const onReadyButtonClickCallback = useCallback(() => {
    setIsReady(true);
  }, []);
  const onRetryButtonClickCallback = useCallback(() => {
    // want to trigger a full refresh so that we try and fetch all the data from the server again
    window.location.reload();
  }, []);

  const resultsLoadingData = useResultsLoading({
    isDataLoading,
    onReadyButtonClickCallback,
    onRetryButtonClickCallback,
    isServerErrors,
  });

  const mandatoryChangeData = useMandatoryChange();

  return (
    <>
      {isReady ? (
        <ResultsLayout />
      ) : (
        <LoadingPage
          {...(HAMandatoryChange ? mandatoryChangeData : resultsLoadingData)}
        />
      )}
    </>
  );
};
