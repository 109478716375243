import {
  getUserAccessTokenRequest,
  logoutClientOnly,
} from 'modules/Auth/actions';
import {
  GET_USER_ACCESS_TOKEN_ERROR,
  GET_USER_ACCESS_TOKEN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS,
  OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS,
  OAUTH_LOGIN_WITH_AUTH_CODE_ERROR,
} from 'modules/Auth/constants';
import {
  CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS,
  REGISTER_TO_QUESTIONNAIRE_SUCCESS,
} from 'modules/Questionnaire/constants';
import { RootState } from 'reducers';
import { AnyAction, Middleware } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import api, { RequestActions } from 'utils/api';
import authStorage from 'utils/authStorage';
import { setToStorage } from 'utils/helpers';
import request from 'utils/request';
import {
  displayDefaultErrorPopup,
  displaySessionExpiredErrorPopup,
  displayErrorPopup,
  displayNonFieldError,
  displayFieldErrors,
  clearErrors,
} from 'modules/NotificationsHandler/actions';

const apiRequestMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action): Promise<AnyAction> => {
    if (action.type.split('_').pop() !== 'REQUEST') return next(action);

    const { type, payload } = action;

    const requestConfig = api[type as RequestActions](payload);

    const accessToken = authStorage.accessToken;
    if (accessToken && requestConfig.headers) {
      requestConfig.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    if (type.includes('_REQUEST')) {
      dispatch(clearErrors());
    }

    // allow REQUEST action go forward till reducer
    // to catch the moment that REQUEST was initiated
    next(action);

    try {
      // dispatch RESPONSE action if request was successful
      const response = await request(requestConfig);
      return dispatch({
        type: type.replace('_REQUEST', '_RESPONSE'),
        payload,
        response,
      });
    } catch (error) {
      // dispatch ERROR action if request failed
      return dispatch({
        type: type.replace('_REQUEST', '_ERROR'),
        payload,
        error,
      });
    }
  };

const apiProcessResponseMiddleware: Middleware<
  unknown,
  unknown,
  ThunkDispatch<RootState, unknown, AnyAction>
> =
  ({ dispatch }) =>
  (next) =>
  (action): Promise<AnyAction> | void => {
    if (
      action.type.split('_').pop() !== 'RESPONSE' &&
      action.type.split('_').pop() !== 'ERROR'
    )
      return next(action);

    // RESPONSE and ERROR actions won't come to the reducer
    // they are changed to the SUCCESS and FAIL types and format known for reducers
    const { type, payload, response, error } = action;
    if (response) {
      const nextAction = {
        response,
        type: type.replace('_RESPONSE', '_SUCCESS'),
        payload,
      };
      dispatch(nextAction);
    } else if (error) {
      const response = {
        status: '',
        message: error.message,
        ...error.response,
      };

      switch (response.status) {
        case 500:
          dispatch(displayDefaultErrorPopup());
          break;
        case 401:
          if (type === GET_USER_ACCESS_TOKEN_ERROR) {
            dispatch(displaySessionExpiredErrorPopup());
            dispatch(logoutClientOnly());
          } else if (
            type === LOGIN_ERROR ||
            type === OAUTH_LOGIN_WITH_AUTH_CODE_ERROR
          ) {
            dispatch(displayErrorPopup(response.data?.detail));
          } else {
            dispatch(
              getUserAccessTokenRequest({
                ...action,
                type: action.type.replace('_ERROR', '_REQUEST'),
              }),
            );
          }
          break;
        case 403:
          response.data?.detail
            ? dispatch(displayErrorPopup(response.data?.detail))
            : dispatch(displayDefaultErrorPopup());
          break;
        case 400:
          if (response.data?.detail) {
            dispatch(displayErrorPopup(response.data?.detail));
          } else if (response.data?.non_field_errors) {
            dispatch(
              displayNonFieldError(response.data?.non_field_errors.join('\n')),
            );
          } else {
            dispatch(displayFieldErrors(response.data));
          }
          break;
        default:
          dispatch(displayDefaultErrorPopup());
      }

      dispatch({ response, type: type.replace('_ERROR', '_FAIL'), payload });
    }
  };

const apiSetTokenMiddleware: Middleware =
  () =>
  (next) =>
  (action): void => {
    // list all actions that give a token
    const { type, response } = action;
    if (
      type !== GET_USER_ACCESS_TOKEN_SUCCESS &&
      type !== LOGIN_SUCCESS &&
      type !== REGISTER_TO_QUESTIONNAIRE_SUCCESS &&
      type !== CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS &&
      type !== OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS &&
      type !== OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS
    )
      return next(action);

    // set token
    authStorage.accessToken = response.data.access;
    next(action);
  };

const apiRetryRequest: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action): void => {
    const { type, payload } = action;
    if (type !== GET_USER_ACCESS_TOKEN_SUCCESS) return next(action);

    // retry request
    dispatch(payload.action);
  };

const apiSetLoginStatusToStorage: Middleware =
  () =>
  (next) =>
  async (action): Promise<AnyAction | void> => {
    if (
      action.type !== LOGIN_SUCCESS &&
      action.type !== REGISTER_TO_QUESTIONNAIRE_SUCCESS &&
      action.type !== CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS &&
      action.type !== OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS &&
      action.type !== OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS
    )
      return next(action);

    await setToStorage('isLoggedIn', true);
    next(action);
  };

export {
  apiRequestMiddleware,
  apiProcessResponseMiddleware,
  apiSetTokenMiddleware,
  apiRetryRequest,
  apiSetLoginStatusToStorage,
};
