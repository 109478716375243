import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Ring: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3333 14.5C20.3333 17.7217 17.7217 20.3333 14.5 20.3333C11.2783 20.3333 8.66667 17.7217 8.66667 14.5C8.66667 11.2783 11.2783 8.66667 14.5 8.66667C17.7217 8.66667 20.3333 11.2783 20.3333 14.5ZM14.5 11C16.433 11 18 12.567 18 14.5C18 16.433 16.433 18 14.5 18C12.567 18 11 16.433 11 14.5C11 12.567 12.567 11 14.5 11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 14.5C25 20.299 20.299 25 14.5 25C8.70101 25 4 20.299 4 14.5C4 8.70101 8.70101 4 14.5 4C20.299 4 25 8.70101 25 14.5ZM14.5 6.33333C19.0103 6.33333 22.6667 9.98968 22.6667 14.5C22.6667 19.0103 19.0103 22.6667 14.5 22.6667C9.98968 22.6667 6.33333 19.0103 6.33333 14.5C6.33333 9.98967 9.98968 6.33333 14.5 6.33333Z"
    />
  </SvgIcon>
);
