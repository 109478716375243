import styled from 'styled-components';

export const Indicator = styled.div`
  font-size: 12px;
  font-weight: 700;

  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.purple[900]};

  width: 24px;
  height: 24px;
  min-width: 24px;
  padding-top: 4px;

  border-radius: 50%;
  text-align: center;
`;
