import styled from 'styled-components';
import {
  Paper as BasePaper,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import { BODY1, H5 } from '@tuunetech/tuune-components';

export const Paper = styled(BasePaper)`
  border-radius: 32px;
  padding: 40px 24px;
  max-width: 390px;

  .MuiDialogContent-root {
    margin: 0;
    padding: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-row-gap: 32px;

  ${BODY1}, ${H5} {
    text-align: center;
  }
`;
