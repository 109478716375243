import {
  OAuthProvider,
  OAUTH_CONFIGS,
} from 'modules/Auth/constants/socialAuth';
import { EcommerceProduct } from 'utils/analytics';
import { EnhancedEcommerce } from 'utils/analytics/enhancedEcommerce';

export const oAuthRedirect = (
  oAuthProvider: OAuthProvider,
  state?: Record<string, string | boolean | number | string[]>,
): void => {
  const oAuthConfig = OAUTH_CONFIGS[oAuthProvider];
  const urlParams = new URLSearchParams();

  urlParams.set('client_id', oAuthConfig.clientId);
  urlParams.set(
    'redirect_uri',
    window.location.origin + oAuthConfig.redirectUri,
  );

  if (oAuthConfig.responseType) {
    urlParams.set('response_type', oAuthConfig.responseType);
  }
  if (oAuthConfig.scope) {
    urlParams.set('scope', oAuthConfig.scope);
  }
  if (state) {
    urlParams.set('state', btoa(JSON.stringify(state)));
  }

  const url = oAuthConfig.authServerUrl + '?' + urlParams.toString();

  window.location.href = url;
};

export const redirectToPaymentUrl = (
  signupPaymentUrl: string,
  product: EcommerceProduct,
): void => {
  EnhancedEcommerce.logBeginCheckoutEvent(product);
  window.location.href = signupPaymentUrl;
};
