import React from 'react';
import { theme } from '@tuunetech/tuune-components';
import { CivicCookieCategory } from './types';
import { CookieConsentEventTypes, COOKIE_CATEGORY_NAMES } from './constants';
import { getAppPrivacyPolicyUrl } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const ANALYTICS: CivicCookieCategory = {
  name: COOKIE_CATEGORY_NAMES.ANALYTICS,
  label: 'Analytics',
  description: `We use some cookies to collect information about how people are using our Services.
                  For example, these cookies allow us to know which pages are visited the most often, how people are moving from one link to another and if they get error messages from certain pages. Overall, these cookies provide us with analytical information about how our Services are performing and how we can improve them.`,
  cookies: ['_ga', '_gid', '_gat*', 'amp*'],
  vendors: [
    {
      name: 'Google',
      url: 'https://policies.google.com/technologies/types?hl=en-US',
      description: 'Google Analytics',
    },
  ],
  onAccept: function () {
    if (window.dataLayer) {
      window.dataLayer.push({
        civic_cookies_analytics: 'consent_given',
        event: 'analytics_consent_given',
      });
    }
    if (window.CookiesConsentEventTarget) {
      window.CookiesConsentEventTarget.dispatchEvent(
        new CustomEvent(CookieConsentEventTypes.AnalyticsGranted),
      );
    }
  },
  onRevoke: function () {
    if (window.dataLayer) {
      window.dataLayer.push({
        civic_cookies_analytics: 'consent_revoked',
        event: 'analytics_consent_revoked',
      });
    }
    if (window.CookiesConsentEventTarget) {
      window.CookiesConsentEventTarget.dispatchEvent(
        new CustomEvent(CookieConsentEventTypes.AnalyticsRevoked),
      );
    }
  },
};

const MARKETING: CivicCookieCategory = {
  name: COOKIE_CATEGORY_NAMES.MARKETING,
  label: 'Marketing',
  description: `These show us which products you’ve been looking at so we can show you ads you might like later.`,
  cookies: ['fr'],
  vendors: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/policies/cookies/',
      description: 'Facebook',
    },
  ],
  // Facebook & Hotjar scripts are handled by Google Tag Manager,
  // therefore notify GTM on consent greanted / denied events
  onAccept: function () {
    if (window.dataLayer) {
      window.dataLayer.push({
        civic_cookies_marketing: 'consent_given',
        event: 'marketing_consent_given',
      });
    }
  },
  onRevoke: function () {
    if (window.dataLayer) {
      window.dataLayer.push({
        civic_cookies_marketing: 'consent_revoked',
        event: 'marketing_consent_revoked',
      });
    }
  },
};

const optionalCookies: CivicCookieCategory[] = [];

if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
  // respect do not track option
  if (!(navigator.doNotTrack == '1' || window.doNotTrack == '1')) {
    optionalCookies.push(ANALYTICS);
    optionalCookies.push(MARKETING);
  }
}

export function isConsentGranted(category: string): boolean {
  const categoryIndex = optionalCookies.findIndex(
    (element) => element.name === category,
  );
  if (!categoryIndex) {
    return false;
  }
  return window.CookieControl.getCategoryConsent(categoryIndex);
}

export const CIVIC_COOKIE_CONFIG = {
  apiKey: process.env.REACT_APP_CIVIC_API_KEY,
  product: 'PRO',
  optionalCookies: optionalCookies,
  excludedCountries: 'all',
  mode: 'CCPA',

  statement: {
    description: 'For more information visit our',
    name: 'Privacy Policy',
    url: getAppPrivacyPolicyUrl(),
    updated: '22/10/2021',
  },
  ccpaConfig: {
    description:
      'This may include the website or our third-party tools processing personal data. In the case of your personal data, you may opt out of such activity by using the buttons below. To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our',
    name: 'Privacy Policy',
    url: getAppPrivacyPolicyUrl(),
    updated: '22/10/2021',
  },

  initialState: 'notify',
  position: 'LEFT',
  theme: 'DARK',
  notifyDismissButton: false,
  branding: {
    fontFamily: theme.typography.fontFamily,
    fontColor: theme.palette.common.white,
    notifyFontColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.purple[900],
    alertBackground: theme.palette.common.purple[900],
  },
};

const CIVIC_SCRIPT = (
  <script
    key="civic_source"
    src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
  />
);

const GOOGLE_TAG_MANAGER_SCRIPT = (
  <script key="gtm_source">
    {`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
       new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
       j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
       'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
       })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM}');
     `}
  </script>
);

// This is a non-essential script and since it's loaded
// into the app's <head>, we load it with 'defer' for
// speeding up the page load
const YOUTUBE_IFRAME_API_SCRIPT = (
  <script
    key="youtube_source"
    src="https://www.youtube.com/iframe_api"
    defer
  ></script>
);

export const COOKIE_SCRIPTS = [
  CIVIC_SCRIPT,
  GOOGLE_TAG_MANAGER_SCRIPT,
  YOUTUBE_IFRAME_API_SCRIPT,
];
