import React from 'react';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';

export const AppFixedFooter: React.FC = ({ children }) => (
  <AppBar position="fixed" style={{ top: 'auto', bottom: 0 }} color="inherit">
    <Toolbar disableGutters>
      <GridContainer justify="center">
        <GridItem xs={10} xl={8}>
          <Container $verticalMargin={16}>
            <Box display="flex">{children}</Box>
          </Container>
        </GridItem>
      </GridContainer>
    </Toolbar>
  </AppBar>
);
