import { amplitudeLogout } from '.';
import { amplitudeIdentify, logAmplitudeEvent } from './amplitude';
import { ANALYTICS_EVENT } from './constants';
import { logGTMEvent } from './gtm';
import { filterGTMAnalyticsEvent } from './helpers';
import { Analytics, AnalyticsTrackData, AnalyticsUserData } from './types';

export const analytics: Analytics = {
  track: (
    event: ANALYTICS_EVENT,
    data: AnalyticsTrackData,
    email?: string,
  ): void => {
    const internal = email?.includes('tuune.com');
    // On prod environment, filter all the events logged for @tuune.com accounts
    const allowTracking =
      process.env.REACT_APP_ENV !== 'prod' ? true : !internal;
    if (allowTracking) {
      logAmplitudeEvent(event, data);
      logGTMEvent(event, filterGTMAnalyticsEvent(event, data));
    }
  },

  identify: (userId?: string | null, data?: AnalyticsUserData) => {
    amplitudeIdentify(userId, data);
  },

  logout: () => {
    amplitudeLogout();
  },
};
