import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getEntries } from 'modules/Contentful/actions';

import { ContentfulHeaderSectionLayout } from 'modules/Questionnaire/components/ContentfulHeaderSectionLayout';
import { Box, CircularProgress } from '@material-ui/core';
import { getIsLoading } from 'modules/Loading/selectors';
import { ContentKeys } from 'modules/Questionnaire/constants/contentfulHeaderSection';

const ContentfulHeaderSectionContainer: React.FC<{
  contentfulSlug: string;
}> = ({ contentfulSlug }) => {
  const dispatch = useDispatch();
  const isContentLoading = useSelector(
    getIsLoading(ContentKeys[CTN.CONTENTFUL_HEADER_SECTION].loadingId),
  );

  useEffect(() => {
    dispatch(
      getEntries({
        ...ContentKeys[CTN.CONTENTFUL_HEADER_SECTION],
        slugs: [contentfulSlug],
      }),
    );
  }, [dispatch, contentfulSlug]);

  return isContentLoading !== 0 ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100px"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <ContentfulHeaderSectionLayout contentfulSlug={contentfulSlug} />
  );
};

export default ContentfulHeaderSectionContainer;
