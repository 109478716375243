import React from 'react';
import {
  useMediaQuery,
  useTheme,
  MenuItem,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Button, H3, BODY1 } from '@tuunetech/tuune-components';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { useWebpImage } from 'utils/image';
import { US_STATES } from 'utils/usStates';
import { PharmacyContainer, Input, Select } from './styles';
import { OnlinePharmacyFormData, PhysicalPharmacyFormData } from '../../types';
import { ErrorBox } from 'shared';
import { AuthLayoutImg } from 'shared/AuthLayout/styles';

type PharmacyForm<T extends FieldValues> = {
  control: Control<T>;
  handleSubmit: () => void;
  errors: FieldErrors<T>;
  isValid: boolean;
};

export type PharmacyProps = {
  pharmacyType: string;
  onlinePharmacyForm: PharmacyForm<OnlinePharmacyFormData>;
  physicalPharmacyForm: PharmacyForm<PhysicalPharmacyFormData>;
  nonFieldErrorMessage?: string;
  isLoading: boolean;
};
const O_PHARMACY = 'Online pharmacy';
const P_PHARMACY = 'Physical pharmacy';

export const Pharmacy: React.FC<PharmacyProps> = ({
  onlinePharmacyForm: onlinePForm,
  physicalPharmacyForm: physicalPForm,
  nonFieldErrorMessage,
  isLoading,
  pharmacyType,
}) => {
  const [PharmacyImg] = useWebpImage('pharmacy.jpg');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const renderSelectValueWithPlaceholder = (placeholder: string) => {
    const renderSelectValue = (selected: unknown) => {
      if (!selected) {
        return <BODY1 color="textSecondary">Select {placeholder}</BODY1>;
      } else {
        return <BODY1>{selected}</BODY1>;
      }
    };
    return renderSelectValue;
  };

  return (
    <PharmacyContainer>
      <Box width="630px" maxWidth="100%" marginX="auto">
        <AuthLayoutImg src={PharmacyImg} />
        <H3 $marginTop={40} $marginBottom={16}>
          Which pharmacy would you like to pick up your birth control from?
        </H3>
        <BODY1 $marginBottom={40}>
          Please get in touch with your pharmacy to check insurance coverage, or
          any costs before requesting a prescription.
        </BODY1>
        {!!nonFieldErrorMessage && (
          <Box marginTop="40px" marginBottom="16px">
            <ErrorBox text={nonFieldErrorMessage} />
          </Box>
        )}
        <form onSubmit={onlinePForm.handleSubmit}>
          <Input
            name="name"
            placeholder="Pharmacy name *"
            control={onlinePForm.control}
            fullWidth
            helperText={onlinePForm.errors.name?.message}
            error={!!onlinePForm.errors.name}
          />
          <Controller
            name="type"
            control={onlinePForm.control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Select
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  renderValue={renderSelectValueWithPlaceholder(
                    'Select pharmacy type *',
                  )}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  helperText={onlinePForm.errors.type?.message}
                  error={!!onlinePForm.errors.type}
                >
                  {[P_PHARMACY, O_PHARMACY].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </form>
        {pharmacyType === P_PHARMACY && (
          <form onSubmit={physicalPForm.handleSubmit}>
            <Input
              name="addressLine1"
              placeholder="Address *"
              control={physicalPForm.control}
              fullWidth
              helperText={
                physicalPForm.errors.addressLine1
                  ? physicalPForm.errors.addressLine1?.message
                  : 'Street address, P.O. box, company name'
              }
              error={!!physicalPForm.errors.addressLine1}
            />
            <Input
              name="city"
              placeholder="City *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.city?.message}
              error={!!physicalPForm.errors.city}
            />
            <Controller
              name="state"
              control={physicalPForm.control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => {
                const SelectorOptions = !!value
                  ? [...[{ abbreviation: 'None' }], ...US_STATES]
                  : US_STATES;
                return (
                  <Select
                    fullWidth
                    variant="outlined"
                    displayEmpty
                    renderValue={renderSelectValueWithPlaceholder('State *')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    helperText={physicalPForm.errors.state?.message}
                    error={!!physicalPForm.errors.state}
                  >
                    {SelectorOptions.map(({ abbreviation }) => (
                      <MenuItem
                        key={abbreviation}
                        value={
                          abbreviation === 'None' ? undefined : abbreviation
                        }
                      >
                        {abbreviation}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
            <Input
              name="zipCode"
              placeholder="ZIP code *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.zipCode?.message}
              error={!!physicalPForm.errors.zipCode}
            />
            <Input
              name="pharmacyPhone"
              placeholder="Pharmacy phone *"
              control={physicalPForm.control}
              fullWidth
              helperText={physicalPForm.errors.pharmacyPhone?.message}
              error={!!physicalPForm.errors.pharmacyPhone}
            />
          </form>
        )}
        <Button
          fullWidth={isMobile}
          onClick={
            pharmacyType === O_PHARMACY
              ? onlinePForm.handleSubmit
              : physicalPForm.handleSubmit
          }
          // disable button logic:
          // if type is online - disable if it's not valid;
          // if type is physical - disable if any of two forms is not valid
          disabled={
            isLoading ||
            (pharmacyType === O_PHARMACY
              ? !onlinePForm.isValid
              : !physicalPForm.isValid || !onlinePForm.isValid)
          }
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          confirm pharmacy
        </Button>
      </Box>
    </PharmacyContainer>
  );
};
