import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';
import { getAccountDataRequest } from 'modules/Account/actions';
import { GET_ACCOUNT_DATA } from 'modules/Account/constants';
import { getIsLoading } from 'modules/Loading/selectors';
import QuestionLayout from '../components/QuestionLayout';
import { FeedbackAnswer } from '../components/Answers';
import { getFeedbackSubmitted, getHasFeedback } from '../selectors';
import { getFeedbackRequest, submitFeedbackRequest } from '../actions';
import { SUBMIT_FEEDBACK } from '../constants';

const DATA = {
  questionTitle:
    'Before we finish, is there something else we should know about we didn’t bring up?',
  subTitle:
    'Write your other concerns here and we’ll get back to you as soon as possible!',
};

const FeedbackPage: React.FC = () => {
  useReportStatusRouter();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const [feedback, setFeedback] = useState('');
  const isFeedbackSubmitLoading = useSelector(getIsLoading(SUBMIT_FEEDBACK));
  const isAccountDataLoading = useSelector(getIsLoading(GET_ACCOUNT_DATA));
  const isLoading = isFeedbackSubmitLoading === 1 || isAccountDataLoading === 1;

  const isSubmitted = useSelector(getFeedbackSubmitted);
  const hasFeedback = useSelector(getHasFeedback);

  const handleSubmit = useCallback(() => {
    if (feedback) {
      analytics.track(ANALYTICS_EVENT.REPORT_FEEDBACK_TEXT, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
      });
    } else {
      analytics.track(ANALYTICS_EVENT.REPORT_NO_TEXT_FEEDBACK, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
      });
    }

    dispatch(submitFeedbackRequest(feedback || null));
  }, [feedback, dispatch, analytics]);

  const handleFeedbackChange = useCallback((fb: string) => {
    setFeedback(fb);
  }, []);

  useEffect(() => {
    if (isSubmitted || hasFeedback) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, isSubmitted, hasFeedback]);

  useEffect(() => {
    dispatch(getFeedbackRequest());
  }, [dispatch]);

  return (
    <QuestionLayout
      removeAuthButton
      questionData={DATA}
      customAnswer={
        <FeedbackAnswer
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          feedback={feedback}
          handleFeedbackChange={handleFeedbackChange}
        />
      }
    />
  );
};

export default FeedbackPage;
