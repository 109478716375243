import styled from 'styled-components';

export const AuthLayoutImg = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 40px;

  height: 190px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 240px;
  }
`;
