export enum SignupType {
  birthControl = 'birth-control-recommendation',
  hormoneTest = 'hormone-test',
  birthControlAndHormoneTest = 'hormonal-wellbeing',
}

export const SignupTypeUrlParams = {
  birthControl: 'birth-control',
  hormoneTest: 'hormone-test',
  birthControlAndHormoneTest: 'hormonal-wellbeing',
};

export const SIGNUP_TYPE_URL_PARAMS_MAPPING = {
  [SignupTypeUrlParams.birthControl]: SignupType.birthControl,
  [SignupTypeUrlParams.hormoneTest]: SignupType.hormoneTest,
  [SignupTypeUrlParams.birthControlAndHormoneTest]:
    SignupType.birthControlAndHormoneTest,
};
