import {
  REPORT_UNLOCK_REQUEST,
  SUBMIT_PRESCRIPTION_ORDER_REQUEST,
  GET_PHARMACY_DETAILS_REQUEST,
  RESEARCH_AUTO_APPROVE_REQUEST,
} from 'modules/Checkout/constants';
import {
  ReportUnlockPayload,
  PrescriptionOrderPayload,
  GetPharmacyDetailsPayload,
  ResearchAutoApprovePayload,
} from 'modules/Checkout/types';
import { CheckoutRequestActionTypes } from 'modules/Checkout/types';
import { ApiHost } from '../axios';
import { ApiReturnFunc } from './index';

const checkoutApi: Record<CheckoutRequestActionTypes, ApiReturnFunc> = {
  [REPORT_UNLOCK_REQUEST]: ({
    acceptShareReportWithClinician,
    acceptShareReportWithProviders,
    promoCode,
  }: ReportUnlockPayload) => {
    return {
      url: `${ApiHost}/api/v1/ordering/report_unlock_payment/`,
      method: 'post',
      headers: {},
      data: {
        accept_share_report_with_clinician: acceptShareReportWithClinician,
        accept_share_data_back: acceptShareReportWithProviders,
        promo_code: promoCode,
      },
    };
  },
  [RESEARCH_AUTO_APPROVE_REQUEST]: ({
    unlockCode,
  }: ResearchAutoApprovePayload) => {
    return {
      url: `${ApiHost}/api/v1/research_auto_approve`,
      method: 'post',
      headers: {},
      data: {
        unlock_code: unlockCode,
      },
    };
  },
  [SUBMIT_PRESCRIPTION_ORDER_REQUEST]: ({
    ampId,
    name,
    addressLine1,
    city,
    state,
    zipCode,
    pharmacyPhone,
  }: PrescriptionOrderPayload) => {
    return {
      url: `${ApiHost}/api/v1/ordering/prescription_order/`,
      method: 'post',
      headers: {},
      data: {
        ampp_id: ampId,
        pharmacy_name: name,
        address_line_1: addressLine1,
        city: city,
        state: state,
        zip_code: zipCode,
        phone: pharmacyPhone,
      },
    };
  },
  [GET_PHARMACY_DETAILS_REQUEST]: ({ uuid }: GetPharmacyDetailsPayload) => {
    return {
      url: `${ApiHost}/api/v1/ordering/prescription_order/${uuid}`,
      method: 'get',
      headers: {},
    };
  },
};

export default checkoutApi;
