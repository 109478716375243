import styled from 'styled-components';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

export const BannerImg = styled.img`
  width: 100%;
  border-radius: 40px;
`;

export const ClinicalReviewInfoContainer = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.common.yellow[50]};
`;

export const HelpCircleIcon = styled(HelpRoundedIcon)`
  fill: ${({ theme }) => theme.palette.common.yellow[500]};
  font-size: 1.2rem;
`;
