import React, { useCallback, useEffect } from 'react';
import { AppHeader } from '@tuunetech/tuune-components';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { ShippingAddressContainer } from '../containers/ShippingAddressContainer';
import Routes from 'routes';
import { useHistory } from 'react-router';
import { HAGoBackContext } from 'modules/Questionnaire/constants';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';
import { Page } from 'shared';

export const HTShippingAddressPage: React.FC = () => {
  const history = useHistory();
  const analytics = useAnalytics();

  const handleGoBack = useCallback(() => {
    history.replace(Routes.HOME);
  }, [history]);
  const headerButtons = useBaseNavButtons();

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT.PAGE_VIEW, {
      category: ANALYTICS_CATEGORY.HT_REG_FLOW,
      location: `${location.pathname}${location.hash}`,
      getStartedStep: 'address entry page',
    });
  }, [analytics]);

  return (
    <Page>
      <AppHeader
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />
      <HAGoBackContext.Provider value={handleGoBack}>
        <ShippingAddressContainer />
      </HAGoBackContext.Provider>
    </Page>
  );
};
