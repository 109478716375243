import React, { ReactNode, useEffect } from 'react';
import {
  BODY1,
  Button,
  Container,
  GridContainer,
  GridItem,
  H2,
  AppHeader,
} from '@tuunetech/tuune-components';
import { Link, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { getEntries } from 'modules/Contentful/actions';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { List } from './styles';
import { Page } from 'shared';

const helpContentSlug = 'help_content';
const helpQuestionSlug = 'help_question';
const helpHeader = 'help_header';

const ContentKeys = {
  [CTN.STATIC_CONTENT]: keyGen.getKey(CONTENT_TYPE.STATIC_CONTENT),
};

const options: Options = {
  /* eslint-disable react/display-name */
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): ReactNode => {
      return (
        <BODY1 color="textPrimary" paragraph>
          {children}
        </BODY1>
      );
    },
    [BLOCKS.UL_LIST]: (node, children): ReactNode => {
      return <List>{children}</List>;
    },
    [BLOCKS.LIST_ITEM]: (node, children): ReactNode => {
      return <li>{children}</li>;
    },
  },
};

const questionOption: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): ReactNode => {
      return <H2 gutterBottom>{children}</H2>;
    },
  },
};

const HelpPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const staticContent = useSelector(
    getArrayContentMapped(ContentKeys[CTN.STATIC_CONTENT].reqId),
  );
  const { contactUs, account, logout, goBack } = useBaseNavButtons();

  useEffect(() => {
    dispatch(
      getEntries({
        ...ContentKeys[CTN.STATIC_CONTENT],
        slugs: [helpContentSlug, helpQuestionSlug, helpHeader],
      }),
    );
  }, [dispatch]);

  return (
    <Page>
      <AppHeader
        pageTitle="Help"
        handleGoBack={goBack}
        buttons={[contactUs, account, logout]}
      />
      <Container fixed={false}>
        <GridContainer>
          <GridItem xs={12}>
            <H2 align={isMobile ? 'left' : 'center'} $marginBottom={24}>
              {documentToReactComponents(
                staticContent[helpQuestionSlug]?.content,
                questionOption,
              )}
            </H2>
          </GridItem>
          <GridItem xs={12} md={6} $offsetMd={3} lg={4} $offsetLg={4}>
            <BODY1 align={isMobile ? 'left' : 'center'} $marginBottom={40}>
              {documentToReactComponents(
                staticContent[helpContentSlug]?.content,
                options,
              )}
            </BODY1>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem>
            <Link
              href="mailto:help@tuune.com"
              target="_blank"
              component={Button}
              underline="none"
            >
              Email us
            </Link>
          </GridItem>
        </GridContainer>
      </Container>
    </Page>
  );
};

export default HelpPage;
