import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import { displayErrorPopup } from 'modules/NotificationsHandler/actions';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Routes from 'routes';
import { ClinicsConfigContext } from 'utils/clinics/context';
import { getObjValueIgnoreCase } from 'utils/helpers/objectUtils';
import { ClinicLandingContainer } from '../containers/ClinicGetStarted/ClinicLandingContainer';

export const ClinicGetStartedPage: React.FC = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clinicSlug } = useParams<{ clinicSlug: string }>();

  const [clinicAuthConfig, setClinicAuthConfig] = useState<ClinicAuthConfig>();

  const { isLoading: isClinicsConfigLoading, clinicsConfig } =
    useContext(ClinicsConfigContext);

  useEffect(() => {
    if (clinicSlug && clinicsConfig && isClinicsConfigLoading === false) {
      const config = getObjValueIgnoreCase(clinicsConfig, clinicSlug);
      if (config !== null) {
        setClinicAuthConfig(config as ClinicAuthConfig);
      } else {
        dispatch(displayErrorPopup(`Clinic ${clinicSlug} not found.`));
        history.replace(Routes.HOME);
      }
    }
  }, [history, dispatch, isClinicsConfigLoading, clinicsConfig, clinicSlug]);

  return clinicAuthConfig ? (
    <ClinicLandingContainer clinicAuthConfig={clinicAuthConfig} />
  ) : (
    <></>
  );
};
