import styled, { css } from 'styled-components';
import { Container } from '@tuunetech/tuune-components';

const verticalAlign = css`
  min-height: calc(100vh - 155px);
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const QuestionContainer = styled(Container)<{
  $alignVertical: boolean;
}>`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    ${({ $alignVertical }) => ($alignVertical ? verticalAlign : null)};
    text-align: ${({ $alignVertical }) =>
      $alignVertical ? 'center' : 'start'};
  }
`;
