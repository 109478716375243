import {
  GET_CURRENT_QUESTION_REQUEST,
  GET_DEALBREAKER_ISSUES_REQUEST,
  GET_FEEDBACK_REQUEST,
  GET_PREV_QUESTION_REQUEST,
  INVALIDATE_COMPLETION_REQUEST,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_QUESTION_REQUEST,
  SET_DEALBREAKER_ISSUES_RANK_REQUEST,
} from 'modules/Questionnaire/constants';
import {
  AnswerPayload,
  QuestionnaireRequestActionTypes,
  SetDealbreakerIssuesRankPayload,
  SubmitFeedbackPayload,
} from 'modules/Questionnaire/types';
import { ApiHost } from '../axios';
import { ApiReturnFunc } from './index';

const questionnaireApi: Record<QuestionnaireRequestActionTypes, ApiReturnFunc> =
  {
    [GET_CURRENT_QUESTION_REQUEST]: ({
      questionId,
    }: Partial<AnswerPayload>) => {
      return {
        url: `${ApiHost}/api/v1/questions/${questionId}/`,
        method: 'get',
        headers: {},
      };
    },
    [SUBMIT_QUESTION_REQUEST]: ({
      questionId,
      currentId,
      answers,
    }: AnswerPayload) => {
      return {
        // TODO: replace with appropriate when backend ready
        url: `${ApiHost}/api/v1/questions/${currentId}/`,
        method: 'post',
        headers: {},
        data: {
          question_id: questionId,
          answers: answers.map(({ factId, answer }) => ({
            fact_id: factId,
            answer: answer,
          })),
        },
      };
    },
    [GET_PREV_QUESTION_REQUEST]: ({ questionId }: Partial<AnswerPayload>) => {
      return {
        url: `${ApiHost}/api/v1/questions/${questionId}/?direction=back`,
        method: 'get',
        headers: {},
      };
    },
    [SUBMIT_FEEDBACK_REQUEST]: ({ feedback }: SubmitFeedbackPayload) => {
      return {
        url: `${ApiHost}/api/v1/feedback/`,
        method: 'post',
        headers: {},
        data: {
          text: feedback,
        },
      };
    },
    [GET_FEEDBACK_REQUEST]: () => {
      return {
        url: `${ApiHost}/api/v1/feedback/`,
        method: 'get',
        headers: {},
      };
    },
    [INVALIDATE_COMPLETION_REQUEST]: () => {
      return {
        url: `${ApiHost}/api/v1/completion_tracker/invalidate/`,
        method: 'post',
        headers: {},
      };
    },
    [GET_DEALBREAKER_ISSUES_REQUEST]: () => {
      return {
        url: `${ApiHost}/api/v1/deal_breaker_issues_rank`,
        method: 'get',
        headers: {},
      };
    },
    [SET_DEALBREAKER_ISSUES_RANK_REQUEST]: ({
      dealbreakerIssue,
      issuesRank,
    }: SetDealbreakerIssuesRankPayload) => {
      return {
        url: `${ApiHost}/api/v1/deal_breaker_issues_rank`,
        method: 'post',
        headers: {},
        data: {
          deal_breaker_issue: dealbreakerIssue,
          ranked_issues: issuesRank,
        },
      };
    },
  };

export default questionnaireApi;
