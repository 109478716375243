import React, { useCallback } from 'react';
import Routes from 'routes';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { Button, Container } from '@tuunetech/tuune-components';

export const DeleteAccountButton: React.FC = () => {
  const history = useHistory();

  const handleDelete = useCallback(() => {
    history.push(Routes.DELETE);
  }, [history]);

  return (
    <Container $verticalPadding={24} $horizontalPading={24}>
      <Box textAlign="center">
        <Button variant="text" onClick={handleDelete}>
          Delete my account
        </Button>
      </Box>
    </Container>
  );
};
