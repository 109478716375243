import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '..';
import {
  ANALYTICS_EVENT,
  MILISECONDS_SPENT_ON_PAGE_TRESHOLD,
} from '../constants';
import { AnalyticsTrackData } from '../types';
import { useHistory } from 'react-router';

export const useAnalyticsTimeSpentOnPage = (
  pageRoutePathname: string,
  analyticsEventName: ANALYTICS_EVENT,
  analyticsEventData: AnalyticsTrackData,
): void => {
  const history = useHistory();
  const analytics = useAnalytics();

  const [mountTimestamp, setMountTimestamp] = useState<number>(0);
  const [isMounted, setIsMounted] = useState(false);
  const [analyticsTimeOnPageTimeout, setAnalyticsTimeOnPageTimeout] =
    useState<NodeJS.Timeout>();

  const dispatchAnalyticsTimeOnPage = useCallback(() => {
    const secondsOnPage = (performance.now() - mountTimestamp) / 1000;
    analytics.track(analyticsEventName, {
      ...analyticsEventData,
      totalSeconds: secondsOnPage,
    });
  }, [analyticsEventData, analyticsEventName, analytics, mountTimestamp]);

  useEffect(() => {
    if (history.location.pathname === pageRoutePathname) {
      if (!isMounted) {
        setMountTimestamp(performance.now());
        setIsMounted(true);
        setAnalyticsTimeOnPageTimeout(
          setTimeout(
            () => dispatchAnalyticsTimeOnPage(),
            MILISECONDS_SPENT_ON_PAGE_TRESHOLD,
          ),
        );
      }
    } else {
      if (isMounted) {
        dispatchAnalyticsTimeOnPage();
        setMountTimestamp(0);
        setIsMounted(false);
        analyticsTimeOnPageTimeout && clearTimeout(analyticsTimeOnPageTimeout);
      }
    }
  }, [
    pageRoutePathname,
    history.location.pathname,
    analyticsTimeOnPageTimeout,
    mountTimestamp,
    isMounted,
    dispatchAnalyticsTimeOnPage,
  ]);
};
