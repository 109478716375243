import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, Box, DialogTitle, DialogContent } from '@material-ui/core';
import { BODY2, Button, H3 } from '@tuunetech/tuune-components';
import CloseIcon from '@material-ui/icons/Close';
import { useWebpImage } from 'utils/image';
import { BannerImg, CloseDialogIconButton, Paper } from './styles';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';

export type RecapProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const ShareReportDialog: React.FC<RecapProps> = ({
  isOpen,
  handleClose,
}) => {
  const analytics = useAnalytics();

  const [workingOnFeatureImg] = useWebpImage('working_on_feature.png');
  const [reportShareClicked, setReportShareClicked] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setReportShareClicked(false);
    }
  }, [isOpen, setReportShareClicked]);

  const onShareReportClick = useCallback(
    (analyticsEventName: ANALYTICS_EVENT) => {
      setReportShareClicked(true);
      analytics.track(analyticsEventName, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      });
    },
    [analytics, setReportShareClicked],
  );

  const shareReportOptionsContent = (
    <>
      <H3 $marginTop={24} $marginBottom={24}>
        Share this report with my doctor
      </H3>
      <BODY2 $marginBottom={24} color="textSecondary">
        Export your results in a readable format, for your own records, or to
        share with your primary-care physician.
      </BODY2>
      <Button
        color="grey"
        fullWidth
        onClick={() =>
          onShareReportClick(
            ANALYTICS_EVENT.RECOMMENDATION_REPORT_SHARE_REPORT_EMAIL,
          )
        }
      >
        Send via email
      </Button>
      <Box marginBottom="10px" />
      <Button
        color="green"
        fullWidth
        onClick={() =>
          onShareReportClick(
            ANALYTICS_EVENT.RECOMMENDATION_REPORT_SHARE_REPORT_DOWNLOAD,
          )
        }
      >
        download pdf
      </Button>
    </>
  );

  const workingOnFeatureContent = (
    <>
      <Box display="flex" alignItems="center" flexDirection="column">
        <BannerImg src={workingOnFeatureImg} alt="working on feature img" />
        <H3 $marginTop={24}>We are working on this feature</H3>
        <BODY2 $marginTop={16} color="textSecondary">
          Sorry, this feature isn’t available yet. While we build it, feel free
          to show your doctor your results on your phone at your next visit.
        </BODY2>
      </Box>
    </>
  );

  const dialogContent = reportShareClicked
    ? workingOnFeatureContent
    : shareReportOptionsContent;

  return (
    <Dialog
      open={isOpen}
      PaperComponent={Paper}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      onClose={handleClose}
    >
      <DialogTitle disableTypography>
        <CloseDialogIconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseDialogIconButton>
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
    </Dialog>
  );
};
