import React, { useCallback, useState } from 'react';
import { BODY1, BODY2, H3, H5, Person } from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { LearnMore } from './styles';
import { HowRecommendationsMadeToggle } from './HowRecommendationMadeToggle';

export type ClinicianLauraBioProps = {
  isReviewed: boolean;
  name?: string;
  onMoreAboutDoctorClick?: () => void;
};

const CLINICAL_BIO_TEXT = (
  <>
    <BODY2 paragraph>
      Dr. Laura Purdy is a board-certified family medicine physician currently
      licensed in 48 states. She served as a physician in the US Army for 14
      years and has worked in both outpatient and inpatient settings providing
      care for all ages.
    </BODY2>
    <BODY2>
      Additionally, she has held several leadership positions in the
      telemedicine industry, with experience as a virtual health physician,
      medical director, consultant, and PC Owner. Dr. Purdy believes that
      telemedicine is the future of healthcare, and she is excited to offer
      virtual health services to patients across the country.
    </BODY2>
  </>
);

export const ClinicianLauraBio: React.FC<ClinicianLauraBioProps> = ({
  isReviewed,
  name,
  onMoreAboutDoctorClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRecInfo, setIsOpenRecInfo] = useState(false);
  const [avatar] = useWebpImage('Laura_md.png');
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const onMoreAboutDoctorToggle = useCallback(() => {
    if (!isOpen && onMoreAboutDoctorClick) {
      onMoreAboutDoctorClick();
    }
    setIsOpen(!isOpen);
  }, [isOpen, onMoreAboutDoctorClick]);

  const onRecommendationInfoToggle = useCallback(() => {
    setIsOpenRecInfo(!isOpenRecInfo);
  }, [isOpenRecInfo]);

  return (
    <>
      <Person
        avatar={<img src={avatar} alt="Laura MD" width={80} height={80} />}
        align={isReviewed ? (isDesktop ? 'center' : 'flex-start') : 'center'}
        imageGrow={isReviewed}
        description={
          <Box flexDirection="column" display="flex">
            {isReviewed ? (
              <H3>{`Hi${name ? ' ' + name : ''},`}</H3>
            ) : (
              <H5>Laura Purdy, MD. is reviewing your results</H5>
            )}
            {isReviewed && (
              <BODY1 $marginTop={8}>
                I’m <b>Dr. Laura Purdy</b>. I&apos;ve reviewed and approved your
                birth control recommendations which are listed below.
              </BODY1>
            )}
          </Box>
        }
      />
      <LearnMore
        isOpen={isOpen}
        onToggle={onMoreAboutDoctorToggle}
        justify="space-between"
        chevronColor="primary"
        linkText={
          <BODY1 color="primary">
            {isReviewed ? 'More about your doctor' : 'More about Laura'}
          </BODY1>
        }
      >
        {CLINICAL_BIO_TEXT}
      </LearnMore>
      {isReviewed && (
        <HowRecommendationsMadeToggle
          isOpen={isOpenRecInfo}
          onToggle={onRecommendationInfoToggle}
        />
      )}
    </>
  );
};
