import { Reducer } from 'redux';
import { PreviewReducerState } from '../types';
import { SET_REPORT_FEEDBACK_SUBMITTED } from '../constants';

const defaultState: PreviewReducerState = {
  assessment: [],
  recommendations: [],
  currentMethod: undefined,
  reportFeedbackSubmitted: {},
};

const previewReducer: Reducer<PreviewReducerState> = (
  state = defaultState,
  { type, payload },
) => {
  switch (type) {
    case SET_REPORT_FEEDBACK_SUBMITTED:
      return {
        ...state,
        reportFeedbackSubmitted: {
          ...state.reportFeedbackSubmitted,
          [payload.feedbackOrigin]: payload.isReportFeedbackSubmitted,
        },
      };
    default:
      return state;
  }
};

export default previewReducer;
