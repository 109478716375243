import { ActionCreator } from 'redux';
import {
  REPORT_UNLOCK_REQUEST,
  RESEARCH_AUTO_APPROVE_REQUEST,
  SUBMIT_PRESCRIPTION_ORDER_REQUEST,
  SET_SIGNUP_ORDER_UUID,
  GET_PHARMACY_DETAILS_REQUEST,
} from '../constants';
import { CheckoutActionTypes, submitPrescriptionOrderPayload } from '../types';

export const reportUnlockRequest: ActionCreator<CheckoutActionTypes> = ({
  acceptShareReportWithClinician,
  acceptShareReportWithProviders,
  promoCode,
}) => {
  return {
    type: REPORT_UNLOCK_REQUEST,
    payload: {
      acceptShareReportWithClinician,
      acceptShareReportWithProviders,
      promoCode,
    },
  };
};

export const researchAutoApproveRequest: ActionCreator<CheckoutActionTypes> = ({
  unlockCode,
}) => {
  return {
    type: RESEARCH_AUTO_APPROVE_REQUEST,
    payload: {
      unlockCode,
    },
  };
};

export const submitPrescriptionOrder: ActionCreator<CheckoutActionTypes> = (
  pharmacyDetails: submitPrescriptionOrderPayload,
) => ({
  type: SUBMIT_PRESCRIPTION_ORDER_REQUEST,
  payload: pharmacyDetails,
});

export const setSignupOrderUuid: ActionCreator<CheckoutActionTypes> = (
  orderUuid,
) => ({
  type: SET_SIGNUP_ORDER_UUID,
  payload: {
    orderUuid,
  },
});

export const getPharmacyDetailsRequest: ActionCreator<CheckoutActionTypes> = ({
  uuid,
}) => {
  return {
    type: GET_PHARMACY_DETAILS_REQUEST,
    payload: { uuid },
  };
};
