import React from 'react';
import { AppHeader, BODY1, ButtonProps, H2 } from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import {
  BottomMoonImage,
  LoadingButton,
  TopMoonImage,
  Container,
} from './styles';

export interface LoadingPageProps {
  CTAProps?: ButtonProps;
  CTAText?: string;
  messages?: string[];
  subMessages?: string[];
}

export const LoadingPage: React.FC<LoadingPageProps> = ({
  CTAProps,
  CTAText,
  messages,
  subMessages,
  children,
}) => {
  const [topMoon, bottomMoon] = useWebpImage('top_moon.png', 'bottom_moon.png');
  const { contactUs, logout } = useBaseNavButtons();

  return (
    <>
      <AppHeader color="lightTransparent" buttons={[contactUs, logout]} />
      <Container maxWidth={false} fixed={false}>
        <TopMoonImage src={topMoon} alt="Top Moon" />
        {messages?.map((snippet) => (
          <H2 key={snippet}>{snippet}</H2>
        ))}
        {subMessages?.map((snippet) => (
          <BODY1 key={snippet}>{snippet}</BODY1>
        ))}
        {children}
        <LoadingButton size="medium" color="defaultWhite" {...CTAProps}>
          {CTAText}
        </LoadingButton>
        <BottomMoonImage src={bottomMoon} alt="Bottom Moon" />
      </Container>
    </>
  );
};
