import { REQUEST, SUCCESS } from 'utils/requestStates';

export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = `${LOGIN}_${REQUEST}` as const;
export const LOGIN_SUCCESS = `${LOGIN}_${SUCCESS}` as const;
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const GET_USER_ACCESS_TOKEN_REQUEST = 'GET_USER_ACCESS_TOKEN_REQUEST';
export const GET_USER_ACCESS_TOKEN_SUCCESS = 'GET_USER_ACCESS_TOKEN_SUCCESS';
export const GET_USER_ACCESS_TOKEN_ERROR = 'GET_USER_ACCESS_TOKEN_ERROR';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';

export const OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST =
  'OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST';
export const OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS =
  'OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS';
export const OAUTH_LOGIN_WITH_AUTH_CODE_ERROR =
  'OAUTH_LOGIN_WITH_AUTH_CODE_ERROR';

export const OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST =
  'OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST';
export const OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS =
  'OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS';
export const OAUTH_SIGNUP_WITH_AUTH_CODE_ERROR =
  'OAUTH_SIGNUP_WITH_AUTH_CODE_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = `${RESET_PASSWORD}_${REQUEST}` as const;
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}_${SUCCESS}` as const;

export const RESEND_ACTIVATION = 'RESEND_ACTIVATION';
export const RESEND_ACTIVATION_REQUEST = `${RESEND_ACTIVATION}_${REQUEST}`;
export const RESEND_ACTIVATION_SUCCESS = `${RESEND_ACTIVATION}_${SUCCESS}`;

export const RESET_PASSWORD_CONFIRM_REQUEST = 'RESET_PASSWORD_CONFIRM_REQUEST';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';

export const RESET_PASSWORD_FLOW_RESET = 'RESET_PASSWORD_FLOW_RESET';

export const ACTIVATE_EMAIL_REQUEST = 'ACTIVATE_EMAIL_REQUEST';
export const ACTIVATE_EMAIL_SUCCESS = 'ACTIVATE_EMAIL_SUCCESS';
