import styled from 'styled-components';
import { Container, CAPTION } from '@tuunetech/tuune-components';

export const EligiblityCaption = styled(CAPTION)`
  color: ${({ theme }) => theme.palette.common.grey[700]};
`;

export const RecommendationContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: max(calc(16.6% - 240px), calc((100% + 18px - 100vw) / 2));
  }
`;
