import { ActionCreator } from 'redux';
import { AppThunk } from 'reduxStore';
import { getFromStorage, setToStorage } from 'utils/helpers';
import {
  ACTIVATE_EMAIL_REQUEST,
  GET_USER_ACCESS_TOKEN_REQUEST,
  LOGIN_REQUEST,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST,
  OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST,
  RESEND_ACTIVATION_REQUEST,
  RESET_PASSWORD_CONFIRM_REQUEST,
  RESET_PASSWORD_FLOW_RESET,
  RESET_PASSWORD_REQUEST,
  SET_IS_AUTHENTICATED,
} from '../constants';
import { AuthMethod } from '../constants/authMethod';
import { AuthActionTypes } from '../types';

export const loginRequest: ActionCreator<AuthActionTypes> = (
  email,
  password,
) => ({
  type: LOGIN_REQUEST,
  payload: { email, password },
});

export const getUserAccessTokenRequest: ActionCreator<AuthActionTypes> = (
  action,
) => ({
  type: GET_USER_ACCESS_TOKEN_REQUEST,
  payload: {
    action,
  },
});

export const setIsAuthenticated: ActionCreator<AuthActionTypes> = (
  isAuthenticated,
  authMethod,
) => ({
  type: SET_IS_AUTHENTICATED,
  payload: {
    isAuthenticated,
    authMethod,
  },
});

export const oAuthLoginWithAuthCodeRequest: ActionCreator<AuthActionTypes> = (
  authCode,
  oAuthProvider,
) => ({
  type: OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST,
  payload: { authCode, oAuthProvider },
});

export const oAuthSignUpWithAuthCodeRequest: ActionCreator<AuthActionTypes> = (
  authCode,
  oAuthProvider,
  registerPayload,
) => ({
  type: OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST,
  payload: { authCode, oAuthProvider, registerPayload },
});

export const resetPasswordRequest: ActionCreator<AuthActionTypes> = ({
  email,
}) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: { email },
});

export const resendActivationRequest: ActionCreator<AuthActionTypes> = ({
  email,
}) => ({
  type: RESEND_ACTIVATION_REQUEST,
  payload: { email },
});

export const activateUser: ActionCreator<AuthActionTypes> = ({
  uid,
  token,
}) => ({
  type: ACTIVATE_EMAIL_REQUEST,
  payload: { uid, token },
});

export const resetPasswordConfirmRequest: ActionCreator<AuthActionTypes> = ({
  uid,
  token,
  password,
  confirmPassword,
}) => ({
  type: RESET_PASSWORD_CONFIRM_REQUEST,
  payload: {
    uid,
    token,
    password,
    confirmPassword,
  },
});

export const resetPasswordFlowReset: ActionCreator<AuthActionTypes> = () => ({
  type: RESET_PASSWORD_FLOW_RESET,
});

export const checkAuth =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const isLoggedIn = await getFromStorage('isLoggedIn');
    if (isLoggedIn === true) {
      dispatch(
        getUserAccessTokenRequest(
          setIsAuthenticated(true, AuthMethod.JwtRefresh),
        ),
      );
    } else {
      dispatch(setIsAuthenticated(false, undefined));
    }
  };

export const logout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    await setToStorage('isLoggedIn', false);
    dispatch({
      type: LOG_OUT_REQUEST,
    });
  };

export const logoutClientOnly =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    await setToStorage('isLoggedIn', false);
    dispatch({
      type: LOG_OUT_SUCCESS,
    });
  };
