import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerHormoneTestAddress } from '../../Account/actions';
import { shippingDetailsSchema } from '../constants';
import { ShippingAddress } from '../components/ShippingAddress';
import { AddressPayload } from 'modules/Account/types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorBox, HAControlButtons } from 'shared';
import {
  Container,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { getStripeRedirectUrl } from '../selectors';
import { getStartedData } from 'modules/Questionnaire/selectors';
import {
  getHasAnyErrorMessages,
  getNonFieldErrorMessage,
} from 'modules/NotificationsHandler/selectors';
import { Box } from '@material-ui/core';
import { getDefaultCountry } from 'utils/helpers';

export type ShippingDetailFormData = AddressPayload;

export const ShippingAddressContainer: React.FC = () => {
  const dispatch = useDispatch();
  const stripeRedirectUrl = useSelector(getStripeRedirectUrl);
  const nonFieldErrorMessage = useSelector(getNonFieldErrorMessage);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);
  const [isLoading, setIsLoading] = useState(false);
  const { accountInfo } = useSelector(getStartedData);

  useEffect(() => {
    if (!!stripeRedirectUrl) {
      window.location.href = stripeRedirectUrl;
    }
  }, [stripeRedirectUrl]);

  useEffect(() => {
    if (hasServerErrors) {
      setIsLoading(false);
    }
  }, [hasServerErrors]);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ShippingDetailFormData>({
    resolver: yupResolver(shippingDetailsSchema),
    mode: 'onTouched',
    defaultValues: {
      country: getDefaultCountry().name,
    },
  });

  const onSubmit = useCallback(
    (data) => {
      dispatch(registerHormoneTestAddress(data));
      setIsLoading(true);
    },
    [dispatch],
  );

  return (
    <Container>
      <GridContainer justify="center">
        <GridItem xs={12} md={6}>
          <H3 $marginBottom={40} paragraph>
            Where shall we deliver your hormone test to?
          </H3>
          {!!nonFieldErrorMessage && (
            <Box marginTop="40px">
              <ErrorBox text={nonFieldErrorMessage} />
            </Box>
          )}
          <ShippingAddress
            handleSubmit={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            defaultStateAddress={accountInfo?.state}
          />
          <HAControlButtons
            onClick={handleSubmit(onSubmit)}
            align="left"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </GridItem>
      </GridContainer>
    </Container>
  );
};
