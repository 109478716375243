import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Feedback,
  FeedbackModule,
  H3,
} from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import {
  getReportFeedbackSubmittedFromStorage,
  setReportFeedbackSubmitted,
  setReportFeedbackSubmittedToStorage,
  submitHAFeedbackRequest,
} from 'modules/PreviewReport/actions';
import { useAnalytics } from 'utils/analytics';
import { getIsReportFeedbackSubmitted } from 'modules/PreviewReport/selectors';
import {
  feedbackOriginReportLight,
  feedbackOriginReportPlus,
} from './constants';

type ReportFeedbackSectionProps = {
  title: string;
  negativeSentiments: string[];
  positiveSentiments: string[];
  feedbackOrigin: string;
};

// TODO: component should read BE to know if feedback is already submitted on later stage
// For now it uses local storage to persist this info
// We also need to track that feedback was skipped somehow (by using localStorage flag)

export const ReportFeedbackSection: React.FC<ReportFeedbackSectionProps> = ({
  title,
  negativeSentiments,
  positiveSentiments,
  feedbackOrigin,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const [feedback, setFeedback] = useState<Feedback>({});
  const onFeedbackChange = (fb: Feedback) => {
    setFeedback(fb);
  };

  const isSubmitted = useSelector(getIsReportFeedbackSubmitted);

  useEffect(() => {
    dispatch(getReportFeedbackSubmittedFromStorage(feedbackOriginReportPlus));
    dispatch(getReportFeedbackSubmittedFromStorage(feedbackOriginReportLight));
  }, [dispatch]);

  useEffect(() => {
    if (
      feedbackOrigin === feedbackOriginReportPlus &&
      isSubmitted[feedbackOriginReportLight] === false
    ) {
      analytics.track(ANALYTICS_EVENT.REPORT_FEEDBACK_SKIP, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        feedbackOrigin: feedbackOrigin,
      });
      // I set feedbackSubmitted to undefined to avoid triggering analytics more than once
      // (Unfortunately it will if user switches device/browser)
      dispatch(
        setReportFeedbackSubmitted(feedbackOriginReportLight, 'undefined'),
      );
      dispatch(
        setReportFeedbackSubmittedToStorage(
          feedbackOriginReportLight,
          'undefined',
        ),
      );
    }
  }, [analytics, dispatch, feedbackOrigin, isSubmitted]);

  const onFeedbackReadyButtonClick = useCallback(() => {
    if (feedback.binary) {
      analytics.track(ANALYTICS_EVENT.REPORT_FEEDBACK_THUMBS, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        feedbackOrigin: feedbackOrigin,
        value: feedback.binary,
      });
    }

    if (feedback.tags) {
      analytics.track(ANALYTICS_EVENT.REPORT_FEEDBACK_CHIPS, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        feedbackOrigin: feedbackOrigin,
        value: feedback.tags,
      });
    }

    // we skip sending empty feedback to product board if only thumbs triggered
    if (feedback.text) {
      const tags = feedback.tags ? [...feedback.tags] : [];
      // we add unique feedbackOrigin to filter feedbacks on product board by page
      tags.unshift(feedbackOrigin);
      dispatch(submitHAFeedbackRequest(tags, feedback.text));
    }

    dispatch(setReportFeedbackSubmitted(feedbackOrigin, true));
    dispatch(setReportFeedbackSubmittedToStorage(feedbackOrigin, true));
  }, [
    analytics,
    dispatch,
    feedback.binary,
    feedback.tags,
    feedback.text,
    feedbackOrigin,
  ]);

  return (
    <>
      {isSubmitted[feedbackOrigin] ? (
        <H3 align="center">Thank you for your feedback!</H3>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <H3 $marginBottom={24}>{title}</H3>
          <Box width="600px" maxWidth="100%" marginBottom="16px">
            <FeedbackModule
              thumbsVariant="outlined"
              buttonColor="inherit"
              positiveSentiments={positiveSentiments}
              negativeSentiments={negativeSentiments}
              feedbackChangeCallback={onFeedbackChange}
            />
          </Box>
          {feedback.binary && (
            <Button onClick={onFeedbackReadyButtonClick}>Submit</Button>
          )}
        </Box>
      )}
    </>
  );
};
