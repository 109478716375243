import React from 'react';
import { AnswerComponentProps, AnswerComponentTypes } from '../types';
import { AnswerTypesMap } from '../constants';
import NumberContainer from '../containers/Answers/NumberContainer';
import ChoiceContainer from '../containers/Answers/ChoiceContainer';
import DateContainer from '../containers/Answers/DateContainer';
import TextContainer from '../containers/Answers/TextContainer';
import DobContainer from '../containers/Answers/DobContainer';
import PillContainer from '../containers/Answers/PillContainer';
import InfoContainer from '../containers/Answers/InfoContainer';

export * from './numberContainer';
export * from './choiceContainer';
export * from './dateContainer';

export const getAnswerComponent = (
  answerType?: AnswerComponentTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.FC<AnswerComponentProps & any> | undefined => {
  switch (answerType) {
    case AnswerTypesMap.HEIGHT:
    case AnswerTypesMap.WEIGHT:
    case AnswerTypesMap.NUMBER:
    case AnswerTypesMap.FLOAT:
    case AnswerTypesMap.INT:
      return NumberContainer;
    case AnswerTypesMap.PILL:
      return PillContainer;
    case AnswerTypesMap.BOOL:
    case AnswerTypesMap.CHOICE:
    case AnswerTypesMap.MULTI_CHOICE:
    case AnswerTypesMap.CYCLE:
    case AnswerTypesMap.SUBJECTIVE:
      return ChoiceContainer;
    case AnswerTypesMap.DATE:
    case AnswerTypesMap.DATE_MONTH:
      return DateContainer;
    case AnswerTypesMap.DOB:
      return DobContainer;
    case AnswerTypesMap.TEXT:
      return TextContainer;
    case AnswerTypesMap.INFO:
    case AnswerTypesMap.CONTENTFUL_HEADER_SECTION:
      return InfoContainer;
    default:
      return undefined;
  }
};
