import { ReactNode } from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { ContentfulEntriesSet } from '../types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

export const documentToPlainTextRecords = (
  contentfulEntriesSet: ContentfulEntriesSet,
): Record<string, string> => {
  return Object.keys(contentfulEntriesSet).reduce((content, key) => {
    content[key] = documentToPlainTextString(
      contentfulEntriesSet[key]?.content,
    );
    return content;
  }, {} as Record<string, string>);
};

export const documentToReactNodeRecords = (
  contentfulEntriesSet: ContentfulEntriesSet,
  options?: Options,
): Record<string, ReactNode> => {
  return Object.keys(contentfulEntriesSet).reduce<Record<string, ReactNode>>(
    (content, key) => {
      content[key] = documentToReactComponents(
        contentfulEntriesSet[key]?.content,
        options,
      );
      return content;
    },
    {},
  );
};
