import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { AnswerComponentProps } from '../../types';
import { ChoiceAnswer } from '../../components/Answers';
import {
  checkIsDirtyOption,
  getAnswersFromOptions,
  getIsShowButton,
  mapOptionsFromFacts,
  setActiveStateOnOptions,
} from '../../helpers';
import { AnswerOption } from '../../types';
import { AnswerTypesMap } from '../../constants';
import { useSelector } from 'react-redux';
import { getAssetsMapped } from 'modules/Contentful/selectors';
import { ContentKeys } from '../../constants/contentfulHeaderSection';
import { ContentTypeNames } from 'modules/Contentful/constants';
import { getIsLoading } from '../../../Loading/selectors';

const assetsMap = getAssetsMapped(
  ContentKeys[ContentTypeNames.ISSUE_ICON].reqId,
);

// This is a reusable choice container for Bool, Choice, MultiChoice
const ChoiceContainer: React.FC<
  AnswerComponentProps & { notaAnswer: string }
> = ({
  answers,
  notaAnswer,
  previousAnswers,
  handleSubmitAnswer,
  answerType,
  handleRequestNext,
}) => {
  const icons = useSelector(assetsMap);
  const isIconsLoading = useSelector(
    getIsLoading(ContentKeys[ContentTypeNames.ISSUE_ICON].loadingId),
  );

  const isMultiChoice =
    answerType === AnswerTypesMap.MULTI_CHOICE ||
    answerType === AnswerTypesMap.CYCLE;

  const [initialOptions] = useState(
    setActiveStateOnOptions(
      mapOptionsFromFacts(answers, answerType, notaAnswer),
      previousAnswers,
      isMultiChoice,
    ),
  );

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    if (isIconsLoading === 0) {
      setOptions((options) =>
        options.map((option) => ({
          ...option,
          startIcon:
            option.startIcon ||
            (option.slug && icons[option.slug] && (
              <svg data-src={icons[option.slug].asset} width={24} height={24} />
            )),
        })),
      );
    }
  }, [isIconsLoading, icons]);

  const [showContinue] = useState(
    getIsShowButton(isMultiChoice, initialOptions),
  );
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const [dirty, setDirty] = useState(false);

  const handleSubmit = useCallback(() => {
    const result = getAnswersFromOptions(options, isMultiChoice);
    handleSubmitAnswer(result);
  }, [handleSubmitAnswer, options, isMultiChoice]);

  const handleOptionClick = useCallback(
    (option: AnswerOption): void => {
      const searchField = isMultiChoice ? 'factId' : 'value';
      const optionIndex = options.findIndex(
        (opt: AnswerOption) => opt[searchField] === option[searchField],
      );

      // copy previous options and change clicked option isActive flag to true
      // for multi choice to opposite
      const newValue = isMultiChoice ? !options[optionIndex].isActive : true;
      let resultOptions = update<AnswerOption[]>(options, {
        [optionIndex]: { isActive: { $set: newValue } },
      });

      resultOptions =
        isMultiChoice && option.factId !== -1
          ? // if index -1: 'None of the above' option selected, same behavior as for single choice
            // if MULTI_CHOICE leave array as it is
            resultOptions
          : // if !MULTI_CHOICE set other options isActive flag to false
            resultOptions.map((option, idx) =>
              idx !== optionIndex ? { ...option, isActive: false } : option,
            );

      if (isMultiChoice) {
        const anyActiveOption = resultOptions.some(
          (option) => option.isActive && option.factId !== -1,
        );
        if (anyActiveOption) {
          const noneOptionIndex = resultOptions.findIndex(
            (option) => option.factId === -1,
          );
          // disable noneOfTheAbove option if any other option is active
          // if noneOfTheAbove option exists
          noneOptionIndex !== -1 &&
            (resultOptions[noneOptionIndex].isActive = false);
        }
      }

      setOptions(resultOptions);

      if (!showContinue) {
        setIsSubmitEnabled(true);
      }
    },
    [setOptions, showContinue, options, setIsSubmitEnabled, isMultiChoice],
  );

  useEffect(() => {
    if (isSubmitEnabled) {
      setIsSubmitEnabled(false);
      handleSubmit();
    }
  }, [options, isSubmitEnabled, handleSubmit, setIsSubmitEnabled]);

  useEffect(() => {
    setDisableContinue(!options.some((option) => option.isActive));
    setDirty(checkIsDirtyOption(initialOptions, options));
  }, [options, initialOptions]);

  return (
    <ChoiceAnswer
      handleOptionClick={handleOptionClick}
      options={options}
      handleSubmit={handleSubmit}
      disableContinue={disableContinue}
      showContinue={showContinue}
      handleRequestNext={handleRequestNext}
      dirty={dirty}
    />
  );
};

export default ChoiceContainer;
