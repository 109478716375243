import { AnswerTypesMap } from './answers';
import { ConversionFunc } from '../helpers';

export const H_UNITS = {
  cm: 'cm',
  ft: 'ft',
};

export const W_UNITS = {
  kg: 'kg',
  lb: 'lb',
};

export const HEIGHT_UNITS = Object.values(H_UNITS);
export const WEIGHT_UNITS = Object.values(W_UNITS);

export const MIN_MAX_VALUES = {
  [AnswerTypesMap.HEIGHT]: {
    min: '30',
    max: '300',
  },
  [AnswerTypesMap.WEIGHT]: {
    min: '15',
    max: '500',
  },
};

export const MEASURE_SYSTEMS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
};

// export const DOMAIN_MEASURE_SYSTEM_MAP = {
//   [DOMAIN_MAP.us]: MEASURE_SYSTEMS.IMPERIAL,
//   [DOMAIN_MAP.uk]: MEASURE_SYSTEMS.METRIC,
// };

interface Units {
  [key: string]: {
    [key: string]: string;
  };
}

export const UNITS: Units = {
  [MEASURE_SYSTEMS.METRIC]: {
    [AnswerTypesMap.HEIGHT]: H_UNITS.cm,
    [AnswerTypesMap.WEIGHT]: W_UNITS.kg,
  },
  [MEASURE_SYSTEMS.IMPERIAL]: {
    [AnswerTypesMap.HEIGHT]: H_UNITS.ft,
    [AnswerTypesMap.WEIGHT]: W_UNITS.lb,
  },
};

interface ConvertMap {
  [key: string]: Record<string, ConversionFunc>;
}

export const CONVERT_MAP: ConvertMap = {
  [AnswerTypesMap.HEIGHT]: {
    toMETRIC: ((prevMeasureSystem, value) => {
      if (!value) return undefined;
      if (prevMeasureSystem === MEASURE_SYSTEMS.METRIC)
        return Math.round(Number(value)).toString();
      if (prevMeasureSystem === MEASURE_SYSTEMS.IMPERIAL) {
        const [ft, inch] = (value as string).split('_');
        return Math.round(Number(ft) * 30.48 + Number(inch) * 2.54).toString();
      }
    }) as ConversionFunc,
    toIMPERIAL: ((prevMeasureSystem, value) => {
      if (!value) return undefined;
      if (prevMeasureSystem === MEASURE_SYSTEMS.IMPERIAL)
        return Math.round(Number(value)).toString();
      if (prevMeasureSystem === MEASURE_SYSTEMS.METRIC) {
        const cm = Number(value);
        const inchTotal = cm / 2.54;
        const ft = Math.trunc(inchTotal / 12);
        const inch = Math.round(inchTotal - 12 * ft);
        return [ft, inch].join('_');
      }
    }) as ConversionFunc,
  },
  [AnswerTypesMap.WEIGHT]: {
    toMETRIC: ((prevMeasureSystem, value) => {
      if (!value) return undefined;
      if (prevMeasureSystem === MEASURE_SYSTEMS.METRIC)
        return Math.round(Number(value)).toString();
      if (prevMeasureSystem === MEASURE_SYSTEMS.IMPERIAL) {
        const kg = Number(value) * 0.45359237;
        return Math.round(kg).toString();
      }
    }) as ConversionFunc,
    toIMPERIAL: ((prevMeasureSystem, value) => {
      if (!value) return undefined;
      if (prevMeasureSystem === MEASURE_SYSTEMS.IMPERIAL)
        return Math.round(Number(value)).toString();
      if (prevMeasureSystem === MEASURE_SYSTEMS.METRIC) {
        const lb = Number(value) / 0.45359237;
        return Math.round(lb).toString();
      }
    }) as ConversionFunc,
  },
};

export const feetInput = {
  placeholder: 'ft',
  name: 'ft',
  min: '0',
  max: '9',
  step: '1',
};

export const inchInput = {
  placeholder: 'in',
  name: 'inch',
  min: '0',
  max: '12',
  step: '1',
};

export type NonMetricSystemOption = {
  metricValue: number;
  displayValue: string;
};
export type MetricSystemOption = string;

// HEIGHT
const FEET_MAX = 9;
const FEET_MIN = 1;
const FEET_STEP = 1;
const INCH_MIN = 0;
const INCH_MAX = 12;
const INCH_STEP = 1;

export const HEIGHT_IMPERIAL: NonMetricSystemOption[] = [];
for (let ft = FEET_MIN; ft < FEET_MAX + 1; ft += FEET_STEP) {
  for (let inch = INCH_MIN; inch < INCH_MAX + 1; inch += INCH_STEP) {
    // 1’ 0’’ ≈≈≈ 30cm
    // 9’ 10’’ ≈≈≈ 300cm
    if (ft === 9 && inch > 10) continue;
    HEIGHT_IMPERIAL.push({
      metricValue: Math.round(Number(ft) * 30.48 + Number(inch) * 2.54),
      displayValue: `${ft}' ${inch}''`,
    });
  }
}
const CM_MIN = 30;
const CM_MAX = 300;
const CM_STEP = 1;

export const HEIGHT_METRIC: MetricSystemOption[] = [];
for (let h = CM_MIN; h < CM_MAX + 1; h += CM_STEP) {
  HEIGHT_METRIC.push(h.toString());
}

// WEIGHT
const LB_MIN = 33;
const LB_MAX = 1102;
const LB_STEP = 1;

export const WEIGHT_IMPERIAL: NonMetricSystemOption[] = [];
for (let w = LB_MIN; w < LB_MAX + 1; w += LB_STEP) {
  WEIGHT_IMPERIAL.push({
    metricValue: Math.round(w * 0.4535923),
    displayValue: w.toString(),
  });
}

const KG_MIN = 15;
const KG_MAX = 500;
const KG_STEP = 1;

export const WEIGHT_METRIC: MetricSystemOption[] = [];
for (let w = KG_MIN; w < KG_MAX + 1; w += KG_STEP) {
  WEIGHT_METRIC.push(w.toString());
}
