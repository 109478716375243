import styled from 'styled-components';
import {
  Button,
  Container,
  H3,
  ContentBlock,
} from '@tuunetech/tuune-components';

export const PrescriptionConfirmationContainer = styled(Container)`
  ${H3} {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
  }

  ${Button} {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
  }

  img {
    width: 100%;
    border-radius: 40px;
  }
`;

export const ListItemIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.75rem;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const ListItem = styled(ContentBlock)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;
