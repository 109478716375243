import type { AnswerPayload, Fact, ChoiceOption } from '../types';
import { AnswerTypesMap } from 'modules/Questionnaire/constants';

export const getNonTextAnswers = (
  payload: AnswerPayload,
): Array<{
  factSlug: string;
  answer: string | number | boolean;
  answerText: string;
}> => {
  const { answers, facts } = payload;

  const nonText = answers.reduce(
    (
      accumulator: {
        factSlug: string;
        answer: string | number | boolean;
        answerText: string;
      }[],
      currentValue: {
        factId: number;
        answer: string | boolean | number;
      },
    ) => {
      const fact = facts.find((fact: Fact) => fact.id === currentValue.factId);

      if (!fact || fact.answer_type == AnswerTypesMap.TEXT) {
        // Skip text answers
        return accumulator;
      }

      const amplitudeAnswer = {
        factSlug: fact.slug,
        answer: currentValue.answer,
        answerText: fact.name,
      };
      // For choice answers types, the answer is the
      // actual id, which doesn't tell much information
      // In this case, we'll use the text option for the
      // answer text
      if (fact.answer_type === AnswerTypesMap.CHOICE) {
        const answerDetails = fact.answers.find(
          (answer: ChoiceOption) => answer.id === currentValue.answer,
        );
        amplitudeAnswer.answerText = answerDetails.option;
      }

      // TODO We need to find a more robust approach
      // for binary questions
      const isBinaryQuestion =
        // There is only one fact with bool answer type
        facts.length === 1 &&
        fact.answer_type === AnswerTypesMap.BOOL &&
        // There are only two possible answers (yes/no)
        fact.answers.length === 2;
      if (isBinaryQuestion) {
        return [...accumulator, amplitudeAnswer];
      } else {
        if (currentValue.answer) {
          // Make sore for multi-choice or radio we are sending
          // only the selected answers
          return [...accumulator, amplitudeAnswer];
        } else {
          return accumulator;
        }
      }
    },
    [],
  );

  return nonText;
};
