import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { Assessment } from 'shared/Assessment/types/state';
import { RecommendationReducerState } from '../types';
import {
  ContraceptiveType,
  RECOMMENDATION_GROUP,
  UserFriendlyVersionKeys,
  VersionKeys,
} from '../constants';

const selectRecommendation: Selector<RootState, RecommendationReducerState> = (
  state,
) => state.recommendation;

export const getAssessmentData = createSelector(
  selectRecommendation,
  (state) => {
    const conditions: Assessment[] = [];
    const symptomsHormonal: Assessment[] = [];
    const symptomsUnknown: Assessment[] = [];
    state.assessment.forEach((issue) => {
      const {
        issueType,
        scores: { hormonal },
      } = issue;
      // Hormonal Condition
      if (issueType === 'condition') {
        conditions.push(issue);
      } else {
        // Likely affected by hormones
        if (hormonal >= 0.6) {
          symptomsHormonal.push(issue);
        } else {
          // Needs more investigation
          symptomsUnknown.push(issue);
        }
      }
    });
    return {
      conditions,
      symptomsHormonal,
      symptomsUnknown,
    };
  },
);

export const getAllAssessmentData = createSelector(
  selectRecommendation,
  (state) => state.assessment,
);

export const getReportStatus = createSelector(
  selectRecommendation,
  (state) => state.reportStatus,
);

export const getRecommendationType = createSelector(
  selectRecommendation,
  ({ isPOPPermitted, isCOCPermitted }) => {
    if (isCOCPermitted && isPOPPermitted) return RECOMMENDATION_GROUP.GROUP1;
    if (isPOPPermitted) return RECOMMENDATION_GROUP.GROUP2;
    return RECOMMENDATION_GROUP.GROUP3;
  },
);

export const getRecommendations = createSelector(
  selectRecommendation,
  (state) => state.recommendations,
);

export const getReferralRecommendation = createSelector(
  selectRecommendation,
  (state) => state.referralRecommendation,
);

export const getOrangeFlags = createSelector(
  selectRecommendation,
  (state) => state.flagOrange,
);

export const getRedFlags = createSelector(
  selectRecommendation,
  (state) => state.flagRed,
);

export const getGreenFlags = createSelector(
  selectRecommendation,
  (state) => state.flagGreen,
);

export const getSpecialFlags = createSelector(
  selectRecommendation,
  (state) => state.flagExtra,
);

export const getMEC = createSelector(
  selectRecommendation,
  (state) => state.mec,
);

export const getInfoRequiredFlags = createSelector(
  selectRecommendation,
  (state) => state.infoRequiredFlags,
);

export const getVmpSlugs = createSelector(
  getRecommendations,
  (recommendations) => recommendations.map(({ slug }) => slug),
);

export const getPreferredContraceptives = createSelector(
  selectRecommendation,
  (state) => {
    return (
      Object.entries(state.patientPreferences.contraceptiveMethods)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, isPreferred]) => isPreferred)
        .map(([key]) => key as ContraceptiveType)
    );
  },
);

export const getReportVersions = createSelector(selectRecommendation, (state) =>
  Object.keys(state.versions || {}).reduce<Record<string, string>>(
    (acc, versionKey) => {
      if (UserFriendlyVersionKeys[versionKey as VersionKeys]) {
        acc[UserFriendlyVersionKeys[versionKey as VersionKeys]] = state.versions
          ? state.versions[versionKey as VersionKeys]
          : '';
      }
      return acc;
    },
    {},
  ),
);

export const getReportGenerationDate = createSelector(
  selectRecommendation,
  (state) => state.generationDate,
);
