import { MARKETING, PRIVACY } from 'modules/Questionnaire/constants';
import { RootState } from 'reducers';
import { createSelector, Selector } from 'reselect';
import { AccountReducerState } from '../types';

const selectAccount: Selector<RootState, AccountReducerState> = (state) =>
  state.account;

export const getFirstName = createSelector(
  selectAccount,
  (state) => state.firstName,
);

export const getLastName = createSelector(
  selectAccount,
  (state) => state.lastName,
);

export const getEmail = createSelector(selectAccount, (state) => state.email);

export const getPhone = createSelector(
  selectAccount,
  (state) => state.phoneNumber,
);

export const getMarketingPreference = createSelector(selectAccount, (state) => {
  const marketingPreference: Set<MARKETING> = new Set();
  if (state.acceptMarketingPreference) {
    marketingPreference.add(MARKETING.marketingPreference);
  }
  if (state.acceptResearchPreference) {
    marketingPreference.add(MARKETING.researchPreference);
  }
  return marketingPreference;
});

export const getPrivacyOptions = createSelector(selectAccount, (state) => {
  const privacyOptions: Set<PRIVACY> = new Set();
  if (state.acceptAnswersTruthful) {
    privacyOptions.add(PRIVACY.acceptAnswersTruthful);
  }
  if (state.acceptDataUsage) {
    privacyOptions.add(PRIVACY.acceptDataUsage);
  }
  if (state.acceptTerms) {
    privacyOptions.add(PRIVACY.acceptTerms);
  }
  if (state.acceptAssignedAtBirth) {
    privacyOptions.add(PRIVACY.acceptAssignedAtBirth);
  }
  if (state.acceptTestingAgreement) {
    privacyOptions.add(PRIVACY.acceptTestingAgreement);
  }
  if (state.acceptShareReportWithClinician) {
    privacyOptions.add(PRIVACY.acceptDataUsage);
  }
  if (state.acceptShareDataBack) {
    privacyOptions.add(PRIVACY.acceptDataUsage);
  }

  return privacyOptions;
});

export const getSignupPaymentUrl = createSelector(
  selectAccount,
  (state) => state.signupPaymentUrl,
);

export const getSignupType = createSelector(
  selectAccount,
  (state) => state.signupType,
);

export const getIsActive = createSelector(
  selectAccount,
  (state) => state.isActive,
);

export const getReportStageStatus = createSelector(
  selectAccount,
  (state) => state.reportStageStatus,
);

export const getFeedbackSectionSeen = createSelector(
  selectAccount,
  (state) => state.feedbackSectionSeen,
);

export const getChatToObgynFeedbackSubmitted = createSelector(
  selectAccount,
  (state) => state.chatToObgynFeedbackSubmitted,
);

export const getMonitoringFeedbackSubmitted = createSelector(
  selectAccount,
  (state) => state.monitoringFeedbackSubmitted,
);

export const getHormoneTestingFeedbackSubmitted = createSelector(
  selectAccount,
  (state) => state.hormoneTestingFeedbackSubmitted,
);

export const getDeletionReasons = createSelector(
  selectAccount,
  (state) => state.deletionReasons,
);

export const getClinicInfo = createSelector(
  selectAccount,
  (state) => state.clinic,
);
