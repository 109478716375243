import { Reducer } from 'redux';
import {
  CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS,
  REGISTER_TO_QUESTIONNAIRE_SUCCESS,
} from 'modules/Questionnaire/constants';
import {
  LOG_OUT_SUCCESS,
  LOGIN_SUCCESS,
  SET_IS_AUTHENTICATED,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_FLOW_RESET,
  RESET_PASSWORD_SUCCESS,
  ACTIVATE_EMAIL_SUCCESS,
  RESEND_ACTIVATION_SUCCESS,
  OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS,
  OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS,
  OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST,
  OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST,
} from '../constants';
import { AuthReducerState } from '../types';
import { AuthMethod } from '../constants/authMethod';

export const defaultState: AuthReducerState = {
  isAuthenticated: undefined,
  initialAuthMethod: undefined,
  resetPasswordStage: 0,
  isResetPasswordConfirmed: false,
  isEmailActivated: false,
  unauthenticatedEmailAddress: '',
  lastOAuthCode: undefined,
};

const authReducer: Reducer<AuthReducerState> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case OAUTH_LOGIN_WITH_AUTH_CODE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        initialAuthMethod: AuthMethod.JwtCreate,
      };
    case REGISTER_TO_QUESTIONNAIRE_SUCCESS:
    case CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS:
    case OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        initialAuthMethod: AuthMethod.JwtRegister,
      };
    case OAUTH_LOGIN_WITH_AUTH_CODE_REQUEST:
    case OAUTH_SIGNUP_WITH_AUTH_CODE_REQUEST:
      return {
        ...state,
        lastOAuthCode: action.payload.authCode,
      };
    case SET_IS_AUTHENTICATED:
      // Set the initialAuthMethod only if it wasn't previously set
      // or if the user is not authenticated
      let initialAuthMethod = state.initialAuthMethod;
      if (
        action.payload.authMethod === undefined ||
        initialAuthMethod === undefined
      ) {
        initialAuthMethod = action.payload.authMethod;
      }
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        initialAuthMethod: initialAuthMethod,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordConfirmed: false,
        resetPasswordStage: 1,
      };
    case RESET_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        isResetPasswordConfirmed: true,
      };
    case RESET_PASSWORD_FLOW_RESET:
      return {
        ...state,
        isResetPasswordConfirmed: false,
        resetPasswordStage: 0,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...defaultState,
        isAuthenticated: false,
        initialAuthMethod: undefined,
      };
    case ACTIVATE_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailActivated: true,
      };
    case RESEND_ACTIVATION_SUCCESS:
      return {
        ...state,
        unauthenticatedEmailAddress: action.payload.email,
      };
    default:
      return state;
  }
};

export { authReducer as auth };
