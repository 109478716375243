import { AuthMethod } from 'modules/Auth/constants/authMethod';
import { getStartedData } from 'modules/Questionnaire/selectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { registerPayloadToAPIPayload } from 'utils/api/helpers';
import { oAuthRedirect } from 'utils/helpers/auth';

export const useOAuthSignupRedirect = (): (() => void) => {
  const {
    oAuthProvider,
    marketingOptions,
    privacyOptions,
    accountInfo,
    signupType,
  } = useSelector(getStartedData);

  const signupRedirect = useCallback(() => {
    if (signupType) {
      const registerPayload = {
        ...accountInfo,
        privacyOptions,
        marketingOptions,
        signupType,
      };

      if (oAuthProvider) {
        oAuthRedirect(oAuthProvider, {
          authMethod: AuthMethod.JwtRegister,
          ...registerPayloadToAPIPayload(registerPayload),
        });
      }
    }
  }, [
    accountInfo,
    privacyOptions,
    marketingOptions,
    signupType,
    oAuthProvider,
  ]);

  return signupRedirect;
};
