import React from 'react';

export type OAuthConfig = {
  clientId: string;
  redirectUri: string;
  responseType: string;
  scope: string;
  authServerUrl: string;
  authProviderLogo: React.FC;
};

export enum SocialAuthType {
  SignIn,
  SignUp,
}
