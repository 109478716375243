import React from 'react';

import { Section as SectionType } from 'modules/Questionnaire/types';
import { Container, CAPTION } from '@tuunetech/tuune-components';
import { Indicator, Section } from './styles';
import { Box } from '@material-ui/core';

type ProgressBarProps = {
  currentSection: SectionType;
  sections: SectionType[];
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentSection,
  sections,
}) => {
  return (
    <Container $marginBottom={16}>
      <Box display="flex" width="100%" justifyContent="center">
        {sections
          .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
          .map(({ name, order }) => (
            <Section
              key={name}
              $isActive={order <= currentSection.order}
              data-testid="section"
            >
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="center"
              >
                <Indicator />
                <CAPTION color="primary" display="block">
                  {name}
                </CAPTION>
              </Box>
            </Section>
          ))}
      </Box>
    </Container>
  );
};

export default ProgressBar;
