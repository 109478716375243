import styled from 'styled-components';
import { TuuneInput } from 'shared';
import {
  BODY1,
  Button,
  Container,
  Select as TuuneSelect,
} from '@tuunetech/tuune-components';

export const PharmacyContainer = styled(Container)`
  ${Button} {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
  }
  ${BODY1} + ${BODY1} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Input = styled(TuuneInput)`
  margin-bottom: 16px;
`;

export const Select = styled(TuuneSelect)`
  margin-bottom: 16px;
`;
