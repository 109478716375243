import styled from 'styled-components';
import { TuuneInput } from 'shared';
import { Button } from '@tuunetech/tuune-components';

export const Input = styled(TuuneInput)`
  & + & {
    margin-top: 16px;
  }
`;

export const TextButton = styled(Button)`
  & + & {
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0 !important;
    & + & {
      margin-left: 8px;
    }
  }
`;
