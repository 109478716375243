import { Switch } from '@material-ui/core';
import MuiForwardIcon from '@material-ui/icons/ArrowForward';
import MuiCancelIcon from '@material-ui/icons/CancelRounded';
import { ContentBlock } from '@tuunetech/tuune-components';
import { Chip } from '@tuunetech/tuune-components';
import styled from 'styled-components';

export const ShowFilterSwitch = styled(Switch)`
  margin-left: auto;
`;

export const MethodChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.common.purple[50]};
  color: ${({ theme }) => theme.palette.common.purple[900]};

  :hover {
    background-color: ${({ theme }) => theme.palette.common.purple[100]};
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.common.purple[50]};
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.common.grey[100]};
    color: ${({ theme }) => theme.palette.common.grey[600]};
  }

  .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.palette.common.purple[900]};
  }
`;

export const ChipLabel = styled.div`
  display: flex;
  align-items: center;

  span:not(:only-child) {
    padding-right: 8px;
  }
`;

export const ForwardIcon = styled(MuiForwardIcon)`
  width: 16px;
  height: 16px;
`;

export const CancelIcon = styled(MuiCancelIcon)`
  color: ${({ theme }) => theme.palette.common.red[900]};
`;

export const IneligibilityBlock = styled.div`
  margin-top: 24px;
`;

export const MecEligibilityBlock = styled(ContentBlock)`
  margin-top: 16px;
`;
