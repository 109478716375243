import { FAIL, REQUEST, SUCCESS } from 'utils/requestStates';

export const GET_CURRENT_QUESTION = 'GET_CURRENT_QUESTION';
export const GET_CURRENT_QUESTION_REQUEST = 'GET_CURRENT_QUESTION_REQUEST';
export const GET_CURRENT_QUESTION_SUCCESS = 'GET_CURRENT_QUESTION_SUCCESS';

export const SUBMIT_QUESTION = 'SUBMIT_QUESTION';
export const SUBMIT_QUESTION_REQUEST = 'SUBMIT_QUESTION_REQUEST';
export const SUBMIT_QUESTION_SUCCESS = 'SUBMIT_QUESTION_SUCCESS';
export const SUBMIT_QUESTION_FAIL = `${SUBMIT_QUESTION}_${FAIL}` as const;

export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';

export const GET_PREV_QUESTION_REQUEST = 'GET_PREV_QUESTION_REQUEST';
export const GET_PREV_QUESTION_SUCCESS = 'GET_PREV_QUESTION_SUCCESS';

export const SET_GET_STARTED_ACCOUNT_INFO = 'SET_GET_STARTED_ACCOUNT_INFO';
export const SET_GET_STARTED_MARKETING_AND_STATE_INFO =
  'SET_GET_STARTED_MARKETING_AND_STATE_INFO';
export const SET_GET_STARTED_QUESTION = 'SET_GET_STARTED_QUESTION';
export const SET_GET_STARTED_PRIVACY = 'SET_GET_STARTED_PRIVACY';
export const SET_GET_STARTED_OAUTH_PROVIDER = 'SET_GET_STARTED_OAUTH_PROVIDER';
export const SET_GET_STARTED_SIGNUP_TYPE = 'SET_GET_STARTED_SIGNUP_TYPE';

export const SET_CLINIC_GET_STARTED_CLINIC_CONFIG =
  'SET_CLINIC_GET_STARTED_CLINIC_CONFIG';
export const SET_CLINIC_GET_STARTED_APPOINTMENT_DETAILS =
  'SET_CLINIC_GET_STARTED_APPOINTMENT_DETAILS';

export const REGISTER_TO_QUESTIONNAIRE_REQUEST =
  'REGISTER_TO_QUESTIONNAIRE_REQUEST';
export const REGISTER_TO_QUESTIONNAIRE_SUCCESS =
  'REGISTER_TO_QUESTIONNAIRE_SUCCESS';

export const CLINIC_REGISTER_TO_QUESTIONNAIRE_REQUEST =
  'CLINIC_REGISTER_TO_QUESTIONNAIRE_REQUEST';
export const CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS =
  'CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS';

export const PRE_SIGN_UP = 'PRE_SIGN_UP';
export const PRE_SIGN_UP_REQUEST = `${PRE_SIGN_UP}_${REQUEST}` as const;

export const OAUTH_PRE_SIGN_UP_REQUEST = 'OAUTH_PRE_SIGN_UP_REQUEST';

export const CLINIC_SIGN_UP_GET_CLINICIANS_REQUEST =
  'CLINIC_SIGN_UP_GET_CLINICIANS_REQUEST';
export const CLINIC_SIGN_UP_GET_CLINICIANS_SUCCESS =
  'CLINIC_SIGN_UP_GET_CLINICIANS_SUCCESS';

export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_REQUEST = `${SUBMIT_FEEDBACK}_${REQUEST}` as const;
export const SUBMIT_FEEDBACK_SUCCESS = `${SUBMIT_FEEDBACK}_${SUCCESS}` as const;

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_FEEDBACK_REQUEST = `${GET_FEEDBACK}_${REQUEST}`;
export const GET_FEEDBACK_SUCCESS = `${GET_FEEDBACK}_${SUCCESS}`;

export const INVALIDATE_COMPLETION = 'INVALIDATE_COMPLETION' as const;
export const INVALIDATE_COMPLETION_REQUEST = `${INVALIDATE_COMPLETION}_${REQUEST}`;
export const INVALIDATE_COMPLETION_SUCCESS = `${INVALIDATE_COMPLETION}_${SUCCESS}`;

export const GET_DEALBREAKER_ISSUES = 'GET_DEALBREAKER_ISSUES';
export const GET_DEALBREAKER_ISSUES_REQUEST =
  `${GET_DEALBREAKER_ISSUES}_${REQUEST}` as const;
export const GET_DEALBREAKER_ISSUES_SUCCESS =
  `${GET_DEALBREAKER_ISSUES}_${SUCCESS}` as const;

export const SET_DEALBREAKER_ISSUES_RANK = 'SET_DEALBREAKER_ISSUES_RANK';
export const SET_DEALBREAKER_ISSUES_RANK_REQUEST =
  `${SET_DEALBREAKER_ISSUES_RANK}_${REQUEST}` as const;
export const SET_DEALBREAKER_ISSUES_RANK_SUCCESS =
  `${SET_DEALBREAKER_ISSUES_RANK}_${SUCCESS}` as const;

export const SET_DEALBREAKER = 'SET_DEALBREAKER';
export const SET_PAGE_NUM = 'SET_PAGE_NUM';
