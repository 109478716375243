import React, { useReducer, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoading } from 'modules/Loading/selectors';
import { GET_ACCOUNT_DATA } from 'modules/Account/constants';
import { getFeedbackSectionSeen } from 'modules/Account/selectors';
import LoadingContainer from 'shared/LoadingContainer';
import { LoadingPage } from 'shared/LoadingPage';
import { useInterval } from 'utils/hooks';
import {
  BUTTON_MESSAGE_FEEDBACK_SUBMIT,
  BUTTON_MESSAGE_LOADING,
} from 'shared/LoadingPage/constants';
import { HAFeedbackModuleContainer } from 'modules/Questionnaire/containers/HAFeedbackModule/HAFeedbackModuleContainer';
import {
  Action,
  initialState,
  reducer,
  State,
} from 'modules/Questionnaire/containers/HAFeedbackModule/state';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';
import { submitHAFeedbackRequest } from 'modules/PreviewReport/actions';
import { Page } from '../Page';
import { lastQuestionNum } from 'modules/Questionnaire/containers/HAFeedbackModule/questions';

const FEEDBACK_LOADING_INTERVAL = 5000;

const HAFeedbackLoadingContainer: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState,
  );

  const analytics = useAnalytics();
  const reduxDispatch = useDispatch();

  const isFeedbackModuleSeen = useSelector(getFeedbackSectionSeen);
  const isFeedbackModuleSeenLoading = useSelector(
    getIsLoading(GET_ACCOUNT_DATA),
  );

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState<boolean>(true);

  useInterval(() => {
    setIsFeedbackLoading(false);
  }, FEEDBACK_LOADING_INTERVAL);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { feedbackQnNum, ...feedbackData } = state;

  const onFeedbackReadyButtonClick = () => {
    if (isFeedbackLoading && feedbackQnNum !== lastQuestionNum) return;

    if (isFeedbackModuleSeen === false) {
      const skipped = 'skipped';

      const insurance =
        feedbackData.userHasInsurance === undefined
          ? skipped
          : feedbackData.userHasInsurance;
      if (insurance !== skipped) {
        analytics.track(ANALYTICS_EVENT.HA_FEEDBACK_INSURANCE, {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          value: insurance,
        });
      }

      const healthcareServices = !feedbackData.healthcareServices?.size
        ? skipped
        : Array.from(feedbackData.healthcareServices);
      if (healthcareServices !== skipped) {
        analytics.track(ANALYTICS_EVENT.HA_FEEDBACK_HEALTHCARE, {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          value: healthcareServices,
        });
      }

      const primarySocial = feedbackData.socialMediaChannel || skipped;
      if (primarySocial !== skipped) {
        analytics.track(ANALYTICS_EVENT.HA_FEEDBACK_SOCIALS, {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          value: primarySocial,
        });
      }

      const subs = !feedbackData.subscriptions?.size
        ? skipped
        : Array.from(feedbackData.subscriptions);
      if (subs !== skipped) {
        analytics.track(ANALYTICS_EVENT.HA_FEEDBACK_SUBSCRIPTIONS, {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          value: subs,
        });
      }

      analytics.track(ANALYTICS_EVENT.HA_FEEDBACK_USER_OVERVIEW, {
        category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
        [ANALYTICS_EVENT.HA_FEEDBACK_INSURANCE]: insurance,
        [ANALYTICS_EVENT.HA_FEEDBACK_HEALTHCARE]: healthcareServices,
        [ANALYTICS_EVENT.HA_FEEDBACK_SOCIALS]: primarySocial,
        [ANALYTICS_EVENT.HA_FEEDBACK_SUBSCRIPTIONS]: subs,
      });
      reduxDispatch(submitHAFeedbackRequest());
    }
    setIsReady(true);
  };

  return !(isFeedbackModuleSeenLoading === 0) ? (
    <LoadingContainer />
  ) : isReady || isFeedbackModuleSeen ? (
    <Page>{children}</Page>
  ) : (
    <LoadingPage
      CTAProps={{
        startIcon: isFeedbackLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : null,
        onClick: onFeedbackReadyButtonClick,
        variant: 'text',
      }}
      CTAText={
        feedbackQnNum === lastQuestionNum
          ? undefined
          : isFeedbackLoading
          ? BUTTON_MESSAGE_LOADING
          : BUTTON_MESSAGE_FEEDBACK_SUBMIT
      }
    >
      <HAFeedbackModuleContainer
        state={state}
        dispatch={dispatch}
        submitFeedback={onFeedbackReadyButtonClick}
      />
    </LoadingPage>
  );
};

export default HAFeedbackLoadingContainer;
