import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const IUD: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6586 5.43455C11.1638 5.35035 11.6648 5.60549 11.8939 6.06359L12.6614 7.59864C11.7003 7.88718 11 8.7787 11 9.83377V20.3338C11 21.6224 12.0447 22.6671 13.3333 22.6671V26.1667H14.4684C14.4789 26.167 14.4894 26.1671 14.5 26.1671C14.5105 26.1671 14.5211 26.167 14.5316 26.1667H15.6667V22.6671C16.9553 22.6671 18 21.6224 18 20.3338V9.83377C18 8.7787 17.2997 7.88718 16.3386 7.59864L17.1061 6.06359C17.3352 5.60549 17.8362 5.35035 18.3414 5.43455L20.1896 5.74258C19.9095 6.24581 19.75 6.82533 19.75 7.4421C19.75 9.3751 21.317 10.9421 23.25 10.9421C25.183 10.9421 26.75 9.3751 26.75 7.4421C26.75 5.88798 25.7371 4.57043 24.3354 4.11366C24.2393 4.06793 24.1353 4.03468 24.0251 4.01632L18.725 3.13296C17.2094 2.88036 15.7063 3.64579 15.0191 5.0201L14.5 6.05837L13.9809 5.0201C13.2937 3.64579 11.7906 2.88036 10.275 3.13296L4.97486 4.01632C4.86468 4.03468 4.76069 4.06793 4.66462 4.11365C3.26293 4.57043 2.25 5.88797 2.25 7.4421C2.25 9.3751 3.817 10.9421 5.75 10.9421C7.683 10.9421 9.25 9.3751 9.25 7.4421C9.25 6.82533 9.09046 6.24581 8.8104 5.74258L10.6586 5.43455ZM14.4746 9.83377L14.5 9.83403L14.5254 9.83377H15.6667V20.3338H13.3333V9.83377H14.4746ZM6.91667 7.4421C6.91667 8.08643 6.39433 8.60877 5.75 8.60877C5.10567 8.60877 4.58333 8.08643 4.58333 7.4421C4.58333 6.79777 5.10567 6.27543 5.75 6.27543C6.39433 6.27543 6.91667 6.79777 6.91667 7.4421ZM23.25 8.60877C23.8943 8.60877 24.4167 8.08643 24.4167 7.4421C24.4167 6.79777 23.8943 6.27543 23.25 6.27543C22.6057 6.27543 22.0833 6.79777 22.0833 7.4421C22.0833 8.08643 22.6057 8.60877 23.25 8.60877Z"
    />
  </SvgIcon>
);
