import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopupErrorMessage, getPopupInfoMessage } from '../selectors';
import { clearNotificationPopup } from '../actions';
import NotificationPopup, {
  NotificationPopupProps,
} from '../components/NotificationPopup';

const NotificationPopupContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] =
    useState<NotificationPopupProps['color']>('primary');
  const [notificationMessage, setNotificationMessage] = useState<
    string | undefined
  >(undefined);
  const errorMessage = useSelector(getPopupErrorMessage);
  const infoMessage = useSelector(getPopupInfoMessage);

  useEffect(() => {
    if (errorMessage) {
      setNotificationMessage(errorMessage);
      setColor('error');
      setIsOpen(true);
    } else if (infoMessage) {
      setNotificationMessage(infoMessage);
      setColor('primary');
      setIsOpen(true);
    }
  }, [errorMessage, infoMessage]);

  const handleDismiss = useCallback(() => {
    setIsOpen(false);
    dispatch(clearNotificationPopup());
  }, [dispatch]);

  return (
    <NotificationPopup
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      notificationMessage={notificationMessage}
      color={color}
    />
  );
};

export default NotificationPopupContainer;
