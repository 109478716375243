import React from 'react';
import { ErrorBox, TuuneInput } from 'shared';
import AuthLayout from '../AuthLayout';
import { Button } from '@tuunetech/tuune-components';
import { Box, CircularProgress } from '@material-ui/core';
import { Control, FieldErrors } from 'react-hook-form';
import { ResetPasswordFormData } from '../../pages/ResetPasswordPage';
import Routes from 'routes';
import NotificationPopup from 'modules/NotificationsHandler/components/NotificationPopup';

interface ResetPasswordProps {
  control: Control<ResetPasswordFormData>;
  handleSubmit: () => void;
  errors: FieldErrors<ResetPasswordFormData>;
  nonFieldErrorMessage?: string;
  fieldErrorMessages?: Record<string, string>;
  isLoading?: boolean;
  handleResetPopupDismiss: () => void;
  isResetPasswordConfirmed: boolean;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  control,
  handleSubmit,
  errors,
  nonFieldErrorMessage,
  fieldErrorMessages,
  isLoading,
  isResetPasswordConfirmed,
  handleResetPopupDismiss,
}) => {
  const content = (
    <form onSubmit={handleSubmit}>
      {!!nonFieldErrorMessage && <ErrorBox text={nonFieldErrorMessage} />}
      <TuuneInput
        autoFocus
        required
        name="newPassword"
        control={control}
        placeholder="Password"
        type="password"
        fullWidth
        autoComplete="new-password"
        error={!!fieldErrorMessages?.newPassword || !!errors.newPassword}
        helperText={
          fieldErrorMessages?.newPassword || errors?.newPassword?.message
        }
      />
      <Box height="16px" />
      <TuuneInput
        required
        name="rePassword"
        placeholder="Confirm password"
        control={control}
        fullWidth
        type="password"
        helperText={
          fieldErrorMessages?.rePassword ||
          errors?.rePassword?.message ||
          'Your password needs to be unique, contain letters and numbers, and be at least 8 characters long. Also please refrain from using words like ‘password’, your name, or any part of your email address.'
        }
        error={!!fieldErrorMessages?.rePassword || !!errors.rePassword}
      />
      <Box marginTop="40px">
        <Button
          disabled={isLoading}
          type="submit"
          fullWidth
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          Change Password
        </Button>
      </Box>
      <Box marginTop="16px" display="flex" justifyContent="center">
        <Button href={Routes.LOGIN} variant="text">
          back to login
        </Button>
      </Box>
    </form>
  );

  return (
    <>
      <AuthLayout title="New password" content={content} />
      <NotificationPopup
        isOpen={isResetPasswordConfirmed}
        handleDismiss={handleResetPopupDismiss}
        notificationMessage="Password reset successfully. You'll be redirected to Login"
        color="primary"
      />
    </>
  );
};

export default ResetPassword;
