import React, { ReactNode } from 'react';
import { BODY2 } from '@tuunetech/tuune-components';

export type ReviewStep = {
  image: string;
  description: ReactNode;
};

const getAlgorithmStep = ({
  clinician = 'Laura',
  clinic,
}: {
  clinician?: string;
  clinic?: string;
}): ReviewStep => ({
  image: 'step2',
  description: (
    <BODY2>
      Our algorithm will look at your specific issues and{' '}
      <b>recommend a birth control option</b> (and alternatives) for you.{' '}
      {clinic ? `Your clinician at ${clinic}` : clinician} will personally{' '}
      <b>review and approve</b> your recommendation.
    </BODY2>
  ),
});

export const REVIEW_STEPS: ReviewStep[] = [
  {
    image: 'step1',
    description: (
      <BODY2>
        We’ve reviewed <b>2000+ scientific papers</b>, assessing{' '}
        <b>250+ birth control options</b> available on the market, analyzing the
        impact they may have on different issues.
      </BODY2>
    ),
  },
  getAlgorithmStep({
    clinician: 'Laura',
  }),
  {
    image: 'step3',
    description: (
      <BODY2>
        Book a <b>15-minute call</b> with us so that we can introduce you to
        your detailed personalized birth control recommendation report.
      </BODY2>
    ),
  },
];

export const getReviewStepsClinic = (clinicName: string): ReviewStep[] => [
  REVIEW_STEPS[0],
  getAlgorithmStep({ clinic: clinicName }),
  {
    image: 'stepClinic',
    description: (
      <BODY2>
        <b>Attend your appointment</b> at {clinicName} and evaluate your
        options. <b>Discuss with your clinician</b> what birth control method
        works the best for you and <b>get your prescription</b> there and then!
      </BODY2>
    ),
  },
];
