import React, { useState } from 'react';
import { Box, Checkbox, Paper } from '@material-ui/core';
import { Control, FieldErrors } from 'react-hook-form';
import {
  BODY1,
  BODY2,
  Button,
  Container,
  ContentBlock,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { ProfileItemHeader } from './styles';
import { TuuneInput } from 'shared';
import { AccountInfoFormData } from 'modules/Questionnaire/types';

export type PersonalInfoProps = {
  firstName: string;
  lastName: string;
  readOnlyProfileData: {
    name: string;
    value?: string;
  }[];
  isEditing: boolean;
  handleSubmit: () => void;
  handleEditClick: () => void;
  control: Control<Partial<AccountInfoFormData>>;
  errors: FieldErrors<Partial<AccountInfoFormData>>;
};

export const PersonalInfo: React.FC<PersonalInfoProps> = ({
  firstName,
  lastName,
  readOnlyProfileData,
  isEditing,
  handleSubmit,
  handleEditClick,
  control,
  errors,
}) => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  const renderReadOnlyField = (name: string, value?: string) => {
    return (
      <React.Fragment key={name}>
        <GridItem xs={4}>
          <ProfileItemHeader>{name}</ProfileItemHeader>
        </GridItem>
        <GridItem xs={8}>
          <BODY1>{value}</BODY1>
        </GridItem>
      </React.Fragment>
    );
  };

  const editableProfile = (
    <form onSubmit={handleSubmit}>
      <H3>Your personal info</H3>
      <GridContainer alignItems="flex-end">
        <GridItem xs={4}>
          <ProfileItemHeader>First name</ProfileItemHeader>
        </GridItem>
        <GridItem xs={8}>
          <TuuneInput
            inputComponent={BODY2}
            control={control}
            name="firstName"
            disabled={!isEditing}
            type="text"
            variant="standard"
            defaultValue={firstName}
            helperText={errors.firstName?.message}
            error={!!errors.firstName}
          />
        </GridItem>
        <GridItem xs={4}>
          <ProfileItemHeader>Last name</ProfileItemHeader>
        </GridItem>
        <GridItem xs={8}>
          <TuuneInput
            inputComponent={BODY2}
            control={control}
            name="lastName"
            disabled={!isEditing}
            type="text"
            variant="standard"
            defaultValue={lastName}
            helperText={errors.lastName?.message}
            error={!!errors.lastName}
          />
        </GridItem>
        {readOnlyProfileData.map(({ name, value }) =>
          renderReadOnlyField(name, value),
        )}
      </GridContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="32px"
        gridRowGap="32px"
      >
        <ContentBlock
          startAdornment={
            <Checkbox
              id="legal-name-consent"
              checked={isConsentGiven}
              color="primary"
              onChange={() => setIsConsentGiven(!isConsentGiven)}
            />
          }
          content={
            <BODY2
              htmlFor="legal-name-consent"
              component="label"
              align="left"
              color="textSecondary"
            >
              I confirm this is my legal name for medical purposes. Tuune is not
              liable for any false information provided.
            </BODY2>
          }
        />
        <Button type="submit" disabled={!isConsentGiven}>
          save changes
        </Button>
      </Box>
    </form>
  );

  const readOnlyProfile = (
    <>
      <H3>Your personal info</H3>
      <GridContainer alignItems="flex-end">
        {renderReadOnlyField('First name', firstName)}
        {renderReadOnlyField('Last name', lastName)}
        {readOnlyProfileData.map(({ name, value }) =>
          renderReadOnlyField(name, value),
        )}
      </GridContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="32px"
        gridRowGap="32px"
      >
        <Button variant="text" onClick={handleEditClick}>
          edit
        </Button>
      </Box>
    </>
  );

  return (
    <Paper>
      <Container $verticalPadding={24} $horizontalPading={24}>
        {isEditing ? editableProfile : readOnlyProfile}
      </Container>
    </Paper>
  );
};
