import { AppThunk } from 'reduxStore';
import * as Sentry from '@sentry/react';
import { EntryCollection } from 'contentful';
import { client } from '../client';
import {
  REQUEST_CONTENTFUL_DATA,
  SUCCESS_CONTENTFUL_DATA,
} from '../constants/actions';

type Props = {
  contentType: string;
  reqId: string;
  slugs: string[];
  match?: boolean;
  ne?: boolean;
  all?: boolean;
};

const LOCALE_MAP: Record<string, string> = {
  uk: 'en-GB',
  us: 'en-US',
};

type Query = Record<string, string | number>;
const makeRequest = async (
  query: Query,
  res: EntryCollection<unknown> | undefined,
) => {
  let result = res ? JSON.parse(JSON.stringify(res)) : res;
  const data = await client.getEntries(query);
  if (result === undefined) {
    result = data;
  } else {
    result = { ...data, items: [...result.items, ...data.items] };
  }

  return [data, result];
};

const date = new Date();
const today = `${date.getUTCDate()}-${date.getUTCMonth()}-${date.getUTCFullYear()}`;

const BATCH_SIZE = 35;
const URL_LENGTH_WARNING_LIMIT = 1800;

export const getEntries =
  ({
    contentType,
    slugs,
    reqId,
    match = false,
    ne = false,
    all = false,
  }: Props): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch({
      type: `${REQUEST_CONTENTFUL_DATA}_${reqId}`,
      payload: { reqId },
    });

    if (!all && !slugs.length) {
      Sentry.captureMessage(
        `FE tried to request data for ${contentType} with no slugs [${today}]`,
      );
      dispatch({
        type: `${SUCCESS_CONTENTFUL_DATA}_${reqId}`,
        payload: { data: undefined, reqId },
      });

      return;
    }

    const query: Query = {
      content_type: contentType,
      locale: LOCALE_MAP['us'],
    };

    let requestNext = true;
    let result: EntryCollection<unknown> | undefined;

    try {
      if (all) {
        let skip = 0;
        query['limit'] = BATCH_SIZE;

        while (requestNext) {
          query.skip = skip * BATCH_SIZE;

          const [data, res] = await makeRequest(query, result);
          result = res;

          if (data.skip + data.items.length < data.total) {
            skip += 1;
          } else {
            requestNext = false;
          }
        }
      } else {
        let batchIndex = 0;
        const batchedSlugs = [];
        for (let i = 0; i < slugs.length; i += BATCH_SIZE) {
          const batch = slugs.slice(i, i + BATCH_SIZE);
          batchedSlugs.push([batch]);
        }

        while (requestNext) {
          const stringifiedBatchedSlugs = batchedSlugs[batchIndex].join(',');
          if (stringifiedBatchedSlugs.length > URL_LENGTH_WARNING_LIMIT) {
            Sentry.captureException(
              `Warning! Contentful request URL length of ${stringifiedBatchedSlugs.length} is too long. The request might be blocked by the browser.`,
            );
          }

          if (match) {
            query['fields.slug[match]'] = stringifiedBatchedSlugs;
          } else if (ne) {
            query['fields.slug[ne]'] = stringifiedBatchedSlugs;
          } else {
            query['fields.slug[in]'] = stringifiedBatchedSlugs;
          }

          const [, res] = await makeRequest(query, result);
          result = res;

          if (batchIndex < batchedSlugs.length - 1) {
            batchIndex += 1;
          } else {
            requestNext = false;
          }
        }
      }

      // for `all` and `ne` cases it will always be a mismatch in slugs length
      if (!all && !ne && result && result.items.length !== slugs.length) {
        const resultSlugs = result?.items.map(
          (item) => (item.fields as { slug: string }).slug,
        );
        if (result?.items.length > slugs.length) {
          Sentry.captureMessage(`Duplicated content in contentful [${today}]`, {
            extra: {
              contentType,
              requestedSlugs: slugs,
              receivedSlugs: resultSlugs,
            },
          });
        } else {
          const diff = slugs.filter((slug) => !resultSlugs.includes(slug));
          Sentry.captureMessage(`Missing content in contentful [${today}]`, {
            extra: {
              contentType,
              missingContentForSlugs: diff,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    dispatch({
      type: `${SUCCESS_CONTENTFUL_DATA}_${reqId}`,
      payload: { data: result, reqId },
    });
  };
