import { getIsActive } from 'modules/Account/selectors';
import { checkAuth } from 'modules/Auth/actions';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router';
import Routes from 'routes';
import { getEmail } from 'modules/Account/selectors';
import {
  getAccountDataRequest,
  getIsActive as fetchIsActive,
} from 'modules/Account/actions';

const VERIFY_EMAIL_ROUTES = [
  Routes.HT_ORDER_CONFIRMED,
  Routes.HT_ADDRESS,
  Routes.VERIFY_EMAIL,
];

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isActive = useSelector(getIsActive);
  const history = useHistory();
  const email = useSelector(getEmail);

  useEffect(() => {
    dispatch(checkAuth());
    if (isAuthenticated) dispatch(fetchIsActive());
  }, [dispatch, isActive, isAuthenticated]);

  useEffect(() => {
    // Get account data only when the user is authenticated
    if (isAuthenticated === true && isActive === true) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, email, isActive, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated === false) {
      history.replace(Routes.LOGIN);
    }
    if (isAuthenticated && isActive === false) {
      if (!VERIFY_EMAIL_ROUTES.includes(history.location.pathname)) {
        history.replace(Routes.VERIFY_EMAIL);
      }
    }
  }, [isAuthenticated, isActive, history, dispatch]);

  return isAuthenticated ? (
    <Route component={Component} {...props}>
      {children}
    </Route>
  ) : null;
};

export default PrivateRoute;
