import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import {
  ResetPassword,
  ForgotPassword,
  Login,
  Home,
  OAuthRedirectCallbackPage,
} from 'modules/Auth/pages';
import { Account, DeleteAccount, DeleteConfirm } from 'modules/Account/pages';
import { NotificationPopup } from 'modules/NotificationsHandler/pages';
import { Help } from 'modules/Help/pages';
import { Regulation } from 'modules/Regulation/pages';
import {
  ReplacePreviewPage,
  StudyUnlockPage,
} from 'modules/PreviewReport/pages';
import {
  Question,
  VerifyEmail,
  Feedback,
  Start,
  ActivateAccount,
  ResendCustom,
  Dealbreaker,
} from 'modules/Questionnaire/pages';
import {
  HTShippingAddress,
  Pharmacy,
  PrescriptionConfirmation,
  OrderSuccess,
} from './modules/Checkout/pages';
import PrivacyPage from 'modules/Questionnaire/pages/PrivacyPage';
import { ResultsPage } from 'modules/Results/pages/ResultsPage';
import { RejectedResultsPage } from 'modules/Results/pages/RejectedResultsPage';
import { ReviewResultsPage } from 'modules/Results/pages/ReviewResultsPage';
import { LegalNamePage } from 'modules/Questionnaire/pages/LegalNamePage';
import { ClinicGetStartedPage } from 'modules/Questionnaire/pages/ClinicGetStartedPage';
import { NotFoundPage } from 'modules/static/NotFoundPage';
import { AuthRoute, PrivateRoute, PageViewTracker } from 'shared';
import Routes from './routes';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    CookieControl: any;
    /* eslint-enable */
  }
}

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary>
      <Router>
        <Switch>
          <AuthRoute path={Routes.LOGIN} component={Login} exact={true} />
          <AuthRoute
            path={`${Routes.OAUTH_REDIRECT}/:oAuthProvider`}
            component={OAuthRedirectCallbackPage}
            exact
          />
          <AuthRoute
            path={Routes.FORGOT_PASSWORD}
            component={ForgotPassword}
            exact={true}
          />
          <AuthRoute
            component={ResetPassword}
            exact={true}
            path={`${Routes.RESET_PASSWORD}/confirm/:uid/:token`}
          />
          <PrivateRoute path={Routes.HOME} component={Home} exact />
          <PrivateRoute path={Routes.ACCOUNT} component={Account} />
          <PrivateRoute path={Routes.HELP} component={Help} />
          <AuthRoute path={Routes.REGULATION} component={Regulation} />
          <AuthRoute
            path={`${Routes.CLINICS_GET_STARTED}/:clinicSlug`}
            component={ClinicGetStartedPage}
          />
          <PrivateRoute
            path={Routes.QUESTIONNAIRE}
            component={Question}
            exact
          />
          <PrivateRoute path={Routes.FEEDBACK} component={Feedback} />
          <PrivateRoute path={Routes.RESULTS} component={ResultsPage} exact />
          <PrivateRoute
            path={Routes.REVIEW_RESULTS}
            component={ReviewResultsPage}
            exact
          />
          <PrivateRoute
            path={Routes.RESULTS_CLINICAL_REVIEW}
            component={RejectedResultsPage}
            exact
          />
          <PrivateRoute
            path={Routes.DEALBREAKER}
            component={Dealbreaker}
            exact
          />
          <PrivateRoute path={Routes.VERIFY_EMAIL} component={VerifyEmail} />
          <AuthRoute
            path={Routes.PUBLIC_VERIFY_EMAIL}
            component={VerifyEmail}
          />
          <AuthRoute
            path={Routes.RESEND_EMAIL_CUSTOM}
            component={ResendCustom}
          />
          <AuthRoute
            path={`${Routes.ACTIVATE}/:uid?/:token?`}
            component={ActivateAccount}
          />
          <AuthRoute path={Routes.START_HA} component={Start} exact />
          <PrivateRoute
            path={Routes.PREVIEW}
            component={ReplacePreviewPage}
            exact
          />
          <AuthRoute
            path={Routes.STUDY_UNLOCK}
            component={StudyUnlockPage}
            exact
          />
          <PrivateRoute
            path={Routes.ORDER_SUCCESS}
            component={OrderSuccess}
            exact
          />
          <PrivateRoute
            path={Routes.PRESCRIPTION_CONFIRMATION}
            component={PrescriptionConfirmation}
            exact
          />
          <PrivateRoute
            path={`${Routes.PHARMACY}/:ampId`}
            component={Pharmacy}
            exact
          />
          <PrivateRoute
            path={Routes.HT_ADDRESS}
            component={HTShippingAddress}
            exact
          />
          <PrivateRoute path={Routes.TERMS} component={PrivacyPage} exact />
          <PrivateRoute
            path={Routes.LEGAL_NAME}
            component={LegalNamePage}
            exact
          />
          <PrivateRoute path={Routes.DELETE} component={DeleteAccount} exact />
          <Route path={Routes.DELETE_CONFIRM} component={DeleteConfirm} exact />
          <Route path={Routes.DELETE_CONFIRM} component={DeleteConfirm} exact />
          <Route path={`/:clinicSlug`} component={NotFoundPage} exact />
        </Switch>
        <PageViewTracker />
      </Router>
      <NotificationPopup />
    </Sentry.ErrorBoundary>
  );
};

export default App;
