import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { invalidateCompletion } from 'modules/Questionnaire/actions';
import { getIsLoading } from 'modules/Loading/selectors';
import { INVALIDATE_COMPLETION } from 'modules/Questionnaire/constants';
import { LoadingPageProps } from 'shared/LoadingPage';

export const useMandatoryChange = (): LoadingPageProps => {
  const dispatch = useDispatch();

  const isInvalidationLoading = useSelector(
    getIsLoading(INVALIDATE_COMPLETION),
  );

  const handleBackToHA = useCallback(() => {
    dispatch(invalidateCompletion());
  }, [dispatch]);

  return {
    CTAProps: {
      onClick: handleBackToHA,
      startIcon: isInvalidationLoading ? (
        <CircularProgress size={20} color="inherit" />
      ) : null,
    },
    CTAText: 'COMPLETE HEALTH ASSESSMENT',
    messages: ['We’ve just got a few more questions to ask!'],
    subMessages: [
      'Our Health Assessment has been updated since you last visited us. Answer the following questions to get your most accurate recommendation.',
    ],
  };
};
