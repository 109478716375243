import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dealbreaker } from '../components/Dealbreaker';
import {
  getDealbreakerIssues as selectDealbreakerIssues,
  getDealbreaker,
} from '../selectors';
import {
  setDealbreaker,
  setDealbreakerIssuesRank,
  setPageNum,
} from '../actions';
import { DealbreakerAnalyticsMethods } from '../pages/DealbreakerPage';
import { getIsLoading } from 'modules/Loading/selectors';
import { SET_DEALBREAKER_ISSUES_RANK } from '../constants';
import { GET_ACCOUNT_DATA } from '../../Account/constants';

export const DealbreakerContainer: React.FC<{
  analytics: DealbreakerAnalyticsMethods;
}> = ({ analytics: analyticsMethods }) => {
  const dispatch = useDispatch();

  const isDealbreakerLoading = useSelector(
    getIsLoading(SET_DEALBREAKER_ISSUES_RANK),
  );
  const isAccountDataLoading = useSelector(getIsLoading(GET_ACCOUNT_DATA));

  const symptoms = useSelector(selectDealbreakerIssues);
  const dealbreaker = useSelector(getDealbreaker);

  const handleOptionClick = useCallback(
    (optionSlug: string) => {
      if (optionSlug === dealbreaker) {
        dispatch(setDealbreaker(null));
      } else {
        dispatch(setDealbreaker(optionSlug));
      }
    },
    [dealbreaker, dispatch],
  );

  const handleDealbreakerAnalytics = useCallback(() => {
    const isDealbreakerSkipped = !dealbreaker;
    if (isDealbreakerSkipped) {
      analyticsMethods.trackDealbreakerSkipped('user');
    } else {
      analyticsMethods.trackDealbreakerSelected(dealbreaker);
    }
    // dealbreak page trigger analytics only when ranking is not showed.
    // It means that ranking is always skipped by code
    analyticsMethods.trackRankingSkipped('code');
  }, [dealbreaker, analyticsMethods]);

  const handleContinue = useCallback(() => {
    // if there are two symptoms, but one is selected as dealbreaker, no need in ranking screen.
    // If dealbreaker selected, the screen will have only one item.
    // So I handleAnalytics, submit the dealbreaker and let the user continue journey
    if (symptoms?.length === 2 && !!dealbreaker) {
      handleDealbreakerAnalytics();
      dispatch(
        setDealbreakerIssuesRank({
          dealbreakerIssue: { slug: dealbreaker },
          issuesRank: symptoms?.filter(({ slug }) => slug !== dealbreaker),
        }),
      );
    } else {
      dispatch(setPageNum(1));
    }
  }, [symptoms, dealbreaker, handleDealbreakerAnalytics, dispatch]);

  return (
    <Dealbreaker
      isLoading={
        isDealbreakerLoading === 1 ||
        (isDealbreakerLoading === 0 && isAccountDataLoading === 1)
      }
      symptoms={symptoms}
      dealbreaker={dealbreaker}
      handleOptionClick={handleOptionClick}
      handleContinue={handleContinue}
    />
  );
};
