import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { marketingSchema, MARKETING } from 'modules/Questionnaire/constants';
import { getDefaultCountry } from 'utils/helpers';
import { setGetStartedMarketingAndStateInfo } from 'modules/Questionnaire/actions';
import { MarketingAndStateLayout } from 'modules/Questionnaire/components/GetStarted/MarketingAndStateLayout';
import { getStartedData } from 'modules/Questionnaire/selectors';
import { useOAuthSignupRedirect } from 'utils/hooks/useOAuthSignupRedirect';
import { US_SUPPORTED_STATE_CODES } from 'utils/usStates';
import { useAnalytics } from 'utils/analytics';
import {
  ANALYTICS_CATEGORY,
  ANALYTICS_EVENT,
  SIGNUP_METHOD,
} from 'utils/analytics/constants';

export const MarketingAndStateInfoContainer: React.FC = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const oAuthSignupRedirect = useOAuthSignupRedirect();
  const defaultCountry = useMemo(() => {
    return getDefaultCountry();
  }, []);

  const { currentQuestion, accountInfo, oAuthProvider, signupType } =
    useSelector(getStartedData);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT.PAGE_VIEW, {
      category: ANALYTICS_CATEGORY.GET_STARTED,
      location: `${location.pathname}${location.hash}`,
      getStartedStep: 'state select',
    });
  }, [analytics]);

  useEffect(() => {
    if (isFormSubmitted && accountInfo?.state) {
      if (US_SUPPORTED_STATE_CODES.includes(accountInfo?.state)) {
        analytics.track(ANALYTICS_EVENT.CREATE_ACCOUNT, {
          category: ANALYTICS_CATEGORY.ACCOUNT,
          signUpMethod: SIGNUP_METHOD.SOCIAL,
          authProvider: oAuthProvider,
          signupType,
        });
      }

      oAuthSignupRedirect();
    }
  }, [
    isFormSubmitted,
    accountInfo?.state,
    dispatch,
    currentQuestion,
    oAuthSignupRedirect,
    analytics,
    oAuthProvider,
    signupType,
  ]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<AccountInfoFormData>>({
    defaultValues: {
      [MARKETING.researchPreference]: true,
      [MARKETING.marketingPreference]: true,
    },
    resolver: yupResolver(marketingSchema),
    mode: 'onTouched',
  });

  const submit = useCallback(
    ({ state, phone, ...optionAnswers }) => {
      const marketingOptions = new Set();
      if (optionAnswers[MARKETING.marketingPreference])
        marketingOptions.add(MARKETING.marketingPreference);
      if (optionAnswers[MARKETING.researchPreference])
        marketingOptions.add(MARKETING.researchPreference);

      const userData = {
        state,
        phone: `${getDefaultCountry().phoneCode} ${phone}`,
        country: defaultCountry?.name,
        marketingOptions,
      };

      dispatch(setGetStartedMarketingAndStateInfo(userData));
      setIsFormSubmitted(true);
    },
    [dispatch, setIsFormSubmitted, defaultCountry?.name],
  );

  const onStateSelectedCallback = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
    ) => {
      if (event?.target?.value) {
        analytics.track(ANALYTICS_EVENT.GET_STARTED_STATE_DROPDOWN, {
          category: ANALYTICS_CATEGORY.GET_STARTED,
          selectedState: event.target.value as string,
        });
      }
    },
    [analytics],
  );

  return (
    <MarketingAndStateLayout
      control={control}
      handleSubmit={handleSubmit(submit)}
      errors={errors}
      onStateSelected={onStateSelectedCallback}
    />
  );
};
