import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import { useInterval } from './useInterval';
import { getIsActive as fetchIsActive } from 'modules/Account/actions';
import { getIsActive } from 'modules/Account/selectors';
import { checkAuth } from 'modules/Auth/actions';

export const useCheckIsUserActive = (): void => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isActive = useSelector(getIsActive);
  const [interval, setInterval] = useState<number | null>(5000);

  useInterval(() => {
    if (isAuthenticated) dispatch(fetchIsActive());
  }, interval);

  useEffect(() => {
    if (isAuthenticated === undefined) dispatch(checkAuth());
    if (isAuthenticated && isActive === undefined) dispatch(fetchIsActive());
  }, [isAuthenticated, isActive, dispatch]);

  useEffect(() => {
    if (isAuthenticated === false || isActive) {
      setInterval(null);
    }
  }, [dispatch, isActive, isAuthenticated]);
};
