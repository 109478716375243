import React from 'react';
import { BODY1, Button, H2, H4 } from '@tuunetech/tuune-components';
import { SpinnerContainer } from 'shared/Spinner/styles';
import { VerifyContainer, ChangeEmailButton } from './styles';
import { useWebpImage } from 'utils/image';
import { CircularProgress, Box } from '@material-ui/core';
import { AuthLayoutImg } from 'shared/AuthLayout/styles';

interface VerifyEmailProps {
  handleResend: () => void;
  handleChangeEmail: () => void;
  firstName: string;
  email: string;
  isAuthenticated?: boolean;
  isOrderSuccessFlow: boolean;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  email,
  firstName,
  handleResend,
  handleChangeEmail,
  isAuthenticated,
  isOrderSuccessFlow,
}) => {
  const [activationEmail] = useWebpImage('activation_email.jpg');

  const getTitleSection = () =>
    isOrderSuccessFlow ? (
      <>
        <H2 data-hj-suppress>
          <span data-hj-suppress>{firstName}</span>, thank you for your order!
        </H2>
        <H4>Please verify your account to continue.</H4>
      </>
    ) : (
      <H2 data-hj-suppress>
        <span data-hj-suppress>{firstName}</span>, please verify your account to
        continue.
      </H2>
    );

  return (
    <VerifyContainer>
      <Box width="630px" maxWidth="100%" marginX="auto">
        <AuthLayoutImg src={activationEmail} alt="activate email" />
        {email ? (
          <>
            {getTitleSection()}
            <BODY1>
              <span>We sent a verification link to {email}</span>
              {!isAuthenticated ? (
                <>
                  <span>
                    If this is the wrong email address click the button below to
                    enter a new one.
                  </span>
                  <ChangeEmailButton
                    variant="outlined"
                    onClick={(): void => handleChangeEmail()}
                    fullWidth
                  >
                    Change email address
                  </ChangeEmailButton>
                </>
              ) : null}
              <span>
                If you didn’t get it, check your junk folder or click the button
                below.
              </span>
            </BODY1>
            <Button
              variant="outlined"
              onClick={(): void => handleResend()}
              fullWidth
            >
              resend activation link
            </Button>
          </>
        ) : (
          <SpinnerContainer>
            <CircularProgress color="primary" />
          </SpinnerContainer>
        )}
      </Box>
    </VerifyContainer>
  );
};

export default VerifyEmail;
