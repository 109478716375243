import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import { StartLayout } from '../components/StartLayout';

export const StartPage: React.FC = () => {
  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const handleNext = useCallback(() => {
    if (isAuthenticated) {
      history.replace(Routes.QUESTIONNAIRE);
    } else {
      history.replace(Routes.LOGIN);
    }
  }, [history, isAuthenticated]);

  return <StartLayout handleNext={handleNext} />;
};
