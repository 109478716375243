import styled from 'styled-components';

export const Banner = styled.div<{ $isWarning: boolean }>`
  background-color: ${({ theme, $isWarning }) =>
    $isWarning
      ? theme.palette.common.purple[50]
      : theme.palette.common.grey[50]};
  display: flex;
  justify-content: center;
`;
