export const positiveSentimentsReportLight = [
  'Accurate',
  'Insightful',
  'Detailed',
  'Approachable',
  'Empathetic',
  'Science-driven',
  'Clear',
  'Validating',
  'Valuable',
  'Easy to understand',
];
export const negativeSentimentsReportLight = [
  'Confusing',
  'Unclear',
  'Questionable',
  'Overcomplicated',
  'Inaccurate',
  'Underwhelming',
  'Generic',
  'Insensitive',
];
export const positiveSentimentsReportPlus = [
  'Trustworthy',
  'Educational',
  'Personalized',
  'Actionable',
  'Approachable',
  'Empathetic',
  'Science-driven',
  'Easy to understand',
  'Accurate',
];
export const negativeSentimentsReportPlus = [
  'Confusing',
  'Unclear',
  'Questionable',
  'Overcomplicated',
  'Overwhelming',
  'Generic',
  'Not valuable',
  'Unhelpful',
  'Too clinical',
];

export const sectionTitleReportLight =
  'What do you think about your Personalized Wellbeing Report?';
export const sectionTitleReportPlus =
  'What do you think about your birth control recommendation?';

export const feedbackOriginReportLight = 'freemium';
export const feedbackOriginReportPlus = 'full_report';
