import React from 'react';
import { BODY2, AppHeader, Container, H3 } from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { Page } from 'shared';
import { AuthLayoutImg } from 'shared/AuthLayout/styles';

interface AuthLayoutProps {
  title: string;
  subTitle?: string;
  content: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  title,
  subTitle,
  content,
}) => {
  const [authBg] = useWebpImage('auth_bg.jpg');
  const { contactUs } = useBaseNavButtons();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Page>
      <AppHeader buttons={[contactUs]} />
      <Container>
        <Box width="630px" maxWidth="100%" marginX="auto">
          <AuthLayoutImg src={authBg} alt="Sign in" />
          <H3
            $verticalMargin={isDesktop ? 40 : 32}
            $marginBottom={subTitle ? 5 : undefined}
          >
            {title}
          </H3>
          {subTitle && (
            <BODY2 color="textSecondary" $marginBottom={isDesktop ? 40 : 32}>
              {subTitle}
            </BODY2>
          )}
          <div>{content}</div>
        </Box>
      </Container>
    </Page>
  );
};

export default AuthLayout;
