import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { QuestionnaireReducerState } from '../types';
import { AnswerTypesMap, ServerQuestionTypesMap } from '../constants';

const selectQuestionnaire: Selector<RootState, QuestionnaireReducerState> = (
  state,
) => state.questionnaire;

export const getQuestionId = createSelector(
  selectQuestionnaire,
  (state) => state.questionId,
);

export const getCurrent = createSelector(
  selectQuestionnaire,
  (state) => state.current,
);

export const getQuestionData = createSelector(selectQuestionnaire, (state) => ({
  totalCount: state.totalCount,
  current: state.current,
  sectionName: state.sectionName,
  questionTitle: state.questionTitle,
  subTitle: state.subTitle,
  helpText: state.helpText,
  sectionId: state.sectionId,
  questionType: state.questionType,

  currentSection: state.currentSection,
  sections: state.sections,
}));

export const getQuestionTitle = createSelector(
  selectQuestionnaire,
  (state) => state.questionTitle,
);

export const getFactsSlugs = createSelector(
  selectQuestionnaire,
  (state) => state.factsSlugs,
);

export const getAnswers = createSelector(
  selectQuestionnaire,
  (state) => state.answers,
);

export const getPreviousAnswers = createSelector(
  selectQuestionnaire,
  (state) => state.previousAnswers,
);

export const getNotaAnswer = createSelector(
  selectQuestionnaire,
  (state) => state.notaAnswer,
);

export const getAnswerComponentType = createSelector(
  selectQuestionnaire,
  (state) => {
    switch (state.questionType) {
      case ServerQuestionTypesMap.WEIGHT:
      case ServerQuestionTypesMap.HEIGHT:
      case ServerQuestionTypesMap.CURRENT_PILL:
      case ServerQuestionTypesMap.SIDE_EFFECTS:
      case ServerQuestionTypesMap.CYCLE:
      case ServerQuestionTypesMap.INFO:
      case ServerQuestionTypesMap.SUBJECTIVE:
      case ServerQuestionTypesMap.CONTENTFUL_HEADER_SECTION:
        return state.questionType;
      case ServerQuestionTypesMap.AUTOCOMPLETE:
        return AnswerTypesMap.PILL;
      case ServerQuestionTypesMap.FACT:
      case ServerQuestionTypesMap.FACT_RADIO:
        if (state.answers.length > 1) {
          return AnswerTypesMap.MULTI_CHOICE;
        } else if (state.answers.length === 1) {
          if (state.answers[0].name === AnswerTypesMap.DOB) {
            return AnswerTypesMap.DOB;
          }
          return state.answers[0].answer_type;
        }
        return undefined;
      default:
        return undefined;
    }
  },
);

export const getMeasureSystem = createSelector(
  selectQuestionnaire,
  (state) => state.measureSystem,
);

export const getIsCompleted = createSelector(
  selectQuestionnaire,
  (state) => state.isCompleted,
);

export const getStartedData = createSelector(
  selectQuestionnaire,
  (state) => state.getStarted,
);

export const getIsClinicSignupFlow = createSelector(
  selectQuestionnaire,
  (state) => !!state.getStarted.clinicDetails,
);

export const getPasswordSet = createSelector(
  selectQuestionnaire,
  (state) => state.passwordSet,
);

export const getIsQuestionLoading = createSelector(
  selectQuestionnaire,
  (state) => state.isQuestionLoading,
);

export const getChoiceQuestionType = createSelector(
  selectQuestionnaire,
  (state) => state.questionType,
);

export const getFeedbackSubmitted = createSelector(
  selectQuestionnaire,
  (state) => state.feedbackSubmitted,
);

export const getHasFeedback = createSelector(
  selectQuestionnaire,
  (state) => state.feedback !== undefined,
);
