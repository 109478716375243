import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { ComplexOutputSelector } from 'reduxStore';
import {
  ContentfulAssetsSet,
  ContentfulEntriesSet,
  ContentfulReducerState,
} from '../types';

const selectContentful: Selector<RootState, ContentfulReducerState> = (state) =>
  state.contentful;

const FALLBACK = {};

export const getArrayContentMapped = (
  key: string,
): ComplexOutputSelector<ContentfulReducerState, ContentfulEntriesSet> =>
  createSelector(selectContentful, (state) => {
    return (state[key] as ContentfulEntriesSet) || FALLBACK;
  });

export type AssetRecord = Record<
  string,
  {
    name: string;
    slug: string;
    asset: string;
  }
>;
export const getAssetsMapped = (
  key: string,
): ComplexOutputSelector<ContentfulReducerState, AssetRecord> =>
  createSelector(selectContentful, (state) => {
    const content = state[key] as ContentfulAssetsSet;
    if (!content) return <AssetRecord>FALLBACK;

    return Object.keys(content).reduce<AssetRecord>((assetsContent, slug) => {
      const item = content[slug];
      assetsContent[slug] = {
        name: item.name,
        slug: item.slug,
        asset: `https:${item.content.fields.file.url}`,
      };
      return assetsContent;
    }, {});
  });
