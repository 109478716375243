import React from 'react';
import { Box } from '@material-ui/core';
import {
  Container,
  GridContainer,
  GridItem,
  H5,
} from '@tuunetech/tuune-components';

import { Banner } from './styles';

import { QuestionsForAppointment } from '../QuestionsForAppointment';

export const RejectedResults: React.FC = () => {
  return (
    <Container>
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={10} lg={7}>
          <Banner>
            <H5>
              Your report has been reviewed and your clinician has some more
              questions before it can be unlocked.
            </H5>
            <H5 $marginTop={16}>
              Please discuss your health assessment with your clinician during
              your appointment.
            </H5>
          </Banner>
          <Box marginTop="40px">
            <QuestionsForAppointment />
          </Box>
        </GridItem>
      </GridContainer>
    </Container>
  );
};
