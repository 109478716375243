import level from './level';
import dna from './dna';
import calendar from './calendar';
import medHistory from './medHistory';
import pillHistory from './pillHistory';
import heart from './heart';
import bloodClotRisk from './bloodClotRisk';
import keepHavingPeriods from './keepHavingPeriods';
import medicalProcedure from './medicalProcedure';
import pregnancyPreventionTypical from './pregnancyPreventionTypical';
import renewEvery from './renewEvery';
import returnBaselineFertility from './returnBaselineFertility';

const iconsObject: { [key: string]: string } = {
  level,
  dna,
  calendar,
  medHistory,
  pillHistory,
  heart,
  bloodClotRisk,
  keepHavingPeriods,
  medicalProcedure,
  pregnancyPreventionTypical,
  renewEvery,
  returnBaselineFertility,
};

Object.keys(iconsObject).forEach(
  (key) => (iconsObject[key] = `data:image/svg+xml,${iconsObject[key]}`),
);

export const icons: { [key: string]: string } = {
  ...iconsObject,
};

export { Account as AccountIcon } from './Account';
export { CalendarDate } from './CalendarDate';
export { Star as StarIcon } from './Star';
export * from './mood';
