import { ActionCreator } from 'redux';
import { AppThunk } from 'reduxStore';
import { getFromStorage, setToStorage } from 'utils/helpers';
import {
  GET_RECOMMENDATION_RESULTS_REQUEST,
  SET_BENEFITS_CURRENT,
  SET_PILL_INFO_CURRENT,
  SET_RECAP_GOALS_CLOSED,
  SET_REGIMEN_CURRENT,
  SET_SYMPTOM_CURRENT,
} from '../constants';
import { BenefitCurrent, PillInfoCurrent, ResultsActionTypes } from '../types';

export const getRecommendationResultsRequest: ActionCreator<
  ResultsActionTypes
> = () => {
  return {
    type: GET_RECOMMENDATION_RESULTS_REQUEST,
  };
};

export const setRecapGoalsClosed =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    await setToStorage('recapGoalsClosed', true);
    dispatch({
      type: SET_RECAP_GOALS_CLOSED,
      payload: {
        isClosed: true,
      },
    });
  };

export const getRecapGoalsClosedFromStorage =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const isRecapGoalsClosed = await getFromStorage('recapGoalsClosed');
    dispatch({
      type: SET_RECAP_GOALS_CLOSED,
      payload: {
        isClosed: !!isRecapGoalsClosed,
      },
    });
  };

export const setPillInfoCurrent: ActionCreator<ResultsActionTypes> = (
  pillInfo: PillInfoCurrent,
) => {
  return {
    type: SET_PILL_INFO_CURRENT,
    payload: {
      pillInfo,
    },
  };
};

export const setSymptomCurrent: ActionCreator<ResultsActionTypes> = (
  symptomSlug: string,
) => {
  return {
    type: SET_SYMPTOM_CURRENT,
    payload: {
      symptom: symptomSlug,
    },
  };
};

export const setBenefitsCurrent: ActionCreator<ResultsActionTypes> = (
  benefits: BenefitCurrent,
) => {
  return {
    type: SET_BENEFITS_CURRENT,
    payload: {
      benefits,
    },
  };
};

export const setRegimenCurrent: ActionCreator<ResultsActionTypes> = (
  regimen: string,
) => {
  return {
    type: SET_REGIMEN_CURRENT,
    payload: {
      regimen,
    },
  };
};
