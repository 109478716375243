import { FAIL, REQUEST, SUCCESS } from 'utils/requestStates';

export const REPORT_UNLOCK = 'REPORT_UNLOCK';
export const REPORT_UNLOCK_REQUEST = `${REPORT_UNLOCK}_${REQUEST}` as const;
export const REPORT_UNLOCK_SUCCESS = `${REPORT_UNLOCK}_${SUCCESS}` as const;
export const REPORT_UNLOCK_FAIL = `${REPORT_UNLOCK}_${FAIL}` as const;

export const RESEARCH_AUTO_APPROVE = 'RESEARCH_AUTO_APPROVE';
export const RESEARCH_AUTO_APPROVE_REQUEST =
  `${RESEARCH_AUTO_APPROVE}_${REQUEST}` as const;
export const RESEARCH_AUTO_APPROVE_SUCCESS =
  `${RESEARCH_AUTO_APPROVE}_${SUCCESS}` as const;
export const RESEARCH_AUTO_APPROVE_FAIL =
  `${RESEARCH_AUTO_APPROVE}_${FAIL}` as const;

export const SUBMIT_PRESCRIPTION_ORDER = 'SUBMIT_PRESCRIPTION_ORDER';
export const SUBMIT_PRESCRIPTION_ORDER_REQUEST =
  `${SUBMIT_PRESCRIPTION_ORDER}_${REQUEST}` as const;
export const SUBMIT_PRESCRIPTION_ORDER_SUCCESS =
  `${SUBMIT_PRESCRIPTION_ORDER}_${SUCCESS}` as const;

export const GET_PHARMACY_DETAILS = 'GET_PHARMACY_DETAILS';
export const GET_PHARMACY_DETAILS_REQUEST =
  `${GET_PHARMACY_DETAILS}_${REQUEST}` as const;
export const GET_PHARMACY_DETAILS_SUCCESS =
  `${GET_PHARMACY_DETAILS}_${SUCCESS}` as const;

export const SET_SIGNUP_ORDER_UUID = 'SET_SIGNUP_ORDER_UUID';
