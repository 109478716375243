import { SUBMIT_HA_USER_FEEDBACK_REQUEST } from 'modules/PreviewReport/constants';
import { PreviewRequestActionTypes } from 'modules/PreviewReport/types';
import { HAFeedbackPayload } from 'modules/PreviewReport/types/payloads';
import { ApiHost } from 'utils/axios';
import { ApiReturnFunc } from './index';

const previewApi: Record<PreviewRequestActionTypes, ApiReturnFunc> = {
  [SUBMIT_HA_USER_FEEDBACK_REQUEST]: ({ tags, text }: HAFeedbackPayload) => {
    return {
      url: `${ApiHost}/api/v1/user_feedback/`,
      method: 'post',
      data: {
        tags: tags,
        text: text,
      },
      headers: {},
    };
  },
};

export default previewApi;
