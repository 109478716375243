export enum ContentTypeNames {
  STATIC_CONTENT = 'STATIC_CONTENT',
  ISSUE_SNIPPET_GENERIC = 'ISSUE_SNIPPET_GENERIC',
  RECOMMENDATION_INTRO = 'RECOMMENDATION_INTRO',
  ISSUE_SNIPPET_SPECIFIC = 'ISSUE_SNIPPET_SPECIFIC',
  RECOMMENDED_REGIMEN_SUMMARY = 'RECOMMENDED_REGIMEN_SUMMARY',
  RECOMMENDED_REGIMEN_DETAILS_GENERIC = 'RECOMMENDED_REGIMEN_DETAILS_GENERIC',
  RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC = 'RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC',
  RECOMMENDED_CONSIDERATIONS_SUMMARY = 'RECOMMENDED_CONSIDERATIONS_SUMMARY',
  CONTRACEPTION_DETAIL_PERSONALISED = 'CONTRACEPTION_DETAIL_PERSONALISED',
  CONTRACEPTION_TYPE_BENEFIT_DETAIL_GENERIC = 'CONTRACEPTION_TYPE_BENEFIT_DETAIL_GENERIC',
  CONTRACEPTION_INGREDIENT_DETAILS = 'CONTRACEPTION_INGREDIENT_DETAILS',
  OC_SUITABILITY = 'OC_SUITABILITY',
  OC_SUITABILITY_EXPLANATION = 'OC_SUITABILITY_EXPLANATION',
  CHC_UNSUITABILITY_EXPLANATION = 'CHC_UNSUITABILITY_EXPLANATION',
  SWITCHING_CONTRACEPTIVE_METHOD_GENERIC = 'SWITCHING_CONTRACEPTIVE_METHOD_GENERIC',
  IMPACT_SCORE = 'IMPACT_SCORE',
  IMPACT_SCORE_EXPLAINED = 'IMPACT_SCORE_EXPLAINED',
  ISSUE_ICON = 'ISSUE_ICON',
  CONTRACEPTION_ADDITIONAL_INFORMATION = 'CONTRACEPTION_ADDITIONAL_INFORMATION',
  CONTENTFUL_HEADER_SECTION = 'CONTENTFUL_HEADER_SECTION',
}

export const CONTENT_TYPE: Record<ContentTypeNames, string> = {
  [ContentTypeNames.STATIC_CONTENT]: 'staticContent',
  ISSUE_SNIPPET_GENERIC: 'sideEffectconditionSnippet',
  ISSUE_SNIPPET_SPECIFIC: 'issueSnippetSpecific',
  RECOMMENDATION_INTRO: 'recommendationIntro',
  RECOMMENDED_REGIMEN_SUMMARY: 'regimenSummary',
  RECOMMENDED_REGIMEN_DETAILS_GENERIC: 'regimenDetailsGeneric',
  RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC:
    'recommendedRegimenSafetyEfficacyGeneric',
  RECOMMENDED_CONSIDERATIONS_SUMMARY: 'pillGenericConsiderationDetails',
  CONTRACEPTION_DETAIL_PERSONALISED: 'contraceptionDetailPersonalised',
  CONTRACEPTION_TYPE_BENEFIT_DETAIL_GENERIC:
    'contraceptionTypeBenefitDetailGeneric',
  CONTRACEPTION_INGREDIENT_DETAILS: 'pillIngredient',
  OC_SUITABILITY: 'cocSuitability',
  OC_SUITABILITY_EXPLANATION: 'cocSuitabilityExplanation',
  CHC_UNSUITABILITY_EXPLANATION: 'chcUnsuitabilityExplanation',
  SWITCHING_CONTRACEPTIVE_METHOD_GENERIC: 'switchingContraceptiveMethodGeneric',
  [ContentTypeNames.IMPACT_SCORE]: 'impactScore',
  [ContentTypeNames.IMPACT_SCORE_EXPLAINED]: 'impactScoreExplained',
  [ContentTypeNames.ISSUE_ICON]: 'issue',
  [ContentTypeNames.CONTRACEPTION_ADDITIONAL_INFORMATION]:
    'contraceptionAdditionalInformation',
  [ContentTypeNames.CONTENTFUL_HEADER_SECTION]: 'sampleContent',
};
