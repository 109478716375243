import React, { ReactNode, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { CAPTION } from '@tuunetech/tuune-components';

import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getArrayContentMapped } from 'modules/Contentful/selectors';

import {
  SectionSubTitle,
  SectionTitle,
  HeaderImage,
  HelpIcon,
  SectionParagraph,
  VideoContainer,
  YoutubeIframe,
  RootContainer,
} from './styles';
import {
  CONTENTFUL_HELP_ICON_DESCRIPTION,
  ContentKeys,
} from 'modules/Questionnaire/constants/contentfulHeaderSection';
import { useAnalytics } from 'utils/analytics/hooks/useAnalytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

const YOUTUBE_IFRAME_ID = 'youtube-player';
const YOUTUBE_ENABLE_JS_URL_PREFIX = '?enablejsapi=1';

export const ContentfulHeaderSectionLayout: React.FC<{
  contentfulSlug: string;
}> = ({ contentfulSlug }) => {
  const sectionContent = useSelector(
    getArrayContentMapped(ContentKeys[CTN.CONTENTFUL_HEADER_SECTION].reqId),
  );

  const analytics = useAnalytics();

  const videoStartTimestampRef = useRef<number | undefined>(undefined);
  const setVideoStartTimestamp = useCallback(() => {
    videoStartTimestampRef.current = performance.now();
  }, []);

  const getSecondsPlayed = () => {
    if (videoStartTimestampRef.current) {
      return (
        (performance.now() - videoStartTimestampRef.current) /
        1000
      ).toFixed(2);
    }
  };

  const setYoutubeOnClickEvent = useCallback(() => {
    new window.YT.Player(YOUTUBE_IFRAME_ID, {
      events: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onStateChange: (event: any) => {
          switch (event.data) {
            case window.YT.PlayerState.PLAYING: {
              setVideoStartTimestamp();
              analytics.track(ANALYTICS_EVENT.GET_STARTED_PLAY_YOUTUBE_VIDEO, {
                category: ANALYTICS_CATEGORY.GET_STARTED,
              });
              break;
            }
            case window.YT.PlayerState.PAUSED: {
              analytics.track(ANALYTICS_EVENT.GET_STARTED_PAUSE_YOUTUBE_VIDEO, {
                category: ANALYTICS_CATEGORY.GET_STARTED,
                viewDurationSeconds: getSecondsPlayed(),
              });
              break;
            }
            case window.YT.PlayerState.ENDED: {
              analytics.track(ANALYTICS_EVENT.GET_STARTED_ENDED_YOUTUBE_VIDEO, {
                category: ANALYTICS_CATEGORY.GET_STARTED,
                viewDurationSeconds: getSecondsPlayed(),
              });
              break;
            }
          }
        },
      },
    });
  }, [analytics, setVideoStartTimestamp]);

  const youtubeRef = useCallback(
    (node) => {
      if (node) {
        // Setup the youtube player onClick event only after the iframe has been fully
        // loaded, to prevent 'postMessage' race conditions between Youtube API and the iframe
        node.onload = () => {
          if (window.YT.Player) {
            setYoutubeOnClickEvent();
          } else {
            // Youtube API has not been initialized yet, setup the onYouTubeIframeAPIReady()
            // function that will be called by Youtube API after being fully initialized
            window.onYouTubeIframeAPIReady = () => setYoutubeOnClickEvent();
          }
        };
      }
    },
    [setYoutubeOnClickEvent],
  );

  const renderOption: Options = {
    /* eslint-disable react/display-name */
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children): ReactNode => {
        return <SectionTitle $marginBottom={16}>{children}</SectionTitle>;
      },
      [BLOCKS.HEADING_2]: (node, children): ReactNode => {
        return (
          <SectionSubTitle $marginTop={40} $marginBottom={16}>
            {children}
          </SectionSubTitle>
        );
      },
      [BLOCKS.HEADING_3]: (node, children): ReactNode => {
        return <CAPTION $marginTop={16}>{children}</CAPTION>;
      },
      [BLOCKS.HEADING_6]: (node, children): ReactNode => {
        // Heading 6 is reserved for rendering embeddeed videos
        return <>{children}</>;
      },
      [BLOCKS.PARAGRAPH]: (node, children): ReactNode => {
        return <SectionParagraph paragraph>{children}</SectionParagraph>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imgUrl = node.data.target.fields.file.url;
        const contentfulDescription = node.data.target.fields.description;

        if (contentfulDescription === CONTENTFUL_HELP_ICON_DESCRIPTION) {
          return <HelpIcon src={`https://${imgUrl}`} />;
        } else {
          return <HeaderImage src={`https://${imgUrl}`} />;
        }
      },
      [INLINES.HYPERLINK]: (node): ReactNode => {
        return (
          <VideoContainer>
            <YoutubeIframe
              id={YOUTUBE_IFRAME_ID}
              ref={youtubeRef}
              src={node.data.uri + YOUTUBE_ENABLE_JS_URL_PREFIX}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoContainer>
        );
      },
    },
    renderMark: {
      [MARKS.BOLD]: (text) => {
        return <b>{text}</b>;
      },
      [MARKS.UNDERLINE]: (text) => {
        return <u>{text}</u>;
      },
      [MARKS.ITALIC]: (text) => {
        return <i>{text}</i>;
      },
    },
  };

  return (
    <RootContainer>
      {documentToReactComponents(
        sectionContent[contentfulSlug]?.content,
        renderOption,
      )}
    </RootContainer>
  );
};
