import { MARKETING } from 'modules/Questionnaire/constants';
import { ActionCreator, AnyAction } from 'redux';
import {
  GET_ACCOUNT_DATA_REQUEST,
  UPDATE_ACCOUNT_DATA_REQUEST,
  GET_IS_ACTIVE_REQUEST,
  UPDATE_MARKETING_PREFERENCE_REQUEST,
  SET_IS_ACTIVE,
  REGISTER_HORMONE_TEST_ADDRESS_REQUEST,
  UPDATE_PRIVACY_OPTIONS_REQUEST,
  GET_DELETION_REASONS_REQUEST,
  DEACTIVATE_USER_REQUEST,
} from '../constants';
import { AccountActionTypes, DeactivateUserPayload } from '../types';

export const getAccountDataRequest: ActionCreator<AccountActionTypes> = () => ({
  type: GET_ACCOUNT_DATA_REQUEST,
});

export const updateAccountData: ActionCreator<AccountActionTypes> = (user) => ({
  type: UPDATE_ACCOUNT_DATA_REQUEST,
  payload: {
    ...user,
  },
});

export const updateMarketingPreference: ActionCreator<AccountActionTypes> = (
  marketingPreference: Set<MARKETING>,
) => ({
  type: UPDATE_MARKETING_PREFERENCE_REQUEST,
  payload: {
    marketingPreference,
  },
});

export const updatePrivacyOptions: ActionCreator<AccountActionTypes> = (
  privacyOptions: string[],
) => ({
  type: UPDATE_PRIVACY_OPTIONS_REQUEST,
  payload: {
    privacyOptions,
  },
});

export const getIsActive: ActionCreator<AccountActionTypes> = () => ({
  type: GET_IS_ACTIVE_REQUEST,
});

export const setIsActive: ActionCreator<AccountActionTypes> = (isActive) => ({
  type: SET_IS_ACTIVE,
  payload: {
    isActive,
  },
});

export const registerHormoneTestAddress: ActionCreator<AccountActionTypes> = (
  address,
) => ({
  type: REGISTER_HORMONE_TEST_ADDRESS_REQUEST,
  payload: {
    ...address,
  },
});

export const getDeletionReasonsRequest: ActionCreator<AnyAction> = () => ({
  type: GET_DELETION_REASONS_REQUEST,
});

export const deactivateUserRequest: ActionCreator<AnyAction> = ({
  reason,
  freeText,
}: DeactivateUserPayload) => ({
  type: DEACTIVATE_USER_REQUEST,
  payload: {
    reason,
    freeText,
  },
});
