import camelcaseKeys from 'camelcase-keys';

export type VersionKeys =
  | 'clinicalReferral'
  | 'contraceptives'
  | 'mecRules'
  | 'highestMin'
  | 'lowestMax'
  | 'ha'
  | 'flags'
  | 'buildNumber'
  | 'assessment'
  | 'benefitMatrix'
  | 'benefitMatrixBuildNumber';

export const UserFriendlyVersionKeys: Record<VersionKeys, string> = {
  clinicalReferral: 'Clinical Referral',
  contraceptives: 'Contraceptives',
  mecRules: 'MEC Rules',
  highestMin: 'Questions Highest Min',
  lowestMax: 'Questions Lowest Max',
  ha: 'Questions',
  flags: 'Dynamic Rules',
  buildNumber: 'Build Num',
  assessment: 'Assessment',
  benefitMatrix: 'Impact matrix',
  benefitMatrixBuildNumber: 'Backend Version',
};

export const remapRawVersions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawVersions: Record<string, any>,
): Record<VersionKeys, string> => {
  const versions: Record<
    string,
    string | Record<'lowestMax' | 'highestMin', string>
  > = {
    ...camelcaseKeys(rawVersions, {
      deep: true,
    }),
  };

  return Object.keys(versions).reduce<Record<string, string>>((acc, vKey) => {
    if (typeof versions[vKey] === 'string') {
      acc[vKey] = versions[vKey] as string;
    } else {
      const minMaxVersion = versions[vKey] as Record<
        'lowestMax' | 'highestMin',
        string
      >;
      if (minMaxVersion.lowestMax === minMaxVersion.highestMin) {
        acc[vKey] = minMaxVersion.lowestMax;
      } else {
        acc.highestMin = minMaxVersion.highestMin;
        acc.lowestMax = minMaxVersion.lowestMax;
        delete acc[vKey];
      }
    }

    return acc;
  }, {});
};
