import styled from 'styled-components';

export const Banner = styled.div`
  background-color: ${({ theme }) => theme.palette.common.purple[50]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-top: 16px;
  border-radius: 16px;
`;
