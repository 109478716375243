import { BODY2 } from '@tuunetech/tuune-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DrawerModal } from 'shared';
import RecommendationFilter, {
  RecommendationFilterProps,
} from '../components/RecommendationFilter';
import { isIneligible } from '../components/RecommendationFilter/helpers';
import {
  ContraceptiveType,
  CONTRACEPTIVE_METHODS_NAMES_MAPPING,
} from '../constants';
import { getFilteredRecommendations } from '../helpers';
import { getMEC, getRecommendations, getSpecialFlags } from '../selectors';
import { Flag } from '../types';

export type RecommendationFilterContainerProps = {
  handleRecommendationFilterChange: RecommendationFilterProps['handleRecommendationFilterChange'];
};

export const RecommendationFilterContainer: React.FC<
  RecommendationFilterContainerProps
> = ({ handleRecommendationFilterChange }) => {
  const [selectedContraceptiveTypes, setSelectedContraceptiveTypes] = useState<
    ContraceptiveType[]
  >([]);
  const mecFlags = useSelector(getMEC);
  const specialFlags = useSelector(getSpecialFlags);
  const recommendations = useSelector(getRecommendations);
  const [allAlternativesCount, setAllAlternativesCount] = useState<number>();
  const [selectedAlternativesCount, setSelectedAlternativesCount] =
    useState<number>();

  const ineligibleMethods = useMemo(() => {
    return (
      Object.keys(CONTRACEPTIVE_METHODS_NAMES_MAPPING)
        // Select contraceptive methods based on the filtered contraceptive types
        .filter((contraceptiveMethod: string) =>
          isIneligible(contraceptiveMethod, mecFlags),
        )
    );
  }, [mecFlags]);

  const currentRecommendation = useMemo(() => {
    return recommendations.find((recommendation) => recommendation.current);
  }, [recommendations]);

  useEffect(() => {
    handleRecommendationFilterChange(
      selectedContraceptiveTypes as ContraceptiveType[],
    );
  }, [selectedContraceptiveTypes, handleRecommendationFilterChange]);

  const onFilterChangeCallback = useCallback(
    (contraceptiveMethods: ContraceptiveType[]) => {
      setSelectedContraceptiveTypes(contraceptiveMethods);
      handleRecommendationFilterChange(contraceptiveMethods);
    },
    [setSelectedContraceptiveTypes, handleRecommendationFilterChange],
  );

  useEffect(() => {
    setAllAlternativesCount(
      getFilteredRecommendations(
        recommendations,
        Object.values(ContraceptiveType),
        true,
      ).length,
    );

    setSelectedAlternativesCount(
      getFilteredRecommendations(
        recommendations,
        selectedContraceptiveTypes,
        true,
      ).length,
    );
  }, [recommendations, selectedContraceptiveTypes]);

  const filterIncludesOral = selectedContraceptiveTypes.includes(
    ContraceptiveType.Pill,
  );

  const flags = useMemo(() => {
    let flags: Flag[] = specialFlags || [];

    if (!filterIncludesOral) {
      // filter pop / coc flags
      const oralFlagsContentSlugs = [
        'recommended_POP_previous_issues',
        'recommended_COC_previous_issues',
      ];
      flags = flags.filter(
        ({ contentSlug }) =>
          contentSlug && !oralFlagsContentSlugs.includes(contentSlug),
      );
    }

    return flags;
  }, [specialFlags, filterIncludesOral]);

  const [isIneligibilityInfoOpen, setIneligibilityInfoOpen] = useState(false);

  const handleIneligibilityInfoOpen = useCallback(() => {
    setIneligibilityInfoOpen(true);
  }, []);

  const handleIneligibilityInfoClose = useCallback(() => {
    setIneligibilityInfoOpen(false);
  }, []);

  return (
    <>
      <DrawerModal
        onClose={handleIneligibilityInfoClose}
        onOpen={handleIneligibilityInfoOpen}
        open={isIneligibilityInfoOpen}
      >
        <>
          <BODY2 color="textSecondary">
            According to the CDC&#39;s Medical Eligibility Criteria for
            Contraceptive Use, this method may not be a suitable option for
            people like you.
          </BODY2>
        </>
      </DrawerModal>
      <RecommendationFilter
        handleRecommendationFilterChange={onFilterChangeCallback}
        selectedContraceptiveTypes={selectedContraceptiveTypes}
        ineligibleMethods={ineligibleMethods}
        allAlternativesCount={allAlternativesCount}
        selectedAlternativesCount={selectedAlternativesCount}
        mecFlags={mecFlags}
        specialFlags={flags}
        handleIneligibilityInfoOpen={handleIneligibilityInfoOpen}
        hasCurrentRecommendation={!!currentRecommendation}
      />
    </>
  );
};

export default RecommendationFilterContainer;
