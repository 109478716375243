import { Reducer } from 'redux';
import { SUCCESS_CONTENTFUL_DATA } from '../constants/actions';
import {
  ContentfulAssetsSet,
  ContentfulEntriesSet,
  ContentfulReducerState,
  GetContentRequest,
} from '../types';

export const defaultState: ContentfulReducerState = {};

const contentfulReducer: Reducer<ContentfulReducerState, GetContentRequest> = (
  state = defaultState,
  { type, payload: { data = {}, reqId = '' } = {} },
) => {
  if (type.includes(SUCCESS_CONTENTFUL_DATA)) {
    const remappedData = data.items
      ?.map(({ fields }) => fields)
      .reduce<ContentfulEntriesSet | ContentfulAssetsSet>((mapData, item) => {
        mapData[item.slug] = item;
        return mapData;
      }, {});
    return {
      ...state,
      [reqId]: remappedData || {},
    };
  }

  return state;
};

export { contentfulReducer as contentful };
