import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStartedData } from '../../selectors';
import { ClinicPasswordModal } from 'modules/Questionnaire/components/ClinicGetStarted/ClinicPasswordModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClinicPasswordSchema } from 'modules/Auth/constants/validation';

const PASSWORD_VALIDATION_MESSAGE = 'The password you entered is invalid.';
const PASSWORD_VALIDATION_DURATION = 500;

export type ClinicPasswordContainerProps = {
  onValidPasswordCallback: () => void;
};

export type CinicPasswordFormData = {
  password: string;
};

export const ClinicPasswordModalContainer: React.FC<
  ClinicPasswordContainerProps
> = ({ onValidPasswordCallback }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const { clinicDetails } = useSelector(getStartedData);

  const clinicAuthConfig = clinicDetails?.authConfig;

  const submit = useCallback(
    ({ password }) => {
      setIsLoading(true);
      setValidationMessage('');

      setTimeout(() => {
        setIsLoading(false);
        if (
          password.toUpperCase() ===
          clinicAuthConfig?.accessPassword.toUpperCase()
        ) {
          onValidPasswordCallback();
        } else {
          setValidationMessage(PASSWORD_VALIDATION_MESSAGE);
        }
      }, PASSWORD_VALIDATION_DURATION);
    },
    [setIsLoading, clinicAuthConfig?.accessPassword, onValidPasswordCallback],
  );

  const { control, handleSubmit, formState } = useForm<CinicPasswordFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(ClinicPasswordSchema),
  });

  useEffect(() => {
    if (formState.isValidating) {
      setValidationMessage('');
    }
  }, [formState]);
  return (
    <>
      {clinicAuthConfig && (
        <ClinicPasswordModal
          clinicAuthConfig={clinicAuthConfig}
          isLoading={isLoading}
          handleSubmit={handleSubmit(submit)}
          control={control}
          errors={formState.errors}
          validationErrorMessage={validationMessage}
        />
      )}
    </>
  );
};
