import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SymptomRanking } from '../components/SymptomsRanking';
import { Symptom } from '../types';
import { getDealbreakerIssues, getDealbreaker } from '../selectors';
import { setDealbreakerIssuesRank, setPageNum } from '../actions';
import { DealbreakerAnalyticsMethods } from '../pages/DealbreakerPage';
import { getIsLoading } from '../../Loading/selectors';
import { SET_DEALBREAKER_ISSUES_RANK } from '../constants';
import { GET_ACCOUNT_DATA } from '../../Account/constants';

export const SymptomsRankingContainer: React.FC<{
  analytics: DealbreakerAnalyticsMethods;
}> = ({ analytics: analyticsMethods }) => {
  const dispatch = useDispatch();

  const isDealbreakerLoading = useSelector(
    getIsLoading(SET_DEALBREAKER_ISSUES_RANK),
  );
  const isAccountDataLoading = useSelector(getIsLoading(GET_ACCOUNT_DATA));

  const [sortedSymptoms, setSortedSymptoms] = useState<Symptom[]>([]);
  const symptoms = useSelector(getDealbreakerIssues);
  const dealbreaker = useSelector(getDealbreaker);

  useEffect(() => {
    if (symptoms) {
      setSortedSymptoms(symptoms.filter(({ slug }) => slug !== dealbreaker));
    }
  }, [dealbreaker, symptoms]);

  const handleUpdateRank = (symptoms: Symptom[]) => {
    setSortedSymptoms(symptoms);
  };

  const handleBack = useCallback(() => {
    dispatch(setPageNum(0));
  }, [dispatch]);

  const handleDealbreakerAnalytics = useCallback(() => {
    const isDealbreakerSkipped = !dealbreaker;
    if (isDealbreakerSkipped) {
      analyticsMethods.trackDealbreakerSkipped('user');
    } else {
      analyticsMethods.trackDealbreakerSelected(dealbreaker);
    }

    const symptomsForRanking =
      symptoms?.filter(({ slug }) => slug !== dealbreaker) || [];

    // I remap arrays to contain primitives only. It will ensure the truthy result
    const isRankingSkipped =
      JSON.stringify(symptomsForRanking?.map(({ slug }) => slug)) ===
      JSON.stringify(sortedSymptoms.map(({ slug }) => slug));

    if (isRankingSkipped) {
      analyticsMethods.trackRankingSkipped('user');
    } else {
      analyticsMethods.trackIssuesReordered(sortedSymptoms, symptomsForRanking);
    }
  }, [analyticsMethods, dealbreaker, sortedSymptoms, symptoms]);

  const handleContinue = useCallback(() => {
    handleDealbreakerAnalytics();

    dispatch(
      setDealbreakerIssuesRank({
        dealbreakerIssue: { slug: dealbreaker },
        issuesRank: sortedSymptoms,
      }),
    );
  }, [dealbreaker, dispatch, handleDealbreakerAnalytics, sortedSymptoms]);

  return (
    <SymptomRanking
      isLoading={
        isDealbreakerLoading === 1 ||
        (isDealbreakerLoading === 0 && isAccountDataLoading === 1)
      }
      symptoms={sortedSymptoms}
      handleUpdateRank={handleUpdateRank}
      handleBack={handleBack}
      handleContinue={handleContinue}
    />
  );
};
