import { RecommendationTableProps } from '@tuunetech/tuune-components';
import {
  ContraceptiveType,
  getRecommendationText,
  PillType,
} from 'modules/Results/constants';
import { Flag } from 'modules/Results/types';
import { ANALYTICS_EVENT } from './constants';
import { AnalyticsTrackData } from './types';

type ReportContraceptiveType =
  RecommendationTableProps['recommendations'][number];

export const contraceptivesToAnalyticsObj = (
  contraceptives: ReportContraceptiveType[],
): Record<string, string> => {
  const contraceptivesReducer = (
    accumulator: Record<string, string>,
    currentValue: ReportContraceptiveType,
  ) => {
    const keysPerEntry = 2;
    const length = Object.keys(accumulator).length / keysPerEntry;

    const name = currentValue.name;
    const type = currentValue.type;

    return {
      ...accumulator,
      [`recommended_${length + 1}_name`]: name,
      [`recommended_${length + 1}_type`]: type,
    };
  };

  return contraceptives.reduce<Record<string, string>>(
    contraceptivesReducer,
    {},
  );
};

export const issuesToAnalyticsObj = (
  issues: { name: string }[],
): Record<string, string> => {
  const issuesReducer = (
    accumulator: Record<string, string>,
    currentIssue: { name: string },
  ) => {
    const length = Object.keys(accumulator).length;
    return {
      ...accumulator,
      [`symptom_${length + 1}_name`]: currentIssue.name,
    };
  };

  return issues.reduce<Record<string, string>>(issuesReducer, {});
};

export const specialFlagsToAnalyticsObj = (
  specialFlags: Flag[],
): Record<string, boolean> => {
  const analyticsSpecialFlags = [
    'recommended_POP_previous_issues',
    'recommended_COC_previous_issues',
  ];

  const flagsReducer = (
    accumulator: Record<string, boolean>,
    flagSlug: string,
  ) => {
    return {
      ...accumulator,
      [flagSlug]: specialFlags.some(({ contentSlug }) => {
        contentSlug === flagSlug;
      }),
    };
  };

  return analyticsSpecialFlags.reduce<Record<string, boolean>>(
    flagsReducer,
    {},
  );
};

/**
 * Searches for the first pill, prioritizing COC pills over POP
 * and returns its name, or 'none' if no pills are found
 */
export const getFirstPillName = (
  recommendations: RecommendationTableProps['recommendations'],
): string => {
  const cocRecomendationText = getRecommendationText({
    recommendationType: ContraceptiveType.Pill,
    subType: PillType.COC,
  });

  const popRecommendationText = getRecommendationText({
    recommendationType: ContraceptiveType.Pill,
    subType: PillType.POP,
  });

  const filteredRecommendations = recommendations
    .filter((recommendation) =>
      [cocRecomendationText, popRecommendationText].includes(
        recommendation.type,
      ),
    )
    .sort((recommendation1, recommendation2) => {
      if (recommendation1.type === cocRecomendationText) {
        return 1;
      } else if (recommendation2.type === cocRecomendationText) {
        return -1;
      } else if (recommendation1.type === popRecommendationText) {
        return 1;
      } else if (recommendation2.type === popRecommendationText) {
        return -1;
      } else {
        return 0;
      }
    });

  if (filteredRecommendations.length > 0) {
    return filteredRecommendations[0].name;
  } else {
    return 'none';
  }
};

export const filterGTMAnalyticsEvent = (
  event: ANALYTICS_EVENT,
  data: AnalyticsTrackData,
): AnalyticsTrackData => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  switch (event) {
    case ANALYTICS_EVENT.COMPLETE_QUESTION: {
      const { questionId, questionTitle, answers, ...filteredData } = data;
      return filteredData;
    }
    case ANALYTICS_EVENT.QUESTION_BACK: {
      const { questionId, ...filteredData } = data;
      return filteredData;
    }
    case ANALYTICS_EVENT.VIEW_REPORT: {
      const { category, reportStageStatus, reportHeaderVariation } = data;
      return { category, reportStageStatus, reportHeaderVariation };
    }
    case ANALYTICS_EVENT.RECOMMENDATION_BENEFITS_DROPDOWN:
    case ANALYTICS_EVENT.RECOMMENDATION_CONSIDERATIONS:
    case ANALYTICS_EVENT.RECOMMENDATION_HOW_TO_TAKE_PILL:
    case ANALYTICS_EVENT.RECOMMENDATION_HOW_TO_START:
    case ANALYTICS_EVENT.RECOMMENDATION_REPORT_FILTER_CHANGE:
    case ANALYTICS_EVENT.RECOMMENDATION_CONTRACEPTIVE_CARD_LEARN_MORE_HYPERLINK:
    case ANALYTICS_EVENT.RECOMMENDATION_CONTRACEPTIVE_CARD_LEARN_MORE_BUTTON: {
      const { category } = data;
      return { category };
    }

    default:
      return data;
  }
  /* eslint-enable */
};
