import React from 'react';
import { Page } from 'shared';
import { QuestionnaireHeader } from '../components/QuestionnaireHeader';
import { LegalNameContainer } from '../containers/GetStarted/LegalNameContainer';

export const LegalNamePage: React.FC = () => {
  return (
    <Page>
      <QuestionnaireHeader />
      <LegalNameContainer />
    </Page>
  );
};
