import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Routes from 'routes';
import {
  ORDER_UUID_SEARCH_PARAM,
  SIGNUP_TYPE_SEARCH_PARAM,
} from '../constants';
import { setSignupOrderUuid } from '../actions';
import {
  ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE,
  EnhancedEcommerce,
} from 'utils/analytics/enhancedEcommerce';
import { SignupType } from 'modules/Questionnaire/constants/signupType';

export const OrderSuccessContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const orderUuid = urlSearchParams.get(ORDER_UUID_SEARCH_PARAM);
    const signupType = urlSearchParams.get(SIGNUP_TYPE_SEARCH_PARAM);

    if (orderUuid) {
      dispatch(setSignupOrderUuid(orderUuid));

      if (
        signupType &&
        // Check if the URL search param is a valid SignupType
        Object.values(SignupType).some((type) => type === signupType)
      ) {
        EnhancedEcommerce.logPurchaseEvent(
          ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE[signupType as SignupType],
          orderUuid,
        );
      }

      history.replace(Routes.VERIFY_EMAIL);
    }
  }, [dispatch, history, location.search]);

  return null;
};
