import React, { useState } from 'react';
import {
  BODY1,
  Button,
  GridContainer,
  GridItem,
  H3,
  Input,
} from '@tuunetech/tuune-components';
import { Box, CircularProgress, Container } from '@material-ui/core';
import { LoadingContainer } from './styles';
import Routes from 'routes';

type StudyUnlockProps = {
  isLoading: boolean;
  handleSubmit: (unlockCode: string) => void;
};

export const StudyUnlock: React.FC<StudyUnlockProps> = ({
  isLoading,
  handleSubmit,
}) => {
  const [unlockCode, setUnlockCode] = useState<string>('');

  return isLoading ? (
    <LoadingContainer>
      <CircularProgress color="inherit" />
    </LoadingContainer>
  ) : (
    <Container>
      <GridContainer justify="center">
        <GridItem xs={12} md={6}>
          <H3 paragraph $marginTop={24}>
            Please enter your code below to access your report
          </H3>
          <BODY1 color="textSecondary" $marginTop={16} $marginBottom={24}>
            You should have received a code from our team.
          </BODY1>
          <Box marginTop="24px">
            <Input
              name="code"
              placeholder="Code"
              fullWidth
              value={unlockCode}
              onChange={(e): void => setUnlockCode(e.target.value)}
            />
          </Box>
          <Box marginTop="24px">
            <Button
              fullWidth
              disabled={!unlockCode}
              onClick={() => handleSubmit(unlockCode)}
            >
              continue
            </Button>
          </Box>
          <Box marginTop="16px">
            <Button href={Routes.HOME} variant="text" fullWidth>
              cancel
            </Button>
          </Box>
        </GridItem>
      </GridContainer>
    </Container>
  );
};
