import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStartedData } from '../../selectors';
import { setGetStartedQuestion } from '../../actions';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { ClinicLanding } from 'modules/Questionnaire/components/ClinicGetStarted/ClinicLanding';

export const LandingContainer: React.FC = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { clinicDetails } = useSelector(getStartedData);

  const { currentQuestion } = useSelector(getStartedData);
  const handleSubmit = useCallback(() => {
    dispatch(setGetStartedQuestion(currentQuestion + 1));
  }, [dispatch, currentQuestion]);

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT.PAGE_VIEW, {
      category: ANALYTICS_CATEGORY.GET_STARTED,
      location: `${location.pathname}${location.hash}`,
      getStartedStep: 'landing',
    });
  }, [analytics]);

  return clinicDetails?.authConfig ? (
    <ClinicLanding
      handleSubmit={handleSubmit}
      clinicAuthConfig={clinicDetails.authConfig}
    />
  ) : (
    <></>
  );
};
