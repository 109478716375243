import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Divider, H3 } from '@tuunetech/tuune-components';
import { CircularProgress, SwipeableDrawerProps } from '@material-ui/core';
import { DrawerModal } from 'shared';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { getIsLoading } from 'modules/Loading/selectors';
import { getEntries } from 'modules/Contentful/actions';
import { getRegimenCurrent, getVmpSlugs } from '../selectors';
import {
  CONTENT_BLOCK_NAMES,
  getResultsContentfulOptions,
  REGIMEN_DETAILS_TAB,
  REGIMEN_SAFETY_TAB,
  REGIMEN_TABS,
} from '../constants';
import { Tab, Tabs } from 'shared/Tabs/styles';

export type RegimenInfoProps = {
  isRegimenInfoOpen: boolean;
  handleRegimenInfoOpen: (
    event: React.SyntheticEvent<HTMLButtonElement>,
    vmpSlug?: string,
  ) => void;
  handleRegimenInfoClose: SwipeableDrawerProps['onClose'];
};

const CK = {
  [CTN.RECOMMENDED_REGIMEN_DETAILS_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.RECOMMENDED_REGIMEN_DETAILS_GENERIC,
  ),
  [CTN.RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC,
  ),
};

export const RegimenInfoContainer: React.FC<RegimenInfoProps> = ({
  handleRegimenInfoClose,
  handleRegimenInfoOpen,
  isRegimenInfoOpen,
}) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(REGIMEN_TABS[0].key);

  const handleTabChange = useCallback((e, newTab) => {
    setActiveTab(newTab);
  }, []);

  const vmpSlugs = useSelector(getVmpSlugs);
  const regimenCurrent = useSelector(getRegimenCurrent);

  // get regimen details for each pill
  const pillRegimenDetails = useSelector(
    getArrayContentMapped(CK[CTN.RECOMMENDED_REGIMEN_DETAILS_GENERIC].reqId),
  );
  const isPillRegimenDetailsLoading = useSelector(
    getIsLoading(CK[CTN.RECOMMENDED_REGIMEN_DETAILS_GENERIC].loadingId),
  );
  // get regimen safety & efficacy for each pill
  const pillRegimenSafety = useSelector(
    getArrayContentMapped(
      CK[CTN.RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC].reqId,
    ),
  );
  const isPillRegimenSafetyLoading = useSelector(
    getIsLoading(CK[CTN.RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC].loadingId),
  );

  useEffect(() => {
    if (isRegimenInfoOpen) {
      // request regimen details
      if (isPillRegimenDetailsLoading === undefined) {
        dispatch(
          getEntries({
            ...CK[CTN.RECOMMENDED_REGIMEN_DETAILS_GENERIC],
            slugs: vmpSlugs,
          }),
        );
      }

      // request regimen safety & efficacy
      if (isPillRegimenSafetyLoading === undefined) {
        dispatch(
          getEntries({
            ...CK[CTN.RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC],
            slugs: vmpSlugs,
          }),
        );
      }
    }
  }, [
    dispatch,
    isPillRegimenDetailsLoading,
    isPillRegimenSafetyLoading,
    isRegimenInfoOpen,
    vmpSlugs,
  ]);

  const regimenContent = useMemo(() => {
    return Object.keys(pillRegimenDetails).reduce((content, key) => {
      content[key] = documentToReactComponents(
        pillRegimenDetails[key]?.content,
        getResultsContentfulOptions(),
      );
      return content;
    }, {} as Record<string, ReactNode>);
  }, [pillRegimenDetails]);

  const safetyContent = useMemo(() => {
    return Object.keys(pillRegimenSafety).reduce((content, key) => {
      content[key] = documentToReactComponents(
        pillRegimenSafety[key]?.content,
        getResultsContentfulOptions(CONTENT_BLOCK_NAMES.REGIMEN),
      );
      return content;
    }, {} as Record<string, ReactNode>);
  }, [pillRegimenSafety]);

  return (
    <DrawerModal
      onClose={handleRegimenInfoClose}
      onOpen={handleRegimenInfoOpen}
      open={isRegimenInfoOpen}
      stickyPart={
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          {REGIMEN_TABS.map(({ key, value }) => (
            <Tab key={key} value={key} label={value} />
          ))}
        </Tabs>
      }
    >
      {isPillRegimenDetailsLoading !== 0 && isPillRegimenSafetyLoading !== 0 ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          <H3 paragraph align="left">
            {REGIMEN_TABS.find((tab) => tab.key === activeTab)?.value}
          </H3>
          <Divider $verticalMargin={24} />
          {regimenCurrent &&
            (activeTab === REGIMEN_SAFETY_TAB.key
              ? regimenCurrent && safetyContent[regimenCurrent]
              : activeTab === REGIMEN_DETAILS_TAB.key
              ? regimenContent[regimenCurrent]
              : null)}
        </>
      )}
    </DrawerModal>
  );
};
