import React from 'react';
import { BaseLink } from '@tuunetech/tuune-components';
import { LinkProps } from '@material-ui/core';

const Link: React.FC<LinkProps> = ({
  children,
  // IonRouter Internal Link Props
  href,
  target,
  rel,
  color,
  //  Mui Styling Link Props
  underline = 'always',
  ...props
}) => {
  return (
    <BaseLink
      href={href}
      rel={rel}
      underline={underline}
      color={color}
      target={target}
      {...props}
    >
      {children}
    </BaseLink>
  );
};

export default Link;
