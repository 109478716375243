import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NumberAnswer from 'modules/Questionnaire/components/Answers/Number';
import { AnswerComponentProps } from '../../types';
import {
  AnswerTypesMap,
  HEIGHT_IMPERIAL,
  MEASURE_SYSTEMS,
  NonMetricSystemOption,
  UNITS,
  WEIGHT_IMPERIAL,
} from '../../constants';
import {
  getCurrentUnits,
  getMeasureSystemByUnit,
  getOptions,
} from '../../helpers';
import { setMeasureSystem, setMeasureSystemToStorage } from '../../actions';
import { getMeasureSystem } from '../../selectors';

export type NumAnswerFormData = {
  answer: string | NonMetricSystemOption | null;
};

const intValidationSchema = yup.object().shape({
  answer: yup
    .number()
    .typeError('Answer must be a number')
    .required('This field is required')
    .moreThan(-1, 'Answer must be positive')
    .integer('Answer must be an integer'),
});

const floatValidationSchema = yup.object().shape({
  answer: yup
    .number()
    .typeError('Answer must be a number')
    .required('This field is required')
    .moreThan(-1, 'Answer must be positive'),
});

const NumberContainer: React.FC<AnswerComponentProps> = ({
  answerType,
  answers,
  previousAnswers,
  handleSubmitAnswer,
  handleRequestNext,
}) => {
  const dispatch = useDispatch();

  const factId = answers[0].id;
  const prevValue = previousAnswers[factId];
  const prevNum = !prevValue
    ? prevValue
    : answerType === AnswerTypesMap.HEIGHT
    ? (prevValue as number) * 100
    : prevValue;
  const measureSystem = useSelector(getMeasureSystem) as string;
  const selectorUnits = useMemo(
    () => getCurrentUnits(answerType),
    [answerType],
  );
  const selectedUnit = useMemo(
    () => UNITS[measureSystem][answerType],
    [measureSystem, answerType],
  );

  const isAnswerSpecific = useMemo(() => {
    return (
      answerType === AnswerTypesMap.WEIGHT ||
      answerType === AnswerTypesMap.HEIGHT
    );
  }, [answerType]);

  const defaultValue = useMemo(() => {
    if (isAnswerSpecific) {
      if (typeof prevNum !== 'number') {
        return prevNum;
      }
      switch (measureSystem) {
        case MEASURE_SYSTEMS.METRIC:
          return prevNum.toString();
        case MEASURE_SYSTEMS.IMPERIAL:
          switch (answerType) {
            case AnswerTypesMap.HEIGHT:
              return HEIGHT_IMPERIAL.find(
                ({ metricValue }) =>
                  prevNum < metricValue + 2 && prevNum > metricValue - 2,
              );
            case AnswerTypesMap.WEIGHT:
              return WEIGHT_IMPERIAL.find(
                ({ metricValue }) => metricValue === prevNum,
              );
          }
      }
    } else {
      return prevNum;
    }
  }, [answerType, isAnswerSpecific, measureSystem, prevNum]);

  const getValidationResolver = () => {
    if (answerType === AnswerTypesMap.INT) {
      return intValidationSchema;
    } else if (answerType === AnswerTypesMap.FLOAT) {
      return floatValidationSchema;
    } else {
      return yup.object().shape({
        answer: yup.mixed().required('This field is required'),
      });
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<NumAnswerFormData>({
    resolver: yupResolver(getValidationResolver()),
    mode: 'onTouched',
  });

  const handleUnitChange = useCallback(
    (e) => {
      const unit = e.target.value;
      const toSystem = getMeasureSystemByUnit(unit);
      if (toSystem !== selectedUnit) {
        reset({ answer: null });
        dispatch(setMeasureSystem(toSystem));
      }
    },
    [selectedUnit, reset, dispatch],
  );

  const options = useMemo(() => {
    return getOptions(measureSystem, answerType);
  }, [measureSystem, answerType]);

  const onSubmit = useCallback(
    ({ answer }) => {
      let result;
      // save measure system in which value was submitted
      // to later show values in that system first
      if (isAnswerSpecific) {
        dispatch(setMeasureSystemToStorage(measureSystem));
        if (measureSystem !== MEASURE_SYSTEMS.METRIC) {
          result = answer.metricValue;
        } else {
          result = Number(answer);
        }
      } else {
        result = Number(answer);
      }

      if (result === prevNum) {
        handleRequestNext();
      } else {
        if (answerType === AnswerTypesMap.HEIGHT) {
          result = result / 100;
        }

        handleSubmitAnswer([{ factId, answer: result }]);
      }
    },
    [
      answerType,
      isAnswerSpecific,
      dispatch,
      measureSystem,
      prevNum,
      handleRequestNext,
      handleSubmitAnswer,
      factId,
    ],
  );

  return (
    <NumberAnswer
      handleSubmit={handleSubmit(onSubmit)}
      options={options}
      control={control}
      isAnswerSpecific={isAnswerSpecific}
      errors={errors}
      defaultValue={defaultValue}
      selectorUnits={selectorUnits}
      selectedUnit={selectedUnit}
      handleUnitChange={handleUnitChange}
    />
  );
};

export default NumberContainer;
