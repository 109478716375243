import { isNotEmpty, validatePhoneNumber } from 'utils/helpers';
import * as yup from 'yup';
import { TextInputValue } from '../types';
import { PRIVACY } from './privacy';

export const validateSingleTextAnswer = ({
  answer,
}: TextInputValue): TextInputValue => {
  const errors: TextInputValue = {};

  const answerError = isNotEmpty('answer', answer);
  answerError && (errors.answer = answerError);

  return errors;
};

const accountInfoShape = {
  firstName: yup.string().required('First name is a required field').max(255),
  lastName: yup.string().required('Last name is a required field').max(255),
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
};

const phoneShape = {
  phone: yup
    .string()
    .required('Phone number is a required field')
    .test('mobile', 'Phone number format incorrect', function (value = '') {
      return validatePhoneNumber(value);
    }),
};

const stateShape = {
  state: yup.string().test('state', 'State is a required field', (value) => {
    return !!value;
  }),
};

export const accountInfoSchema: ReturnType<
  typeof yup.ObjectSchema.prototype.shape
> = yup.object().shape({
  ...phoneShape,
  ...stateShape,
  ...accountInfoShape,
});

export const clinicAccountInfoSchema: ReturnType<
  typeof yup.ObjectSchema.prototype.shape
> = yup.object().shape({
  ...phoneShape,
  ...stateShape,
  ...accountInfoShape,
  clinicianId: yup.number().required('Clinician is a required field'),
  appointmentDate: yup
    .date()
    .nullable(true)
    .required('Appointment date is a required field'),
});

export const marketingSchema: ReturnType<
  typeof yup.ObjectSchema.prototype.shape
> = yup.object().shape({ ...phoneShape, ...stateShape });

export const createAccountSchema = (
  email?: string,
  firstName?: string,
  lastName?: string,
): ReturnType<typeof yup.ObjectSchema.prototype.shape> => {
  return yup.object().shape({
    password: yup
      .string()
      .required()
      .test('password', 'Password is too similar to first name', (value) =>
        firstName && value
          ? !value.toLowerCase().includes(firstName.toLowerCase())
          : true,
      )
      .test('password', 'Password is too similar to last name', (value) =>
        lastName && value
          ? !value.toLowerCase().includes(lastName.toLowerCase())
          : true,
      )
      .test('password', 'Password is too similar to email address', (value) =>
        email && value
          ? !value.toLowerCase().includes(email.split('@')[0].toLowerCase())
          : true,
      )
      .min(8)
      .matches(/[0-9]/, 'must contain numbers')
      .matches(/[a-zA-Z]/, 'must contain letters'),
    rePassword: yup
      .string()
      .required('Confirm password is a required field')
      .test('rePassword', 'Passwords should match', function (value = '') {
        const { parent } = this;
        return parent.password === value;
      }),
  });
};

export const privacySchema = yup.object().shape({
  [PRIVACY.acceptAnswersTruthful]: yup.boolean().isTrue(),
  [PRIVACY.acceptAssignedAtBirth]: yup.boolean().isTrue(),
  [PRIVACY.acceptDataUsage]: yup.boolean().isTrue(),
  [PRIVACY.acceptTerms]: yup.boolean().isTrue(),
  [PRIVACY.acceptTestingAgreement]: yup.boolean().isTrue(),
});
