import React, { useCallback, useMemo } from 'react';
import DateContainer from './DateContainer';
import { AnswerComponentProps, DateAnswerFormValues } from '../../types';
import { getDateFromInput, isUnderMinAge, validateDob } from '../../helpers';
import { useAnalytics } from 'utils/analytics';
import { stringErrorMessages } from 'utils/helpers/validation';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import throttle from 'lodash.throttle';

const DobContainer: React.FC<AnswerComponentProps> = (props) => {
  const analytics = useAnalytics();

  const trackUserUnderAge = useCallback(() => {
    analytics.track(ANALYTICS_EVENT.USER_UNDER_AGE, {
      category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
    });
  }, [analytics]);

  const throttledTrackUserUnderAge = useMemo(
    () => throttle(trackUserUnderAge, 5000),
    [trackUserUnderAge],
  );

  const validateDobCallback = useCallback(
    ({
      day = '',
      month = '',
      year = '',
    }: DateAnswerFormValues): string | undefined => {
      const dobValidationErorr = validateDob({ day, month, year });
      if (dobValidationErorr === stringErrorMessages.AGE) {
        const dob = getDateFromInput({ day, month, year });

        if (dob && isUnderMinAge(dob)) {
          throttledTrackUserUnderAge();
        }
      }

      return dobValidationErorr;
    },
    [throttledTrackUserUnderAge],
  );

  return <DateContainer additValidate={validateDobCallback} {...props} />;
};

export default DobContainer;
