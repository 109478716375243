import axios from 'axios';

export const LOCAL_ENV = 'local';

export const LOCAL_HOST = 'http://localhost:8000';
const HOST_MAP: Record<string, string> = {
  prod: 'https://api.tuune.com',
  staging: 'https://api.staging.tuune.com',
  dev: 'https://api.dev.tuune.com',
  localDev: 'https://api.dev.tuune.com',
};

const currentEnv = process.env.REACT_APP_ENV || LOCAL_ENV;
const ApiHost = currentEnv === LOCAL_ENV ? LOCAL_HOST : HOST_MAP[currentEnv];

export { ApiHost, currentEnv as environment };

const instance = axios.create({
  baseURL: ApiHost,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
