import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { Page } from 'shared';
import { getIsClinicSignupFlow, getStartedData } from '../selectors';
import { setGetStartedQuestion, setGetStartedSignupType } from '../actions';
import {
  LandingContainer,
  CreateAccountContainer,
} from '../containers/GetStarted';
import { QuestionnaireHeader } from '../components/QuestionnaireHeader';
import { HAGoBackContext } from '../constants';
import { MarketingAndStateInfoContainer } from '../containers/GetStarted/MarketingAndStateInfoContainer';
import {
  SignupTypeUrlParams,
  SIGNUP_TYPE_URL_PARAMS_MAPPING,
} from '../constants/signupType';

const GetStartedPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { oAuthProvider } = useSelector(getStartedData);
  const { currentQuestion } = useSelector(getStartedData);
  const isClinicSignupFlow = useSelector(getIsClinicSignupFlow);
  const [questions, setQuestions] = useState([
    <LandingContainer key="landing" />,
  ]);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const EMAIL_FLOW_QUESTIONS = useMemo(() => {
    return [
      <LandingContainer key="landing" />,
      <CreateAccountContainer key="set password" />,
    ].map((question, index) => ({
      ...question,
      totalCount: undefined,
      current: index + 1,
    }));
  }, []);

  const OAUTH_FLOW_QUESTIONS = useMemo(() => {
    return [
      <LandingContainer key="landing" />,
      <MarketingAndStateInfoContainer key="state and marketing" />,
    ].map((question, index) => ({
      ...question,
      totalCount: undefined,
      current: index + 1,
    }));
  }, []);

  useEffect(() => {
    if (!isClinicSignupFlow) {
      const signupType = new URLSearchParams(location.search).get('st');

      if (
        signupType &&
        // Check if the URL search param is a valid SignupType
        Object.values(SignupTypeUrlParams).some((type) => type === signupType)
      ) {
        dispatch(
          setGetStartedSignupType(SIGNUP_TYPE_URL_PARAMS_MAPPING[signupType]),
        );
      } else {
        history.replace(Routes.LOGIN);
      }
    }
  }, [dispatch, history, isClinicSignupFlow]);

  useEffect(() => {
    if (oAuthProvider) {
      setQuestions([...OAUTH_FLOW_QUESTIONS]);
    } else {
      setQuestions([...EMAIL_FLOW_QUESTIONS]);
    }
  }, [oAuthProvider, OAUTH_FLOW_QUESTIONS, EMAIL_FLOW_QUESTIONS]);

  const handleGoBack = useCallback(() => {
    dispatch(setGetStartedQuestion(currentQuestion - 1));
  }, [currentQuestion, dispatch]);

  useEffect(() => {
    // when question changes ensure that content is scrolled to top
    if (contentRef.current) {
      contentRef.current.scroll(0, 0);
    }
  }, [currentQuestion]);

  const renderCurrentQuestion = () => {
    if (currentQuestion < questions.length) {
      return questions[currentQuestion];
    }
  };

  return (
    <Page ref={contentRef}>
      <QuestionnaireHeader />
      <HAGoBackContext.Provider
        value={currentQuestion === 0 ? undefined : handleGoBack}
      >
        {renderCurrentQuestion()}
      </HAGoBackContext.Provider>
    </Page>
  );
};

export default GetStartedPage;
