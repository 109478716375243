import * as yup from 'yup';

export const LoginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
  // No need to validate already existing password.
  // Manually created account passwords can be atypical
  password: yup.string().required('Password is a required field'),
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
});

export const ResetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required()
    .min(8)
    .matches(/[0-9]/, 'must contain numbers')
    .matches(/[a-zA-Z]/, 'must contain letters'),
  rePassword: yup
    .string()
    .required('Confirm password is a required field')
    .test('rePassword', 'Passwords should match', function (value = '') {
      const { parent } = this;
      return parent.newPassword === value;
    }),
});

export const ClinicPasswordSchema = yup.object().shape({
  password: yup.string().required('Password is a required field'),
});
