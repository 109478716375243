import React, { ReactNode, useMemo } from 'react';
import { AppHeaderProps, AppHeader } from '@tuunetech/tuune-components';
import Routes from 'routes';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { getIsAuthenticated } from 'modules/Auth/selectors';

type QuestionnaireHeaderProps = {
  removeAuthButton?: boolean;
  handleGoBack?: () => void;
  additionalNodes?: ReactNode;
};

export const QuestionnaireHeader: React.FC<QuestionnaireHeaderProps> = ({
  removeAuthButton = false,
  handleGoBack,
  additionalNodes,
}) => {
  const history = useHistory();
  const { contactUs, logout } = useBaseNavButtons();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const headerButtons = useMemo(() => {
    const buttons: AppHeaderProps['buttons'] = [contactUs];

    if (!removeAuthButton) {
      if (isAuthenticated) {
        // Questionnaire
        buttons.push({ ...logout, title: 'Save & sign out' });
      } else {
        // Get started
        buttons.push({
          title: 'Sign in',
          onClick: () => {
            history.push(Routes.LOGIN);
          },
        });
      }
    }

    return buttons;
  }, [logout, contactUs, removeAuthButton, history, isAuthenticated]);

  return (
    <AppHeader
      position="fixed"
      handleGoBack={handleGoBack}
      additionalNodes={additionalNodes}
      buttons={headerButtons}
    />
  );
};
