export const getObjValueIgnoreCase = (
  obj: Record<string, unknown>,
  propertyKey: string,
): unknown | null => {
  const values = Object.keys(obj).filter((key) => {
    return key.toLowerCase() === propertyKey.toLowerCase();
  });

  return values?.length > 0 ? obj[values[0]] : null;
};
