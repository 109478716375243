import { reportStageStatus } from 'modules/Account/constants/status';
import { Config } from 'amplitude-js';
import { EcommerceProduct } from './types';

export const DEFAULT_AMPLITUDE_CONFIG: Config = {
  saveEvents: true,
  includeFbclid: true,
  includeGclid: true,
  includeReferrer: true,
  includeUtm: true,
  sameSiteCookie: 'Lax',
  trackingOptions: {
    ip_address: false,
    city: false,
    dma: false,
  },
};

export enum ANALYTICS_EVENT {
  CREATE_ACCOUNT = 'create account',
  ACTIVATE_ACCOUNT = 'activate account',
  LOGIN_ACCOUNT = 'login to account',
  LOGOUT_ACCOUNT = 'logout from account',
  RESET_PASSWORD = 'click reset password',
  CHANGE_PASSWORD = 'click change password',
  START_HA = 'start health assessment',
  COMPLETE_QUESTION = 'complete a question',
  COMPLETE_HA = 'complete health assessment',
  ADD_TO_PLAN = 'click add to plan',
  QUESTION_HELP = 'click info icon',
  QUESTION_BACK = 'click back button',
  USER_UNDER_AGE = 'user-under-age',
  RECOMMENDATION_BENEFITS = 'click benefits',
  RECOMMENDATION_HOW_TO_TAKE_PILL = 'click how should I take my pill',
  RECOMMENDATION_CONSIDERATIONS = 'click considerations',
  RECOMMENDATION_HOW_TO_START = 'click how to start this method',
  RECOMMENDATION_HOW_WAS_IT_MADE = 'click how have you made my rec',
  RECOMMENDATION_TIME_SPENT_ON_PAGE = 'time spent on report',
  RECOMMENDATION_RETURNED_TO_VIEW_REPORT = 'returned to view report',
  RECOMMENDATION_BENEFITS_DROPDOWN = 'click benefits dropdown',
  RECOMMENDATION_ELIGIBILIGY_ROW_CLICK = 'clicked_mec_eligibility',
  RECOMMENDATION_CONTRACEPTIVE_CARD_LEARN_MORE_BUTTON = 'click learn more on contraceptive card',
  RECOMMENDATION_CONTRACEPTIVE_CARD_LEARN_MORE_HYPERLINK = 'click learn more on contraceptive card info hyperlink - no button',
  RECOMMENDATION_REPORT_LOADING_ERROR = 'error loading the report',
  RECOMMENDATION_REPORT_FILTER_SHOW_ALTERNATIVES = 'clicked_show_alternatives',
  RECOMMENDATION_REPORT_FILTER_CHANGE = 'recommendation filter drop down value changed',
  RECOMMENDATION_REPORT_FILTER_CLICK_ALTERNATIVE_CHIP = 'clicked_alternative_chip',
  RECOMMENDATION_REPORT_DOWNLOAD_POPOUP_OPEN = 'clicked_report_download_popup_open',
  RECOMMENDATION_REPORT_SHARE_REPORT_EMAIL = 'clicked_share_report_email',
  RECOMMENDATION_REPORT_SHARE_REPORT_DOWNLOAD = 'clicked_share_report_download',
  RECOMMENDATION_REPORT_CLICK_MORE_ABOUT_DOCTOR = 'clicked_more_about_reviewer_doctor',
  RECOMMENDATION_REPORT_CLICK_HOW_CHOOSE_RECOMMENDATIONS = 'clicked_how_choose_recommendations',
  RECOMMENDATION_REPORT_SCROLLED_RECOMMENDATIONS_CARDS = 'scrolled_recommendations_cards',
  PILL_NOT_LISTED = 'click pill not listed',
  PILL_UNKNOWN = 'click pill unknown',
  GET_STARTED_INFO = 'click get started info next',
  GET_STARTED_PLAY_YOUTUBE_VIDEO = 'click get started play youtube video',
  GET_STARTED_PAUSE_YOUTUBE_VIDEO = 'click get started pause youtube video',
  GET_STARTED_ENDED_YOUTUBE_VIDEO = 'get started youtube video ended',
  GET_STARTED_STATE_DROPDOWN = 'click get started state dropdown',
  GET_STARTED_CAROUSEL_VIEW_SLIDE = 'get-started-carousel-view-slide',
  PRIVACY_POLICY_SUBMIT = 'submit privacy policy answer',
  USER_TESTING_AGREEMENT_SUBMIT = 'submit user testing agreement answer',
  PAGE_VIEW = 'page view',
  VIEW_REPORT = 'view_report',
  CTA = 'click_cta',
  VIEW_LOADING_SCREEN = 'view_loading_screen',
  APPLY_PROMO_CODE = 'apply promo code',
  VIEW_ORDER_SUCCESS_PAGE = 'view_order_success_page',
  UNLOCK_BENEFIT = 'click recommendation table unlock benefit',
  REPORT_FEEDBACK_THUMBS = 'report feedback thumbs',
  REPORT_FEEDBACK_CHIPS = 'report feedback chips',
  REPORT_FEEDBACK_SKIP = 'report feedback skipped',
  REPORT_FEEDBACK_TEXT = 'report feedback text',
  REPORT_NO_TEXT_FEEDBACK = 'report no text feedback',
  SELECT_DEALBREAKER = 'select deal breaker issue',
  SKIP_DEALBREAKER = 'skip selecting deal breaker issue',
  DEALBREAKER_ISSUES_REORDER = 'user issues are ranked',
  SKIP_ISSUES_RANK = 'skip ranking issues',
  CLICK_BLOG_ARTICLE = 'click blog article',

  HA_FEEDBACK_INSURANCE = 'HA feedback: health insurance',
  HA_FEEDBACK_HEALTHCARE = 'HA feedback: healthcare services/memberships',
  HA_FEEDBACK_SOCIALS = 'HA feedback: primary social media channel',
  HA_FEEDBACK_SUBSCRIPTIONS = "HA feedback: user's subscriptions",
  HA_FEEDBACK_USER_OVERVIEW = 'HA feedback: user overview',
}

export enum ANALYTICS_CATEGORY {
  ACCOUNT = 'account',
  LOGIN = 'login',
  HEALTH_ASSESSMENT = 'health assessment',
  RECOMMENDATION_REPORT = 'recommendation report',
  ORDER = 'order',
  GET_STARTED = 'get started',
  VIEWS = 'page views',
  HT_REG_FLOW = 'hormone test registration flow',
}

export const REPORT_STAGE_STATUS_MAPPING = {
  [reportStageStatus.INCOMPLETE_HORMONE_TEST_ORDER]:
    reportStageStatus.INCOMPLETE_HORMONE_TEST_ORDER,
  [reportStageStatus.HA_NOT_COMPLETED]: reportStageStatus.HA_NOT_COMPLETED,
  [reportStageStatus.HA_COMPLETED_FEEDBACK_PENDING]:
    reportStageStatus.HA_COMPLETED_FEEDBACK_PENDING,
  [reportStageStatus.HA_COMPLETED_PENDING_ISSUES_RANKING]:
    reportStageStatus.HA_COMPLETED_PENDING_ISSUES_RANKING,
  [reportStageStatus.HA_COMPLETED_REPORT_LOCKED]: 'freemium',
  [reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_PENDING_APPROVAL]: 'pending',
  [reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_IN_REVIEW]: 'pending',
  [reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_APPROVED]: 'complete',
  [reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_REJECTED]:
    reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_REJECTED,
  [reportStageStatus.PRESCRIPTION_PENDING_PAYMENT]:
    reportStageStatus.PRESCRIPTION_PENDING_PAYMENT,
  [reportStageStatus.PRESCRIPTION_ORDERED]:
    reportStageStatus.PRESCRIPTION_ORDERED,
  [reportStageStatus.PRESCRIPTION_SUBMITTED]:
    reportStageStatus.PRESCRIPTION_SUBMITTED,
  [reportStageStatus.PRESCRIPTION_DISPATCHED]:
    reportStageStatus.PRESCRIPTION_DISPATCHED,
  [reportStageStatus.TERMS_AND_CONDITIONS_PENDING]:
    reportStageStatus.TERMS_AND_CONDITIONS_PENDING,
  [reportStageStatus.SIGNUP_PAYMENT_PENDING]:
    reportStageStatus.SIGNUP_PAYMENT_PENDING,
  [reportStageStatus.NAME_MISSING]: reportStageStatus.NAME_MISSING,
};

export enum CTA_POSITION {
  PREVIEW_REPORT_PAGE = 'freemiumReport',
  FULL_REPORT_PAGE = 'fullReport',
  ORDER_CHECKOUT_MODAL = 'orderCheckoutModal',
  ORDER_SUCCESS_PAGE = 'orderSuccessPage',
  GET_STARTED_PAGE = 'getStartedPage',
  MONITORING_MODAL = 'monitoringModal',
  HT_ORDER_CONFIRM_PAGE = 'hormoneTestOrderConfirmedPage',
  RECOMMENDATION_MODAL = 'recommendationModal',
}

export enum CTA_TEXT {
  UNLOCK_REPORT = 'unlock report',
  RETURN_TO_REPORT = 'return to report',
  CHECKOUT = 'checkout',
  GET_STARTED = 'get started',
  SOCIAL_SIGN_UP = 'sign up with',
  VIEW_AN_EXAMPLE = 'view an example',
  ORDER_PRESCRIPTION = 'order_prescription',
  MORE_INFO_TALK_TO_OBGYN = 'more_info_talk_to_obgyn',
  UPDATE_BP_READING = 'update_bp_reading',

  START_MONITORING = 'start monitoring',
  BOOK_OBGYN_CALL = 'book obgyn call',
  ORDER_HORMONE_TESTING = 'order hormone testing',

  SUBMIT_MONITORING_FEEDBACK = 'submit monitoring feedback',
  SUBMIT_OBGYN_FEEDBACK = 'submit obgyn feedback',
  SUBMIT_HORMONE_TESTING_FEEDBACK = 'submit hormone testing feedback',

  BOOK_A_FREE_CALL = 'book a free call',
  VIEW_TESTIMONIAL = 'read testimonial',

  RESEND_ACTIVATION_LINK = 'resend activation link',
  START_HA = 'go to health assessment',
}

export const MILISECONDS_SPENT_ON_PAGE_TRESHOLD = 3 * 60 * 1000; // 3 minutes

export enum APPLY_PROMO_CODE_STATUS {
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum SIGNUP_METHOD {
  EMAIL = 'email sign up',
  SOCIAL = 'social sign up',
}

export enum USER_SOURCE {
  CLINIC = 'clinic',
  NON_CLINIC = 'non-clinic',
}

export enum ENHANCED_ECOMMERCE_EVENT {
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
}

export const ENHANCED_ECOMMERCE_CONSTANTS = {
  brandName: 'Tuune',
  currencyCode: 'USD',
};

export const ECOMMERCE_BIRTH_CONTROL_PRODUCT: EcommerceProduct = {
  item_name: 'Birth Control',
  item_brand: ENHANCED_ECOMMERCE_CONSTANTS.brandName,
  currency: ENHANCED_ECOMMERCE_CONSTANTS.currencyCode,
  price: 29,
  quantity: 1,
};

export const ECOMMERCE_HORMONE_TEST_PRODUCT: EcommerceProduct = {
  item_name: 'Hormone Test',
  item_brand: ENHANCED_ECOMMERCE_CONSTANTS.brandName,
  currency: ENHANCED_ECOMMERCE_CONSTANTS.currencyCode,
  price: 179,
  quantity: 1,
};

export const ECOMMERCE_HORMONAL_WELLBEING_PRODUCT: EcommerceProduct = {
  item_name: 'Hormonal Wellbeing',
  item_brand: ENHANCED_ECOMMERCE_CONSTANTS.brandName,
  currency: ENHANCED_ECOMMERCE_CONSTANTS.currencyCode,
  price: 199,
  quantity: 1,
};
