import { Reducer } from 'redux';
import { DealbreakerReducerState } from '../types';
import {
  GET_DEALBREAKER_ISSUES_SUCCESS,
  SET_DEALBREAKER,
  SET_DEALBREAKER_ISSUES_RANK_SUCCESS,
  SET_PAGE_NUM,
} from '../constants';

export const defaultState: DealbreakerReducerState = {
  dealbreaker: null,
  dealbreakerIssues: undefined,
  dealbreakerSubmitted: false,
  pageNum: 0,
};

const dealbreakerReducer: Reducer<DealbreakerReducerState> = (
  state = defaultState,
  { type, payload, response: { data = {} } = {} },
) => {
  switch (type) {
    case GET_DEALBREAKER_ISSUES_SUCCESS:
      return {
        ...state,
        dealbreakerIssues: data,
      };
    case SET_DEALBREAKER_ISSUES_RANK_SUCCESS:
      return {
        ...state,
        dealbreakerSubmitted: true,
      };
    case SET_DEALBREAKER:
      return {
        ...state,
        dealbreaker: payload.dealbreaker,
      };
    case SET_PAGE_NUM:
      return {
        ...state,
        pageNum: payload.pageNum,
      };
    default:
      return state;
  }
};

export { dealbreakerReducer as dealbreaker };
