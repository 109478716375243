import styled from 'styled-components';
import { BaseDivider } from '../ResultsLayout/styles';

export const Banner = styled.div<{
  $textAlign?: string;
}>`
  background-color: ${({ theme }) => theme.palette.common.purple[50]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-top: 16px;
  border-radius: 16px;
  text-align: ${({ $textAlign }) => $textAlign};
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
  row-gap: 56px;
  column-gap: 24px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-wrap: nowrap;
    margin-bottom: 64px;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  img {
    width: 168px;
    height: 168px;
    align-self: center;
    margin-bottom: 16px;
  }
`;

export const Divider = styled(BaseDivider)`
  margin-bottom: 64px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 40px;
  }
`;
