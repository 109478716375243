import React, { ReactNode } from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { BODY2, BODY1, H6 } from '@tuunetech/tuune-components';
import { Link } from 'shared';

export const CONTENT_BLOCK_NAMES = {
  RECOMMENDATION_SUMMARY_HEADER: 'RECOMMENDATION_SUMMARY_HEADER',
  RECOMMENDATION_SUMMARY_CONTENT: 'RECOMMENDATION_SUMMARY_CONTENT',
  BENEFITS: 'BENEFITS',
  REGIMEN: 'REGIMEN',
  SYMPTOMS_SNIPPET: 'SYMPTOMS_SNIPPET',
};

export const getResultsContentfulOptions = (
  contentBlockName?: string,
): Options => {
  return {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => {
        switch (contentBlockName) {
          case CONTENT_BLOCK_NAMES.RECOMMENDATION_SUMMARY_HEADER:
            return (
              <BODY1 align="left" color="textPrimary" paragraph>
                {children}
              </BODY1>
            );
          case CONTENT_BLOCK_NAMES.RECOMMENDATION_SUMMARY_CONTENT:
          case CONTENT_BLOCK_NAMES.SYMPTOMS_SNIPPET:
            return (
              <BODY2 align="left" color="textPrimary" paragraph>
                {children}
              </BODY2>
            );
          case CONTENT_BLOCK_NAMES.BENEFITS:
            return <>{children}</>;
          default:
            return (
              <BODY2 align="left" color="textSecondary" paragraph>
                {children}
              </BODY2>
            );
        }
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: ({ data }, children): ReactNode => {
        return (
          <Link
            href={data.uri}
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 'inherit' }}
          >
            {children}
          </Link>
        );
      },
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.BOLD]: (text) => {
        switch (contentBlockName) {
          case CONTENT_BLOCK_NAMES.BENEFITS:
          case CONTENT_BLOCK_NAMES.REGIMEN:
            return (
              <H6
                $marginTop={16}
                $marginBottom={8}
                align="left"
                component="span"
                display="block"
              >
                {text}
              </H6>
            );
          default:
            return <b>{text}</b>;
        }
      },
      // eslint-disable-next-line react/display-name
      [MARKS.UNDERLINE]: (text) => {
        switch (contentBlockName) {
          case CONTENT_BLOCK_NAMES.REGIMEN:
            return (
              <BODY1
                color="textPrimary"
                paragraph
                align="left"
                component="span"
                display="block"
              >
                <u>{text}</u>
              </BODY1>
            );
          default:
            return <u>{text}</u>;
        }
      },
    },
  };
};
