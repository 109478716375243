import React from 'react';
import { CAPTION } from '@tuunetech/tuune-components';
import { Block } from './styles';

const ErrorBox: React.FC<{ text: string; className?: string }> = ({
  text,
  className,
}) => {
  return (
    <Block
      className={className}
      content={<CAPTION color="error">{text}</CAPTION>}
    />
  );
};

export default ErrorBox;
