import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { getQuestionData, getStartedData } from '../../selectors';
import { setGetStartedQuestion } from '../../actions';
import { AnswerComponentProps } from '../../types';
import { InfoAnswer } from '../../components/Answers';
import { AnswerTypesMap } from 'modules/Questionnaire/constants';

const START_SECTION_BUTTON_TEXT = 'start section';
const GET_STARTED_BUTTON_TEXT = 'start';

export type InfoComponentProps = {
  handleSubmit: () => void;
};

type InfoContainerProps = Partial<AnswerComponentProps> & {
  component?: React.FC<InfoComponentProps>;
};

const InfoContainer: React.FC<InfoContainerProps> = ({
  component: Component,
  answerType,
  handleRequestNext,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { currentQuestion } = useSelector(getStartedData);
  const questionData = useSelector(getQuestionData);

  const handleSubmit = useCallback(() => {
    dispatch(setGetStartedQuestion(currentQuestion + 1));
    analytics.track(ANALYTICS_EVENT.GET_STARTED_INFO, {
      category: ANALYTICS_CATEGORY.GET_STARTED,
    });
  }, [dispatch, currentQuestion, analytics]);

  const handleSubmitByKey = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (Component !== undefined) {
          handleSubmit();
        } else if (handleRequestNext) {
          handleRequestNext();
        }
      }
    },
    [Component, handleRequestNext, handleSubmit],
  );

  useEffect(() => {
    window.addEventListener('keypress', handleSubmitByKey);
    return (): void => {
      window.removeEventListener('keypress', handleSubmitByKey);
    };
  }, [handleSubmitByKey]);

  const getContentfulHeaderContinueText = () => {
    // This check is required because the backend sends a current section
    // with an empty name for the get started info question
    return questionData.currentSection?.name
      ? START_SECTION_BUTTON_TEXT
      : GET_STARTED_BUTTON_TEXT;
  };

  return Component !== undefined ? (
    <Component handleSubmit={handleSubmit} />
  ) : (
    <InfoAnswer
      text={
        answerType === AnswerTypesMap.CONTENTFUL_HEADER_SECTION
          ? getContentfulHeaderContinueText()
          : undefined
      }
      handleRequestNext={handleRequestNext}
    />
  );
};

export default InfoContainer;
