import React from 'react';
import { HAControlButtons } from 'shared';
import { Input } from '@tuunetech/tuune-components';

interface FeedbackAnswerProps {
  handleSubmit: () => void;
  feedback: string;
  handleFeedbackChange: (fb: string) => void;
  isLoading: boolean;
}

const FeedbackAnswer: React.FC<FeedbackAnswerProps> = ({
  handleSubmit,
  feedback,
  handleFeedbackChange,
  isLoading,
}) => {
  return (
    <>
      <Input
        autoFocus
        multiline
        rows={5}
        value={feedback}
        onChange={(e): void => handleFeedbackChange(e.target.value || '')}
        inputProps={{
          maxLength: 240,
        }}
        fullWidth
      />
      <HAControlButtons
        isLoading={isLoading}
        disabled={isLoading}
        onClick={handleSubmit}
        text={
          isLoading
            ? 'Saving your responses…'
            : feedback
            ? undefined
            : 'Nope, all good!'
        }
      />
    </>
  );
};

export default FeedbackAnswer;
