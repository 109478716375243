import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { BODY1, Button, Option } from '@tuunetech/tuune-components';
import { Symptom } from '../../types';
import { AppFixedFooter } from 'shared/AppFixedFooter';

export type DelabreakerProps = {
  symptoms?: Symptom[];
  dealbreaker: string | null;
  handleOptionClick: (optionSlug: string) => void;
  handleContinue: () => void;
  isLoading: boolean;
};

export const Dealbreaker: React.FC<DelabreakerProps> = ({
  symptoms,
  dealbreaker,
  handleOptionClick,
  handleContinue,
  isLoading,
}) => {
  return (
    <>
      {symptoms?.map(({ name, slug }) => (
        <Box key={slug} marginBottom="8px">
          <Option
            handleOptionClick={() => handleOptionClick(slug)}
            isActive={slug === dealbreaker}
          >
            <BODY1>{name}</BODY1>
          </Option>
        </Box>
      ))}
      <AppFixedFooter>
        <Button
          fullWidth
          onClick={handleContinue}
          disabled={isLoading}
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          continue
        </Button>
      </AppFixedFooter>
    </>
  );
};
