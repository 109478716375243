import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Patch: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path d="M9.83325 7.49998V5.16665L19.1666 5.16665V7.49998H21.4999V5.16665C21.4999 3.87798 20.4553 2.83331 19.1666 2.83331H9.83325C8.54459 2.83331 7.49992 3.87798 7.49992 5.16665V7.49998H9.83325Z" />
    <path d="M7.49992 11.5833C6.85559 11.5833 6.33325 12.1056 6.33325 12.75C6.33325 13.3943 6.85559 13.9166 7.49992 13.9166C8.14425 13.9166 8.66659 13.3943 8.66659 12.75C8.66659 12.1056 8.14425 11.5833 7.49992 11.5833Z" />
    <path d="M9.83325 15.0833C10.4776 15.0833 10.9999 15.6056 10.9999 16.25C10.9999 16.8943 10.4776 17.4166 9.83325 17.4166C9.18892 17.4166 8.66659 16.8943 8.66659 16.25C8.66659 15.6056 9.18892 15.0833 9.83325 15.0833Z" />
    <path d="M10.9999 12.75C10.9999 12.1056 11.5223 11.5833 12.1666 11.5833C12.8109 11.5833 13.3333 12.1056 13.3333 12.75C13.3333 13.3943 12.8109 13.9166 12.1666 13.9166C11.5223 13.9166 10.9999 13.3943 10.9999 12.75Z" />
    <path d="M16.8333 11.5833C16.1889 11.5833 15.6666 12.1056 15.6666 12.75C15.6666 13.3943 16.1889 13.9166 16.8333 13.9166C17.4776 13.9166 17.9999 13.3943 17.9999 12.75C17.9999 12.1056 17.4776 11.5833 16.8333 11.5833Z" />
    <path d="M20.3333 12.75C20.3333 12.1056 20.8556 11.5833 21.4999 11.5833C22.1443 11.5833 22.6666 12.1056 22.6666 12.75C22.6666 13.3943 22.1443 13.9166 21.4999 13.9166C20.8556 13.9166 20.3333 13.3943 20.3333 12.75Z" />
    <path d="M14.4999 15.0833C13.8556 15.0833 13.3333 15.6056 13.3333 16.25C13.3333 16.8943 13.8556 17.4166 14.4999 17.4166C15.1443 17.4166 15.6666 16.8943 15.6666 16.25C15.6666 15.6056 15.1443 15.0833 14.4999 15.0833Z" />
    <path d="M17.9999 16.25C17.9999 15.6056 18.5223 15.0833 19.1666 15.0833C19.8109 15.0833 20.3333 15.6056 20.3333 16.25C20.3333 16.8943 19.8109 17.4166 19.1666 17.4166C18.5223 17.4166 17.9999 16.8943 17.9999 16.25Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16659 21.5L7.49992 21.5V23.8333C7.49992 25.122 8.54459 26.1666 9.83325 26.1666H19.1666C20.4553 26.1666 21.4999 25.122 21.4999 23.8333V21.5L23.8333 21.5C25.1219 21.5 26.1666 20.4553 26.1666 19.1666V9.83331C26.1666 8.54465 25.1219 7.49998 23.8333 7.49998H5.16659C3.87792 7.49998 2.83325 8.54465 2.83325 9.83331V19.1666C2.83325 20.4553 3.87792 21.5 5.16659 21.5ZM19.1666 23.8333H9.83325V21.5H19.1666V23.8333ZM23.8333 15.0833V9.83331L5.16659 9.83331V15.0833C5.81092 15.0833 6.33325 15.6056 6.33325 16.25C6.33325 16.8943 5.81092 17.4166 5.16659 17.4166L5.16659 19.1666H23.8333V17.4166C23.1889 17.4166 22.6666 16.8943 22.6666 16.25C22.6666 15.6056 23.1889 15.0833 23.8333 15.0833Z"
    />
  </SvgIcon>
);
