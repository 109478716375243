import React, { SyntheticEvent } from 'react';
import {
  AppHeader,
  BODY2,
  Button,
  Container,
  Divider,
  H3,
  H4,
  Input,
  Option,
} from '@tuunetech/tuune-components';
import { Box, CircularProgress, Theme, useMediaQuery } from '@material-ui/core';
import { Link, Page } from 'shared';
import { FamilyPlanning, PillPack } from 'assets/icons/contraceptiveTypes';
import { Account } from 'assets/icons/Account';
import { Lock } from 'assets/icons/Lock';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { ContentBlock } from './styles';
import { FREE_TEXT_REASON_ID } from '../../pages/DeleteAccountPage';
import { DeleteReason } from '../../types';

const ACTIONS_LIST = [
  {
    id: '1',
    icon: <Lock />,
    content: <>You will be logged out on all devices.</>,
  },
  {
    id: '2',
    icon: <PillPack />,
    content: (
      <>
        You&apos;ll lose access to your tailored recommendations and/or your
        test results.
      </>
    ),
  },
  {
    id: '3',
    icon: <FamilyPlanning />,
    content: (
      <>You have the chance to reactivate it within the first 30 days.</>
    ),
  },
  {
    id: '4',
    icon: <Account />,
    content: (
      <>
        We will keep your anonymized data for 10 years to help improve our
        algorithm so we can continue to help people across the globe imporove
        their hormonal health. You can read more about it in our{' '}
        <Link
          href="https://www.tuune.com/terms-and-conditions"
          display="inline"
          variant="body2"
          color="textPrimary"
          target="_blank"
        >
          Terms & Conditions
        </Link>
        .
      </>
    ),
  },
];

type DeleteAccountProps = {
  reasons?: DeleteReason[];
  activeReason?: string;
  handleSelectReason: (reason: string) => void;
  handleFreeTextReasonChange: (e: SyntheticEvent) => void;
  freeTextReason?: string;
  handleDelete: () => void;
  isLoading: boolean;
};

export const DeleteAccount: React.FC<DeleteAccountProps> = ({
  reasons,
  activeReason,
  handleSelectReason,
  handleFreeTextReasonChange,
  freeTextReason,
  handleDelete,
  isLoading,
}) => {
  const { contactUs, account, logout, goBack } = useBaseNavButtons();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Page>
      <AppHeader
        pageTitle="My account"
        handleGoBack={goBack}
        buttons={[contactUs, account, logout]}
      />
      <Container>
        <Box width="630px" maxWidth="100%" marginX="auto">
          <H3>
            We&apos;re sorry to see you go! Why are you deleting your account?
          </H3>
          <BODY2 color="textSecondary" $verticalMargin={24}>
            Select the one you feel the strongest about.
          </BODY2>
          <Box display="flex" flexDirection="column" gridRowGap="8px">
            {reasons === undefined ? (
              <CircularProgress color="primary" />
            ) : (
              reasons.map(([id, name]) => (
                <Option
                  key={id}
                  isActive={id === activeReason}
                  handleOptionClick={() => {
                    handleSelectReason(id);
                  }}
                >
                  {name}
                </Option>
              ))
            )}
            {activeReason === FREE_TEXT_REASON_ID && (
              <Input onChange={handleFreeTextReasonChange} autoFocus />
            )}
          </Box>

          <Divider $verticalMargin={24} />
          <H4 $marginBottom={24}>By deleting your account:</H4>
          {ACTIONS_LIST.map(({ id, icon, content }) => (
            <ContentBlock
              key={id}
              startAdornment={icon}
              content={<BODY2>{content}</BODY2>}
            />
          ))}

          <Box
            marginTop="24px"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Button
              fullWidth={isMobile}
              onClick={handleDelete}
              disabled={
                !activeReason ||
                (activeReason === FREE_TEXT_REASON_ID && !freeTextReason) ||
                isLoading
              }
              startIcon={
                isLoading && <CircularProgress color="inherit" size={12} />
              }
            >
              I understand, Delete my account
            </Button>
            <Box height="8px" />
            <Button fullWidth={isMobile} onClick={goBack} variant="outlined">
              Go back
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};
