import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Container } from '@tuunetech/tuune-components';
import { SpinnerContainer } from 'shared/Spinner/styles';

const LoadingContainer: React.FC = () => {
  return (
    <Container $marginTop={100}>
      <SpinnerContainer>
        <CircularProgress color="primary" />
      </SpinnerContainer>
    </Container>
  );
};

export default LoadingContainer;
