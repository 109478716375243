import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ErrorBox, HAControlButtons } from 'shared';
import { AnswerOption } from 'modules/Questionnaire/types';
import { Box } from '@material-ui/core';
import { BODY1, CAPTION, Option } from '@tuunetech/tuune-components';

interface ChoiceAnswerProps {
  options: AnswerOption[];
  handleOptionClick: (option: AnswerOption) => void;
  handleSubmit: () => void;
  handleRequestNext: () => void;
  disableContinue: boolean;
  showContinue: boolean;
  dirty?: boolean;
}

const ChoiceAnswer: React.FC<ChoiceAnswerProps> = ({
  options,
  handleOptionClick,
  handleSubmit,
  disableContinue,
  showContinue,
  dirty = true,
  handleRequestNext,
}) => {
  const [errorVisible, setErrorVisible] = useState(false);

  const submit = useCallback((): void => {
    if (!disableContinue) {
      return dirty ? handleSubmit() : handleRequestNext();
    } else {
      setErrorVisible(true);
    }
  }, [disableContinue, dirty, handleRequestNext, handleSubmit]);

  useEffect(() => {
    if (!disableContinue) {
      setErrorVisible(false);
    }
  }, [disableContinue]);

  const renderOption = (option: AnswerOption, idx: number): ReactNode => {
    return (
      <Option
        key={option.value.toString() + idx}
        handleOptionClick={() => handleOptionClick(option)}
        isActive={option.isActive}
        startAdornment={option.startIcon}
      >
        <div>
          <BODY1>{option.option}</BODY1>
          {option.description && <CAPTION>{option.description}</CAPTION>}
        </div>
      </Option>
    );
  };

  return (
    <>
      <Box>
        {errorVisible && (
          <>
            <ErrorBox text="You must select at least one option to continue." />
            <Box height="24px" />
          </>
        )}
        <Box flexDirection="column" display="flex" gridRowGap="8px">
          {options.map(renderOption)}
        </Box>
      </Box>
      <HAControlButtons isContinueVisible={showContinue} onClick={submit} />
    </>
  );
};

export default ChoiceAnswer;
