const HEIGHT = 'height';
const WEIGHT = 'weight';
const FACT = 'fact';
const FACT_RADIO = 'fact_radio';
const INFO = 'info';
const SIDE_EFFECTS = 'side_effects';
const CURRENT_PILL = 'current_pill';
const AUTOCOMPLETE = 'autocomplete';
const EMAIL = 'email';
const CYCLE = 'cycle_timing';
const SUBJECTIVE = 'subjective';
const CONTENTFUL_HEADER_SECTION = 'contentful_header_section';

export const ServerQuestionTypesMap = {
  HEIGHT,
  WEIGHT,
  FACT,
  FACT_RADIO,
  INFO,
  SIDE_EFFECTS,
  CURRENT_PILL,
  AUTOCOMPLETE,
  EMAIL,
  CYCLE,
  SUBJECTIVE,
  CONTENTFUL_HEADER_SECTION,
};
