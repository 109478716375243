export const SET_FEEDBACK_Q_NUM = 'SET_FEEDBACK_Q_NUM';
type SetFeedbackQnNumAction = {
  type: typeof SET_FEEDBACK_Q_NUM;
  payload: {
    feedbackQnNum: number;
  };
};

export const SET_FEEDBACK_Q_DATA = 'SET_FEEDBACK_Q_DATA';
export type FeedbackDataType = Partial<Omit<State, 'feedbackQnNum'>>;
type SetFeedbackQnData = {
  type: typeof SET_FEEDBACK_Q_DATA;
  payload: FeedbackDataType;
};

export type State = {
  feedbackQnNum: number;
  userHasInsurance?: boolean;
  healthcareServices?: Set<string>;
  socialMediaChannel?: string;
  subscriptions?: Set<string>;
};

export type Action = SetFeedbackQnNumAction | SetFeedbackQnData;

export type Reducer = (prevState: State, action: Action) => State;

export const initialState: State = {
  feedbackQnNum: 0,
};

export const reducer: Reducer = (prevState, action) => {
  switch (action.type) {
    case SET_FEEDBACK_Q_NUM:
      return {
        ...prevState,
        feedbackQnNum: action.payload.feedbackQnNum,
      };
    case SET_FEEDBACK_Q_DATA:
      return {
        ...prevState,
        ...action.payload,
      };
    default:
      return prevState;
  }
};
