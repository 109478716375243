import React from 'react';
import { ClinicianLauraBio, StatusBanner } from 'shared';
import { ClinicianLauraBioProps } from 'shared/ClinicianLauraBio';

export type IntroProps = Omit<ClinicianLauraBioProps, 'isReviewed'>;

const Intro: React.FC<IntroProps> = ({ name, onMoreAboutDoctorClick }) => {
  return (
    <StatusBanner
      content={
        <ClinicianLauraBio
          isReviewed
          name={name}
          onMoreAboutDoctorClick={onMoreAboutDoctorClick}
        />
      }
    />
  );
};

export default Intro;
