import React from 'react';
import { AppHeader } from '@tuunetech/tuune-components';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { OrderSuccessContainer } from '../containers';
import { Page } from 'shared';

export const OrderSuccessPage: React.FC = () => {
  const headerButtons = useBaseNavButtons();
  return (
    <Page>
      <AppHeader
        pageTitle="Order confirmation"
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />
      <OrderSuccessContainer />
    </Page>
  );
};
