import React, { useMemo } from 'react';
import { ReportContentfulKeys as CK } from '../constants';
import { getGreenFlags, getOrangeFlags, getRedFlags } from '../selectors';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { useSelector } from 'react-redux';
import { documentToPlainTextRecords } from 'modules/Contentful/helpers';
import { FlagsSectionLayout } from '../components/FlagsSectionLayout';

export const FlagsContainer: React.FC = () => {
  const greenFlags = useSelector(getGreenFlags);
  const orangeFlags = useSelector(getOrangeFlags);
  const redFlags = useSelector(getRedFlags);

  // static data
  const staticData = useSelector(
    getArrayContentMapped(CK[CTN.STATIC_CONTENT].reqId),
  );

  const staticDataContent = useMemo(() => {
    return documentToPlainTextRecords(staticData);
  }, [staticData]);

  return (
    <FlagsSectionLayout
      greenFlags={greenFlags}
      orangeFlags={orangeFlags}
      redFlags={redFlags}
      staticDataContent={staticDataContent}
    />
  );
};
