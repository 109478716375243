import styled, { keyframes } from 'styled-components';

interface TransitionAnimationProps {
  duration: number;
  animation?: string;
}

const PageAnimation = (direction = 'forward') => keyframes`
  0% {
    opacity: 0.3;
    transform: translateX(${direction === 'back' ? '-' : ''}100%);
  }
  
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const TransitionAnimation = styled.div<TransitionAnimationProps>`
  animation: ${(props) => PageAnimation(props.animation)}
    ${(props) => props.duration}ms linear;
  animation-fill-mode: backwards;
`;

export default TransitionAnimation;
