import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { BODY1, Select } from '@tuunetech/tuune-components';
import { Box, MenuItem } from '@material-ui/core';
import { TuuneInput } from 'shared';
import { ShippingDetailFormData } from 'modules/Checkout/containers/ShippingAddressContainer';
import { PhoneInput } from 'shared/PhoneInput';
import { US_SUPPORTED_STATES } from 'utils/usStates';
import { getDefaultCountry } from 'utils/helpers';

export type ShippingAddressProps = {
  handleSubmit: () => void;
  control: Control<ShippingDetailFormData>;
  errors: FieldErrors<ShippingDetailFormData>;
  defaultStateAddress?: string;
};

export const ShippingAddress: React.FC<ShippingAddressProps> = ({
  handleSubmit,
  control,
  errors,
  defaultStateAddress,
}) => {
  const renderStateSelectValue = (selected: unknown) => {
    if (!selected) {
      return <BODY1 color="textSecondary">Select State</BODY1>;
    } else {
      return <BODY1>{selected}</BODY1>;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TuuneInput
        name="addressLine1"
        autoComplete="address-line1"
        control={control}
        placeholder="Address line 1*"
        type="text"
        helperText={
          errors.addressLine1?.message
            ? errors.addressLine1?.message
            : 'Street address, P.O. box, company name'
        }
        error={!!errors.addressLine1}
        fullWidth
      />
      <Box marginBottom="16px" />

      <TuuneInput
        name="addressLine2"
        autoComplete="address-line2"
        control={control}
        placeholder="Address line 2"
        type="text"
        helperText={
          errors.addressLine2?.message
            ? errors.addressLine2?.message
            : 'Apartment, suite, unit, building, floor, etc.'
        }
        error={!!errors.addressLine2}
        fullWidth
      />
      <Box marginBottom="16px" />

      <TuuneInput
        name="city"
        autoComplete="address-level2"
        control={control}
        placeholder="City*"
        type="text"
        helperText={errors.city?.message}
        error={!!errors.city}
        fullWidth
      />
      <Box marginBottom="16px" />

      <Controller
        name="state"
        control={control}
        defaultValue={defaultStateAddress}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Select
              fullWidth
              variant="outlined"
              displayEmpty
              renderValue={renderStateSelectValue}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              helperText={errors.state?.message}
              error={!!errors.state}
            >
              {US_SUPPORTED_STATES?.map(({ abbreviation }) => (
                <MenuItem key={abbreviation} value={abbreviation}>
                  {abbreviation}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
      <Box marginBottom="16px" />

      <TuuneInput
        name="zipCode"
        autoComplete="postal-code"
        control={control}
        placeholder="ZIP Code*"
        type="text"
        helperText={errors.zipCode?.message}
        error={!!errors.zipCode}
        fullWidth
      />
      <Box marginBottom="40px" />

      <BODY1 $marginBottom={16}>
        Please provide your phone number in case we need to contact you.
      </BODY1>
      <PhoneInput
        control={control}
        helperText={errors.phone?.message}
        error={!!errors.phone}
        countryObj={getDefaultCountry()}
        fullWidth
      />
    </form>
  );
};
