import React from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { DialogContent, Paper, ImageWrapper } from './styles';
import { BODY1, Button, H5 } from '@tuunetech/tuune-components';
import { TuuneInput } from 'shared';
import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import { Control, FieldErrors } from 'react-hook-form';
import { CinicPasswordFormData } from 'modules/Questionnaire/containers/ClinicGetStarted/ClinicPasswordModalContainer';

export type ClinicPasswordModalProps = {
  control: Control<CinicPasswordFormData>;
  errors: FieldErrors<CinicPasswordFormData>;
  clinicAuthConfig: ClinicAuthConfig;
  isLoading: boolean;
  handleSubmit: () => void;
  validationErrorMessage?: string;
};

export const ClinicPasswordModal: React.FC<ClinicPasswordModalProps> = ({
  clinicAuthConfig,
  isLoading,
  handleSubmit,
  control,
  errors,
  validationErrorMessage,
}) => {
  return (
    <Dialog open PaperComponent={Paper} fullWidth maxWidth="sm" scroll="paper">
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <ImageWrapper>
            <img src={clinicAuthConfig.logoImg} />
          </ImageWrapper>
          <BODY1>{clinicAuthConfig.passwordModalIntro}</BODY1>
          {!!clinicAuthConfig.passwordModalIntroSubTitle && (
            <H5>{clinicAuthConfig.passwordModalIntroSubTitle}</H5>
          )}
          <TuuneInput
            name="password"
            placeholder="Password"
            disabled={isLoading}
            control={control}
            error={!!validationErrorMessage || !!errors?.password}
            helperText={validationErrorMessage || errors?.password?.message}
            fullWidth
            autoFocus
          />
          <Button
            type="submit"
            disabled={isLoading}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
            fullWidth
          >
            Continue
          </Button>
        </DialogContent>
      </form>
    </Dialog>
  );
};
