import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { Assessment } from 'shared/Assessment/types/state';
import { PreviewReducerState } from '../types';

const selectPreview: Selector<RootState, PreviewReducerState> = (state) =>
  state.preview;

export const getAssessmentData = createSelector(selectPreview, (state) => {
  const conditions: Assessment[] = [];
  const symptomsHormonal: Assessment[] = [];
  const symptomsUnknown: Assessment[] = [];
  state.assessment.forEach((issue) => {
    const {
      issueType,
      scores: { hormonal },
    } = issue;
    // Hormonal Condition
    if (issueType === 'condition') {
      conditions.push(issue);
    } else {
      // Likely affected by hormones
      if (hormonal >= 0.6) {
        symptomsHormonal.push(issue);
      } else {
        // Needs more investigation
        symptomsUnknown.push(issue);
      }
    }
  });
  return {
    conditions,
    symptomsHormonal,
    symptomsUnknown,
  };
});

export const getAllAssessmentData = createSelector(
  selectPreview,
  (state) => state.assessment,
);

export const getRecommendations = createSelector(
  selectPreview,
  (state) => state.recommendations,
);

export const getCurrentMethodData = createSelector(
  selectPreview,
  (state) => state.currentMethod,
);

export const getIsReportFeedbackSubmitted = createSelector(
  selectPreview,
  (state) => state.reportFeedbackSubmitted,
);
