import { ContraceptiveMethod, Mec } from 'modules/Results/types';
import {
  CONTRACEPTIVE_METHODS_NAMES_MAPPING,
  FabType,
  lowercaseFirstLetter,
} from '../../constants';

export const isIneligible = (
  contraceptiveMethod: ContraceptiveMethod,
  mec: Record<ContraceptiveMethod, Mec>,
): boolean => {
  return mec[contraceptiveMethod]?.permitted === 'no';
};

export const getIneligibilityText = (
  contraceptiveMethod: ContraceptiveMethod,
  mecFlags: Record<ContraceptiveMethod, Mec>,
): string => {
  let intro: string;
  if (
    contraceptiveMethod !== FabType.CalendarBased &&
    contraceptiveMethod !== FabType.SymptomBased
  ) {
    intro = CONTRACEPTIVE_METHODS_NAMES_MAPPING[contraceptiveMethod];
  } else {
    intro = `The ${lowercaseFirstLetter(
      CONTRACEPTIVE_METHODS_NAMES_MAPPING[contraceptiveMethod],
    )}`;
  }

  return `${intro} is not a good option for you because: ${mecFlags[
    contraceptiveMethod
  ].triggeredRules
    .map((redFlag: string) => lowercaseFirstLetter(redFlag))
    .join('; ')}`;
};

export const getSwipeIndicatorText = (alternativesCount?: number): string => {
  if (alternativesCount) {
    return `Swipe to compare ${alternativesCount} method${
      alternativesCount > 1 ? 's' : ''
    }`;
  } else {
    return 'Swipe to compare';
  }
};
