import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { QuestionnaireHeader } from 'modules/Questionnaire/components/QuestionnaireHeader';
import { LoadingContainer } from './styles';
import { BODY1 } from '@tuunetech/tuune-components';
import { Page } from 'shared';

interface OAuthRedirectCallbackProps {
  isLoading: boolean;
}

const OAuthRedirectCallback: React.FC<OAuthRedirectCallbackProps> = ({
  isLoading,
}) => {
  return (
    <Page>
      <QuestionnaireHeader />
      {isLoading && (
        <LoadingContainer>
          <CircularProgress color="inherit" />
          <BODY1 $marginTop={44}>Redirecting you to Tuune...</BODY1>
        </LoadingContainer>
      )}
    </Page>
  );
};

export default OAuthRedirectCallback;
