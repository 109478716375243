import React, { Fragment, useCallback, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import MuiCheckIcon from '@material-ui/icons/Check';
import {
  BODY1,
  BODY2,
  CAPTION,
  ContentBlock,
  Divider,
  LearnMore,
} from '@tuunetech/tuune-components';
import { FunnelFilter } from 'assets/icons/FunnelFilter';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import {
  ContraceptiveType,
  CONTRACEPTIVE_TYPE_NAMES_MAPPING,
} from '../../constants';
import {
  ContraceptiveMethod,
  Flag as FlagType,
  Mec,
} from 'modules/Results/types';
import Flag, { FLAG_TYPES } from 'shared/Flag';
import { Link } from 'shared';
import {
  CancelIcon,
  ChipLabel,
  ForwardIcon,
  IneligibilityBlock,
  MecEligibilityBlock,
  MethodChip,
  ShowFilterSwitch,
} from './styles';
import { getIneligibilityText, getSwipeIndicatorText } from './helpers';

export type RecommendationFilterProps = {
  handleRecommendationFilterChange: (
    contraceptiveMethods: ContraceptiveType[],
  ) => void;
  selectedContraceptiveTypes: string[];
  ineligibleMethods: string[];
  allAlternativesCount?: number;
  selectedAlternativesCount?: number;
  mecFlags: Record<ContraceptiveMethod, Mec>;
  specialFlags: FlagType[];
  handleIneligibilityInfoOpen: () => void;
  hasCurrentRecommendation: boolean;
};

const RecommendationFilter: React.FC<RecommendationFilterProps> = ({
  handleRecommendationFilterChange,
  selectedContraceptiveTypes,
  ineligibleMethods,
  allAlternativesCount,
  selectedAlternativesCount,
  mecFlags,
  specialFlags,
  handleIneligibilityInfoOpen,
  hasCurrentRecommendation,
}) => {
  const analytics = useAnalytics();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  const showSwipeIndicator =
    isMobile && (!!selectedAlternativesCount || hasCurrentRecommendation);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);

  const onMethodChipClick = useCallback(
    (clickedType: string, isChecked: boolean) => {
      let newSelection: ContraceptiveType[] = [];
      if (selectedContraceptiveTypes.includes(clickedType)) {
        newSelection = selectedContraceptiveTypes.filter(
          (contraceptiveType) => contraceptiveType !== clickedType,
        ) as ContraceptiveType[];
      } else {
        newSelection = [
          ...selectedContraceptiveTypes,
          clickedType,
        ] as ContraceptiveType[];
      }

      handleRecommendationFilterChange(newSelection);
      analytics.track(ANALYTICS_EVENT.RECOMMENDATION_REPORT_FILTER_CHANGE, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        contraceptiveTypes: newSelection,
      });

      analytics.track(
        ANALYTICS_EVENT.RECOMMENDATION_REPORT_FILTER_CLICK_ALTERNATIVE_CHIP,
        {
          category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
          contraceptiveType: clickedType,
          isChecked,
        },
      );
    },
    [analytics, selectedContraceptiveTypes, handleRecommendationFilterChange],
  );

  const onSwitchToggle = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        // Select all methods
        handleRecommendationFilterChange(Object.values(ContraceptiveType));

        analytics.track(
          ANALYTICS_EVENT.RECOMMENDATION_REPORT_FILTER_SHOW_ALTERNATIVES,
          {
            category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
          },
        );
      } else {
        // Deselect all methods
        handleRecommendationFilterChange([]);
      }
      setIsFilterVisible(isChecked);
    },
    [setIsFilterVisible, handleRecommendationFilterChange, analytics],
  );

  return (
    <>
      <Box display="flex" flexDirection="row">
        <div>
          <BODY1>See {allAlternativesCount} alternative methods</BODY1>
          <BODY2>(pills, patches, IUDs and more)</BODY2>
        </div>
        <ShowFilterSwitch
          color="primary"
          checked={isFilterVisible}
          onChange={(e) => onSwitchToggle(e.target.checked)}
        />
      </Box>
      {isFilterVisible && (
        <>
          <Box marginTop="24px" />
          <LearnMore
            isOpen={isFilterDropdownOpen}
            onToggle={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
            justify="space-between"
            linkText={
              <ContentBlock
                startAdornment={<FunnelFilter />}
                content={<BODY2>Filter by method</BODY2>}
              />
            }
          >
            <Divider $marginTop={12} $marginBottom={24} />
            <Box display="flex" flexWrap="wrap" gridGap={8}>
              {Object.entries(CONTRACEPTIVE_TYPE_NAMES_MAPPING).map(
                ([contraceptiveType, contraceptiveName]) => {
                  const isSelected =
                    selectedContraceptiveTypes.includes(contraceptiveType);
                  const isDisabled =
                    ineligibleMethods.includes(contraceptiveType);
                  return (
                    <MethodChip
                      disableRipple
                      key={contraceptiveType}
                      label={
                        <ChipLabel>
                          <span>{contraceptiveName}</span>
                          {isSelected && !isDisabled && (
                            <MuiCheckIcon color="primary" fontSize="small" />
                          )}
                        </ChipLabel>
                      }
                      onClick={() => {
                        onMethodChipClick(contraceptiveType, isSelected);
                      }}
                      clickable={!isDisabled}
                      disabled={isDisabled}
                    />
                  );
                },
              )}
            </Box>
          </LearnMore>
          <IneligibilityBlock>
            {!!specialFlags.length &&
              specialFlags.map(({ content, id }) => (
                <Flag key={id} info={content} type={FLAG_TYPES.RED} />
              ))}
            {ineligibleMethods?.map(
              (contraceptiveMethod: ContraceptiveMethod) => (
                <Fragment key={contraceptiveMethod}>
                  <MecEligibilityBlock
                    startAdornment={<CancelIcon />}
                    content={
                      <Link
                        variant="body1"
                        color="textPrimary"
                        onClick={() => handleIneligibilityInfoOpen()}
                      >
                        {getIneligibilityText(contraceptiveMethod, mecFlags)}
                      </Link>
                    }
                  />
                </Fragment>
              ),
            )}
          </IneligibilityBlock>
        </>
      )}
      {showSwipeIndicator && (
        <>
          <Box marginTop="24px" display="flex" flexDirection="row-reverse">
            <ContentBlock
              content={
                <CAPTION>
                  <i>{getSwipeIndicatorText(selectedAlternativesCount)}</i>
                </CAPTION>
              }
              endAdornment={<ForwardIcon />}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default RecommendationFilter;
