import { ContraceptiveMethod } from '../types';
import * as contraceptiveTypesIcons from 'assets/icons/contraceptiveTypes';
import { icons as additionalInfoIcons } from 'assets/icons';
import { ContraceptiveEligibilityStatus } from '@tuunetech/tuune-components';

export enum RECOMMENDATION_GROUP {
  GROUP1 = 'group_1',
  GROUP2 = 'group_2',
  GROUP3 = 'group_3',
}

export const RECOMMENDATION_SUMMARY = {
  [RECOMMENDATION_GROUP.GROUP1]:
    "We've found some contraceptive health recommendations just for you, we’ve prioritised the ones that will have the most positive impact on your health.",
  [RECOMMENDATION_GROUP.GROUP2]: {
    header: `${RECOMMENDATION_GROUP.GROUP2}_header`,
    content: `${RECOMMENDATION_GROUP.GROUP2}_content`,
  },
  [RECOMMENDATION_GROUP.GROUP3]: {
    header: `${RECOMMENDATION_GROUP.GROUP3}_header`,
    content: `${RECOMMENDATION_GROUP.GROUP3}_content`,
  },
};

export const RECOMMENDATION_CALCULATION_SLUGS = {
  TITLE: 'recommendation_calculation_title',
  CONTENT: 'recommendation_prioritize_content',
};

export const MY_BENEFITS_TAB = {
  key: 'my',
  value: 'My benefits',
  heading: 'Your benefits, explained:',
  isActive: false,
};

export const OTHER_BENEFITS_TAB = {
  key: 'other',
  value: 'Other benefits',
  heading: 'Other benefits',
  isActive: false,
};

export const BENEFITS_RISKS_TAB = {
  key: 'type',
  value: 'Reduced health risks',
  heading: 'Reduced health risks',
  isActive: false,
};

export const BENEFITS_TABS = [
  MY_BENEFITS_TAB,
  OTHER_BENEFITS_TAB,
  BENEFITS_RISKS_TAB,
];

export const REGIMEN_DETAILS_TAB = {
  key: 'details',
  value: 'Regimen details',
};

export const REGIMEN_SAFETY_TAB = {
  key: 'safety',
  value: 'Regimen safety & efficacy',
};
export const REGIMEN_TABS = [REGIMEN_DETAILS_TAB, REGIMEN_SAFETY_TAB];

export const PILL_TYPE_CODE = {
  coc: 'coc',
  pop: 'pop',
};

export const PILL_TYPE = {
  [PILL_TYPE_CODE.coc]: 'combined',
  [PILL_TYPE_CODE.pop]: 'progestogen-only',
};

export const RECOMMENDATION_TYPE = {
  PILL: 'oral',
  REFERRAL: 'referral',
};

export const HOW_TO_START_SUMMARY_SLUG = 'how_to_start_generic';

export enum ContraceptiveType {
  Pill = 'oral',
  Vaginal = 'vaginal',
  Transdermal = 'transdermal',
  Intramuscular = 'intramuscular',
  Subcutaneous = 'subcutaneous',
  HormonalIntrauterine = 'hormonal_intrauterine',
  IntrauterineNonHormonal = 'intrauterine_non-hormonal',
  Natural = 'natural',
}

export enum AdditionalInfoType {
  BloodClotRisk = 'blood_clot_risk',
  KeepHavingPeriods = 'keep_having_periods',
  RenewEvery = 'renew_every',
  MedicalProcedure = 'medical_procedure',
  PregnancyPreventionTypical = 'pregnancy_prevention_typical',
  ReturnBaselineFertility = 'return_baseline_fertility',
}

export enum PillType {
  COC = 'coc',
  POP = 'pop',
}

export enum FabType {
  CalendarBased = 'calendarBased',
  SymptomBased = 'symptomBased',
}

export enum MecGroupName {
  POP = 'POP',
  COC = 'COC',
  RING = 'Ring',
  PATCH = 'Patch',
  DMPA = 'DMPA',
  IMP = 'IMP',
  CU_IUD = 'Cu-IUD',
  LNG_IUD = 'LNG-IUD',
  FAB_SYMPTOM_BASED = 'FAB_SYMPTOM_BASED',
  FAB_CALENDAR_BASED = 'FAB_CALENDAR_BASED',
}

export const CONTRACEPTIVE_PREFERENCES_SLUGS_MAPPING = {
  contraception_open_to_consider_pill: ContraceptiveType.Pill,
  contraception_open_to_consider_ring: ContraceptiveType.Vaginal,
  contraception_open_to_consider_patch: ContraceptiveType.Transdermal,
  contraception_open_to_consider_injection: ContraceptiveType.Intramuscular,
  contraception_open_to_consider_implant: ContraceptiveType.Subcutaneous,
  contraception_open_to_consider_hormonal_iud:
    ContraceptiveType.HormonalIntrauterine,
  contraception_open_to_consider_copper_iud:
    ContraceptiveType.IntrauterineNonHormonal,
  contraception_open_to_consider_natural: ContraceptiveType.Natural,
};

export const CONTRACEPTIVE_TYPE_NAMES_MAPPING = {
  [ContraceptiveType.Pill]: 'Pill',
  [ContraceptiveType.Vaginal]: 'Ring',
  [ContraceptiveType.Transdermal]: 'Patch',
  [ContraceptiveType.Intramuscular]: 'Injection',
  [ContraceptiveType.Subcutaneous]: 'Implant',
  [ContraceptiveType.HormonalIntrauterine]: 'Hormonal IUD',
  [ContraceptiveType.IntrauterineNonHormonal]: 'Copper IUD',
  [ContraceptiveType.Natural]: 'Natural Methods',
};

export const CONTRACEPTIVE_METHODS_NAMES_MAPPING = {
  [PILL_TYPE_CODE.coc]: 'Combined pill',
  [PILL_TYPE_CODE.pop]: 'Progestin-only pill',
  [ContraceptiveType.Vaginal]: 'Vaginal ring',
  [ContraceptiveType.Transdermal]: 'Patch',
  [ContraceptiveType.Intramuscular]: 'Injection',
  [ContraceptiveType.Subcutaneous]: 'Implant',
  [ContraceptiveType.HormonalIntrauterine]: 'Hormonal IUD',
  [ContraceptiveType.IntrauterineNonHormonal]: 'Copper IUD',
  [FabType.SymptomBased]: 'Fertility Awareness Method',
  [FabType.CalendarBased]: 'Fertility Awareness Method',
};

export const CONTRACEPTIVE_METHODS_ICONS_MAPPING = {
  [ContraceptiveType.Pill]: contraceptiveTypesIcons.PillPack,
  [ContraceptiveType.Vaginal]: contraceptiveTypesIcons.Ring,
  [ContraceptiveType.Transdermal]: contraceptiveTypesIcons.Patch,
  [ContraceptiveType.Intramuscular]: contraceptiveTypesIcons.Injection,
  [ContraceptiveType.Subcutaneous]: contraceptiveTypesIcons.Implant,
  [ContraceptiveType.HormonalIntrauterine]: contraceptiveTypesIcons.IUD,
  [ContraceptiveType.IntrauterineNonHormonal]: contraceptiveTypesIcons.IUD,
  [ContraceptiveType.Natural]: contraceptiveTypesIcons.FamilyPlanning,
};

export const ADDITIONAL_INFO_ICONS_MAPPING = {
  [AdditionalInfoType.BloodClotRisk]: additionalInfoIcons.bloodClotRisk,
  [AdditionalInfoType.KeepHavingPeriods]: additionalInfoIcons.keepHavingPeriods,
  [AdditionalInfoType.RenewEvery]: additionalInfoIcons.renewEvery,
  [AdditionalInfoType.MedicalProcedure]: additionalInfoIcons.medicalProcedure,
  [AdditionalInfoType.PregnancyPreventionTypical]:
    additionalInfoIcons.pregnancyPreventionTypical,
  [AdditionalInfoType.ReturnBaselineFertility]:
    additionalInfoIcons.returnBaselineFertility,
};

export const CONTRACEPTIVE_MEC_GROUP_NAMES_MAPPING = {
  [PillType.COC]: MecGroupName.COC,
  [PillType.POP]: MecGroupName.POP,
  [ContraceptiveType.Vaginal]: MecGroupName.RING,
  [ContraceptiveType.Transdermal]: MecGroupName.PATCH,
  [ContraceptiveType.Intramuscular]: MecGroupName.DMPA,
  [ContraceptiveType.Subcutaneous]: MecGroupName.IMP,
  [ContraceptiveType.HormonalIntrauterine]: MecGroupName.LNG_IUD,
  [ContraceptiveType.IntrauterineNonHormonal]: MecGroupName.CU_IUD,
  [FabType.SymptomBased]: MecGroupName.FAB_SYMPTOM_BASED,
  [FabType.CalendarBased]: MecGroupName.FAB_CALENDAR_BASED,
};

const getValueFromMapping = (
  recommendation: {
    recommendationType: ContraceptiveType;
    subType?: PillType | FabType;
  },
  mapping: Record<ContraceptiveMethod, string>,
): string => {
  if (
    recommendation.recommendationType === ContraceptiveType.Pill ||
    recommendation.recommendationType === ContraceptiveType.Natural
  ) {
    if (!recommendation.subType) {
      return '';
    } else {
      return mapping[recommendation.subType];
    }
  } else {
    return mapping[recommendation.recommendationType];
  }
};

export const getRecommendationText = (recommendation: {
  recommendationType: ContraceptiveType;
  subType?: PillType | FabType;
}): string => {
  return getValueFromMapping(
    recommendation,
    CONTRACEPTIVE_METHODS_NAMES_MAPPING,
  );
};

export const getContraceptiveMethodType = (
  contraceptiveMethod: ContraceptiveMethod,
): ContraceptiveType => {
  if (
    Object.entries(PillType)
      .map(([, pillType]) => pillType)
      .includes(contraceptiveMethod)
  ) {
    // PillType -> Pill
    return ContraceptiveType.Pill;
  } else if (
    // FabType -> Natural
    Object.entries(FabType)
      .map(([, fabType]) => fabType)
      .includes(contraceptiveMethod)
  ) {
    return ContraceptiveType.Natural;
  } else {
    // Already a ContraceptiveType with no subtypes
    return contraceptiveMethod;
  }
};

export const getMethodByMecGroup = (mecGroup: string): ContraceptiveMethod => {
  return Object.entries(CONTRACEPTIVE_MEC_GROUP_NAMES_MAPPING).find(
    ([, groupName]) => groupName === mecGroup,
  )?.[0];
};

export const CONTRACEPTIVE_TYPES_FOR_PRESCRIPTION = [
  CONTRACEPTIVE_METHODS_NAMES_MAPPING[PILL_TYPE_CODE.coc],
  CONTRACEPTIVE_METHODS_NAMES_MAPPING[PILL_TYPE_CODE.pop],
  CONTRACEPTIVE_METHODS_NAMES_MAPPING[ContraceptiveType.Vaginal],
  CONTRACEPTIVE_METHODS_NAMES_MAPPING[ContraceptiveType.Transdermal],
  CONTRACEPTIVE_METHODS_NAMES_MAPPING[ContraceptiveType.Intramuscular],
];

export const PRESCRIBABLE_ELIGIBILITY_STATUS: string[] = [
  ContraceptiveEligibilityStatus.Best,
  // None status is for mec permitted current methods
  ContraceptiveEligibilityStatus.None,
];
