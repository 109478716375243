import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import {
  GET_ACCOUNT_DATA_SUCCESS,
  GET_IS_ACTIVE_SUCCESS,
  SET_IS_ACTIVE,
  UPDATE_ACCOUNT_DATA_SUCCESS,
  UPDATE_MARKETING_PREFERENCE_SUCCESS,
  UPDATE_PRIVACY_OPTIONS_SUCCESS,
  GET_DELETION_REASONS_SUCCESS,
} from '../constants';
import { AccountReducerState } from '../types';
import {
  CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS,
  REGISTER_TO_QUESTIONNAIRE_SUCCESS,
} from 'modules/Questionnaire/constants';
import { OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS } from 'modules/Auth/constants';

export const defaultState: AccountReducerState = {
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  isActive: undefined,

  clinic: null,

  acceptMarketingPreference: false,
  acceptResearchPreference: false,

  acceptAnswersTruthful: false,
  acceptDataUsage: false,
  acceptTerms: false,
  acceptAssignedAtBirth: false,
  acceptTestingAgreement: false,
  acceptShareDataBack: false,
  acceptShareReportWithClinician: false,

  signupPaymentUrl: undefined,
  signupType: undefined,

  chatToObgynFeedbackSubmitted: undefined,
  monitoringFeedbackSubmitted: undefined,
  hormoneTestingFeedbackSubmitted: undefined,

  reportStageStatus: undefined,

  feedbackSectionSeen: false,

  deletionReasons: undefined,
};

const accountReducer: Reducer<AccountReducerState> = (
  state = defaultState,
  { type, response: { data = {} } = {}, payload = {} },
) => {
  switch (type) {
    case GET_ACCOUNT_DATA_SUCCESS:
    case UPDATE_ACCOUNT_DATA_SUCCESS:
    case UPDATE_MARKETING_PREFERENCE_SUCCESS:
    case UPDATE_PRIVACY_OPTIONS_SUCCESS:
    case GET_IS_ACTIVE_SUCCESS:
      return {
        ...state,
        ...camelcaseKeys(data, { deep: true }),
      };
    case REGISTER_TO_QUESTIONNAIRE_SUCCESS:
    case CLINIC_REGISTER_TO_QUESTIONNAIRE_SUCCESS:
    case OAUTH_SIGNUP_WITH_AUTH_CODE_SUCCESS:
      return {
        ...state,
        signupPaymentUrl: data.stripe_url,
      };
    case SET_IS_ACTIVE:
      return {
        ...state,
        isActive: payload.isActive,
      };
    case GET_DELETION_REASONS_SUCCESS:
      return {
        ...state,
        deletionReasons: data || [],
      };
    default:
      return state;
  }
};

export { accountReducer as account };
