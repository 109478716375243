import React from 'react';
import { HAControlButtons } from 'shared';

const InfoAnswer: React.FC<{
  handleRequestNext?: () => void;
  text?: string;
}> = ({ text, handleRequestNext }) => {
  return (
    <HAControlButtons
      text={text}
      onClick={(): void => handleRequestNext && handleRequestNext()}
    />
  );
};

export default InfoAnswer;
