import React from 'react';
import { HAControlButtons } from 'shared';
import { PillOption } from 'modules/Questionnaire/types';
import {
  Autocomplete,
  BODY1,
  Button,
  Input,
} from '@tuunetech/tuune-components';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  PillFormData,
  SkipChoice,
} from 'modules/Questionnaire/containers/Answers/PillContainer';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';

interface PillAnswerProps {
  pills: PillOption[];
  current: boolean;
  handleSubmit: () => void;
  handleSkip: (choice: SkipChoice) => void;
  control: Control<PillFormData>;
  errors: FieldErrors<PillFormData>;
  defaultValue?: string;
}

const PillAnswer: React.FC<PillAnswerProps> = ({
  pills,
  current,
  handleSubmit,
  handleSkip,
  control,
  errors,
  defaultValue,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form noValidate>
      <Controller
        control={control}
        name="pill"
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Autocomplete
              options={pills.map(({ name }) => name)}
              renderOption={(option) => <BODY1>{option}</BODY1>}
              onChange={(e, newValue) => {
                onChange({
                  ...e,
                  target: { value: newValue },
                });
              }}
              onBlur={(e) => {
                if (!(e.target as HTMLInputElement).value) {
                  onChange({ ...e, target: { value: defaultValue } });
                }
                onBlur();
              }}
              value={value}
              renderInput={(params) => (
                <Input
                  {...params}
                  name="pill"
                  placeholder="Select"
                  ref={params.InputProps.ref}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  error={!!errors.pill}
                  helperText={
                    errors.pill &&
                    'Please, select a contraceptive from the list'
                  }
                />
              )}
            />
          );
        }}
      />
      <HAControlButtons onClick={handleSubmit} />
      <Box
        display="flex"
        justifyContent="center"
        marginTop="20px"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
      >
        {!current && (
          <Button
            fullWidth={isMobile}
            variant="text"
            onClick={() => handleSkip('unknown')}
          >
            Can&apos;t remember
          </Button>
        )}
        <Button
          fullWidth={isMobile}
          variant="text"
          onClick={() => handleSkip('unlisted')}
        >
          Not listed
        </Button>
      </Box>
    </form>
  );
};

export default PillAnswer;
