import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateAccount } from 'modules/Questionnaire/components/GetStarted';
import { getStartedData } from '../../selectors';
import { AccountInfoFormData } from '../../types';
import { createAccountSchema } from '../../constants';
import {
  clinicRegisterToQuestionnaireRequest,
  registerToQuestionnaireRequest,
} from '../../actions';
import {
  getHasAnyErrorMessages,
  getNonFieldErrorMessage,
  getFieldErrorMessages,
} from 'modules/NotificationsHandler/selectors';
import { getSignupPaymentUrl } from 'modules/Account/selectors';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE } from 'utils/analytics/enhancedEcommerce';
import { redirectToPaymentUrl } from 'utils/helpers/auth';

export const CreateAccountContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    privacyOptions,
    accountInfo,
    marketingOptions,
    signupType,
    clinicDetails,
  } = useSelector(getStartedData);
  const nonFieldErrorMessage = useSelector(getNonFieldErrorMessage);
  const fieldErrorMessages = useSelector(getFieldErrorMessages);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);
  const [isLoading, setIsLoading] = useState(false);
  const signupPaymentUrl = useSelector(getSignupPaymentUrl);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (!!signupPaymentUrl && !!signupType && !clinicDetails) {
      // Redirect to Stripe payment page for the normal signup flow
      redirectToPaymentUrl(
        signupPaymentUrl,
        ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE[signupType],
      );
    }
  }, [signupPaymentUrl, signupType, clinicDetails]);

  useEffect(() => {
    if (isAuthenticated && clinicDetails) {
      // Redirect to verify email page for the clinic signup flow
      history.push(Routes.VERIFY_EMAIL);
    }
  }, [history, isAuthenticated, clinicDetails]);

  useEffect(() => {
    if (hasServerErrors) {
      setIsLoading(false);
    }
  }, [hasServerErrors]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<AccountInfoFormData>>({
    resolver: yupResolver(
      createAccountSchema(
        accountInfo?.email,
        accountInfo?.firstName,
        accountInfo?.lastName,
      ),
    ),
    mode: 'all',
  });

  const submit = useCallback(
    ({ rePassword, password }) => {
      if (clinicDetails) {
        dispatch(
          clinicRegisterToQuestionnaireRequest({
            ...accountInfo,
            ...clinicDetails,
            password,
            rePassword,
            privacyOptions,
            marketingOptions,
          }),
        );
      } else {
        dispatch(
          registerToQuestionnaireRequest({
            ...accountInfo,
            password,
            rePassword,
            privacyOptions,
            marketingOptions,
            signupType,
          }),
        );
      }

      setIsLoading(true);
    },
    [
      dispatch,
      accountInfo,
      privacyOptions,
      marketingOptions,
      signupType,
      clinicDetails,
    ],
  );

  return (
    <CreateAccount
      control={control}
      handleSubmit={handleSubmit(submit)}
      errors={errors}
      nonFieldErrorMessage={nonFieldErrorMessage}
      fieldErrorMessages={fieldErrorMessages}
      isLoading={isLoading}
    />
  );
};
