import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useInterval } from 'utils/hooks';
import { LoadingPageProps } from 'shared/LoadingPage';
import {
  LOADING_MESSAGE_1,
  LOADING_MESSAGE_2,
  LOADING_MESSAGE_ERROR,
} from '../constants';
import {
  BUTTON_MESSAGE_ERROR,
  BUTTON_MESSAGE_LOADING,
  BUTTON_MESSAGE_READY,
} from 'shared/LoadingPage/constants';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';

type LoadingProps = {
  isDataLoading: boolean;
  onReadyButtonClickCallback: () => void;
  onRetryButtonClickCallback: () => void;
  isServerErrors?: boolean;
};

const messages = [LOADING_MESSAGE_1, LOADING_MESSAGE_2, LOADING_MESSAGE_ERROR];

export const useResultsLoading = ({
  isDataLoading,
  onReadyButtonClickCallback,
  onRetryButtonClickCallback,
  isServerErrors,
}: LoadingProps): LoadingPageProps => {
  const [interval, setInterval] = useState<number | null>(5000);
  const [currentLoadingMessageIndex, setCurrentLoadingMessageIndex] =
    useState(0);
  const analytics = useAnalytics();

  useEffect(() => {
    if (!!isServerErrors) {
      // error message is of the last index
      setCurrentLoadingMessageIndex(messages.length - 1);
      setInterval(null);
    }
  }, [isServerErrors]);

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT.VIEW_LOADING_SCREEN, {
      category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      loadingScreenStage: currentLoadingMessageIndex + 1,
    });
  }, [currentLoadingMessageIndex, analytics]);

  // Cycle through the messages
  useInterval(() => {
    // messages.length - 2 is the last non-error message index
    if (currentLoadingMessageIndex < messages.length - 2) {
      setCurrentLoadingMessageIndex(currentLoadingMessageIndex + 1);
    } else {
      setInterval(null);
    }
  }, interval);

  const onButtonClick = () => {
    if (!!isServerErrors) {
      onRetryButtonClickCallback();
    } else if (!isLoading) onReadyButtonClickCallback();
  };

  const isLoading =
    !(currentLoadingMessageIndex === messages.length - 2) || isDataLoading;

  const buttonText = !!isServerErrors
    ? BUTTON_MESSAGE_ERROR
    : isLoading
    ? BUTTON_MESSAGE_LOADING
    : BUTTON_MESSAGE_READY;

  return {
    CTAProps: {
      startIcon:
        isLoading && !isServerErrors ? (
          <CircularProgress size={20} color="inherit" />
        ) : null,
      onClick: onButtonClick,
    },
    CTAText: buttonText,
    messages: messages[currentLoadingMessageIndex],
  };
};
