import React from 'react';
import { Box } from '@material-ui/core';
import { BODY2, Button, Divider, H3 } from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { BannerImg } from './styles';
import { Link } from 'shared';

import { InfoModal } from 'shared/InfoModal';

type BPCheckModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};

export const BPCheckModal: React.FC<BPCheckModalProps> = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const [bpCheckRequiredImg] = useWebpImage('bp_check_required.png');

  return (
    <InfoModal isOpen={isOpen} handleClose={handleClose}>
      <Box
        margin="24px 0px"
        display="flex"
        flexDirection="column"
        gridRowGap="24px"
      >
        <BannerImg src={bpCheckRequiredImg} alt="" />
        <H3>One last thing - we need you to check your blood pressure!</H3>
        <BODY2>
          In line with current birth control prescribing guidelines, your blood
          pressure should be measured before starting this method.
        </BODY2>
        <BODY2>
          If you don’t have a blood pressure monitor at home,{' '}
          <b>you can get a reading at your local pharmacy</b> using their
          digital blood pressure measurement machine.
        </BODY2>
        <BODY2>
          Once you know your blood pressure, you can let us know and we will
          update your records. This method will then become available for you to
          order.
        </BODY2>
      </Box>
      <Box marginTop="auto" textAlign="center">
        <Divider $marginBottom={16} />
        <BODY2 $marginBottom={10}>
          Please email us at{' '}
          <Link
            href="mailto:help@tuune.com"
            target="_blank"
            color="textPrimary"
          >
            help@tuune.com
          </Link>{' '}
          to update us on your blood pressure reading.
        </BODY2>

        <Button fullWidth onClick={() => handleSubmit()}>
          update blood pressure
        </Button>
      </Box>
    </InfoModal>
  );
};
