const SHORT_MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getDateShortMonth = (date: Date): string => {
  return SHORT_MONTH_NAMES[date.getMonth()];
};
