import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';

export const CONTENTFUL_HELP_ICON_DESCRIPTION = 'help-icon';

export const ContentKeys = {
  [CTN.CONTENTFUL_HEADER_SECTION]: keyGen.getKey(
    CONTENT_TYPE.CONTENTFUL_HEADER_SECTION,
  ),
  [CTN.ISSUE_ICON]: keyGen.getKey(CONTENT_TYPE.ISSUE_ICON),
};
