export const LOADING_MESSAGE_1 = [
  'Welcome back!',
  `Your personalized Tuune recommendation has`,
  'been reviewed and approved by a clinician.',
];

export const LOADING_MESSAGE_2 = [
  'Your personalized recommendation is',
  'ready for you.',
];

export const LOADING_MESSAGE_ERROR = [
  'Looks like something went wrong.',
  'If the issue persists, email us at help@tuune.com',
];
