import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GoogleLogo: React.FC<SvgIconProps> = () => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1567_17066)">
        <path
          d="M21.7874 12.225C21.7874 11.5667 21.7291 10.9417 21.6291 10.3334H12.2124V14.0917H17.6041C17.3624 15.325 16.6541 16.3667 15.6041 17.075V19.575H18.8207C20.7041 17.8334 21.7874 15.2667 21.7874 12.225Z"
          fill="#4285F4"
        />
        <path
          d="M12.2126 22C14.9126 22 17.1709 21.1 18.8209 19.575L15.6043 17.075C14.7043 17.675 13.5626 18.0417 12.2126 18.0417C9.60427 18.0417 7.39593 16.2833 6.60427 13.9083H3.2876V16.4833C4.92926 19.75 8.30427 22 12.2126 22Z"
          fill="#34A853"
        />
        <path
          d="M6.60407 13.9084C6.39574 13.3084 6.2874 12.6667 6.2874 12.0001C6.2874 11.3334 6.40407 10.6917 6.60407 10.0917V7.51672H3.2874C2.60407 8.86672 2.2124 10.3834 2.2124 12.0001C2.2124 13.6167 2.60407 15.1334 3.2874 16.4834L6.60407 13.9084Z"
          fill="#FBBC05"
        />
        <path
          d="M12.2126 5.95833C13.6876 5.95833 15.0043 6.46667 16.0459 7.45834L18.8959 4.60833C17.1709 2.99167 14.9126 2 12.2126 2C8.30426 2 4.92926 4.25 3.2876 7.51667L6.60427 10.0917C7.39593 7.71667 9.60426 5.95833 12.2126 5.95833Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_1567_17066">
          <rect
            width="19.575"
            height="20"
            fill="white"
            transform="translate(2.2124 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
