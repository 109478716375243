import React from 'react';
import { Box } from '@material-ui/core';
import { BODY1, Divider, H3 } from '@tuunetech/tuune-components';
import { useSelector } from 'react-redux';
import { getClinicInfo } from 'modules/Account/selectors';
import styled from 'styled-components';

const QUESTIONS = [
  `What is the risk of blood clots with each birth control?`,
  `How effective are the different birth control methods?`,
  `If I stop taking this method, how soon will I return to baseline fertility?`,
  `Can I keep my period or skip my period with these birth control methods?`,
  `Is there a medical procedure for this method?`,
  `How often do I have to take this method? Do I need to start it at a certain time of my cycle?`,
  `How can I switch from one method to another?`,
];

const LI = styled(BODY1)`
  display: list-item;
  margin-left: 25px;
`;

export const QuestionsForAppointment: React.FC = () => {
  const clinicInfo = useSelector(getClinicInfo);
  return (
    <Box>
      <H3 $marginBottom={40}>
        Questions to ask during your appointment with {clinicInfo?.shortName}:
      </H3>
      {QUESTIONS.map((q) => (
        <>
          <Divider $verticalMargin={16} />
          <LI>{q}</LI>
        </>
      ))}
    </Box>
  );
};
