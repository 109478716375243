import React, { PropsWithChildren } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

type PageProps = PropsWithChildren<{
  withFixedHeader?: boolean;
  removePadding?: boolean;
}>;

const Box = styled.div<{
  $withFixedHeader: boolean;
  $isDesktop: boolean;
  $removePadding: boolean;
}>`
  padding-bottom: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: ${({ $withFixedHeader }) => ($withFixedHeader ? '64px' : 0)};
  padding-top: ${({ $withFixedHeader, $isDesktop, $removePadding }) =>
    $removePadding ? 0 : $withFixedHeader ? `${$isDesktop ? 48 : 40}px` : 0};
  height: ${({ $withFixedHeader }) =>
    `calc(100% - ${$withFixedHeader ? 64 : 0}px)`};
  width: 100%;
`;

// eslint-disable-next-line react/display-name
export const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ withFixedHeader = true, children, removePadding = false }, ref) => {
    const isDesktop = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up('md'),
    );

    return (
      <Box
        $withFixedHeader={withFixedHeader}
        $isDesktop={isDesktop}
        ref={ref}
        $removePadding={removePadding}
      >
        {children}
      </Box>
    );
  },
);
