import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { LegalName } from 'modules/Questionnaire/components/GetStarted/LegalName';
import { personalInfoSchema } from 'modules/Account/constants/validation';
import { updateAccountData } from 'modules/Account/actions';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';

export const LegalNameContainer: React.FC = () => {
  useReportStatusRouter();

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<AccountInfoFormData>>({
    resolver: yupResolver(personalInfoSchema),
    mode: 'onTouched',
  });

  const submit = useCallback(
    ({ firstName, lastName }) => {
      dispatch(updateAccountData({ firstName, lastName }));
    },
    [dispatch],
  );

  return (
    <LegalName
      control={control}
      handleSubmit={handleSubmit(submit)}
      errors={errors}
    />
  );
};
