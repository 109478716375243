export enum CONTENT_TYPE_ID {
  STATIC_CONTENT = 'staticContent',
  ISSUE_SNIPPET_GENERIC = 'sideEffectconditionSnippet',
  ISSUE_SNIPPET_SPECIFIC = 'issueSnippetSpecific',
  RECOMMENDATION_INTRO = 'recommendationIntro',
  RECOMMENDED_REGIMEN_SUMMARY = 'regimenSummary',
  RECOMMENDED_REGIMEN_DETAILS_GENERIC = 'regimenDetailsGeneric',
  RECOMMENDED_REGIMEN_SAFETY_EFFICACY_GENERIC = 'recommendedRegimenSafetyEfficacyGeneric',
  RECOMMENDED_CONSIDERATIONS_SUMMARY = 'pillGenericConsiderationDetails',
  CONTRACEPTION_DETAIL_PERSONALISED = 'contraceptionDetailPersonalised',
  CONTRACEPTION_TYPE_BENEFIT_DETAIL_GENERIC = 'contraceptionTypeBenefitDetailGeneric',
  CONTRACEPTION_INGREDIENT_DETAILS = 'pillIngredient',
  OC_SUITABILITY = 'cocSuitability',
  OC_SUITABILITY_EXPLANATION = 'cocSuitabilityExplanation',
  CHC_UNSUITABILITY_EXPLANATION = 'chcUnsuitabilityExplanation',
  SWITCHING_CONTRACEPTIVE_METHOD_GENERIC = 'switchingContraceptiveMethodGeneric',
  IMPACT_SCORE = 'impactScore',
  IMPACT_SCORE_EXPLAINED = 'impactScoreExplained',
  ISSUE_ICON = 'issue',
  CONTRACEPTION_ADDITIONAL_INFORMATION = 'contraceptionAdditionalInformation',
  CONTENTFUL_HEADER_SECTION = 'sampleContent',
}
