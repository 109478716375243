import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Routes from 'routes';
import { activateUser, checkAuth } from 'modules/Auth/actions';
import {
  getIsAuthenticated,
  getIsEmailActivated,
} from 'modules/Auth/selectors';
import { CircularProgress } from '@material-ui/core';
import { getHasAnyErrorMessages } from 'modules/NotificationsHandler/selectors';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { getIsActive } from 'modules/Account/actions';
import { displayInfoPopup } from 'modules/NotificationsHandler/actions';

const ACCOUNT_ACTIVATED_INFO_MESSAGE = 'Account Successfully Activated';

export const ActivateAccountPage: React.FC = () => {
  useReportStatusRouter();
  const dispatch = useDispatch();
  const hasErrors = useSelector(getHasAnyErrorMessages);
  const isActivated = useSelector(getIsEmailActivated);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const history = useHistory();
  const { uid, token } = useParams<{ uid: string; token: string }>();

  useEffect(() => {
    if (uid && token) {
      dispatch(activateUser({ uid, token }));
    }
  }, [dispatch, uid, token]);

  useEffect(() => {
    if (isActivated) {
      if (isAuthenticated === undefined) {
        dispatch(checkAuth());
      } else {
        dispatch(displayInfoPopup(ACCOUNT_ACTIVATED_INFO_MESSAGE));
        if (isAuthenticated) {
          // Once authenticated, check if the user account
          // got activated and reportStatusRouter will take care of
          // re-routing
          dispatch(getIsActive());
        } else {
          // Unauthenticated user only activated the account,
          // redirect to login page
          history.replace(Routes.LOGIN);
        }
      }
    } else if (hasErrors) {
      history.push(Routes.RESEND_EMAIL_CUSTOM);
    }
  }, [history, dispatch, isActivated, isAuthenticated, hasErrors]);

  return <CircularProgress color="primary" />;
};
