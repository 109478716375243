import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Injection: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1667 4.00002H9.83333V1.66669H19.1667V4.00002H16.8333V6.33335H21.5V8.66669H19.1667V20.3334C19.1667 22.5078 17.6794 24.335 15.6667 24.853V27.3334H13.3333V24.853C11.3206 24.335 9.83333 22.5078 9.83333 20.3334V8.66669H7.5V6.33335H12.1667V4.00002ZM16.8333 20.3334C16.8333 21.622 15.7887 22.6667 14.5 22.6667C13.2113 22.6667 12.1667 21.622 12.1667 20.3334V8.66669H16.8333V13.3334H14.5V15.6667H16.8333V18H14.5V20.3334H16.8333Z"
    />
  </SvgIcon>
);
