import styled from 'styled-components';
import { CAPTION } from '@tuunetech/tuune-components';

export const SymptomIconSvg = styled.svg`
  width: 16px;
  height: 16px;
`;

export const BenefitCaption = styled(CAPTION)`
  color: ${({ theme }) => theme.palette.common.grey[700]};
`;
