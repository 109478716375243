import React, { useCallback, useEffect, useState } from 'react';
import { AppHeader } from '@tuunetech/tuune-components';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { StudyUnlock } from '../components/StudyUnlock';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import { checkAuth } from 'modules/Auth/actions';
import Routes from 'routes';
import { useHistory } from 'react-router';
import { displayErrorPopup } from 'modules/NotificationsHandler/actions';
import { RESEARCH_AUTO_APPROVE } from 'modules/Checkout/constants';
import { getIsLoading } from 'modules/Loading/selectors';
import { researchAutoApproveRequest } from 'modules/Checkout/actions';
import { getIsResearchAutoApproved } from 'modules/Checkout/selectors';
import { Page } from 'shared';

const LOGIN_REDIRECT_ERROR_MESSAGE = 'Please log in to continue';

const StudyUnlockPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const researchAutoApproveLoading = useSelector(
    getIsLoading(RESEARCH_AUTO_APPROVE),
  );
  const isResearchAutoApproved = useSelector(getIsResearchAutoApproved);

  const headerButtons = useBaseNavButtons();
  const [isCheckingAuth, setIsCheckingAuth] = useState<boolean>(true);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated === false) {
      history.replace(Routes.LOGIN);
      dispatch(displayErrorPopup(LOGIN_REDIRECT_ERROR_MESSAGE));
    } else {
      setIsCheckingAuth(false);
    }
  }, [isAuthenticated, history, dispatch]);

  useEffect(() => {
    if (isResearchAutoApproved) {
      history.replace(Routes.HOME);
    }
  }, [dispatch, isResearchAutoApproved, history]);

  const onUnlockCodeSubmitCallback = useCallback(
    (unlockCode: string) => {
      dispatch(researchAutoApproveRequest({ unlockCode }));
    },
    [dispatch],
  );

  return (
    <Page>
      <AppHeader
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />
      <StudyUnlock
        isLoading={isCheckingAuth || !!researchAutoApproveLoading}
        handleSubmit={onUnlockCodeSubmitCallback}
      />
    </Page>
  );
};

export default StudyUnlockPage;
