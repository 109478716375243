import React from 'react';
import {
  GridContainer,
  GridItem,
  Container,
  BODY1,
  CAPTION,
} from '@tuunetech/tuune-components';
import { StateSelectProps } from '../StateSelect';
import { Box } from '@material-ui/core';
import { NavigationButtonsFooter } from 'shared/NavigationButtonsFooter';
import { TuuneInput } from 'shared';

interface MarketingAndStateLayoutProps extends StateSelectProps {
  handleSubmit: () => void;
}

export const LegalName: React.FC<MarketingAndStateLayoutProps> = ({
  handleSubmit,
  errors,
  control,
}) => {
  return (
    <Container $marginBottom={40}>
      <GridContainer justify="center">
        <GridItem xs={12} md={6}>
          <BODY1 paragraph>What&#39;s your legal name?</BODY1>
          <CAPTION color="textSecondary">
            We need your real, legal name in order to show you any
            recommendations and/or test results.
          </CAPTION>
          <Box marginBottom="40px" marginTop="16px">
            <TuuneInput
              name="firstName"
              placeholder="First name"
              control={control}
              fullWidth
              autoComplete="given-name"
              helperText={errors.firstName?.message}
              error={!!errors.firstName}
            />
            <Box marginTop="16px" />
            <TuuneInput
              name="lastName"
              placeholder="Last name"
              control={control}
              fullWidth
              autoComplete="family-name"
              helperText={errors.lastName?.message}
              error={!!errors.lastName}
            />
          </Box>
          <NavigationButtonsFooter handleContinue={handleSubmit} />
        </GridItem>
      </GridContainer>
    </Container>
  );
};
