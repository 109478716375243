import React from 'react';
import {
  AppHeader,
  Container,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { Link } from '@material-ui/core';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { Page } from 'shared';

const ReplacePreviewPage: React.FC = () => {
  const { logout, account, contactUs } = useBaseNavButtons();
  return (
    <Page>
      <AppHeader buttons={[contactUs, account, logout]} />
      <Container>
        <GridContainer justify="center">
          <GridItem xs={12} md={10}>
            <H3 align="center">
              Your recommendation report is no longer visible.
            </H3>
            <H3 align="center" $marginTop={30}>
              Please get in touch with us at{' '}
              <Link
                href="mailto:help@tuune.com"
                target="_blank"
                underline="none"
              >
                help@tuune.com
              </Link>{' '}
              and we’ll unlock your full report for free.
            </H3>
          </GridItem>
        </GridContainer>
      </Container>
    </Page>
  );
};

export default ReplacePreviewPage;
