import React from 'react';
import {
  OAuthProvider,
  OAUTH_CONFIGS,
} from 'modules/Auth/constants/socialAuth';
import { SocialAuthType } from 'modules/Auth/types/socialAuth';
import { SocialAuthContainer } from './styles';
import { Button } from '@tuunetech/tuune-components';

interface SocialAuthProps {
  authType: SocialAuthType;
  onAuthButtonClick: (oAuthProvider: OAuthProvider) => void;
}

export const SocialAuth: React.FC<SocialAuthProps> = ({
  authType,
  onAuthButtonClick,
}) => {
  const getAuthButtonText = (providerName: string) => {
    if (authType === SocialAuthType.SignIn) {
      return `sign in with ${providerName}`;
    } else if (authType === SocialAuthType.SignUp) {
      return `sign up with ${providerName}`;
    }
  };

  return (
    <SocialAuthContainer>
      {Object.entries(OAUTH_CONFIGS).map(([providerName, OAuthConfig]) => (
        <Button
          key={providerName}
          startIcon={<OAuthConfig.authProviderLogo />}
          variant="outlined"
          fullWidth
          onClick={() =>
            onAuthButtonClick(
              OAuthProvider[providerName as keyof typeof OAuthProvider],
            )
          }
        >
          {getAuthButtonText(providerName)}
        </Button>
      ))}
    </SocialAuthContainer>
  );
};
