export enum MARKETING {
  marketingPreference = 'accept_marketing_preference',
  researchPreference = 'accept_research_preference',
}

export const MARKETING_OPTIONS_LABEL = [
  {
    id: MARKETING.marketingPreference,
    label: 'I want to receive the latest news and updates from Tuune.',
  },
  {
    id: MARKETING.researchPreference,
    label:
      'I authorize Tuune to contact me for surveys, interviews or other research purposes.',
  },
];
