import { REQUEST, SUCCESS } from 'utils/requestStates';

export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA';
export const GET_ACCOUNT_DATA_REQUEST =
  `${GET_ACCOUNT_DATA}_${REQUEST}` as const;
export const GET_ACCOUNT_DATA_SUCCESS =
  `${GET_ACCOUNT_DATA}_${SUCCESS}` as const;

const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';
export const UPDATE_ACCOUNT_DATA_REQUEST = `${UPDATE_ACCOUNT_DATA}_${REQUEST}`;
export const UPDATE_ACCOUNT_DATA_SUCCESS = `${UPDATE_ACCOUNT_DATA}_${SUCCESS}`;

const UPDATE_MARKETING_PREFERENCE = 'UPDATE_MARKETING_PREFERENCE';
export const UPDATE_MARKETING_PREFERENCE_REQUEST = `${UPDATE_MARKETING_PREFERENCE}_${REQUEST}`;
export const UPDATE_MARKETING_PREFERENCE_SUCCESS = `${UPDATE_MARKETING_PREFERENCE}_${SUCCESS}`;

const UPDATE_PRIVACY_OPTIONS = 'UPDATE_PRIVACY_OPTIONS';
export const UPDATE_PRIVACY_OPTIONS_REQUEST =
  `${UPDATE_PRIVACY_OPTIONS}_${REQUEST}` as const;
export const UPDATE_PRIVACY_OPTIONS_SUCCESS =
  `${UPDATE_PRIVACY_OPTIONS}_${SUCCESS}` as const;

export const GET_IS_ACTIVE_REQUEST = 'GET_IS_ACTIVE_REQUEST';
export const GET_IS_ACTIVE_SUCCESS = 'GET_IS_ACTIVE_SUCCESS';
export const SET_IS_ACTIVE = 'SET_IS_ACTIVE';

export const REGISTER_HORMONE_TEST_ADDRESS = 'REGISTER_HORMONE_TEST_ADDRESS';
export const REGISTER_HORMONE_TEST_ADDRESS_REQUEST =
  `${REGISTER_HORMONE_TEST_ADDRESS}_${REQUEST}` as const;
export const REGISTER_HORMONE_TEST_ADDRESS_SUCCESS =
  `${REGISTER_HORMONE_TEST_ADDRESS}_${SUCCESS}` as const;

export const GET_DELETION_REASONS = 'GET_DELETION_REASONS';
export const GET_DELETION_REASONS_REQUEST =
  `${GET_DELETION_REASONS}_${REQUEST}` as const;
export const GET_DELETION_REASONS_SUCCESS =
  `${GET_DELETION_REASONS}_${SUCCESS}` as const;

export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const DEACTIVATE_USER_REQUEST = `${DEACTIVATE_USER}_${REQUEST}` as const;
