import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Box, Checkbox, Link } from '@material-ui/core';
import { BODY1, CAPTION, ContentBlock } from '@tuunetech/tuune-components';
import { MARKETING_OPTIONS_LABEL } from 'modules/Questionnaire/constants';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { getAppPrivacyPolicyUrl, getAppTermsUrl } from 'utils/cookies/helpers';

export interface MarketingOptionsProps {
  control: Control<Partial<AccountInfoFormData>>;
}

export const MarketingOptions: React.FC<MarketingOptionsProps> = ({
  control,
}) => {
  return (
    <>
      <Box display="flex" flexDirection="column" gridRowGap="16px">
        {MARKETING_OPTIONS_LABEL.map(({ id, label }) => (
          <ContentBlock
            key={id}
            startAdornment={
              <Controller
                name={id}
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Checkbox
                    id={id}
                    color="primary"
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                  />
                )}
              />
            }
            content={
              <BODY1 component="label" htmlFor={id} color="textSecondary">
                {label}
              </BODY1>
            }
          />
        ))}
      </Box>
      <CAPTION $marginTop={16} color="textSecondary">
        By entering your phone number and submitting this form, you consent to
        receive marketing text messages (such as promotion codes and cart
        reminders) from Tuune at the number provided, including messages sent by
        autodialer. Consent is not a condition of any purchase. Message and data
        rates may apply. Message frequency varies. You can unsubscribe at any
        time by replying STOP or clicking the unsubscribe link (where available)
        in one of our messages. View our{' '}
        <Link
          href={getAppPrivacyPolicyUrl()}
          variant="caption"
          color="textSecondary"
          target="_blank"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any): void => e.stopPropagation()}
        >
          Privacy policy
        </Link>{' '}
        and{' '}
        <Link
          href={getAppTermsUrl()}
          variant="caption"
          color="textSecondary"
          target="_blank"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any): void => e.stopPropagation()}
        >
          Terms of Service
        </Link>
        .
      </CAPTION>
    </>
  );
};
