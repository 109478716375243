import React, { useContext } from 'react';
import {
  GridContainer,
  GridItem,
  Container,
  BODY1,
} from '@tuunetech/tuune-components';
import { StateSelect, StateSelectProps } from '../StateSelect';
import { MarketingOptions } from '../MarketingOptions';
import { Box } from '@material-ui/core';
import { PhoneInput } from 'shared/PhoneInput';
import { getDefaultCountry } from 'utils/helpers';
import { NavigationButtonsFooter } from 'shared/NavigationButtonsFooter';
import { HAGoBackContext } from 'modules/Questionnaire/constants';

interface MarketingAndStateLayoutProps extends StateSelectProps {
  handleSubmit: () => void;
}

export const MarketingAndStateLayout: React.FC<
  MarketingAndStateLayoutProps
> = ({ handleSubmit, errors, control, onStateSelected }) => {
  const goBack = useContext(HAGoBackContext);

  return (
    <Container $marginBottom={40}>
      <GridContainer justify="center">
        <GridItem xs={12} md={6}>
          <BODY1 paragraph>Which state do you live in?</BODY1>
          <Box marginBottom="40px">
            <StateSelect
              control={control}
              errors={errors}
              onStateSelected={onStateSelected}
            />
          </Box>
          <BODY1 paragraph>What&#39;s your phone number?</BODY1>
          <Box marginBottom="40px">
            <PhoneInput
              control={control}
              helperText={errors.phone?.message}
              error={!!errors.phone}
              countryObj={getDefaultCountry()}
              fullWidth
            />
          </Box>
          <MarketingOptions control={control} />
          <NavigationButtonsFooter
            handleContinue={handleSubmit}
            handleBack={goBack}
            showBack
          />
        </GridItem>
      </GridContainer>
    </Container>
  );
};
