import { REQUEST, SUCCESS } from 'utils/requestStates';

export const RECAP_GOALS_CLOSED = 'RECAP_GOALS_CLOSED';
export const SET_RECAP_GOALS_CLOSED = 'SET_RECAP_GOALS_CLOSED';

export const GET_RECOMMENDATION_RESULTS = 'GET_RECOMMENDATION_RESULTS';
export const GET_RECOMMENDATION_RESULTS_REQUEST =
  `${GET_RECOMMENDATION_RESULTS}_${REQUEST}` as const;
export const GET_RECOMMENDATION_RESULTS_SUCCESS =
  `${GET_RECOMMENDATION_RESULTS}_${SUCCESS}` as const;

export const SET_PILL_INFO_CURRENT = 'SET_PILL_INFO_CURRENT';
export const SET_SYMPTOM_CURRENT = 'SET_SYMPTOM_CURRENT';
export const SET_BENEFITS_CURRENT = 'SET_BENEFITS_CURRENT';
export const SET_REGIMEN_CURRENT = 'SET_REGIMEN_CURRENT';
