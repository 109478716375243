import styled from 'styled-components';
import { Button, Container } from '@tuunetech/tuune-components';

export const StartContainer = styled(Container)`
  img {
    width: 100%;
    border-radius: 40px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${Button} {
      width: 100%;
    }
  }
`;
