import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getFirstName } from 'modules/Account/selectors';
import Intro from '../components/Intro';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';

export const IntroContainer: React.FC = ({}) => {
  const firstName = useSelector(getFirstName);
  const analytics = useAnalytics();

  const onMoreAboutDoctorClick = useCallback(() => {
    analytics.track(
      ANALYTICS_EVENT.RECOMMENDATION_REPORT_CLICK_MORE_ABOUT_DOCTOR,
      {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      },
    );
  }, [analytics]);

  return (
    <Intro name={firstName} onMoreAboutDoctorClick={onMoreAboutDoctorClick} />
  );
};
