import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getHasAnyErrorMessages,
  getNonFieldErrorMessage,
} from 'modules/NotificationsHandler/selectors';
import { OnlinePharmacyFormData, PhysicalPharmacyFormData } from '../types';
import { onlinePharmacySchema, physicalPharmacySchema } from '../constants';
import { Pharmacy } from '../components/Pharmacy';
import { submitPrescriptionOrder } from '../actions';
import { getPrescriptionOrderUuid } from '../selectors';
import Routes from 'routes';

const phoneValidationNotValidSymbolsRegexp = RegExp(/[^\d\-)(+\s]+/g);

export const PharmacyContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const prescriptionOrderUuid = useSelector(getPrescriptionOrderUuid);
  const [isLoading, setIsLoading] = useState(false);

  const { ampId } = useParams<{ ampId: string }>();
  const nonFieldErrorMessage = useSelector(getNonFieldErrorMessage);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);

  useEffect(() => {
    if (prescriptionOrderUuid) {
      history.replace(Routes.PRESCRIPTION_CONFIRMATION);
    }
  }, [history, prescriptionOrderUuid]);

  useEffect(() => {
    if (hasServerErrors) {
      setIsLoading(false);
    }
  }, [hasServerErrors]);

  // Online Pharmacy
  const {
    control: onlinePControl,
    handleSubmit: onlinePHandleSubmit,
    formState: { errors: onlinePErrors, isValid: onlinePIsValid },
    watch: OPwatch,
  } = useForm<OnlinePharmacyFormData>({
    resolver: yupResolver(onlinePharmacySchema),
    mode: 'onChange',
  });

  // Physical Pharmacy
  const {
    control: physicalPControl,
    handleSubmit: physicalPHandleSubmit,
    formState: { errors: physicalPErrors, isValid: physicalPIsValid },
    watch: PPwatch,
    setValue: PPsetValue,
  } = useForm<PhysicalPharmacyFormData>({
    resolver: yupResolver(physicalPharmacySchema),
    mode: 'onChange',
  });

  const pharmacyType = OPwatch('type');
  const pharmacyName = OPwatch('name');
  const pharmacyPhone = PPwatch('pharmacyPhone');

  useEffect(() => {
    // do not allow user to put random symbols in input field
    const invalidSymbols = pharmacyPhone?.match(
      phoneValidationNotValidSymbolsRegexp,
    );
    if (invalidSymbols !== null) {
      PPsetValue(
        'pharmacyPhone',
        pharmacyPhone?.replace((invalidSymbols || []).join(''), ''),
      );
    }
  }, [pharmacyPhone, PPsetValue]);

  const onlinePSubmit = useCallback(
    ({ name }) => {
      dispatch(
        submitPrescriptionOrder({
          ampId,
          name,
        }),
      );
      setIsLoading(true);
    },
    [ampId, dispatch],
  );

  const physicalPSubmit = useCallback(
    ({ addressLine1, city, state, zipCode, pharmacyPhone }) => {
      dispatch(
        submitPrescriptionOrder({
          ampId,
          name: pharmacyName,
          addressLine1,
          city,
          state,
          zipCode,
          pharmacyPhone,
        }),
      );
      setIsLoading(true);
    },
    [dispatch, ampId, pharmacyName],
  );

  return (
    <Pharmacy
      pharmacyType={pharmacyType}
      onlinePharmacyForm={{
        control: onlinePControl,
        handleSubmit: onlinePHandleSubmit(onlinePSubmit),
        errors: onlinePErrors,
        isValid: onlinePIsValid,
      }}
      physicalPharmacyForm={{
        control: physicalPControl,
        handleSubmit: physicalPHandleSubmit(physicalPSubmit),
        errors: physicalPErrors,
        isValid: physicalPIsValid,
      }}
      nonFieldErrorMessage={nonFieldErrorMessage}
      isLoading={isLoading}
    />
  );
};
