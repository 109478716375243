import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BODY1 } from '@tuunetech/tuune-components';
import { Box, CircularProgress } from '@material-ui/core';
import { getHasAnyErrorMessages } from 'modules/NotificationsHandler/selectors';
import {
  getAnswerComponentType,
  getAnswers,
  getPreviousAnswers,
  getQuestionId,
  getQuestionTitle,
  getCurrent,
  getMeasureSystem,
  getNotaAnswer,
  getIsQuestionLoading,
  getChoiceQuestionType,
  getFactsSlugs,
} from '../selectors';
import {
  getCurrentQuestionRequest,
  getMeasureSystemFromStorage,
  submitQuestionRequest,
} from '../actions';
import { getAnswerComponent } from '../helpers';
import { MEASURE_SYSTEMS } from '../constants';

const AnswerContainer: React.FC = () => {
  const dispatch = useDispatch();

  const answerType = useSelector(getAnswerComponentType);
  const questionId = useSelector(getQuestionId);
  const questionTitle = useSelector(getQuestionTitle);
  const current = useSelector(getCurrent);
  const facts = useSelector(getAnswers);
  const factsSlugs = useSelector(getFactsSlugs);
  const questionType = useSelector(getChoiceQuestionType);
  const previousAnswers = useSelector(getPreviousAnswers);
  const measureSystem = useSelector(getMeasureSystem);
  const notaAnswer = useSelector(getNotaAnswer);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);
  const isQuestionLoading = useSelector(getIsQuestionLoading);

  const AnswerComponent = useMemo(
    () => getAnswerComponent(answerType),
    [answerType],
  );

  const handleSubmitAnswer = useCallback(
    (answers) => {
      if (!isQuestionLoading) {
        dispatch(
          submitQuestionRequest({
            currentId: current,
            questionId,
            questionTitle,
            answers,
            facts,
            factsSlugs,
            questionType,
          }),
        );
      }
    },
    [
      dispatch,
      questionId,
      questionTitle,
      current,
      isQuestionLoading,
      facts,
      factsSlugs,
      questionType,
    ],
  );

  const handleRequestNext = useCallback(() => {
    if (!isQuestionLoading) {
      dispatch(getCurrentQuestionRequest((current as number) + 1));
    }
  }, [dispatch, current, isQuestionLoading]);

  useEffect(() => {
    if (!measureSystem) {
      const defaultSystem = MEASURE_SYSTEMS.IMPERIAL;
      dispatch(getMeasureSystemFromStorage(defaultSystem));
    }
  }, [dispatch, measureSystem]);

  return isQuestionLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100px"
    >
      <CircularProgress color="primary" />
    </Box>
  ) : AnswerComponent ? (
    <AnswerComponent
      answers={facts}
      notaAnswer={notaAnswer}
      previousAnswers={previousAnswers}
      handleSubmitAnswer={handleSubmitAnswer}
      handleRequestNext={handleRequestNext}
      answerType={answerType as string}
      key={questionId}
    />
  ) : hasServerErrors ? (
    <BODY1>Sorry, no data</BODY1>
  ) : null;
};

export default AnswerContainer;
