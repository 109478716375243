import { ContraceptiveEligibilityStatus } from '@tuunetech/tuune-components';

export const lowercaseFirstLetter = (text: string): string => {
  return text.charAt(0).toLowerCase() + text.slice(1);
};

export const getEligibilityCaption = (
  status: string,
  type: string,
  reasons: string[],
): string | undefined => {
  const formatedReasons = reasons
    .map((reason) => lowercaseFirstLetter(reason))
    .join(', ');
  const formattedType = type ? lowercaseFirstLetter(type) : 'method';
  switch (status) {
    case ContraceptiveEligibilityStatus.Best:
      return `The ${formattedType} may be a good option. According to the CDC's Medical Eligibility Criteria for Contraceptive Use, it may be safe for people like you to use the ${formattedType}.`;
    case ContraceptiveEligibilityStatus.Unrecommended:
      return `According to the CDC's Medical Eligibility Criteria for Contraceptive Use, the ${formattedType} may not be a good option for you because ${formatedReasons}. You should discuss this with your clinician (if you have not done so already).`;
    case ContraceptiveEligibilityStatus.RequiresSpecialist:
      return `The ${formattedType} may be a good option. However, according to the CDC's Medical Eligibility Criteria for Contraceptive Use, careful clinical judgment is required first to see if you can use the ${formattedType} because ${formatedReasons}. Our OBGYNs are available to help you with this.`;
    case ContraceptiveEligibilityStatus.RequiresBPCheck:
      return `The ${formattedType} may be a good option. However, in line with current contraception prescribing guidelines, your blood pressure should be measured before starting the ${formattedType}.`;
    case ContraceptiveEligibilityStatus.RequiresBPCheckAndSpecialist:
      return `The ${formattedType} may be a good option. However, in line with the CDC's Medical Eligibility Criteria for Contraceptive Use, your blood pressure should be measured before starting and careful clinical judgment is also required to see if you can use the ${type}, because ${formatedReasons}. Our OBGYNs are available to help you with this.`;
    default:
      return undefined;
  }
};
