import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { getPrivacyOptions } from 'modules/Account/selectors';
import { BODY1 } from '@tuunetech/tuune-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Privacy } from 'modules/Questionnaire/components/GetStarted';
import { PRIVACY } from 'modules/Questionnaire/constants/privacy';
import AssignedAtBirth from 'modules/Questionnaire/components/AssignedAtBirth';
import { Link } from 'shared';
import { PrivacyFormData } from '../../types';
import { privacySchema } from '../../constants';
import { getAppPrivacyPolicyUrl, getAppTermsUrl } from 'utils/cookies/helpers';
import { updatePrivacyOptions } from 'modules/Account/actions';
import { useHistory } from 'react-router';
import Routes from 'routes';

const getOptions = () => [
  {
    id: PRIVACY.acceptAssignedAtBirth,
    label: <AssignedAtBirth />,
  },
  {
    id: PRIVACY.acceptAnswersTruthful,
    label:
      'I confirm that my answers are truthful to the best of my knowledge.',
  },
  {
    id: PRIVACY.acceptTerms,
    label: (
      <BODY1 align="left" color="textSecondary">
        I accept {"Tuune's"}{' '}
        <Link
          href={getAppTermsUrl()}
          display="inline"
          variant="body1"
          color="textSecondary"
          target="_blank"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any): void => e.stopPropagation()}
        >
          Terms and Conditions
        </Link>
        .
      </BODY1>
    ),
  },
  {
    id: PRIVACY.acceptDataUsage,
    label: (
      <BODY1 align="left" color="textSecondary">
        I give consent for Tuune to use my data as stated above and according to
        their{' '}
        <Link
          href={getAppPrivacyPolicyUrl()}
          display="inline"
          variant="body1"
          color="textSecondary"
          target="_blank"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(e: any): void => e.stopPropagation()}
        >
          Privacy policy
        </Link>
        , including sharing my personal health information{' '}
        <Link
          variant="body1"
          target="_blank"
          href="https://www.tuune.com/informed-consent"
        >
          with a clinician for review
        </Link>{' '}
        and with{' '}
        <Link
          variant="body1"
          target="_blank"
          href="https://www.tuune.com/hipaa-authorization-to-use-and-disclose-phi"
        >
          affiliated providers and labs
        </Link>
        .
      </BODY1>
    ),
  },
];

export const PrivacyContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = useAnalytics();

  const privacyOptions = useSelector(getPrivacyOptions);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    analytics.track(ANALYTICS_EVENT.PAGE_VIEW, {
      category: ANALYTICS_CATEGORY.GET_STARTED,
      location: `${location.pathname}${location.hash}`,
      getStartedStep: 'privacy',
    });
  }, [analytics]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PrivacyFormData>({
    resolver: yupResolver(privacySchema),
    defaultValues: {
      [PRIVACY.acceptTerms]: false,
      [PRIVACY.acceptDataUsage]: false,
      [PRIVACY.acceptAssignedAtBirth]: false,
      [PRIVACY.acceptAnswersTruthful]: false,
    },
  });

  useEffect(() => {
    if (isFormSubmitted && privacyOptions.size) {
      history.replace(Routes.QUESTIONNAIRE);
    }
  }, [privacyOptions, isFormSubmitted, history]);

  const onSubmit = useCallback(
    (data) => {
      const activeOptions = Object.keys(data).filter(
        (key) => data[key] === true,
      );
      dispatch(updatePrivacyOptions(activeOptions));
      analytics.track(ANALYTICS_EVENT.PRIVACY_POLICY_SUBMIT, {
        category: ANALYTICS_CATEGORY.GET_STARTED,
      });

      setIsFormSubmitted(true);
    },
    [dispatch, setIsFormSubmitted, analytics],
  );

  return (
    <Privacy
      options={getOptions()}
      handleSubmit={handleSubmit(onSubmit)}
      error={!!Object.keys(errors).length}
      control={control}
      isSubmitDisabled={isFormSubmitted}
    />
  );
};

export default PrivacyContainer;
