import styled from 'styled-components';
import { LearnMore as BaseLearnMore } from '@tuunetech/tuune-components';

export const LearnMore = styled(BaseLearnMore)`
  margin-top: 24px;

  & + & {
    margin-top: 16px;
  }
`;
