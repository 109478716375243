import React from 'react';
import { Page } from 'shared/Page';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { AppHeader, BODY2, Container, H1 } from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';

export const DeleteConfirm: React.FC = () => {
  const { contactUs } = useBaseNavButtons();

  return (
    <Page>
      <AppHeader buttons={[contactUs]} />
      <Container>
        <Box width="630px" maxWidth="100%" marginX="auto">
          <H1 $marginBottom={24} $marginTop={80} align="center">
            Your account has been deleted.
          </H1>
          <BODY2 align="center">
            Remember you can reactivate your account within the first 30 days of
            deletion if you change your mind.
          </BODY2>
        </Box>
      </Container>
    </Page>
  );
};
