import React from 'react';
import {
  Container,
  GridContainer,
  GridItem,
  AppHeader,
} from '@tuunetech/tuune-components';
import { RegulationContainer } from '../../containers';
import { Page } from 'shared';

export const RegulationLayout: React.FC = () => {
  return (
    <Page>
      <AppHeader pageTitle="Regulation" />
      <Container>
        <GridContainer justify="center" direction="column">
          <GridItem xs={12}>
            <RegulationContainer />
          </GridItem>
        </GridContainer>
      </Container>
    </Page>
  );
};
