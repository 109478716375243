import { HasAssurance } from '../../components/HAFeedbackModule/HasAssurance';
import { HealthcareServices } from '../../components/HAFeedbackModule/HealthcareServices';
import { PrimarySocialMedia } from '../../components/HAFeedbackModule/PrimarySocialMedia';
import { Subscriptions } from '../../components/HAFeedbackModule/Subscriptions';
import { FeedbackDataType } from './state';

export enum QuestionsDataKeys {
  userHasInsurance = 'userHasInsurance',
  healthcareServices = 'healthcareServices',
  socialMediaChannel = 'socialMediaChannel',
  subscriptions = 'subscriptions',
}

export type QuestionProps = {
  handleUpdateData: (data: FeedbackDataType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentData: any;
};

export const feedbackQuestions = [
  {
    key: QuestionsDataKeys.userHasInsurance,
    title: 'Do you have health insurance?',
    component: HasAssurance,
  },
  {
    key: QuestionsDataKeys.healthcareServices,
    title:
      'Do you currently have any of these healthcare services/memberships?',
    component: HealthcareServices,
  },
  {
    key: QuestionsDataKeys.socialMediaChannel,
    title: 'Which social media channel do you spend the most time on?',
    component: PrimarySocialMedia,
  },
  {
    key: QuestionsDataKeys.subscriptions,
    title:
      'Do you currently pay for any of the following subscriptions? (select all that apply)',
    component: Subscriptions,
  },
];

export const lastQuestionNum = feedbackQuestions.length - 1;
