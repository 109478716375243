import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { InputProps } from '@tuunetech/tuune-components';
import { CountryObj } from 'utils/helpers';
import { TuuneInput } from 'shared';
import { PhoneNumberFormat } from 'shared/PhoneNumberFormat';

export type PhoneInputProps<T extends FieldValues> = {
  control: Control<T>;
  countryObj: CountryObj;
} & InputProps;

export const PhoneInput = <T extends FieldValues>(
  props: PhoneInputProps<T>,
) => {
  const { control, countryObj, ...otherProps } = props;

  return (
    <TuuneInput
      name="phone"
      control={control}
      autoComplete="tel"
      placeholder="Phone number"
      {...otherProps}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: PhoneNumberFormat as any,
        inputProps: {
          countryObj,
        },
      }}
    />
  );
};
