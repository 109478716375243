const FACT_SLUG_PILL = 'contraception_current_pill';
const FACT_SLUG_PATCH = 'contraception_current_patch';
const FACT_SLUG_VAGINAL_RING = 'contraception_current_vaginal_ring';
const FACT_SLUG_INJECTION = 'contraception_current_injection';
const FACT_SLUG_IMPLANT = 'contraception_current_implant';
const FACT_SLUG_COPPER_IUD = 'contraception_current_copper_iud';
const FACT_SLUG_HORMONAL_IUD = 'contraception_current_hormonal_iud';

export const currentContraceptionFactSlugs = [
  FACT_SLUG_PILL,
  FACT_SLUG_PATCH,
  FACT_SLUG_VAGINAL_RING,
  FACT_SLUG_INJECTION,
  FACT_SLUG_IMPLANT,
  FACT_SLUG_COPPER_IUD,
  FACT_SLUG_HORMONAL_IUD,
];
