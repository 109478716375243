import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import useWaitForGlobal from 'utils/hooks/useWaitForGlobal';

const PageViewTracker: React.FC = () => {
  const location = useLocation();
  const analytics = useAnalytics();
  const analyticsInitialized = useWaitForGlobal('analyticsInitialized');

  useEffect(() => {
    if (analyticsInitialized) {
      analytics.track(ANALYTICS_EVENT.PAGE_VIEW, {
        category: ANALYTICS_CATEGORY.VIEWS,
        location: `${location.pathname}${location.hash}`,
      });
    }
  }, [location, analytics, analyticsInitialized]);

  return <></>;
};

export default PageViewTracker;
