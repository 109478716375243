import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { BODY1, BODY2 } from '@tuunetech/tuune-components';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';
import { LearnMore } from './styles';

const REC_INFO_TEXT = [
  <>
    We reviewed 1000+ scientific papers, assessing 250+ birth control options
    available on the market, analyzing the impact they have on different
    symptoms
  </>,
  <>
    You filled out our health assessment, telling us about your symptoms, goals
    and medical history
  </>,
  <>
    Our algorithm found the best birth control option (and alternatives) for
    you, analyzing the impacts that every birth control option has on your
    specific symptoms
  </>,
  <>
    A clinician reviewed and approved the recommendations generated by our
    algorithm
  </>,
  <>
    We summarized the literature on how your birth control recommendations
    impact your symptoms, with references to peer-reviewed papers, so you can
    make an informed decision
  </>,
  <>
    At the end of your report you can find more detailed information about your
    symptoms, for you to understand your body better
  </>,
];

type HowRecommendationsMadeToggleProps = {
  isOpen: boolean;
  onToggle: () => void;
};
export const HowRecommendationsMadeToggle: React.FC<
  HowRecommendationsMadeToggleProps
> = ({ isOpen, onToggle }) => {
  const analytics = useAnalytics();

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      analytics.track(
        ANALYTICS_EVENT.RECOMMENDATION_REPORT_CLICK_HOW_CHOOSE_RECOMMENDATIONS,
        {
          category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        },
      );
    }
    onToggle();
  }, [isOpen, onToggle, analytics]);

  return (
    <LearnMore
      isOpen={isOpen}
      onToggle={handleToggle}
      justify="space-between"
      chevronColor="primary"
      linkText={
        <BODY1 color="primary">How we chose your recommendations</BODY1>
      }
    >
      {REC_INFO_TEXT.map((text, id) => (
        <Box key={id} display="flex" marginBottom="16px">
          <BODY2 $horizontalMargin={5}>{id + 1}.</BODY2>
          <BODY2>{text}</BODY2>
        </Box>
      ))}
    </LearnMore>
  );
};
