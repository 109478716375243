import React, { useCallback } from 'react';
import type { QuestionProps } from '../../containers/HAFeedbackModule/questions';
import { Box, Checkbox } from '@material-ui/core';
import { CAPTION, ContentBlock } from '@tuunetech/tuune-components';
import styled from 'styled-components';
import { QuestionsDataKeys } from '../../containers/HAFeedbackModule/questions';

const SUBSCRIPTIONS_LEFT = [
  'At-home exercise equipment with video subscription',
  'Fitness or diet / nutrition apps',
  'Video streaming services',
  'Meal kits',
  'Digital news app',
];

const SUBSCRIPTIONS_RIGHT = [
  'Period/cycle tracking app',
  'Music streaming service',
  'Monthly beauty or skincare products',
  'Vitamins or supplements',
  'Mental health or meditation app',
  'None',
];

const CheckboxButton = styled(Checkbox)`
  color: #fff;
  .MuiSvgIcon-root {
    width: 18px;
  }
`;

export const Subscriptions: React.FC<QuestionProps> = ({
  currentData,
  handleUpdateData,
}) => {
  const handleSelected = useCallback(
    (e) => {
      const subName = e.target.name;
      const updatedSubs = new Set(currentData as Set<string> | undefined);
      if (updatedSubs.has(subName)) {
        updatedSubs.delete(subName);
      } else {
        updatedSubs.add(subName);
      }
      handleUpdateData({
        [QuestionsDataKeys.subscriptions]: updatedSubs,
      });
    },
    [currentData, handleUpdateData],
  );

  const renderColumn = (subscription: string) => {
    return (
      <ContentBlock
        key={subscription}
        startAdornment={
          <CheckboxButton
            color="default"
            name={subscription}
            id={subscription}
            onChange={handleSelected}
            checked={currentData ? currentData.has(subscription) : false}
          />
        }
        content={
          <CAPTION
            component="label"
            align="left"
            htmlFor={subscription}
            $marginLeft={-8}
            $marginTop={4}
          >
            {subscription}
          </CAPTION>
        }
      />
    );
  };

  return (
    <Box display="flex" gridColumnGap="8px">
      <Box width="48%" display="flex" flexDirection="column" gridRowGap="8px">
        {SUBSCRIPTIONS_LEFT.map(renderColumn)}
      </Box>
      <Box width="48%" display="flex" flexDirection="column" gridRowGap="8px">
        {SUBSCRIPTIONS_RIGHT.map(renderColumn)}
      </Box>
    </Box>
  );
};
