import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes';
import {
  getIsActive,
  getReportStageStatus,
  getSignupPaymentUrl,
  getSignupType,
} from 'modules/Account/selectors';
import { reportStageStatus } from 'modules/Account/constants/status';
import { getAccountDataRequest } from 'modules/Account/actions';
import { ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE } from 'utils/analytics/enhancedEcommerce';
import { redirectToPaymentUrl } from 'utils/helpers/auth';

export const useReportStatusRouter = (): void => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const reportStatus = useSelector(getReportStageStatus);
  const isActive = useSelector(getIsActive);
  const signupPaymentUrl = useSelector(getSignupPaymentUrl);
  const signupType = useSelector(getSignupType);

  const isValidRoute = useCallback(() => {
    const routeList = [
      Routes.QUESTIONNAIRE,
      Routes.DEALBREAKER,
      Routes.FEEDBACK,
      Routes.RESULTS,
      Routes.REVIEW_RESULTS,
      Routes.HOME,
      Routes.VERIFY_EMAIL,
      Routes.ACTIVATE,
      Routes.CLINICS_GET_STARTED,
    ];

    return routeList.some((route) => history.location.pathname.includes(route));
  }, [history]);

  useEffect(() => {
    let newUrl = null;

    if (reportStatus && isValidRoute()) {
      switch (reportStatus) {
        case reportStageStatus.INCOMPLETE_HORMONE_TEST_ORDER:
          newUrl = Routes.HT_ADDRESS;
          break;
        case reportStageStatus.HA_NOT_COMPLETED:
          newUrl = Routes.QUESTIONNAIRE;
          break;
        case reportStageStatus.HA_COMPLETED_PENDING_ISSUES_RANKING:
          newUrl = Routes.DEALBREAKER;
          break;
        case reportStageStatus.HA_COMPLETED_FEEDBACK_PENDING:
          newUrl = Routes.FEEDBACK;
          break;
        case reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_PENDING_APPROVAL:
        case reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_IN_REVIEW:
          newUrl = Routes.REVIEW_RESULTS;
          break;
        case reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_APPROVED:
        case reportStageStatus.PRESCRIPTION_PENDING_PAYMENT:
        case reportStageStatus.PRESCRIPTION_ORDERED:
        case reportStageStatus.PRESCRIPTION_SUBMITTED:
        case reportStageStatus.PRESCRIPTION_DISPATCHED:
          newUrl = Routes.RESULTS;
          break;
        case reportStageStatus.HA_COMPLETED_REPORT_UNLOCKED_REJECTED:
          newUrl = Routes.RESULTS_CLINICAL_REVIEW;
          break;
        case reportStageStatus.TERMS_AND_CONDITIONS_PENDING:
          newUrl = Routes.TERMS;
          break;
        case reportStageStatus.SIGNUP_PAYMENT_PENDING:
          if (signupPaymentUrl && signupType) {
            redirectToPaymentUrl(
              signupPaymentUrl,
              ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE[signupType],
            );
          }
          break;
        case reportStageStatus.NAME_MISSING:
          newUrl = Routes.LEGAL_NAME;
          break;
        default:
          null;
      }

      if (newUrl != null) {
        history.replace(newUrl + location.search);
      }
    }
  }, [
    history,
    reportStatus,
    location.search,
    signupPaymentUrl,
    signupType,
    isValidRoute,
  ]);

  useEffect(() => {
    if (isActive) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, isActive]);
};
