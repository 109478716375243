import { Mood1, Mood2, Mood3, Mood4, Mood5, Mood6 } from 'assets/icons';

export const CYCLE_OPTIONS: {
  [key: string]: { phase: string; desc?: string };
} = {
  'Menstruation phase': {
    phase: 'During my period',
    desc: "While you're bleeding",
  },
  'Follicular phase': {
    phase: 'In the two weeks after I get my period',
  },
  'Ovulation phase': {
    phase: 'When I ovulate',
    desc: 'Around the middle of your cycle',
  },
  'Luteal phase': {
    phase: 'In the two weeks before my period',
  },
};

export const MOOD_ICONS = [Mood1, Mood2, Mood3, Mood4, Mood5, Mood6];
