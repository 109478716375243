import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PillPack: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path d="M13.3334 8.66667C13.3334 9.63316 12.5499 10.4167 11.5834 10.4167C10.6169 10.4167 9.83341 9.63316 9.83341 8.66667C9.83341 7.70017 10.6169 6.91667 11.5834 6.91667C12.5499 6.91667 13.3334 7.70017 13.3334 8.66667Z" />
    <path d="M11.5834 16.25C12.5499 16.25 13.3334 15.4665 13.3334 14.5C13.3334 13.5335 12.5499 12.75 11.5834 12.75C10.6169 12.75 9.83341 13.5335 9.83341 14.5C9.83341 15.4665 10.6169 16.25 11.5834 16.25Z" />
    <path d="M13.3334 20.3333C13.3334 21.2998 12.5499 22.0833 11.5834 22.0833C10.6169 22.0833 9.83341 21.2998 9.83341 20.3333C9.83341 19.3668 10.6169 18.5833 11.5834 18.5833C12.5499 18.5833 13.3334 19.3668 13.3334 20.3333Z" />
    <path d="M17.4167 10.4167C18.3832 10.4167 19.1667 9.63316 19.1667 8.66667C19.1667 7.70017 18.3832 6.91667 17.4167 6.91667C16.4502 6.91667 15.6667 7.70017 15.6667 8.66667C15.6667 9.63316 16.4502 10.4167 17.4167 10.4167Z" />
    <path d="M19.1667 14.5C19.1667 15.4665 18.3832 16.25 17.4167 16.25C16.4502 16.25 15.6667 15.4665 15.6667 14.5C15.6667 13.5335 16.4502 12.75 17.4167 12.75C18.3832 12.75 19.1667 13.5335 19.1667 14.5Z" />
    <path d="M17.4167 22.0833C18.3832 22.0833 19.1667 21.2998 19.1667 20.3333C19.1667 19.3668 18.3832 18.5833 17.4167 18.5833C16.4502 18.5833 15.6667 19.3668 15.6667 20.3333C15.6667 21.2998 16.4502 22.0833 17.4167 22.0833Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50008 2.25C6.21142 2.25 5.16675 3.29467 5.16675 4.58333V24.4167C5.16675 25.7053 6.21142 26.75 7.50008 26.75H21.5001C22.7887 26.75 23.8334 25.7053 23.8334 24.4167V4.58333C23.8334 3.29467 22.7887 2.25 21.5001 2.25H7.50008ZM21.5001 4.58333H7.50008V24.4167H21.5001V4.58333Z"
    />
  </SvgIcon>
);
