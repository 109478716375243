import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import {
  ContraceptiveMethod,
  ContraceptiveRecommendation,
  Flag,
  Mec,
  RecommendationReducerState,
} from '../types';
import {
  GET_RECOMMENDATION_RESULTS_SUCCESS,
  RECOMMENDATION_TYPE,
  remapRawVersions,
  CONTRACEPTIVE_PREFERENCES_SLUGS_MAPPING,
  ContraceptiveType,
  ReportStatus,
  CONTRACEPTIVE_MEC_GROUP_NAMES_MAPPING,
  getMethodByMecGroup,
} from '../constants';
import { INVALIDATE_COMPLETION_REQUEST } from '../../Questionnaire/constants';

const defaultState: RecommendationReducerState = {
  recommendations: [],
  referralRecommendation: {
    id: 0,
    price: 0,
    clinician: undefined,
  },
  mec: {} as Record<ContraceptiveMethod, Mec>,
  infoRequiredFlags: [],
  isCOCPermitted: false,
  isPOPPermitted: false,
  versions: undefined,
  patientPreferences: {
    contraceptiveMethods: {} as Record<ContraceptiveType, boolean>,
  },
  flagRed: [],
  flagOrange: [],
  flagGreen: [],
  flagExtra: [],
  reportStatus: ReportStatus.UP_TO_DATE,
  assessment: [],
  generationDate: undefined,
};

const recommendationReducer: Reducer<RecommendationReducerState> = (
  state = defaultState,
  { type, response: { data = {} } = {} },
) => {
  switch (type) {
    case GET_RECOMMENDATION_RESULTS_SUCCESS:
      if (data.report_status === ReportStatus.MANDATORY_CHANGE) {
        return { ...state, reportStatus: data.report_status };
      }
      const current = Object.keys(data.current_method).length
        ? [{ ...data.current_method, current: true }]
        : [];

      const filteredRecommendations = [
        ...data.recommendations,
        ...current,
      ].filter(
        // eslint-disable-next-line camelcase
        ({ recommendation_type: type }: { recommendation_type: string }) =>
          type !== RECOMMENDATION_TYPE.REFERRAL,
      );

      const mec = {} as Record<ContraceptiveMethod, Mec>;
      Object.entries(CONTRACEPTIVE_MEC_GROUP_NAMES_MAPPING).forEach(
        ([contraceptiveMethod, mecGroup]) => {
          if (
            contraceptiveMethod != ContraceptiveType.Pill &&
            contraceptiveMethod != ContraceptiveType.Natural
          ) {
            // Skip pill and natural mapping since they have specific mapped subtypes
            mec[contraceptiveMethod] = camelcaseKeys(data.mec[mecGroup]);
          }
        },
      );

      const recommendations = camelcaseKeys(filteredRecommendations, {
        deep: true,
      })
        .map(
          (
            recommendation: ContraceptiveRecommendation & { pillType: string },
          ) => {
            if (
              recommendation.recommendationType === ContraceptiveType.Pill ||
              recommendation.recommendationType === ContraceptiveType.Natural
            ) {
              recommendation.subType = getMethodByMecGroup(
                recommendation.mec.mecGroupName,
              );
            }
            if (
              recommendation.current ||
              recommendation.slug !== current[0]?.slug
            ) {
              return recommendation;
            }
          },
        )
        .filter((rec) => !!rec);

      const infoRequiredFlags = data.recommendations.reduce(
        (
          acc: Flag[],
          // eslint-disable-next-line camelcase
          {
            information_required: infoRequiredFlags,
            recommendation_type: type,
          }: // eslint-disable-next-line camelcase
          { information_required: Flag[]; recommendation_type: string },
        ) => {
          if (!infoRequiredFlags || type !== RECOMMENDATION_TYPE.PILL) {
            return acc;
          }

          const flags = camelcaseKeys(infoRequiredFlags).filter(
            (infoRequired: Flag) => {
              return !acc.some(
                (flag: Flag) =>
                  flag.contentHeader === infoRequired.contentHeader,
              );
            },
          );
          return [...acc, ...flags];
        },
        [],
      );

      const contraceptiveMethods = {} as Record<ContraceptiveType, boolean>;
      Object.keys(data.patient_preferences.contraceptive_methods).forEach(
        (slug) => {
          const key =
            CONTRACEPTIVE_PREFERENCES_SLUGS_MAPPING[
              slug as keyof typeof CONTRACEPTIVE_PREFERENCES_SLUGS_MAPPING
            ];
          const value = data.patient_preferences.contraceptive_methods[slug];
          contraceptiveMethods[key] = value;
        },
      );

      return {
        ...state,
        isPOPPermitted: data.mec.POP.permitted,
        isCOCPermitted: data.mec.COC.permitted,
        mec,
        recommendations,
        referralRecommendation: camelcaseKeys(data.referral_details, {
          deep: true,
        }),
        infoRequiredFlags,
        versions: {
          ...remapRawVersions(data.versions),
          flags: data.flags.version,
        },
        patientPreferences: {
          contraceptiveMethods,
        },
        // flags
        ...camelcaseKeys(data.flags.rules, { deep: true }),
        assessment: camelcaseKeys(data.assessment, {
          deep: true,
        }),
        generationDate: new Date(data.recommendations_generation_date),
      };
    case INVALIDATE_COMPLETION_REQUEST:
      return {
        ...state,
        reportStatus: defaultState.reportStatus,
      };
    default:
      return state;
  }
};

export default recommendationReducer;
