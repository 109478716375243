import logo from 'assets/images/logo.png';
import logoWebp from 'assets/images/webp/logo.webp';
import md from 'assets/images/md.jpeg';
import mdWebp from 'assets/images/webp/md.webp';
import ce from 'assets/images/ce.png';
import ceWebp from 'assets/images/webp/ce.webp';
import companyFilled from 'assets/images/company_filled.png';
import companyFilledWebp from 'assets/images/webp/company_filled.webp';
import companyOutline from 'assets/images/company_outline.png';
import companyOutlineWebp from 'assets/images/webp/company_outline.webp';
import warning from 'assets/images/warning.png';
import warningWebp from 'assets/images/webp/warning.webp';
import ecAddress from 'assets/images/ec_address.png';
import ecAddressWebp from 'assets/images/webp/ec_address.webp';
import authBg from 'assets/images/auth_bg.jpg';
import authBgWebp from 'assets/images/webp/auth_bg.webp';
import activationEmail from 'assets/images/activation_email.jpg';
import activationEmailWebp from 'assets/images/webp/activation_email.webp';
import startHA from 'assets/images/start_ha.jpg';
import startHAWebp from 'assets/images/webp/start_ha.webp';
import topMoon from 'assets/images/top_moon.png';
import topMoonWebp from 'assets/images/webp/top_moon.webp';
import BottomMoon from 'assets/images/bottom_moon.png';
import BottomMoonWebp from 'assets/images/webp/bottom_moon.webp';
import PreviewNextStep1 from 'assets/images/preview_next_step1.png';
import PreviewNextStep1Webp from 'assets/images/webp/preview_next_step1.webp';
import PreviewNextStep2 from 'assets/images/preview_next_step2.png';
import PreviewNextStep2Webp from 'assets/images/webp/preview_next_step2.webp';
import PreviewNextStep3 from 'assets/images/preview_next_step3.png';
import PreviewNextStep3Webp from 'assets/images/webp/preview_next_step3.webp';
import PreviewNextStepClinic from 'assets/images/preview_next_step_clinic.png';
import PreviewNextStepClinicWebp from 'assets/images/webp/preview_next_step_clinic.webp';
import OrderSuccess from 'assets/images/order_success.jpg';
import OrderSuccessWebp from 'assets/images/webp/order_success.webp';
import Pharmacy from 'assets/images/pharmacy.jpg';
import PharmacyWebp from 'assets/images/webp/pharmacy.webp';
import WorkingOnFeature from 'assets/images/working_on_feature.png';
import WorkingOnFeatureWebp from 'assets/images/webp/working_on_feature.webp';
import LauraMd from 'assets/images/Laura_md.png';
import LauraMdWebp from 'assets/images/webp/Laura_md.webp';
import ClinicalReviewRequired from 'assets/images/clinical_review_required.png';
import ClinicalReviewRequiredWebp from 'assets/images/webp/clinical_review_required.webp';
import BPCheckRequired from 'assets/images/bp_check_required.png';
import BPCheckRequiredWebp from 'assets/images/webp/bp_check_required.webp';

export const getImage = (name: string, webpSupported: boolean): string => {
  const images: Record<string, string> = {
    'logo.png': webpSupported ? logoWebp : logo,
    'md.jpeg': webpSupported ? mdWebp : md,
    'ce.png': webpSupported ? ceWebp : ce,
    'company_filled.png': webpSupported ? companyFilledWebp : companyFilled,
    'company_outline.png': webpSupported ? companyOutlineWebp : companyOutline,
    'warning.png': webpSupported ? warningWebp : warning,
    'ec_address.png': webpSupported ? ecAddressWebp : ecAddress,
    'auth_bg.jpg': webpSupported ? authBgWebp : authBg,
    'activation_email.jpg': webpSupported
      ? activationEmailWebp
      : activationEmail,
    'start_ha.jpg': webpSupported ? startHAWebp : startHA,
    'top_moon.png': webpSupported ? topMoonWebp : topMoon,
    'bottom_moon.png': webpSupported ? BottomMoonWebp : BottomMoon,

    'preview_next_step1.png': webpSupported
      ? PreviewNextStep1Webp
      : PreviewNextStep1,
    'preview_next_step2.png': webpSupported
      ? PreviewNextStep2Webp
      : PreviewNextStep2,
    'preview_next_step3.png': webpSupported
      ? PreviewNextStep3Webp
      : PreviewNextStep3,
    'preview_next_step_clinic.png': webpSupported
      ? PreviewNextStepClinicWebp
      : PreviewNextStepClinic,
    'order_success.jpg': webpSupported ? OrderSuccessWebp : OrderSuccess,
    'pharmacy.jpg': webpSupported ? PharmacyWebp : Pharmacy,
    'working_on_feature.png': webpSupported
      ? WorkingOnFeatureWebp
      : WorkingOnFeature,
    'Laura_md.png': webpSupported ? LauraMdWebp : LauraMd,
    'clinical_review_required.png': webpSupported
      ? ClinicalReviewRequiredWebp
      : ClinicalReviewRequired,
    'bp_check_required.png': webpSupported
      ? BPCheckRequiredWebp
      : BPCheckRequired,
  };

  return images[name];
};
