import React, {
  useCallback,
  ReactElement,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ContraceptiveEligibilityStatus,
  IconPlaceHolder,
} from '@tuunetech/tuune-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { Benefits, ContraceptionAdditionalInfo } from 'modules/Results/types';
import {
  getArrayContentMapped,
  getAssetsMapped,
} from 'modules/Contentful/selectors';
import {
  ReportContentfulKeys as RCK,
  getResultsContentfulOptions,
  HOW_TO_START_SUMMARY_SLUG,
  getRecommendationText,
  ContraceptiveType,
  getEligibilityCaption,
  CONTRACEPTIVE_METHODS_ICONS_MAPPING,
  ADDITIONAL_INFO_ICONS_MAPPING,
  CONTENT_BLOCK_NAMES,
} from '../constants';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import {
  documentToReactNodeRecords,
  documentToPlainTextRecords,
} from 'modules/Contentful/helpers';
import { EligiblityCaption } from '../components/RecommendationLayout/styles';
import {
  SymptomIconSvg,
  BenefitCaption,
} from 'shared/ContraceptiveTable/styles';
import RecommendationLayout from '../components/RecommendationLayout';
import {
  getRecommendations,
  getAllAssessmentData,
  getReferralRecommendation,
} from '../selectors';
import { getContraceptiveStatus, getFilteredRecommendations } from '../helpers';

interface BenefitProps {
  name?: string;
  slug: string;
  icon?: ReactElement;
  impact: string;
  caption?: {
    textLength: number;
    node: ReactElement;
  };
}

type RecommendationContainerProps = {
  contraceptiveTypesFilter: ContraceptiveType[];
};

export const RecommendationContainer: React.FC<
  RecommendationContainerProps
> = ({ contraceptiveTypesFilter }) => {
  const analytics = useAnalytics();
  const referralRecommendation = useSelector(getReferralRecommendation);

  const [
    isRecommendationDetailsDialogOpen,
    setIsRecommendationDetailsDialogOpen,
  ] = useState(false);
  const [currentRecommendation, setCurrentRecommendation] = useState(undefined);

  // get pill&referral recommendations list from BE response
  const recommendations = useSelector(getRecommendations);
  // get regimen summary for each pill in recommendations list

  const recommendationBenefitDetails = useSelector(
    getArrayContentMapped(RCK[CTN.CONTRACEPTION_DETAIL_PERSONALISED].reqId),
  );
  const contraceptionAdditionalInfoDetails = useSelector(
    getArrayContentMapped(RCK[CTN.CONTRACEPTION_ADDITIONAL_INFORMATION].reqId),
  );
  const recommendedConsiderationsSummary = useSelector(
    getArrayContentMapped(RCK[CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY].reqId),
  );
  const pillRegimenSummary = useSelector(
    getArrayContentMapped(RCK[CTN.RECOMMENDED_REGIMEN_SUMMARY].reqId),
  );
  const switchingContraceptionPreview = useSelector(
    getArrayContentMapped(
      RCK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC].reqId,
    ),
  );
  const icons = useSelector(getAssetsMapped(RCK[CTN.ISSUE_ICON].reqId));
  const allAssessmentData = useSelector(getAllAssessmentData);

  const pillRegimenSummaryContent = useMemo(() => {
    // remap contentful document to React components for regimen summary
    return documentToReactNodeRecords(
      pillRegimenSummary,
      getResultsContentfulOptions(),
    );
  }, [pillRegimenSummary]);
  const considerationsSummaryContent = useMemo(() => {
    // remap contentful document to React components for considerations summary
    return documentToReactNodeRecords(
      recommendedConsiderationsSummary,
      getResultsContentfulOptions(),
    );
  }, [recommendedConsiderationsSummary]);

  const switchingPillInfoSummary = useMemo(() => {
    return documentToReactComponents(
      switchingContraceptionPreview[HOW_TO_START_SUMMARY_SLUG]?.content,
      getResultsContentfulOptions(),
    );
  }, [switchingContraceptionPreview]);

  const benefitDetailsRemappedAsNodes = useMemo(() => {
    // remap contentful document to React components for benefit details
    return documentToReactNodeRecords(
      recommendationBenefitDetails,
      getResultsContentfulOptions(CONTENT_BLOCK_NAMES.BENEFITS),
    );
  }, [recommendationBenefitDetails]);
  const benefitDetailsRemappedAsText = documentToPlainTextRecords(
    recommendationBenefitDetails,
  );
  const additionalInfoDetailsRemappedAsNodes = useMemo(() => {
    // remap contentful document to React components for additional info details
    return documentToReactNodeRecords(
      contraceptionAdditionalInfoDetails,
      getResultsContentfulOptions(CONTENT_BLOCK_NAMES.BENEFITS),
    );
  }, [contraceptionAdditionalInfoDetails]);
  const additionalInfoRemappedAsText = documentToPlainTextRecords(
    contraceptionAdditionalInfoDetails,
  );

  const getIssueIcon = useCallback(
    (slug: string): ReactElement =>
      icons[slug] ? (
        <SymptomIconSvg data-src={icons[slug].asset} />
      ) : (
        <IconPlaceHolder />
      ),
    [icons],
  );

  const remapBenefits = useCallback(
    (benefits: Benefits, recommendationSlug: string): BenefitProps[] => {
      return benefits?.recommendationSpecificBenefits.length
        ? benefits.recommendationSpecificBenefits.map(
            ({ impact, issueName, issueSlug }) => {
              const benefitDetailsNode: ReactNode | undefined =
                benefitDetailsRemappedAsNodes[
                  `${issueSlug}-${recommendationSlug}`
                ];
              const benefitDetailsText: string | undefined =
                benefitDetailsRemappedAsText[
                  `${issueSlug}-${recommendationSlug}`
                ];
              const benefitCaption =
                benefitDetailsNode && benefitDetailsRemappedAsNodes
                  ? {
                      node: (
                        <BenefitCaption>{benefitDetailsNode}</BenefitCaption>
                      ),
                      textLength: benefitDetailsText.length,
                    }
                  : undefined;

              return {
                name: issueName,
                slug: issueSlug,
                impact: impact,
                caption: benefitCaption,
              };
            },
          )
        : [];
    },
    [benefitDetailsRemappedAsNodes, benefitDetailsRemappedAsText],
  );

  const remapAdditionalInfo = useCallback(
    (
      additionalInfoDetails: ContraceptionAdditionalInfo[],
      recommendationSlug: string,
    ): BenefitProps[] => {
      return additionalInfoDetails?.length
        ? additionalInfoDetails.map(({ content, slug }) => {
            const additionalInfoDetailsNode: ReactNode | undefined =
              additionalInfoDetailsRemappedAsNodes[
                `${slug}-${recommendationSlug}`
              ];
            const additionalInfoDetailsText: string | undefined =
              additionalInfoRemappedAsText[`${slug}-${recommendationSlug}`];
            const additionalInfoCaption =
              additionalInfoDetailsNode && additionalInfoDetailsRemappedAsNodes
                ? {
                    node: (
                      <BenefitCaption>
                        {additionalInfoDetailsNode}
                      </BenefitCaption>
                    ),
                    textLength: additionalInfoDetailsText.length,
                  }
                : undefined;
            return {
              slug: slug,
              impact: content,
              caption: additionalInfoCaption,
              isAdditionalInfo: true,
            };
          })
        : [];
    },
    [additionalInfoDetailsRemappedAsNodes, additionalInfoRemappedAsText],
  );

  const benefitList = allAssessmentData.map((issue) => {
    return {
      name: issue.name,
      slug: issue.slug,
      icon: getIssueIcon(issue.slug),
    };
  });

  const additionalInfoHeadingList = recommendations.length
    ? recommendations[0].additionalInformation.map((detail) => {
        return {
          name: detail.title,
          slug: detail.slug,
          icon: (
            <SymptomIconSvg
              data-src={ADDITIONAL_INFO_ICONS_MAPPING[detail.slug]}
            />
          ),
        };
      })
    : [];

  const onLearnMoreClickCallback = useCallback(
    (recommendation, analyticsEvent: ANALYTICS_EVENT) => {
      setIsRecommendationDetailsDialogOpen(true);
      setCurrentRecommendation(recommendation);

      analytics.track(analyticsEvent, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        recommendationName: recommendation.name,
        recommendationSlug: recommendation.slug,
        mecPermitted: recommendation.eligibility,
        bpCheckRequired: !!recommendation.informationRequired.length
          ? 'yes'
          : 'no',
      });
    },
    [analytics],
  );

  const onEligibilityRowClickCallback = useCallback(
    (
      status: ContraceptiveEligibilityStatus,
      text: string,
      contraceptiveType: string,
    ) => {
      analytics.track(ANALYTICS_EVENT.RECOMMENDATION_ELIGIBILIGY_ROW_CLICK, {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
        contraceptiveType,
        eligibility: status,
        title: text,
      });
    },
    [analytics],
  );

  const onRecommendationDetailsDialogClose = useCallback(() => {
    setIsRecommendationDetailsDialogOpen(false);
    setCurrentRecommendation(undefined);
  }, []);

  const filteredRecommendations = getFilteredRecommendations(
    recommendations,
    contraceptiveTypesFilter,
  );

  const contraceptiveRecommendations = filteredRecommendations.length
    ? filteredRecommendations.map((recommendation) => {
        const contraceptiveRecommendation = recommendation;
        const status = getContraceptiveStatus(
          contraceptiveRecommendation.current,
          contraceptiveRecommendation.mec.permitted,
          contraceptiveRecommendation.informationRequired,
        );
        const eligibilityCaption = getEligibilityCaption(
          status,
          getRecommendationText(contraceptiveRecommendation),
          contraceptiveRecommendation.mec.triggeredRules,
        );
        const type = getRecommendationText(contraceptiveRecommendation);
        const formattedBenefits = remapBenefits(
          contraceptiveRecommendation.benefits,
          recommendation.slug,
        );
        const formattedAdditionalInfo = remapAdditionalInfo(
          contraceptiveRecommendation.additionalInformation,
          recommendation.slug,
        );
        const formattedRecommendation = {
          name: contraceptiveRecommendation.name,
          typeIcon:
            CONTRACEPTIVE_METHODS_ICONS_MAPPING[
              recommendation.recommendationType
            ],
          id: contraceptiveRecommendation.id,
          generics: contraceptiveRecommendation.otherBrands?.map(
            (generic) => generic.name,
          ),
          status: status,
          eligibilityCaption: eligibilityCaption
            ? {
                node: (
                  <EligiblityCaption>{eligibilityCaption}</EligiblityCaption>
                ),
                textLength: eligibilityCaption.length,
              }
            : undefined,
          slug: contraceptiveRecommendation.slug,
          type: type,
          benefits: [...formattedBenefits, ...formattedAdditionalInfo],
          ingredients: contraceptiveRecommendation.ingredients.map(
            (ingredient) =>
              `${ingredient.name} (${ingredient.dosage
                .split('/')
                .map((d) => d.trim() + 'mg')
                .join(' / ')})`,
          ),
          informationRequired: contraceptiveRecommendation.informationRequired,
          eligibility: contraceptiveRecommendation.mec.permitted,
          isCurrent: contraceptiveRecommendation.current,
        };
        return {
          ...formattedRecommendation,
          onLearnMoreClickCallback: () =>
            onLearnMoreClickCallback(
              formattedRecommendation,
              ANALYTICS_EVENT.RECOMMENDATION_CONTRACEPTIVE_CARD_LEARN_MORE_HYPERLINK,
            ),
          onCtaClickCallback: () =>
            onLearnMoreClickCallback(
              formattedRecommendation,
              ANALYTICS_EVENT.RECOMMENDATION_CONTRACEPTIVE_CARD_LEARN_MORE_BUTTON,
            ),
          onEligibilityRowClick: (
            status: ContraceptiveEligibilityStatus,
            text: string,
          ) =>
            onEligibilityRowClickCallback(
              status,
              text,
              formattedRecommendation.type,
            ),
        };
      })
    : undefined;

  return (
    <RecommendationLayout
      recommendations={contraceptiveRecommendations}
      benefitList={[...benefitList, ...additionalInfoHeadingList]}
      referralRecommendation={referralRecommendation}
      isRecommendationDetailsDialogOpen={isRecommendationDetailsDialogOpen}
      currentRecommendation={currentRecommendation}
      onRecommendationDetailsDialogClose={onRecommendationDetailsDialogClose}
      considerationsSummaryContent={considerationsSummaryContent}
      pillRegimenSummaryContent={pillRegimenSummaryContent}
      switchingPillInfoSummary={switchingPillInfoSummary}
    />
  );
};

export default RecommendationContainer;
