import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { phoneAsYouType } from 'utils/helpers';
import { getEmail, getFirstName, getLastName, getPhone } from '../selectors';
import { getAccountDataRequest, updateAccountData } from '../actions';
import { PersonalInfo } from '../components/PersonalInfo';
import { useForm } from 'react-hook-form';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { personalInfoSchema } from '../constants/validation';

export const PersonalInfoContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const email = useSelector(getEmail);
  const phone = useSelector(getPhone);

  useEffect(() => {
    dispatch(getAccountDataRequest());
  }, [dispatch]);

  const profileData = useMemo(() => {
    phoneAsYouType.reset();
    const formattedPhone = phoneAsYouType.input(phone || '');
    return [
      { name: 'Email', value: email },
      {
        name: 'Phone',
        value: `${formattedPhone.substring(0, formattedPhone.length - 3)}***`,
      },
      { name: 'Password', value: '*********' },
    ];
  }, [email, phone]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<AccountInfoFormData>>({
    resolver: yupResolver(personalInfoSchema),
    mode: 'onTouched',
  });

  const submit = useCallback(
    ({ firstName, lastName }) => {
      dispatch(updateAccountData({ firstName, lastName }));
      setIsEditing(false);
    },
    [dispatch],
  );

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <PersonalInfo
      firstName={firstName}
      lastName={lastName}
      readOnlyProfileData={profileData}
      isEditing={isEditing}
      handleSubmit={handleSubmit(submit)}
      handleEditClick={handleEditClick}
      control={control}
      errors={errors}
    />
  );
};
