import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { BODY1, Button } from '@tuunetech/tuune-components';
import { Box, CircularProgress, Theme, useMediaQuery } from '@material-ui/core';
import Routes from 'routes';
import { SocialAuthType } from 'modules/Auth/types/socialAuth';
import { OAuthProvider } from 'modules/Auth/constants/socialAuth';
import AuthLayout from '../AuthLayout';
import { LoginFormData } from '../../pages/LoginPage';
import { Input, TextButton } from './styles';
import { SocialAuth } from '../SocialAuth';
import { ErrorBox } from 'shared';

interface LoginProps {
  control: Control<LoginFormData>;
  handleSubmit: () => void;
  handleSocialAuthClick: (oAuthProvider: OAuthProvider) => void;
  errors: FieldErrors<LoginFormData>;
  isLoading: boolean;
  nonFieldErrorMessage?: string;
}

const Login: React.FC<LoginProps> = ({
  control,
  errors,
  handleSubmit,
  handleSocialAuthClick,
  isLoading,
  nonFieldErrorMessage,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const content = (
    <form onSubmit={handleSubmit}>
      {nonFieldErrorMessage && (
        <>
          <ErrorBox text={nonFieldErrorMessage} />
          <Box height="24px" />
        </>
      )}
      <SocialAuth
        authType={SocialAuthType.SignIn}
        onAuthButtonClick={(oAuthProvider) =>
          handleSocialAuthClick(oAuthProvider)
        }
      />
      <BODY1 $verticalMargin={24} $fontSize="14px" align="center">
        OR
      </BODY1>
      <Input
        name="email"
        placeholder="Email"
        control={control}
        required
        autoFocus
        autoComplete="email"
        type="email"
        inputProps={{
          inputMode: 'email',
        }}
        fullWidth
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <Input
        name="password"
        placeholder="Password"
        control={control}
        required
        type="password"
        fullWidth
        error={!!errors.password}
        helperText={errors.password?.message}
      />
      <Box marginTop={`${isDesktop ? 40 : 32}px`} marginBottom="16px">
        <Button
          type="submit"
          fullWidth
          disabled={isLoading}
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          Log in
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap={isDesktop ? 'nowrap' : 'wrap'}
      >
        <TextButton href={Routes.FORGOT_PASSWORD} variant="text">
          Forgot password?
        </TextButton>
        <TextButton href="http://www.tuune.com" variant="text">
          sign up
        </TextButton>
      </Box>
    </form>
  );

  return <AuthLayout title="Welcome back!" content={content} />;
};

export default Login;
