import React from 'react';
import { HAFeedbackModule } from '../../components/HAFeedbackModule';
import type { Action, FeedbackDataType, State } from './state';
import { SET_FEEDBACK_Q_DATA, SET_FEEDBACK_Q_NUM } from './state';
import { lastQuestionNum } from './questions';

type HAFeedbackModuleContainerProps = {
  dispatch: React.Dispatch<Action>;
  state: State;
  submitFeedback: () => void;
};
export const HAFeedbackModuleContainer: React.FC<
  HAFeedbackModuleContainerProps
> = ({ state, dispatch, submitFeedback }) => {
  const { feedbackQnNum, ...feedbackData } = state;

  const handleGoBack = () => {
    dispatch({
      type: SET_FEEDBACK_Q_NUM,
      payload: {
        feedbackQnNum: Math.max(feedbackQnNum - 1, 0),
      },
    });
  };

  const handleNext = () => {
    dispatch({
      type: SET_FEEDBACK_Q_NUM,
      payload: {
        feedbackQnNum: Math.min(feedbackQnNum + 1, lastQuestionNum),
      },
    });

    if (feedbackQnNum === lastQuestionNum) {
      submitFeedback();
    }
  };

  const handleUpdateFeedbackQnData = (data: FeedbackDataType) => {
    dispatch({
      type: SET_FEEDBACK_Q_DATA,
      payload: data,
    });
  };

  return (
    <HAFeedbackModule
      feedbackQnNum={feedbackQnNum}
      handleGoBack={handleGoBack}
      handleNext={handleNext}
      handleUpdateFeedbackQnData={handleUpdateFeedbackQnData}
      feedbackData={feedbackData}
    />
  );
};
