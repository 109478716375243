import React from 'react';
import { Page } from 'shared';
import { PrivacyContainer } from '../containers/GetStarted';
import { QuestionnaireHeader } from '../components/QuestionnaireHeader';

const PrivacyPage: React.FC = () => {
  return (
    <Page>
      <QuestionnaireHeader />
      <PrivacyContainer />
    </Page>
  );
};

export default PrivacyPage;
