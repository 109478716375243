import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { DealbreakerReducerState } from '../types';

const selectDealbreaker: Selector<RootState, DealbreakerReducerState> = (
  state,
) => state.dealbreaker;

export const getDealbreakerIssues = createSelector(
  selectDealbreaker,
  (state) => state.dealbreakerIssues,
);

export const getDealbreakerSubmitted = createSelector(
  selectDealbreaker,
  (state) => state.dealbreakerSubmitted,
);

export const getDealbreaker = createSelector(
  selectDealbreaker,
  (state) => state.dealbreaker,
);

export const getPageNum = createSelector(
  selectDealbreaker,
  (state) => state.pageNum,
);
