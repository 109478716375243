import { SignupType } from 'modules/Questionnaire/constants/signupType';
import {
  ECOMMERCE_BIRTH_CONTROL_PRODUCT,
  ECOMMERCE_HORMONAL_WELLBEING_PRODUCT,
  ECOMMERCE_HORMONE_TEST_PRODUCT,
  ENHANCED_ECOMMERCE_EVENT,
} from './constants';
import { logGTMEvent } from './gtm';
import { EcommerceProduct } from './types';

const clearEcommerceObject = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: null,
    });
  }
};

export const EnhancedEcommerce = {
  logBeginCheckoutEvent: (product: EcommerceProduct): void => {
    // Clear the previous ecommerce object
    clearEcommerceObject();
    logGTMEvent(ENHANCED_ECOMMERCE_EVENT.BEGIN_CHECKOUT, {
      ecommerce: {
        items: [product],
      },
    });
  },

  logPurchaseEvent: (product: EcommerceProduct, orderUuid: string): void => {
    // Clear the previous ecommerce object
    clearEcommerceObject();
    logGTMEvent(ENHANCED_ECOMMERCE_EVENT.PURCHASE, {
      ecommerce: {
        transaction_id: orderUuid,
        value: product.price,
        currency: product.currency,
        items: [product],
      },
    });
  },
};

export const ECOMMERCE_PRODUCTS_BY_SIGNUP_TYPE = {
  [SignupType.birthControl]: ECOMMERCE_BIRTH_CONTROL_PRODUCT,
  [SignupType.hormoneTest]: ECOMMERCE_HORMONE_TEST_PRODUCT,
  [SignupType.birthControlAndHormoneTest]: ECOMMERCE_HORMONAL_WELLBEING_PRODUCT,
};
