import { oAuthPreSignUpRequest } from 'modules/Questionnaire/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import Routes from 'routes';
import { US_SUPPORTED_STATE_CODES } from 'utils/usStates';
import { getHasAnyErrorMessages } from 'modules/NotificationsHandler/selectors';
import { displayDefaultErrorPopup } from 'modules/NotificationsHandler/actions';
import {
  oAuthLoginWithAuthCodeRequest,
  oAuthSignUpWithAuthCodeRequest,
} from '../actions';
import OAuthRedirectCallback from '../components/OAuthRedirectCallback';
import { AuthMethod } from '../constants/authMethod';
import { getIsAuthenticated, getLastOAuthCode } from '../selectors';
import { useAnalytics } from 'utils/analytics';
import {
  ANALYTICS_CATEGORY,
  ANALYTICS_EVENT,
  SIGNUP_METHOD,
} from 'utils/analytics/constants';

const OAuthRedirectCallbackPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const analytics = useAnalytics();
  const [isLoading, setIsLoading] = useState(true);
  const [authMethod, setAuthMethod] = useState<AuthMethod | undefined>(
    undefined,
  );

  const { oAuthProvider } = useParams<{ oAuthProvider: string }>();

  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const lastOAuthCode = useSelector(getLastOAuthCode);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);

  useEffect(() => {
    if (hasServerErrors) {
      setIsLoading(false);
      history.replace(Routes.LOGIN);
    }
  }, [hasServerErrors, history]);

  useEffect(() => {
    if (isAuthenticated) {
      if (authMethod === (AuthMethod.JwtRegister as AuthMethod)) {
        analytics.track(ANALYTICS_EVENT.ACTIVATE_ACCOUNT, {
          category: ANALYTICS_CATEGORY.ACCOUNT,
          signUpMethod: SIGNUP_METHOD.SOCIAL,
          authProvider: oAuthProvider,
        });
      }

      history.replace(Routes.HOME);
    }
  }, [isAuthenticated, authMethod, analytics, oAuthProvider, history]);

  const [isProcessed, setIsProcessed] = useState(false);

  useEffect(() => {
    if (isProcessed) return;
    const authCode = new URLSearchParams(location.search).get('code');
    const state = new URLSearchParams(location.search).get('state');

    if (authCode && state && authCode != lastOAuthCode) {
      setIsLoading(false);
      setIsProcessed(true);
      const payloadJson = JSON.parse(atob(state));

      setAuthMethod(payloadJson.authMethod);
      if (payloadJson.authMethod === AuthMethod.JwtRegister) {
        // Signup flow
        const { state } = payloadJson;

        if (!US_SUPPORTED_STATE_CODES.includes(state)) {
          // Register to klaviyo
          dispatch(oAuthPreSignUpRequest(authCode, oAuthProvider, payloadJson));
        } else {
          dispatch(
            oAuthSignUpWithAuthCodeRequest(
              authCode,
              oAuthProvider,
              payloadJson,
            ),
          );
        }
      } else if (payloadJson.authMethod === AuthMethod.JwtCreate) {
        // Login flow
        dispatch(oAuthLoginWithAuthCodeRequest(authCode, oAuthProvider));
      }
    } else {
      history.replace(Routes.LOGIN);
      dispatch(displayDefaultErrorPopup);
    }
  }, [
    dispatch,
    history,
    location.search,
    oAuthProvider,
    setAuthMethod,
    lastOAuthCode,
    isProcessed,
  ]);

  return <OAuthRedirectCallback isLoading={isLoading} />;
};

export default OAuthRedirectCallbackPage;
