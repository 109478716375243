import { BODY1, H3 } from '@tuunetech/tuune-components';
import styled from 'styled-components';

export const ClinicLogoImg = styled.img`
  width: 100%;

  max-width: 390px;
  align-self: center;
`;

export const Title = styled(H3)`
  i {
    color: ${({ theme }) => theme.palette.common.purple[900]};
  }
  text-align: center;
`;

export const IntroParagraph = styled(BODY1)`
  text-align: center;
`;

export const IntroBoldParagraph = styled(BODY1)`
  text-align: center;
  font-weight: bold;
`;

export const Indicator = styled.div`
  font-size: 12px;
  font-weight: 700;

  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.purple[900]};

  width: 24px;
  height: 24px;
  min-width: 24px;
  padding-top: 4px;
  margin-right: 12px;

  border-radius: 50%;
  text-align: center;
`;
