import { BODY1, BODY2, H4 } from '@tuunetech/tuune-components';
import styled from 'styled-components';

export const RootContainer = styled.div`
  width: 582px;
  max-width: 100%;
  margin-bottom: 24px;
`;

export const HeaderImage = styled.img`
  border-radius: 40px;
  width: 100%;
  max-height: 313px;
`;

export const HelpIcon = styled.img`
  height: 24px;
`;

export const SectionTitle = styled(H4)`
  color: ${({ theme }) => theme.palette.common.purple[900]};
`;

export const SectionSubTitle = styled(BODY1)`
  font-weight: bold;
`;

export const SectionParagraph = styled(BODY2)`
  font-weight: 400;
  font-size: 14px;
`;

export const VideoContainer = styled.div`
  width: 100%;

  iframe,
  object,
  embed {
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const YoutubeIframe = styled.iframe`
  height: 310px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 193px;
  }
`;
