import React from 'react';
import {
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button } from '@tuunetech/tuune-components';
import { AppFixedFooter } from 'shared/AppFixedFooter';

type NavigationButtonsFooterProps = {
  showContinue?: boolean;
  showBack?: boolean;
  handleContinue?: () => void;
  handleBack?: () => void;
  isLoading?: boolean;
};

export const NavigationButtonsFooter: React.FC<
  NavigationButtonsFooterProps
> = ({
  showContinue = true,
  showBack = false,
  handleContinue,
  handleBack,
  isLoading,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <AppFixedFooter>
      <Box
        display="flex"
        flexDirection="row"
        gridColumnGap="8px"
        justifyContent="center"
        width="100%"
      >
        {showBack && (
          <Button variant="outlined" onClick={handleBack}>
            back
          </Button>
        )}
        {showContinue && (
          <Button
            fullWidth={isXs}
            onClick={handleContinue}
            disabled={isLoading}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            continue
          </Button>
        )}
      </Box>
    </AppFixedFooter>
  );
};
