import { RootState } from 'reducers';
import { createSelector, Selector } from 'reselect';
import { NotificationsReducerState } from '../types';

const selectNotificationsHandler: Selector<
  RootState,
  NotificationsReducerState
> = (state) => state.notifications;

export const getPopupErrorMessage = createSelector(
  selectNotificationsHandler,
  (state) => state.errors?.message,
);

export const getNonFieldErrorMessage = createSelector(
  selectNotificationsHandler,
  (state) => state.errors?.nonFieldBasedValidationMessage,
);

const processFieldErrorMessages = (
  fieldBasedValidationMessages: Record<string, []> | undefined,
) => {
  if (fieldBasedValidationMessages) {
    const validationMessages: Record<string, string> = {};
    Object.entries(fieldBasedValidationMessages).forEach(
      ([fieldName, messages]) =>
        (validationMessages[fieldName] = messages.join('\n')),
    );
    return validationMessages;
  }
};

export const getFieldErrorMessages = createSelector(
  selectNotificationsHandler,
  (state) =>
    processFieldErrorMessages(state.errors?.fieldBasedValidationMessages),
);

export const getHasAnyErrorMessages = createSelector(
  selectNotificationsHandler,
  (state) =>
    !!processFieldErrorMessages(state.errors?.fieldBasedValidationMessages) ||
    !!state.errors?.nonFieldBasedValidationMessage ||
    !!state.errors?.message,
);

export const getPopupInfoMessage = createSelector(
  selectNotificationsHandler,
  (state) => state.infoMessage,
);
