import { AnyAction, Middleware } from 'redux';
import {
  ADD_TO_LOADING_LIST,
  SET_LOADING_FINISH,
} from 'modules/Loading/constants';
import { GET_USER_ACCESS_TOKEN_REQUEST } from 'modules/Auth/constants';
import {
  REQUEST_CONTENTFUL_DATA,
  SUCCESS_CONTENTFUL_DATA,
} from 'modules/Contentful/constants/actions';

const getPayloadActionType = (type: string) => {
  if (
    type.includes(REQUEST_CONTENTFUL_DATA) ||
    type.includes(SUCCESS_CONTENTFUL_DATA)
  ) {
    return type.split('_').slice(1).join('_');
  }

  return type.split('_').slice(0, -1).join('_');
};

const loading: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action): Promise<AnyAction | void> | void => {
    if (action.type === GET_USER_ACCESS_TOKEN_REQUEST) return next(action);

    const actionStage = action.type && action.type.split('_').pop();

    const isRequest =
      actionStage === 'REQUEST' ||
      action.type.includes(REQUEST_CONTENTFUL_DATA);

    if (isRequest) {
      dispatch({
        type: ADD_TO_LOADING_LIST,
        payload: {
          actionType: getPayloadActionType(action.type),
        },
      });
    }

    next(action);

    const isSuccessOrFailure =
      actionStage === 'SUCCESS' ||
      actionStage === 'FAIL' ||
      action.type.includes(SUCCESS_CONTENTFUL_DATA);

    if (isSuccessOrFailure) {
      dispatch({
        type: SET_LOADING_FINISH,
        payload: {
          actionType: getPayloadActionType(action.type),
        },
      });
    }
  };

export default loading;
