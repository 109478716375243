import React, { useCallback, useState } from 'react';
import { BODY2, Button, H3 } from '@tuunetech/tuune-components';
import { Theme, useMediaQuery } from '@material-ui/core';
import { ShareReportDialog } from '../ShareReportDialog';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';

const ShareReport: React.FC = () => {
  const analytics = useAnalytics();
  const [shareReportModalOpen, setShareReportModalOpen] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const onShareReportClick = useCallback(() => {
    setShareReportModalOpen(true);
    analytics.track(
      ANALYTICS_EVENT.RECOMMENDATION_REPORT_DOWNLOAD_POPOUP_OPEN,
      {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      },
    );
  }, [analytics, setShareReportModalOpen]);

  return (
    <>
      <ShareReportDialog
        handleClose={() => setShareReportModalOpen(false)}
        isOpen={shareReportModalOpen}
      />
      <H3>Share this report with my doctor</H3>
      <BODY2 $marginTop={16} $marginBottom={36} color="textSecondary">
        Export your results in a readable format, for your own records, or to
        share with your primary-care physician.
      </BODY2>
      <Button
        fullWidth={isMobile}
        variant="outlined"
        onClick={onShareReportClick}
      >
        share
      </Button>
    </>
  );
};

export default ShareReport;
