import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes';
import {
  getFieldErrorMessages,
  getHasAnyErrorMessages,
  getNonFieldErrorMessage,
} from 'modules/NotificationsHandler/selectors';
import ResetPassword from '../components/ResetPassword';
import {
  resetPasswordConfirmRequest,
  resetPasswordFlowReset,
} from '../actions';
import { getIsResetPasswordConfirmed } from '../selectors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordSchema } from '../constants/validation';

export type ResetPasswordFormData = {
  newPassword: string;
  rePassword: string;
};

const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const nonFieldErrorMessage = useSelector(getNonFieldErrorMessage);
  const serverErrors = useSelector(getFieldErrorMessages);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);
  const [isLoading, setIsLoading] = useState(false);

  const isResetPasswordConfirmed = useSelector(getIsResetPasswordConfirmed);
  const history = useHistory();
  const { uid, token } = useParams<{ uid: string; token: string }>();

  useEffect(() => {
    if (!!serverErrors?.uid || !!serverErrors?.token) {
      dispatch(resetPasswordFlowReset());
      history.replace(Routes.FORGOT_PASSWORD, { isLinkValid: false });
    }
  }, [history, dispatch, serverErrors?.uid, serverErrors?.token]);

  const handleResetPopupDismiss = useCallback(() => {
    history.replace(Routes.LOGIN);
  }, [history]);

  const submit = useCallback(
    ({ newPassword, rePassword }) => {
      dispatch(
        resetPasswordConfirmRequest({
          uid,
          token,
          password: newPassword,
          confirmPassword: rePassword,
        }),
      );
      setIsLoading(true);
    },
    [uid, token, dispatch],
  );

  useEffect(() => {
    if (hasServerErrors) {
      setIsLoading(false);
    }
  }, [hasServerErrors]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(ResetPasswordSchema),
    mode: 'onSubmit',
  });

  return (
    <ResetPassword
      control={control}
      handleSubmit={handleSubmit(submit)}
      errors={errors}
      nonFieldErrorMessage={nonFieldErrorMessage}
      fieldErrorMessages={serverErrors}
      isLoading={isLoading}
      isResetPasswordConfirmed={isResetPasswordConfirmed}
      handleResetPopupDismiss={handleResetPopupDismiss}
    />
  );
};

export default ResetPasswordPage;
