import { AuthMethod } from 'modules/Auth/constants/authMethod';
import { OAuthProvider } from 'modules/Auth/constants/socialAuth';
import { useCallback } from 'react';
import { oAuthRedirect } from 'utils/helpers/auth';

export const useOAuthLoginRedirect = (): ((
  oAuthProvider: OAuthProvider,
) => void) => {
  const loginRedirect = useCallback((oAuthProvider: OAuthProvider) => {
    if (oAuthProvider) {
      oAuthRedirect(oAuthProvider, {
        authMethod: AuthMethod.JwtCreate,
      });
    }
  }, []);

  return loginRedirect;
};
