import React from 'react';
import { AppHeader } from '@tuunetech/tuune-components';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import ReviewResults from '../components/ReviewResults';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { HAFeedbackLoadingContainer } from 'shared';

export const ReviewResultsPage: React.FC = () => {
  useReportStatusRouter();
  const headerButtons = useBaseNavButtons();

  return (
    <HAFeedbackLoadingContainer>
      <AppHeader
        position="fixed"
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />
      <ReviewResults />
    </HAFeedbackLoadingContainer>
  );
};
