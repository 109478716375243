export enum COOKIE_CATEGORY_NAMES {
  ANALYTICS = 'analytics',
  MARKETING = 'marketing',
}

export enum CookieConsentEventTypes {
  AnalyticsGranted = 'consentGranted',
  AnalyticsRevoked = 'analyticsRevoked',
}
export const PRIVACY_POLICY_URL = 'https://www.tuune.com/privacy-policy-app';

export const TERMS_AND_CONDITIONS_URL =
  'https://www.tuune.com/terms-and-conditions';
