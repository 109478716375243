import { stringErrorMessages } from 'utils/helpers';
import { DateAnswerFormValues } from '../types';
import { AnswerTypesMap } from '../constants';

const MIN_ALLOWED_AGE = 18;
const MAX_ALLOWED_AGE = 55;

export const parseDate = (
  date: string | null,
  answerType?: string,
): DateAnswerFormValues => {
  if (date) {
    const dateStrings = date.split('-');
    return {
      day: dateStrings[2],
      month: dateStrings[1],
      year: dateStrings[0],
    };
  }
  return {
    day: answerType === AnswerTypesMap.DATE_MONTH ? '01' : undefined,
    month: undefined,
    year: undefined,
  };
};

export interface DateEditableFields {
  day: boolean;
  month: boolean;
  year: boolean;
}

export const getEditableFields = (answerType: string): DateEditableFields => {
  const defaultValue = {
    day: true,
    month: true,
    year: true,
  };

  if (answerType === AnswerTypesMap.DATE_MONTH) {
    return {
      ...defaultValue,
      day: false,
    };
  }

  return defaultValue;
};

const getMaxAllowedDob = (): Date => {
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - MIN_ALLOWED_AGE);

  return maxDate;
};

const getMinAllowedDob = (): Date => {
  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - MAX_ALLOWED_AGE);

  return minDate;
};

export const getDateFromInput = ({
  day = '',
  month = '',
  year = '',
}: DateAnswerFormValues): Date | undefined => {
  const d = parseInt(day);
  const m = parseInt(month);
  const y = parseInt(year);

  if (!y || !m || !d) return;

  return new Date(y, m - 1, d);
};

export const validateDob = ({
  day = '',
  month = '',
  year = '',
}: DateAnswerFormValues): string | undefined => {
  const date = getDateFromInput({ day, month, year });
  if (!date) return;

  const maxDate = getMaxAllowedDob();
  const minDate = getMinAllowedDob();

  if (date > maxDate || date < minDate) {
    return stringErrorMessages.AGE;
  }
};

export const isUnderMinAge = (dob: Date): boolean => {
  return dob > getMaxAllowedDob();
};
