import styled from 'styled-components';
import { InfoOutlined } from '@material-ui/icons';

export const Info = styled(InfoOutlined)`
  font-size: 18px;
  margin-right: 7px;
`;

export const HelpLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
