import React, { useEffect, useState } from 'react';
import EventTarget from '@ungap/event-target';

import { AnalyticsContext } from './context';
import { analytics } from './analytics';
import { initAnalytics } from './amplitude';
import { CookieConsentEventTypes } from 'utils/cookies/constants';
import {
  ANALYTICS_EVENT,
  DEFAULT_AMPLITUDE_CONFIG,
  USER_SOURCE,
} from './constants';
import { useSelector } from 'react-redux';
import { getClinicInfo, getEmail } from 'modules/Account/selectors';
import { Analytics, AnalyticsTrackData } from './types';

const apiKey: string = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

export const AnalyticsProvider: React.FC = ({ children }) => {
  const email = useSelector(getEmail);
  const clinicInfo = useSelector(getClinicInfo);
  const [analyticsWrapper, setAnalyticsWrapper] =
    useState<Analytics>(analytics);

  useEffect(() => {
    window.CookiesConsentEventTarget = new EventTarget();

    // Enable Amplitude cookies when analytics consent is granted
    window.CookiesConsentEventTarget.addEventListener(
      CookieConsentEventTypes.AnalyticsGranted,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (evt: Event) => {
        initAnalytics(apiKey, {
          ...DEFAULT_AMPLITUDE_CONFIG,
          disableCookies: false,
        });
      },
    );

    // Disable Amplitude cookies when analytics consent is revoked
    window.CookiesConsentEventTarget.addEventListener(
      CookieConsentEventTypes.AnalyticsRevoked,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (evt: Event) => {
        initAnalytics(apiKey, {
          ...DEFAULT_AMPLITUDE_CONFIG,
          disableCookies: true,
        });
      },
    );
  }, []);

  useEffect(() => {
    const userSource = clinicInfo ? USER_SOURCE.CLINIC : USER_SOURCE.NON_CLINIC;
    const clinicSlug = clinicInfo?.slug || '';

    setAnalyticsWrapper({
      ...analytics,
      track: (event: ANALYTICS_EVENT, data: AnalyticsTrackData): void => {
        analytics.track(event, { userSource, clinicSlug, ...data }, email);
      },
    });
  }, [email, clinicInfo]);

  if (!apiKey) {
    return <>{children}</>;
  }

  return (
    <AnalyticsContext.Provider value={analyticsWrapper}>
      {children}
    </AnalyticsContext.Provider>
  );
};
