import React, { ReactNode, useMemo } from 'react';
import {
  Dialog,
  useMediaQuery,
  useTheme,
  DialogTitle,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { CloseDialogIconButton, DialogContent, Paper } from './styles';
import { H3 } from '@tuunetech/tuune-components';

type InfoModalProps = {
  title?: string | ReactNode;
  isOpen: boolean;
  handleClose: () => void;
};

export const InfoModal: React.FC<InfoModalProps> = ({
  title,
  isOpen,
  handleClose,
  children,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const dialogTitle = useMemo(() => {
    return typeof title === 'string' ? (
      <H3 $marginTop={24} $marginBottom={24}>
        {title}
      </H3>
    ) : (
      title
    );
  }, [title]);

  return (
    <Dialog
      open={isOpen}
      PaperComponent={Paper}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      scroll="paper"
      onClose={handleClose}
    >
      <DialogTitle disableTypography>
        {!!title && dialogTitle}
        <CloseDialogIconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseDialogIconButton>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
