import React from 'react';
import { Button } from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import type { QuestionProps } from '../../containers/HAFeedbackModule/questions';
import { QuestionsDataKeys } from '../../containers/HAFeedbackModule/questions';

export const HasAssurance: React.FC<QuestionProps> = ({ handleUpdateData }) => {
  const handleAnswer = (answer: boolean) => () => {
    handleUpdateData({ [QuestionsDataKeys.userHasInsurance]: answer });
  };

  return (
    <Box display="flex" justifyContent="center">
      <Button color="white" onClick={handleAnswer(true)}>
        <ThumbUp />
      </Button>
      <Box width="8px" />
      <Button color="white" onClick={handleAnswer(false)}>
        <ThumbDown />
      </Button>
    </Box>
  );
};
