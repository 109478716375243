import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { CheckoutReducerState } from '../types';

const selectCheckout: Selector<RootState, CheckoutReducerState> = (state) =>
  state.checkout;

export const getStripeRedirectUrl = createSelector(
  selectCheckout,
  (state) => state.stripeRedirectUrl,
);

export const getPaidStatus = createSelector(
  selectCheckout,
  (state) => state.paid,
);

export const getPrescriptionOrderUuid = createSelector(
  selectCheckout,
  (state) => state.prescriptionOrderUuid,
);

export const getIsResearchAutoApproved = createSelector(
  selectCheckout,
  (state) => state.isResearchAutoApproved,
);

export const getSignupOrderUuid = createSelector(
  selectCheckout,
  (state) => state.signupOrderUuid,
);

export const getPharmacyDetails = createSelector(
  selectCheckout,
  (state) => state.pharmacyDetails,
);
