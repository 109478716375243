import React, { useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnswerComponentProps, PillOption } from '../../types';
import { PillAnswer } from '../../components/Answers';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { getAnswers, getCurrent } from 'modules/Questionnaire/selectors';
import { getCurrentQuestionRequest } from 'modules/Questionnaire/actions';
import { currentContraceptionFactSlugs } from '../../constants';

export type SkipChoice = 'unknown' | 'unlisted';

export type PillFormData = { pill: string };

const PillContainer: React.FC<AnswerComponentProps> = ({
  answers,
  previousAnswers,
  handleSubmitAnswer,
  handleRequestNext,
}) => {
  const dispatch = useDispatch();
  const pills = answers[0].answers;
  const factId = answers[0].id;
  const prevAnswerId = previousAnswers[factId];
  const prevAnswer = pills.find((pill: PillOption) => pill.id === prevAnswerId);
  const analytics = useAnalytics();
  const questionId = useSelector(getCurrent);
  const facts = useSelector(getAnswers);

  // for previous contraception slug property will contain "prev" part that will indicate it not being current
  // current contraception slug is listed in contraceptionType
  const isCurrentContraception = facts.some(({ slug }) =>
    currentContraceptionFactSlugs.includes(slug),
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<PillFormData>({
    mode: 'onTouched',
    resolver: yupResolver(
      yup.object().shape({
        pill: yup.string().required(),
      }),
    ),
  });

  const onSubmit = useCallback(
    (data) => {
      if (data.pill !== prevAnswer?.name) {
        const answer = (pills as { name: string; id: number }[]).find(
          ({ name }) => name === data.pill,
        )?.id;
        if (answer) {
          handleSubmitAnswer([
            {
              factId,
              answer,
            },
          ]);
        }
      } else {
        handleRequestNext();
      }
    },
    [factId, handleRequestNext, handleSubmitAnswer, pills, prevAnswer],
  );

  const handleSkip = (choice: SkipChoice) => {
    const event = {
      unknown: ANALYTICS_EVENT.PILL_UNKNOWN,
      unlisted: ANALYTICS_EVENT.PILL_NOT_LISTED,
    }[choice];
    analytics.track(event, {
      category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
    });
    // If the question is current pill we send them to the next question
    // If not, we want to send them back to the previous where they can change their answer
    if (isCurrentContraception) {
      handleRequestNext();
    } else {
      dispatch(getCurrentQuestionRequest((questionId as number) - 1));
    }
  };

  return (
    <PillAnswer
      pills={pills}
      current={isCurrentContraception}
      handleSubmit={handleSubmit(onSubmit)}
      handleSkip={handleSkip}
      control={control}
      errors={errors}
      defaultValue={prevAnswer?.name}
    />
  );
};

export default PillContainer;
