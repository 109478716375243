import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoading } from 'modules/Loading/selectors';
import { getEntries } from 'modules/Contentful/actions';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { Assessment } from 'shared/Assessment/types/state';

export const SYMPTOM_SECTION_STATIC_DATA_SLUGS = {
  HORMONE_CONDITION_TITLE: 'assessment_screen_hormone_conditions_title',
  HORMONE_CONDITION_CONTENT: 'assessment_screen_hormone_conditions_content',
  HORMONE_SYMPTOMS_TITLE: 'assessment_screen_hormone_symptoms_title',
  HORMONE_SYMPTOMS_CONTENT: 'assessment_screen_hormone_symptoms_content',
  UNKNOWN_SYMPTOMS_TITLE: 'assessment_screen_unknown_symptoms_title',
  UNKNOWN_SYMPTOMS_CONTENT: 'assessment_screen_unknown_symptoms_content',
};

// SymptomsContentfulKeys
export const SCK = {
  [CTN.ISSUE_SNIPPET_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.ISSUE_SNIPPET_GENERIC,
  ),
  // [CTN.IMPACT_SCORE]: keyGen.getKey(CONTENT_TYPE.IMPACT_SCORE),
  [CTN.ISSUE_ICON]: keyGen.getKey(CONTENT_TYPE.ISSUE_ICON),
  [CTN.STATIC_CONTENT]: keyGen.getKey(CONTENT_TYPE.STATIC_CONTENT),
};

export const useLoadSymptoms = (
  assessmentData: {
    conditions: Assessment[];
    symptomsHormonal: Assessment[];
    symptomsUnknown: Assessment[];
  },
  isAssessmentLoading: 0 | 1 | undefined,
): boolean => {
  const dispatch = useDispatch();

  const isStaticDataLoading = useSelector(
    getIsLoading(SCK[CTN.STATIC_CONTENT].loadingId),
  );
  const isIssueSnippetGenericLoading = useSelector(
    getIsLoading(SCK[CTN.ISSUE_SNIPPET_GENERIC].loadingId),
  );
  // const isImpactScoreLoading = useSelector(
  //   getIsLoading(SCK[CTN.IMPACT_SCORE].loadingId),
  // );
  const isIssueIconsLoading = useSelector(
    getIsLoading(SCK[CTN.ISSUE_ICON].loadingId),
  );

  useEffect(() => {
    // request static layout data
    if (isStaticDataLoading === undefined) {
      dispatch(
        getEntries({
          ...SCK[CTN.STATIC_CONTENT],
          slugs: Object.values(SYMPTOM_SECTION_STATIC_DATA_SLUGS),
        }),
      );
    }

    if (isAssessmentLoading === 0) {
      if (
        isIssueSnippetGenericLoading === undefined &&
        // isImpactScoreLoading === undefined &&
        isIssueIconsLoading === undefined
      ) {
        const issuesData = [
          ...assessmentData.conditions,
          ...assessmentData.symptomsHormonal,
          ...assessmentData.symptomsUnknown,
        ];

        const slugs: string[] = [];
        // const impactSlugs = new Set<string>();
        issuesData.forEach(({ slug }) => {
          slugs.push(slug);
        });

        // request impact score values and explained
        // dispatch(
        //   getEntries({
        //     ...SCK[CTN.IMPACT_SCORE],
        //     domain,
        //     slugs: Array.from(impactSlugs),
        //   }),
        // );

        // request generic snippets for symptoms
        dispatch(
          getEntries({
            ...SCK[CTN.ISSUE_SNIPPET_GENERIC],
            slugs: slugs,
          }),
        );
        // request icons
        dispatch(
          getEntries({
            ...SCK[CTN.ISSUE_ICON],
            slugs: slugs,
          }),
        );
      }
    }
  }, [
    isAssessmentLoading,
    dispatch,
    isIssueIconsLoading,
    isIssueSnippetGenericLoading,
    isStaticDataLoading,
    assessmentData.conditions,
    assessmentData.symptomsHormonal,
    assessmentData.symptomsUnknown,
  ]);

  return (
    isIssueSnippetGenericLoading === 0 && isIssueIconsLoading === 0
    // && isImpactScoreLoading === 0
  );
};
