import React, { useCallback } from 'react';
import { BODY1, ContentBlock } from '@tuunetech/tuune-components';
import { FeedbackIcon } from './styles';

export const FLAG_TYPES = {
  RED: 'RED',
  ORANGE: 'ORANGE',
  GREEN: 'GREEN',
  MEC_RED: 'MEC_RED',
};

type FlagProps = {
  info: string;
  type: string;
};

const Flag: React.FC<FlagProps> = ({ info, type }) => {
  const renderFlagIcon = useCallback(() => {
    return <FeedbackIcon $type={type} />;
  }, [type]);

  return (
    <>
      <ContentBlock
        content={<BODY1 align="left">{info}</BODY1>}
        startAdornment={renderFlagIcon()}
      />
    </>
  );
};

export default Flag;
