import { MouseEventHandler, useCallback } from 'react';
import Routes from 'routes';
import { logout } from 'modules/Auth/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export const useBaseNavButtons = (): Record<
  'contactUs' | 'account' | 'logout',
  // TODO: export AppBarButton type from tuune-components and use it here
  // AppBarButton,
  { title: string; onClick: MouseEventHandler<HTMLButtonElement> }
> & { goBack: () => void } => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleContactUs = () => {
    window.open('https://www.tuune.com/contact', '_blank');
  };

  const handleOpenAccount = useCallback(() => {
    history.push(Routes.ACCOUNT);
  }, [history]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return {
    contactUs: {
      title: 'Contact us',
      onClick: handleContactUs,
    },
    account: {
      title: 'My account',
      onClick: handleOpenAccount,
    },
    logout: {
      title: 'Sign out',
      onClick: handleLogout,
    },
    goBack: handleGoBack,
  };
};
