import amplitude, { Config } from 'amplitude-js';
import { ANALYTICS_EVENT } from './constants';
import { AnalyticsTrackData, AnalyticsUserData } from './types';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    analyticsInitialized: any;
    /* eslint-enable */
  }
}

export const initAnalytics = (apiKey: string, config: Config): void => {
  amplitude.getInstance().init(apiKey, '', config);
  window.analyticsInitialized = true;
};

export const logAmplitudeEvent = (
  event: ANALYTICS_EVENT,
  data: AnalyticsTrackData,
): void => {
  amplitude.getInstance().logEvent(event, { property: 'app', ...data });
};

export const amplitudeIdentify = (
  userId?: string | null,
  data?: AnalyticsUserData,
): void => {
  if (userId) {
    amplitude.getInstance().setUserId(userId);
  }
  if (data) {
    amplitude.getInstance().setUserProperties(data);
  }
};

export const amplitudeLogout = (): void => {
  amplitude.getInstance().setUserId(null);
  amplitude.getInstance().regenerateDeviceId();
};
