import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';
import type { QuestionProps } from '../../containers/HAFeedbackModule/questions';
import { QuestionsDataKeys } from '../../containers/HAFeedbackModule/questions';

const SERVICES = [
  'Ask Tia',
  'Maven',
  'Parsley Health',
  'Kindbody',
  'One Medical',
  'Hello Alpha',
  'Talkspace',
  'Cerebral',
  'NURX',
  'Hers',
  'Other',
  'None',
];

const Chip = styled(Button)`
  letter-spacing: normal;
  text-transform: none;
  margin: 0 4px;
`;

export const HealthcareServices: React.FC<QuestionProps> = ({
  currentData,
  handleUpdateData,
}) => {
  const handleSelect = useCallback(
    (e) => {
      const chipName = e.target.innerText;
      const updatedChips = new Set(currentData as Set<string> | undefined);
      if (updatedChips.has(chipName)) {
        updatedChips.delete(chipName);
      } else {
        updatedChips.add(chipName);
      }
      handleUpdateData({
        [QuestionsDataKeys.healthcareServices]: updatedChips,
      });
    },
    [currentData, handleUpdateData],
  );

  return (
    <Box
      display="flex"
      gridRowGap="8px"
      flexWrap="wrap"
      justifyContent="center"
      maxWidth="100%"
      width="500px"
    >
      {SERVICES.map((service) => (
        <Chip
          size="small"
          key={service}
          color="white"
          variant={
            currentData
              ? (currentData as Set<string>).has(service)
                ? 'contained'
                : 'outlined'
              : 'outlined'
          }
          onClick={handleSelect}
        >
          {service}
        </Chip>
      ))}
    </Box>
  );
};
