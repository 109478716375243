import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@tuunetech/tuune-components';
import { GlobalStyle } from './theme/theme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './reduxStore';
import CookieWrapper from 'utils/cookies/CookieWrapper';
import 'utils/sentry';
import { ImageProvider } from 'utils/image';

import 'external-svg-loader';
import { ClinicsConfigProvider } from 'utils/clinics/provider';

ReactDOM.render(
  <Provider store={store}>
    <ClinicsConfigProvider>
      <ThemeProvider>
        <CookieWrapper>
          <ImageProvider>
            <App />
            <GlobalStyle />
          </ImageProvider>
        </CookieWrapper>
      </ThemeProvider>
    </ClinicsConfigProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
