import React, { ReactNode } from 'react';
import { Banner } from './styles';
import { ReportContentWrapper } from '../../ReportContentWrapper';

type StatusBannerProps = {
  isWarning?: boolean;
  content: ReactNode;
};

export const StatusBanner: React.FC<StatusBannerProps> = ({
  isWarning = false,
  content,
}) => {
  return (
    <Banner $isWarning={isWarning}>
      <ReportContentWrapper $verticalMargin={24}>
        {content}
      </ReportContentWrapper>
    </Banner>
  );
};
