import React, { ReactNode } from 'react';
import { BODY2, Button } from '@tuunetech/tuune-components';
import { Snackbar } from '@material-ui/core';
import { Content } from './styles';

export interface NotificationPopupProps {
  isOpen: boolean;
  handleDismiss: () => void;
  notificationMessage?: string;
  action?: ReactNode;
  color: 'primary' | 'error';
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  handleDismiss,
  isOpen,
  notificationMessage,
  action,
  color,
}) => (
  <Snackbar
    open={isOpen}
    autoHideDuration={4000}
    onClose={handleDismiss}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Content
      message={<BODY2>{notificationMessage}</BODY2>}
      $color={color}
      action={
        action || (
          <Button variant="text" color="inherit" dense onClick={handleDismiss}>
            dismiss
          </Button>
        )
      }
    />
  </Snackbar>
);

export default NotificationPopup;
