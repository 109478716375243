import styled from 'styled-components';
import { Paper as BasePaper, IconButton } from '@material-ui/core';

export const Paper = styled(BasePaper)`
  padding: 16px;

  .MuiDialogContent-root {
    margin: 0;
    padding: 0;
  }

  border-radius: 32px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 32px;
  }
`;

export const CloseDialogIconButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const BannerImg = styled.img`
  width: 200px;
`;
