import React, { ReactNode } from 'react';
import { AnswerTypesMap } from 'modules/Questionnaire/constants';
import { HAGoBackContext } from 'modules/Questionnaire/constants';
import { Page, ProgressBar } from 'shared';
import QuestionTitle from '../QuestionTitle';
import HelperBox, { HelpLabel } from '../HelperBox';
import AnswerContainer from '../../containers/AnswerContainer';
import { QuestionnaireReducerState, Section } from '../../types';
import TransitionAnimation from '../TransitionAnimation';
import { QuestionContainer } from './styles';
import { QuestionnaireHeader } from '../QuestionnaireHeader';
import ContentfulHeaderSectionContainer from 'modules/Questionnaire/containers/Answers/ContentfulHeaderSectionContainer';

export interface QuestionLayoutProps {
  removeAuthButton?: boolean;
  handleGoBack?: () => void;
  isGoBackHidden?: boolean;
  questionData: Partial<QuestionnaireReducerState>;
  progress?: {
    current: number;
    currentSection: Section;
    sections: Section[];
  };
  animation?: string;
  customAnswer?: ReactNode;
  handleToggleHelperBox?: (isOpen: boolean) => void;
  isHelperBoxOpen?: boolean;
}

const QuestionLayout: React.FC<QuestionLayoutProps> = ({
  removeAuthButton,
  handleGoBack,
  isGoBackHidden = true,
  questionData,
  progress,
  animation,
  customAnswer: CustomAnswer,
  handleToggleHelperBox,
  isHelperBoxOpen,
}) => {
  const getQuestionTitle = () => {
    if (!questionData.questionTitle) {
      return null;
    }

    if (
      questionData.questionType === AnswerTypesMap.CONTENTFUL_HEADER_SECTION
    ) {
      return (
        <ContentfulHeaderSectionContainer
          contentfulSlug={questionData.questionTitle}
        />
      );
    } else {
      return (
        <QuestionTitle
          title={questionData.questionTitle}
          subTitle={questionData.subTitle}
          helpLabel={
            questionData.helpText ? (
              <HelpLabel
                label={/* questionData.helpLabel */ undefined}
                handleToggleHelperBox={handleToggleHelperBox}
              />
            ) : undefined
          }
        />
      );
    }
  };

  return (
    <Page>
      <QuestionnaireHeader
        removeAuthButton={removeAuthButton}
        additionalNodes={
          progress && progress?.currentSection?.name ? (
            <ProgressBar {...progress} />
          ) : null
        }
      />
      <HelperBox
        text={questionData.helpText}
        isOpen={isHelperBoxOpen}
        handleToggleHelperBox={handleToggleHelperBox}
      />
      <TransitionAnimation
        animation={animation}
        duration={500}
        key={questionData.current}
      >
        <QuestionContainer
          maxWidth="sm"
          $alignVertical={questionData.questionType === AnswerTypesMap.INFO}
        >
          {getQuestionTitle()}
          <HAGoBackContext.Provider
            value={isGoBackHidden ? undefined : handleGoBack}
          >
            <div>{CustomAnswer ? CustomAnswer : <AnswerContainer />}</div>
          </HAGoBackContext.Provider>
        </QuestionContainer>
      </TransitionAnimation>
    </Page>
  );
};

export default QuestionLayout;
