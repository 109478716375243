import React, { useCallback } from 'react';
import {
  validateDaysInDate,
  validateIsPastDate,
  validateMonthInDate,
  validateYearInDate,
} from 'utils/helpers';
import { DateAnswer } from 'modules/Questionnaire/components/Answers';
import {
  DateAnswerFormErrors,
  DateAnswerFormValues,
} from 'modules/Questionnaire/types';
import { parseDate, getEditableFields } from '../../helpers';
import { AnswerComponentProps } from '../../types';

const DateContainer: React.FC<
  AnswerComponentProps & {
    additValidate?: (date: DateAnswerFormValues) => void;
  }
> = ({
  additValidate,
  answers,
  previousAnswers,
  handleSubmitAnswer,
  handleRequestNext,
  answerType,
}) => {
  const factId = answers[0].id;
  const previousDate = previousAnswers[factId];
  const initialValues = parseDate(previousDate as string, answerType);
  const editableFields = getEditableFields(answerType);

  const validate = useCallback(
    ({
      day = '',
      month = '',
      year = '',
    }: DateAnswerFormValues): DateAnswerFormErrors => {
      const errors: DateAnswerFormErrors = {};

      const yearError = validateYearInDate(year);
      yearError && (errors.year = yearError);

      const futureError = validateIsPastDate(day, month, year);
      futureError && (errors.year = futureError);

      const monthError = validateMonthInDate(month);
      monthError && (errors.month = monthError);

      const dayError = validateDaysInDate(day, month, year);
      dayError && (errors.day = dayError);

      const additionalError =
        additValidate && additValidate({ day, month, year });
      additionalError && (errors.year = additionalError);

      return errors;
    },
    [additValidate],
  );

  const handleSubmit = useCallback(
    ({ day, month, year }) => {
      /* TODO: remove undefined values if one of the fields (e.g. day)
       * by configuration is absent
       */
      // TODO: maybe move this logic to helpers
      const date = [year, month, day].join('-');

      handleSubmitAnswer([{ factId, answer: date }]);
    },
    [factId, handleSubmitAnswer],
  );

  return (
    <DateAnswer
      validate={validate}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleRequestNext={handleRequestNext}
      editableFields={editableFields}
    />
  );
};

export default DateContainer;
