import styled from 'styled-components';
import {
  Paper as BasePaper,
  IconButton,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';

export const Paper = styled(BasePaper)`
  border-radius: 0;
  padding: 16px;

  .MuiDialogContent-root {
    margin: 0;
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 32px;
    border-radius: 32px;
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CloseDialogIconButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: ${({ theme }) => theme.palette.common.black};
`;
