import React, { ChangeEvent } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';
import { BODY1, Select } from '@tuunetech/tuune-components';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { US_STATES } from 'utils/usStates';

export interface StateSelectProps {
  control: Control<Partial<AccountInfoFormData>>;
  errors: FieldErrors<Partial<AccountInfoFormData>>;
  onStateSelected?: (
    event: ChangeEvent<{ name?: string; value: unknown }>,
  ) => void;
}

export const StateSelect: React.FC<StateSelectProps> = ({
  errors,
  control,
  onStateSelected,
}) => {
  const renderStateSelectValue = (selected: unknown) => {
    if (!selected) {
      return <BODY1 color="textSecondary">Select State</BODY1>;
    } else {
      return <BODY1>{selected}</BODY1>;
    }
  };

  return (
    <>
      <Controller
        name="state"
        control={control}
        defaultValue=""
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Select
              fullWidth
              variant="outlined"
              displayEmpty
              renderValue={renderStateSelectValue}
              onChange={(e, newValue) => {
                onChange(e, newValue);
                if (onStateSelected) {
                  onStateSelected(e);
                }
              }}
              onBlur={onBlur}
              value={value}
              helperText={errors.state?.message}
              error={!!errors.state}
            >
              {US_STATES?.map(({ abbreviation }) => (
                <MenuItem key={abbreviation} value={abbreviation}>
                  {abbreviation}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </>
  );
};
