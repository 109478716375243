import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

export const AppointmentDatePicker = styled(DatePicker)`
  .MuiOutlinedInput-root {
    border-radius: 10px;
    height: 60px;
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }

  .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
