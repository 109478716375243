import { ActionCreator } from 'redux';
import {
  SET_REPORT_FEEDBACK_SUBMITTED,
  SUBMIT_HA_USER_FEEDBACK_REQUEST,
} from '../constants';
import { PreviewActionTypes } from '../types';
import { AppThunk } from 'reduxStore';
import { getFromStorage, setToStorage } from 'utils/helpers';
import { QuestionnaireActionTypes } from 'modules/Questionnaire/types';

export const submitHAFeedbackRequest: ActionCreator<PreviewActionTypes> = (
  tags?: string[],
  text?: string,
) => ({
  type: SUBMIT_HA_USER_FEEDBACK_REQUEST,
  payload: {
    tags: tags,
    text: text,
  },
});

export const setReportFeedbackSubmitted: ActionCreator<
  QuestionnaireActionTypes
> = (feedbackOrigin: string, isSubmitted: boolean | null | 'undefined') => ({
  type: SET_REPORT_FEEDBACK_SUBMITTED,
  payload: { feedbackOrigin, isReportFeedbackSubmitted: isSubmitted },
});

export const getReportFeedbackSubmittedFromStorage =
  (feedbackOrigin: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const isReportFeedbackSubmitted = await getFromStorage(
      `reportFeedbackSubmitted_${feedbackOrigin}`,
    );
    // if there is no record in localStorage, create and set it to false
    if (isReportFeedbackSubmitted === null) {
      dispatch(setReportFeedbackSubmittedToStorage(feedbackOrigin, false));
      dispatch(setReportFeedbackSubmitted(feedbackOrigin, false));
    } else {
      dispatch(
        setReportFeedbackSubmitted(feedbackOrigin, isReportFeedbackSubmitted),
      );
    }
  };

export const setReportFeedbackSubmittedToStorage =
  (
    feedbackOrigin: string,
    isSubmitted: boolean | null | 'undefined',
  ): AppThunk =>
  async (): Promise<void> => {
    await setToStorage(
      `reportFeedbackSubmitted_${feedbackOrigin}`,
      isSubmitted,
    );
  };
