import React from 'react';
import {
  Container,
  GridContainer,
  GridItem,
  AppHeader,
  BODY2,
  Divider,
} from '@tuunetech/tuune-components';
import { Box, Link } from '@material-ui/core';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import {
  MarketingPreferenceContainer,
  PersonalInfoContainer,
} from '../../containers';
import { Page } from 'shared';
import { DeleteAccountButton } from '../DeleteAccountButton';

export const AccountLayout: React.FC = () => {
  const { contactUs, account, logout, goBack } = useBaseNavButtons();

  return (
    <Page>
      <AppHeader
        pageTitle="My account"
        handleGoBack={goBack}
        buttons={[contactUs, account, logout]}
      />
      <Container>
        <GridContainer alignItems="center" justify="center">
          <GridItem xs={12} md={6} lg={4}>
            <Box display="flex" flexDirection="column">
              <PersonalInfoContainer />
              <Box mt={3}>
                <MarketingPreferenceContainer />
              </Box>
              <Box my={3}>
                <BODY2 color="textSecondary">
                  If you need to change your password please contact us at{' '}
                  <Link
                    href="mailto:help@tuune.com"
                    target="_blank"
                    underline="none"
                  >
                    help@tuune.com
                  </Link>
                  .
                </BODY2>
              </Box>
              <Divider />
              <DeleteAccountButton />
            </Box>
          </GridItem>
        </GridContainer>
      </Container>
    </Page>
  );
};
