import React from 'react';
import {
  BODY1,
  BODY2,
  Container,
  ContentBlock,
  H4,
} from '@tuunetech/tuune-components';

import { CalendarDate } from 'assets/icons';
import { getDateShortMonth } from 'utils/helpers/dateFormatting';

const frontEndVersion = process.env.REACT_APP_BUILD_VERSION || 'V.dev';

export const Version: React.FC<{
  version: string | Record<string, string>;
  generationDate: Date | undefined;
}> = ({ version, generationDate }) => {
  return (
    <>
      <H4>About your report:</H4>

      {generationDate && (
        <Container $marginTop={40} $marginBottom={40} $paddingLeft={0}>
          <ContentBlock
            startAdornment={<CalendarDate />}
            content={
              <BODY1>
                This report was generated on {getDateShortMonth(generationDate)}
                -{generationDate.getDate()}-{generationDate.getFullYear()}.
              </BODY1>
            }
          ></ContentBlock>
        </Container>
      )}

      {typeof version === 'string' ? (
        <BODY1 color="textSecondary">Version {version}</BODY1>
      ) : (
        <>
          <BODY1 color="textSecondary" $marginBottom={8}>
            Versions:
          </BODY1>
          {Object.keys(version).map((key) => (
            <BODY2
              key={key}
              color="textSecondary"
              $marginLeft={10}
              $marginTop={5}
            >
              {key}: {version[key]}
            </BODY2>
          ))}
          <BODY2 color="textSecondary" $marginLeft={10} $marginTop={5}>
            Frontend Version: {frontEndVersion}
          </BODY2>
        </>
      )}
    </>
  );
};
