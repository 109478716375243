import styled from 'styled-components';
import {
  DialogActions as MuiDialogActions,
  Dialog,
  IconButton,
  Paper as MuiPaper,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import { BaseLink, Container, ContentBlock } from '@tuunetech/tuune-components';

export const DialogActions = styled(MuiDialogActions)`
  justify-content: center;
`;

export const RecommendationDetailsDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 32px;
  }
`;

export const CloseDialogIconButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const Paper = styled(MuiPaper)`
  padding-left: 0;
  padding-right: 0;
`;

export const DialogTitle = styled(MuiDialogTitle)`
  margin-bottom: 0;
`;

export const DialogContent = styled(MuiDialogContent)`
  margin-bottom: 16px;
`;

export const GenericsLink = styled(BaseLink)`
  color: ${({ theme }) => theme.palette.common.black};
  margin-left: 5px;
`;

export const SubtitleContainer = styled(Container)`
  padding: 0;
  display: flex;
  flex-direction: row;
`;

export const IngredientCaptionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export const NextStepsContainer = styled.div`
  margin-top: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.common.purple[50]};
`;

export const NextStepContentBlock = styled(ContentBlock)`
  align-items: center;
`;
