import styled from 'styled-components';
import {
  BODY1,
  Button,
  Container,
  GridItem,
} from '@tuunetech/tuune-components';

export const StartContainer = styled(Container)`
  img {
    width: 100%;
    border-radius: 40px;
  }

  ${BODY1}, img {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }

  ${Button} {
    margin-top: 24px;
  }
`;

export const ItemContainer = styled(GridItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
