import React from 'react';
import { Input } from '@tuunetech/tuune-components';
import { DateAnswerFormValues } from 'modules/Questionnaire/types';
import { FormikTouched, FormikErrors } from 'formik';

type DateInputProps = {
  values: DateAnswerFormValues;
  touched: FormikTouched<DateAnswerFormValues>;
  errors: FormikErrors<DateAnswerFormValues>;
  handleChange: React.ChangeEventHandler;
  handleBlur: React.ChangeEventHandler;
  fieldName: 'day' | 'month' | 'year';
  placeHolder: string;
  dateProps: Record<string, number>;
};

export const DateInputs: React.FC<DateInputProps> = ({ ...props }) => {
  const {
    fieldName,
    placeHolder,
    dateProps,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = props;

  return (
    <Input
      name={fieldName}
      placeholder={placeHolder}
      type="number"
      inputProps={dateProps}
      fullWidth
      value={values?.[fieldName]}
      error={touched[fieldName] && !!errors[fieldName]}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default DateInputs;
