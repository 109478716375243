import React from 'react';
import { Box } from '@material-ui/core';
import { BODY2, ContentBlock, H3 } from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import {
  BannerImg,
  ClinicalReviewInfoContainer,
  HelpCircleIcon,
} from './styles';

import { InfoModal } from 'shared/InfoModal';

type TalkToObgynModalProps = {
  clinicalReviewInfoText: string;
  isOpen: boolean;
  handleClose: () => void;
};

export const TalkToObgynModal: React.FC<TalkToObgynModalProps> = ({
  clinicalReviewInfoText,
  isOpen,
  handleClose,
}) => {
  const [clinicalReviewRequiredImg] = useWebpImage(
    'clinical_review_required.png',
  );

  return (
    <InfoModal isOpen={isOpen} handleClose={handleClose}>
      <Box
        margin="24px 0px"
        display="flex"
        flexDirection="column"
        gridRowGap="24px"
      >
        <BannerImg src={clinicalReviewRequiredImg} alt="" />
        <H3>Please talk to your OBGYN</H3>
        <BODY2>
          When a patient has a condition for which{' '}
          <b>
            the theoretical or proven risks usually outweigh the advantages of
            using the method
          </b>
          , the CDC Medical eligibility criteria requires you to speak to an
          OBGYN before receiving a prescription.
        </BODY2>
        <BODY2>
          You can find the specific reasons we recommend that you discuss this
          birth control method with an OBGYN under this section of the method
          card on your report:
        </BODY2>
        <ClinicalReviewInfoContainer>
          <ContentBlock
            content={<BODY2 align="left">{clinicalReviewInfoText}</BODY2>}
            startAdornment={<HelpCircleIcon />}
          />
        </ClinicalReviewInfoContainer>
        <BODY2>
          Unfortunately, we&#39;re not able to put you in contact with an OBGYN
          at this time. We&#39;d recommend you contact your OBGYN and feel free
          to share your Tuune results with them.
        </BODY2>
        <BODY2>
          If you plan to have a virtual or telephone visit with your OBGYN,
          please note that you will need to check your blood pressure before
          they can prescribe this method to you.
        </BODY2>
      </Box>
    </InfoModal>
  );
};
