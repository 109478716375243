import React, { useCallback, ReactNode, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMediaQuery,
  useTheme,
  Popover,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  H4,
  CAPTION,
  Container,
  Divider,
  Button,
  BODY2,
  H5,
} from '@tuunetech/tuune-components';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useAnalytics } from 'utils/analytics';
import { getHasAnyErrorMessages } from 'modules/NotificationsHandler/selectors';
import { getClinicInfo } from 'modules/Account/selectors';
import { Link } from 'shared';
import {
  ANALYTICS_CATEGORY,
  ANALYTICS_EVENT,
  CTA_POSITION,
  CTA_TEXT,
} from 'utils/analytics/constants';
import RecommendationCardPanel from '../RecommendationCardPanel';
import { RegimenInfoContainer } from 'modules/Results/containers/RegimenInfoContainer';
import { SwitchingPillInfoContainer } from 'modules/Results/containers/SwitchingPillInfoContainer';
import { setRegimenCurrent } from 'modules/Results/actions';
import { getPrescriptionOrderUuid } from 'modules/Checkout/selectors';
import {
  DialogActions,
  RecommendationDetailsDialog,
  CloseDialogIconButton,
  Paper,
  DialogTitle,
  DialogContent,
  GenericsLink,
  SubtitleContainer,
  IngredientCaptionContainer,
  NextStepsContainer,
  NextStepContentBlock,
} from './styles';
import { ContraceptiveEligibility, ContraceptiveMethod } from '../../types';
import { NEXT_STEPS_DATA } from './data';
import { CONTRACEPTIVE_TYPES_FOR_PRESCRIPTION } from 'modules/Results/constants';
import {
  getClinicalReviewInfoText,
  getPrescriptionCtaText,
} from 'modules/Results/helpers';
import { TalkToObgynModal } from '../TalkToObgynModal';
import { BPCheckModal } from '../BPCheckModal';
import { useHistory } from 'react-router';
import Routes from 'routes';

export type RecommendationDetailsProps = {
  name: string;
  ingredients?: string[];
  slug: string;
  generics?: string[];
  considerationSummary: ReactNode;
  pillRegimenSummary: ReactNode;
  switchingPillInfoSummary: ReactNode;
  type: ContraceptiveMethod;
  isOpen: boolean;
  onClose: () => void;
  id: number;
  eligibility: ContraceptiveEligibility;
  informationRequired: string[];
};

const RecommendationDetails: React.FC<RecommendationDetailsProps> = ({
  name,
  ingredients,
  slug,
  generics,
  considerationSummary,
  pillRegimenSummary,
  switchingPillInfoSummary,
  type,
  isOpen,
  onClose,
  id,
  eligibility,
  informationRequired,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = useAnalytics();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const prescriptionOrderUuid = useSelector(getPrescriptionOrderUuid);
  const hasServerErrors = useSelector(getHasAnyErrorMessages);
  const clinicInfo = useSelector(getClinicInfo);

  const [BPCheckModalOpen, setBPCheckModalOpen] = useState(false);
  const [talkToObgynModalOpen, setTalkToObgynModalOpen] = useState(false);
  const [isRegimenInfoOpen, setIsRegimenInfoOpen] = useState(false);
  const [isSwitchingPillInfoOpen, setIsSwitchingPillInfoOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleRegimenInfoOpen = useCallback(
    (vmpSlug) => {
      dispatch(setRegimenCurrent(vmpSlug));
      setIsRegimenInfoOpen(true);
    },
    [dispatch],
  );
  const handleRegimenInfoClose = useCallback(() => {
    setIsRegimenInfoOpen(false);
  }, []);

  const handleSwitchingPillInfoOpen = useCallback(() => {
    setIsSwitchingPillInfoOpen(true);
  }, []);
  const handleSwitchingPillInfoClose = useCallback(() => {
    setIsSwitchingPillInfoOpen(false);
  }, []);

  const onBpUpdateClick = useCallback(() => {
    window.open(
      `mailto:help@tuune.com?subject=Update blood pressure measurement reading&body=Hi Tuune team, I wanted to let you know I've taken a new blood pressure measurement reading on the (type here the date). My new blood pressure reading is: (type here your recent blood pressure measurement). Could you update this information in my Tuune account?`,
    );
    analytics.track(ANALYTICS_EVENT.CTA, {
      category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      cta: CTA_TEXT.UPDATE_BP_READING,
      ctaPosition: CTA_POSITION.RECOMMENDATION_MODAL,
    });
  }, [analytics]);

  const trackPanelClicked = useCallback(
    (eventName: ANALYTICS_EVENT) => (_event: Event, expanded: boolean) => {
      if (expanded) {
        analytics.track(eventName, {
          category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
          recommendationId: id,
          recommendationName: name,
          recommendationSlug: slug,
        });
      }
    },
    [analytics, id, name, slug],
  );

  useEffect(() => {
    if (hasServerErrors) {
      setIsLoading(false);
    }
  }, [hasServerErrors]);

  const prescriptionCheckout = useCallback(() => {
    if (eligibility === 'no') return;
    if (eligibility === 'specialist') {
      setTalkToObgynModalOpen(true);
    } else if (!!informationRequired.length) {
      setBPCheckModalOpen(true);
    } else {
      history.push(`${Routes.PHARMACY}/${id}`);
      setIsLoading(true);
    }

    analytics.track(ANALYTICS_EVENT.CTA, {
      category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      cta:
        eligibility === 'specialist'
          ? CTA_TEXT.MORE_INFO_TALK_TO_OBGYN
          : CTA_TEXT.ORDER_PRESCRIPTION,
      ctaPosition: CTA_POSITION.RECOMMENDATION_MODAL,
      ...(eligibility !== 'specialist' && {
        mecPermitted: eligibility,
        bpCheckRequired: !!informationRequired.length ? 'yes' : 'no',
      }),
    });
  }, [
    id,
    history,
    analytics,
    setTalkToObgynModalOpen,
    setBPCheckModalOpen,
    eligibility,
    informationRequired.length,
  ]);

  const [genericCaptionAnchorEl, setGenericCaptionAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setGenericCaptionAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setGenericCaptionAnchorEl(null);
  };
  const open = Boolean(genericCaptionAnchorEl);

  const genericsLinkText =
    generics?.length === 1
      ? `${generics?.length} generic version`
      : `${generics?.length} generic versions`;

  // For clinic flow method cannot be ordered through platform
  const methodCanBeOrdered =
    !clinicInfo &&
    CONTRACEPTIVE_TYPES_FOR_PRESCRIPTION.includes(type) &&
    eligibility !== 'no';

  const orderPrescriptionSection = (
    <>
      <Divider $marginBottom={16} />
      {eligibility === 'specialist' && (
        <Box marginBottom="10px" textAlign="center">
          <BODY2>You need to speak to a doctor to order this method.</BODY2>
        </Box>
      )}
      <Button
        autoFocus
        fullWidth
        onClick={prescriptionCheckout}
        disabled={!!prescriptionOrderUuid || isLoading}
        startIcon={
          isLoading ? <CircularProgress size={20} color="inherit" /> : null
        }
        color={eligibility === 'specialist' ? 'yellow' : 'primary'}
      >
        {getPrescriptionCtaText(eligibility)}
      </Button>
    </>
  );

  const BPCheckDialogBox = (
    <BPCheckModal
      handleSubmit={() => onBpUpdateClick()}
      handleClose={() => setBPCheckModalOpen(false)}
      isOpen={BPCheckModalOpen}
    />
  );

  const TalkToObgynDialogBox = (
    <TalkToObgynModal
      clinicalReviewInfoText={getClinicalReviewInfoText(
        eligibility,
        informationRequired,
      )}
      handleClose={() => setTalkToObgynModalOpen(false)}
      isOpen={talkToObgynModalOpen}
    />
  );

  return (
    <>
      {BPCheckDialogBox}
      {TalkToObgynDialogBox}
      <RegimenInfoContainer
        isRegimenInfoOpen={isRegimenInfoOpen}
        handleRegimenInfoOpen={handleRegimenInfoOpen}
        handleRegimenInfoClose={handleRegimenInfoClose}
      />
      <SwitchingPillInfoContainer
        isSwitchingPillInfoOpen={isSwitchingPillInfoOpen}
        handleSwitchingPillInfoOpen={handleSwitchingPillInfoOpen}
        handleSwitchingPillInfoClose={handleSwitchingPillInfoClose}
      />
      <RecommendationDetailsDialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        PaperProps={{ variant: 'outlined' }}
        PaperComponent={Paper}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <>
          <DialogTitle disableTypography id="alert-dialog-title">
            <Container>
              <CloseDialogIconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </CloseDialogIconButton>
              <H4>{name}</H4>
              <SubtitleContainer>
                <CAPTION>{`${type} •`}</CAPTION>
                <GenericsLink variant="caption" onClick={handleClick}>
                  {genericsLinkText}
                </GenericsLink>
                <Popover
                  open={open}
                  anchorEl={genericCaptionAnchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <CAPTION $paddingRight={10} $paddingLeft={10}>
                    {generics?.join(', ')}
                  </CAPTION>
                </Popover>
              </SubtitleContainer>
              {!!ingredients && (
                <IngredientCaptionContainer disableGutters>
                  <CAPTION>{ingredients[0]}</CAPTION>
                  <CAPTION>{ingredients[1]}</CAPTION>
                </IngredientCaptionContainer>
              )}
            </Container>
          </DialogTitle>
          <DialogContent>
            <Container disableGutters>
              {methodCanBeOrdered && (
                <NextStepsContainer>
                  <H5 $marginBottom={16}>
                    Have your prescription sent to your preferred pharmacy
                  </H5>
                  <Box display="flex" flexDirection="column" gridRowGap="16px">
                    {NEXT_STEPS_DATA.map((stepText: string, index) => (
                      <NextStepContentBlock
                        key={index}
                        startAdornment={<CheckIcon color="primary" />}
                        content={<BODY2>{stepText}</BODY2>}
                      />
                    ))}
                  </Box>
                </NextStepsContainer>
              )}
              <RecommendationCardPanel
                title="Side Effects & Considerations"
                onChange={trackPanelClicked(
                  ANALYTICS_EVENT.RECOMMENDATION_CONSIDERATIONS,
                )}
              >
                {considerationSummary}
              </RecommendationCardPanel>
              <Divider />
              <RecommendationCardPanel
                title="How should I use this method?"
                onChange={trackPanelClicked(
                  ANALYTICS_EVENT.RECOMMENDATION_HOW_TO_TAKE_PILL,
                )}
              >
                {pillRegimenSummary}
                <Button
                  color="grey"
                  fullWidth
                  onClick={() => handleRegimenInfoOpen(slug)}
                >
                  LEARN MORE
                </Button>
              </RecommendationCardPanel>
              <Divider />
              <RecommendationCardPanel
                title="How to start?"
                onChange={trackPanelClicked(
                  ANALYTICS_EVENT.RECOMMENDATION_HOW_TO_START,
                )}
              >
                {switchingPillInfoSummary}
                <Button
                  color="grey"
                  fullWidth
                  onClick={handleSwitchingPillInfoOpen}
                >
                  LEARN MORE
                </Button>
              </RecommendationCardPanel>
            </Container>
          </DialogContent>
        </>

        <DialogActions>
          <Container>
            {methodCanBeOrdered
              ? orderPrescriptionSection
              : !clinicInfo && (
                  <BODY2 color="textSecondary">
                    We aren&lsquo;t able to offer a prescription for this method
                    yet but if you&lsquo;d like to start on it contact us at{' '}
                    <Link href="mailto:help@tuune.com" target="_blank">
                      help@tuune.com
                    </Link>
                  </BODY2>
                )}
          </Container>
        </DialogActions>
      </RecommendationDetailsDialog>
    </>
  );
};

export default RecommendationDetails;
