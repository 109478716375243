import React, { useContext } from 'react';
import { Button, ButtonProps } from '@tuunetech/tuune-components';
import { useMediaQuery, useTheme, CircularProgress } from '@material-ui/core';
import { HAGoBackContext } from 'modules/Questionnaire/constants';
import { HAControlButton, Container } from './styles';

type HAControlButtonsProps = ButtonProps & {
  text?: string;
  isContinueVisible?: boolean;
  align?: 'left' | 'center' | 'right';
  isLoading?: boolean;
};

export const HAControlButtons: React.FC<HAControlButtonsProps> = ({
  text,
  color,
  onClick,
  disabled,
  align = 'center',
  isContinueVisible = true,
  isLoading = false,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const goBack = useContext(HAGoBackContext);

  return (
    <Container $align={align}>
      {!!goBack && (
        <Button
          variant="outlined"
          onClick={goBack}
          fullWidth={isMobile && !isContinueVisible}
        >
          back
        </Button>
      )}
      {isContinueVisible && (
        <HAControlButton
          color={color || 'primary'}
          disabled={disabled}
          onClick={onClick}
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
          {...props}
        >
          {text || 'Continue'}
        </HAControlButton>
      )}
    </Container>
  );
};
