import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Implant: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 29 29" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 2.83331H15.6667V5.16665C16.9553 5.16665 18 6.21132 18 7.49998V21.5C18 22.7886 16.9553 23.8333 15.6667 23.8333V26.1666H13.3333L13.3333 23.8333C12.0447 23.8333 11 22.7886 11 21.5V7.49998C11 6.21132 12.0447 5.16665 13.3333 5.16665V2.83331ZM15.6667 21.5V7.49998H13.3333L13.3333 21.5H15.6667Z"
    />
  </SvgIcon>
);
