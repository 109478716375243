import { ActionCreator } from 'redux';
import { DealbreakerActionTypes } from '../types';
import {
  GET_DEALBREAKER_ISSUES_REQUEST,
  SET_DEALBREAKER,
  SET_DEALBREAKER_ISSUES_RANK_REQUEST,
  SET_PAGE_NUM,
} from '../constants';

export const getDealbreakerIssues: ActionCreator<
  DealbreakerActionTypes
> = () => ({
  type: GET_DEALBREAKER_ISSUES_REQUEST,
});

export const setDealbreaker: ActionCreator<DealbreakerActionTypes> = (
  dealbreaker,
) => ({
  type: SET_DEALBREAKER,
  payload: {
    dealbreaker,
  },
});

export const setDealbreakerIssuesRank: ActionCreator<
  DealbreakerActionTypes
> = ({ dealbreakerIssue, issuesRank }) => ({
  type: SET_DEALBREAKER_ISSUES_RANK_REQUEST,
  payload: { dealbreakerIssue, issuesRank },
});

export const setPageNum: ActionCreator<DealbreakerActionTypes> = (pageNum) => ({
  type: SET_PAGE_NUM,
  payload: { pageNum },
});
