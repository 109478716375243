import { Reducer } from 'redux';
import { QuestionnaireReducerState } from '../types';
import {
  GET_CURRENT_QUESTION_SUCCESS,
  SET_MEASURE_SYSTEM,
  SUBMIT_QUESTION_SUCCESS,
  GET_PREV_QUESTION_SUCCESS,
  SET_GET_STARTED_QUESTION,
  GET_CURRENT_QUESTION_REQUEST,
  SUBMIT_QUESTION_REQUEST,
  GET_PREV_QUESTION_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SET_GET_STARTED_PRIVACY,
  SET_GET_STARTED_ACCOUNT_INFO,
  SUBMIT_QUESTION_FAIL,
  GET_FEEDBACK_SUCCESS,
  INVALIDATE_COMPLETION_SUCCESS,
  SET_GET_STARTED_MARKETING_AND_STATE_INFO,
  SET_GET_STARTED_OAUTH_PROVIDER,
  SET_GET_STARTED_SIGNUP_TYPE,
  CLINIC_SIGN_UP_GET_CLINICIANS_SUCCESS,
  SET_CLINIC_GET_STARTED_APPOINTMENT_DETAILS,
  SET_CLINIC_GET_STARTED_CLINIC_CONFIG,
} from '../constants';
import camelcaseKeys from 'camelcase-keys';

export const defaultState: QuestionnaireReducerState = {
  questionId: 1,
  totalCount: undefined,
  current: undefined,
  sectionName: '',
  questionTitle: '',
  subTitle: '',
  sectionId: 1,
  questionType: 'fact',
  helpText: '',
  factsSlugs: '',
  answers: [],
  notaAnswer: '',
  previousAnswers: {},
  measureSystem: undefined,
  isCompleted: undefined,
  passwordSet: false,
  getStarted: {
    currentQuestion: 0,
    privacyOptions: [],
    marketingOptions: new Set(),
    oAuthProvider: undefined,
    accountInfo: undefined,
    signupType: undefined,
    clinicDetails: undefined,
  },
  isQuestionLoading: false,
  feedbackSubmitted: false,
  feedback: undefined,

  currentSection: undefined,
  sections: [],
};

const questionnaireReducer: Reducer<QuestionnaireReducerState> = (
  state = defaultState,
  { type, payload, response: { data = {} } = {} },
) => {
  switch (type) {
    case GET_CURRENT_QUESTION_REQUEST:
    case SUBMIT_QUESTION_REQUEST:
    case GET_PREV_QUESTION_REQUEST:
      return {
        ...state,
        isQuestionLoading: true,
      };
    case GET_CURRENT_QUESTION_SUCCESS:
    case SUBMIT_QUESTION_SUCCESS:
    case GET_PREV_QUESTION_SUCCESS:
      if (data.status) {
        return {
          ...defaultState,
          isCompleted: true,
        };
      }
      return {
        ...state,
        questionId: data.id,
        totalCount: data.total,
        current: data.current_question,
        sectionName: data.section_name,
        questionTitle: data.text,
        subTitle: data.sub_text,
        sectionId: data.section_id,
        questionType: data.question_type,
        helpText: data.help_text,
        factsSlugs: data.facts_slugs,
        answers: data.facts,
        notaAnswer: data.nota_answer,
        previousAnswers: data.responses,
        isQuestionLoading: false,

        currentSection: data.current_section,
        sections: data.sections,
      };
    case SET_MEASURE_SYSTEM:
      return {
        ...state,
        measureSystem: payload.system,
      };
    case SET_GET_STARTED_ACCOUNT_INFO:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          accountInfo: {
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
          },
        },
      };
    case SET_GET_STARTED_MARKETING_AND_STATE_INFO:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          accountInfo: {
            ...state.getStarted.accountInfo,
            country: payload.country,
            state: payload.state,
            phone: payload.phone,
          },
          marketingOptions: payload.marketingOptions,
        },
      };
    case SET_GET_STARTED_PRIVACY:
      const newOptions = [
        ...state.getStarted.privacyOptions,
        ...payload.options,
      ].reduce((unique, item) => {
        return unique.includes(item) ? unique : [...unique, item];
      }, []);
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          privacyOptions: newOptions,
        },
      };
    case SET_GET_STARTED_OAUTH_PROVIDER:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          // Cleanup the previous email signup flow state
          marketingOptions: new Set(),
          accountInfo: undefined,
          oAuthProvider: payload.oAuthProvider,
        },
      };
    case SET_GET_STARTED_SIGNUP_TYPE:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          signupType: payload.signupType,
        },
      };
    case SET_GET_STARTED_QUESTION:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          currentQuestion: payload.question,
        },
      };
    case SET_CLINIC_GET_STARTED_CLINIC_CONFIG:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          clinicDetails: {
            ...state.getStarted.clinicDetails,
            slug: payload.clinicSlug,
            authConfig: payload.clinicConfig,
          },
        },
      };
    case SET_CLINIC_GET_STARTED_APPOINTMENT_DETAILS:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          clinicDetails: {
            ...state.getStarted.clinicDetails,
            clinicianId: payload.clinicianId,
            appointmentDate: payload.appointmentDate,
          },
        },
      };

    case CLINIC_SIGN_UP_GET_CLINICIANS_SUCCESS:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          clinicDetails: {
            ...state.getStarted.clinicDetails,
            // eslint-disable-next-line
            clinicians: data.map((clinician: any) =>
              camelcaseKeys(clinician, { deep: true }),
            ),
          },
        },
      };
    case SUBMIT_QUESTION_FAIL:
      return {
        ...state,
        isQuestionLoading: false,
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackSubmitted: true,
      };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: data[0]?.text,
      };
    case INVALIDATE_COMPLETION_SUCCESS:
      return {
        ...state,
        isCompleted: false,
      };
    default:
      return state;
  }
};

export { questionnaireReducer as questionnaire };
