import React from 'react';
import { AppHeader } from '@tuunetech/tuune-components';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { Page } from 'shared';
import { RejectedResults } from 'modules/Results/components/RejectedResults';

export const RejectedResultsPage: React.FC = () => {
  useReportStatusRouter();
  const headerButtons = useBaseNavButtons();

  return (
    <Page>
      <AppHeader
        position="fixed"
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />
      <RejectedResults />
    </Page>
  );
};
