import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Routes from 'routes';
import Login from '../components/Login';
import { loginRequest } from '../actions';
import { getIsAuthenticated } from '../selectors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormSchema } from '../constants/validation';
import { OAuthProvider } from '../constants/socialAuth';
import { useOAuthLoginRedirect } from 'utils/hooks/useOAuthLoginRedirect';
import { getIsLoading } from '../../Loading/selectors';
import { LOGIN } from '../constants';
import { getNonFieldErrorMessage } from '../../NotificationsHandler/selectors';

export type LoginFormData = {
  email: string;
  password: string;
};

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const oAuthLoginRedirect = useOAuthLoginRedirect();
  const nonFieldErrorMessage = useSelector(getNonFieldErrorMessage);

  const isAuthenticated = useSelector(getIsAuthenticated);

  const isLoading = useSelector(getIsLoading(LOGIN));

  const submit = useCallback(
    ({ email, password }) => {
      dispatch(loginRequest(email, password));
    },
    [dispatch],
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormSchema),
    mode: 'all',
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(Routes.HOME + location.search);
    }
  }, [isAuthenticated, history, location.search]);

  const handleSocialAuthClick = useCallback(
    (oAuthProvider: OAuthProvider) => {
      oAuthLoginRedirect(oAuthProvider);
    },
    [oAuthLoginRedirect],
  );

  return (
    <Login
      control={control}
      handleSubmit={handleSubmit(submit)}
      handleSocialAuthClick={handleSocialAuthClick}
      errors={errors}
      isLoading={isLoading === 1}
      nonFieldErrorMessage={nonFieldErrorMessage}
    />
  );
};

export default LoginPage;
