import React, { ReactNode } from 'react';
import { BODY2, H3 } from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';

type TitleProps = {
  title?: string;
  subTitle?: string;
  helpLabel?: ReactNode;
};

const QuestionTitle: React.FC<TitleProps> = ({
  title,
  subTitle,
  helpLabel,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box marginTop={`${isDesktop ? 48 : 24}px`} marginBottom="24px">
      <H3>{title}</H3>
      {subTitle && (
        <BODY2 $marginTop={8} color="textSecondary">
          {subTitle}
        </BODY2>
      )}
      {helpLabel && <Box marginTop="16px">{helpLabel}</Box>}
    </Box>
  );
};

export default QuestionTitle;
