import { ANALYTICS_EVENT, ENHANCED_ECOMMERCE_EVENT } from './constants';
import { AnalyticsTrackData, EnhancedEcommerceEvent } from './types';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    dataLayer: any;
    /* eslint-enable */
  }
}

export const logGTMEvent = (
  event: ANALYTICS_EVENT | ENHANCED_ECOMMERCE_EVENT,
  data: AnalyticsTrackData | EnhancedEcommerceEvent,
): void => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      ...data,
    });
  }
};
