import styled from 'styled-components';
import {
  BODY1,
  Container,
  H3,
  Button,
  H4,
  H2,
} from '@tuunetech/tuune-components';

export const VerifyContainer = styled(Container)`
  ${H3}, ${BODY1} {
    span {
      display: block;
    }
  }

  ${H2} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-top: ${({ theme }) => theme.spacing(5)}px;
    }
  }

  ${H4} {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  ${BODY1} {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }
`;

export const ChangeEmailButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
