export enum reportStageStatus {
  INCOMPLETE_HORMONE_TEST_ORDER = 'incomplete_hormone_test_order',
  HA_NOT_COMPLETED = 'ha_not_completed',
  HA_COMPLETED_PENDING_ISSUES_RANKING = 'ha_completed_pending_issues_ranking',
  HA_COMPLETED_FEEDBACK_PENDING = 'ha_completed_pending_feedback',
  HA_COMPLETED_REPORT_LOCKED = 'ha_completed_report_locked',
  HA_COMPLETED_REPORT_UNLOCKED_PENDING_APPROVAL = 'ha_completed_report_unlocked_pending_approval',
  HA_COMPLETED_REPORT_UNLOCKED_IN_REVIEW = 'ha_completed_report_unlocked_in_review',
  HA_COMPLETED_REPORT_UNLOCKED_APPROVED = 'ha_completed_report_unlocked_approved',
  HA_COMPLETED_REPORT_UNLOCKED_REJECTED = 'ha_completed_report_unlocked_rejected',
  PRESCRIPTION_PENDING_PAYMENT = 'prescription_payment_pending',
  PRESCRIPTION_ORDERED = 'prescription_ordered',
  PRESCRIPTION_SUBMITTED = 'prescription_submitted',
  PRESCRIPTION_DISPATCHED = 'prescription_dispatched',
  SIGNUP_PAYMENT_PENDING = 'signup_payment_pending',
  TERMS_AND_CONDITIONS_PENDING = 'terms_and_conditions_pending',
  NAME_MISSING = 'name_missing',
}
