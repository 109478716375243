import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { AuthReducerState } from '../types';

const selectAuth: Selector<RootState, AuthReducerState> = (state) => state.auth;

export const getIsAuthenticated = createSelector(
  selectAuth,
  (state) => state.isAuthenticated,
);

export const getInitialAuthMethod = createSelector(
  selectAuth,
  (state) => state.initialAuthMethod,
);

export const getResetPasswordStage = createSelector(
  selectAuth,
  (state) => state.resetPasswordStage,
);

export const getIsResetPasswordConfirmed = createSelector(
  selectAuth,
  (state) => state.isResetPasswordConfirmed,
);

export const getIsEmailActivated = createSelector(
  selectAuth,
  (state) => state.isEmailActivated,
);

export const getUnauthenticatedEmailAddress = createSelector(
  selectAuth,
  (state) => state.unauthenticatedEmailAddress,
);

export const getLastOAuthCode = createSelector(
  selectAuth,
  (state) => state.lastOAuthCode,
);
