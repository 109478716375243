import {
  DEACTIVATE_USER_REQUEST,
  GET_ACCOUNT_DATA_REQUEST,
  GET_DELETION_REASONS_REQUEST,
  GET_IS_ACTIVE_REQUEST,
  REGISTER_HORMONE_TEST_ADDRESS_REQUEST,
  UPDATE_ACCOUNT_DATA_REQUEST,
  UPDATE_MARKETING_PREFERENCE_REQUEST,
  UPDATE_PRIVACY_OPTIONS_REQUEST,
} from 'modules/Account/constants';
import {
  AccountPayload,
  AccountRequestActionTypes,
  AddressPayload,
  DeactivateUserPayload,
  MarketingPreferencePayload,
  PrivacyOptionsPayload,
} from 'modules/Account/types';
import { MARKETING, PRIVACY } from 'modules/Questionnaire/constants';
import { ApiHost } from '../axios';
import { ApiReturnFunc } from './index';

const accountApi: Record<AccountRequestActionTypes, ApiReturnFunc> = {
  [GET_ACCOUNT_DATA_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/auth/users/me/`,
      method: 'get',
      headers: {},
    };
  },
  [UPDATE_ACCOUNT_DATA_REQUEST]: ({
    firstName,
    lastName,
    phone,
  }: AccountPayload) => {
    return {
      url: `${ApiHost}/api/v1/user/update_current_user/`,
      method: 'patch',
      data: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
      },
      headers: {},
    };
  },
  [UPDATE_MARKETING_PREFERENCE_REQUEST]: ({
    marketingPreference,
  }: MarketingPreferencePayload) => {
    return {
      url: `${ApiHost}/api/v1/user/update_current_user/`,
      method: 'patch',
      data: {
        [MARKETING.marketingPreference]: marketingPreference.has(
          MARKETING.marketingPreference,
        ),
        [MARKETING.researchPreference]: marketingPreference.has(
          MARKETING.researchPreference,
        ),
      },
      headers: {},
    };
  },
  [UPDATE_PRIVACY_OPTIONS_REQUEST]: ({
    privacyOptions,
  }: PrivacyOptionsPayload) => {
    return {
      url: `${ApiHost}/api/v1/user/update_current_user/`,
      method: 'patch',
      data: {
        accept_answers_truthful: privacyOptions.includes(
          PRIVACY.acceptAnswersTruthful,
        ),
        accept_data_usage: privacyOptions.includes(PRIVACY.acceptDataUsage),
        accept_terms: privacyOptions.includes(PRIVACY.acceptTerms),
        accept_assigned_at_birth: privacyOptions.includes(
          PRIVACY.acceptAssignedAtBirth,
        ),
        accept_testing_agreement: privacyOptions.includes(
          PRIVACY.acceptTestingAgreement,
        ),
        accept_share_data_back: privacyOptions.includes(
          PRIVACY.acceptDataUsage,
        ),
        accept_share_report_with_clinician: privacyOptions.includes(
          PRIVACY.acceptDataUsage,
        ),
      },
      headers: {},
    };
  },
  [GET_IS_ACTIVE_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/auth/active`,
      method: 'get',
      headers: {},
    };
  },
  [REGISTER_HORMONE_TEST_ADDRESS_REQUEST]: ({
    addressLine1,
    addressLine2,
    city,
    state,
    phone,
    phoneCode,
    country,
    zipCode,
  }: AddressPayload) => {
    return {
      url: `${ApiHost}/api/v1/user/hormone_test_address_registration/`,
      method: 'post',
      data: {
        line_1: addressLine1,
        line_2: addressLine2,
        city,
        state,
        country,
        phone_number: `${phoneCode} ${phone}`,
        zip_code: zipCode,
      },
      headers: {},
    };
  },
  [GET_DELETION_REASONS_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/user/deactivate`,
      method: 'get',
      headers: {},
    };
  },
  [DEACTIVATE_USER_REQUEST]: ({ reason, freeText }: DeactivateUserPayload) => {
    return {
      url: `${ApiHost}/api/v1/user/deactivate/`,
      method: 'post',
      data: {
        reason,
        reason_other_details: freeText,
      },
      headers: {},
    };
  },
};

export default accountApi;
