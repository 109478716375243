import React, { useCallback } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import styled from 'styled-components';
import { CAPTION } from '@tuunetech/tuune-components';
import type { QuestionProps } from '../../containers/HAFeedbackModule/questions';
import { QuestionsDataKeys } from '../../containers/HAFeedbackModule/questions';

const SOCIALS = [
  'TikTok',
  'Pinterest',
  'Instagram',
  'Twitter',
  'Facebook',
  'Reddit',
  'YouTube',
  'LinkedIn',
];

const RadioButton = styled(Radio)`
  color: #ffffff;
  padding: 6px 8px 6px 12px;

  .MuiSvgIcon-root {
    width: 18px;
  }
`;

export const PrimarySocialMedia: React.FC<QuestionProps> = ({
  handleUpdateData,
  currentData = '',
}) => {
  const handleSelect = useCallback(
    (e) => {
      const sm = e.target.value;
      if (currentData !== sm) {
        handleUpdateData({
          [QuestionsDataKeys.socialMediaChannel]: sm,
        });
      }
    },
    [currentData, handleUpdateData],
  );

  return (
    <RadioGroup value={currentData as string} onChange={handleSelect}>
      <Box
        display="flex"
        flexWrap="wrap"
        gridRowGap="8px"
        maxWidth="378px"
        width="100%"
      >
        {SOCIALS.map((social) => (
          <Box key={social} width="50%" display="flex">
            <FormControlLabel
              value={social}
              control={<RadioButton color="default" />}
              label={<CAPTION>{social}</CAPTION>}
            />
          </Box>
        ))}
      </Box>
    </RadioGroup>
  );
};
