import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCheckIsUserActive } from 'utils/hooks';
import Routes from 'routes';
import { getEmail, getFirstName, getIsActive } from 'modules/Account/selectors';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import { resendActivationRequest } from 'modules/Auth/actions';
import VerifyEmail from '../components/Answers/VerifyEmail';
import { getUnauthenticatedEmailAddress } from 'modules/Auth/selectors';
import { getIsLoading } from 'modules/Loading/selectors';
import { RESEND_ACTIVATION } from 'modules/Auth/constants';
import { QuestionnaireHeader } from '../components/QuestionnaireHeader';
import { Page } from 'shared';
import { getSignupOrderUuid } from 'modules/Checkout/selectors';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';

const VerifyEmailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstName = useSelector(getFirstName);
  const email = useSelector(getEmail);
  const unauthenticatedEmailAddress = useSelector(
    getUnauthenticatedEmailAddress,
  );
  const isUnauthenticatedEmailAddressLoading = useSelector(
    getIsLoading(RESEND_ACTIVATION),
  );
  const isActive = useSelector(getIsActive);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const signupOrderUuid = useSelector(getSignupOrderUuid);

  useCheckIsUserActive();
  useReportStatusRouter();

  useEffect(() => {
    if (!isAuthenticated) {
      if (
        !isUnauthenticatedEmailAddressLoading &&
        !unauthenticatedEmailAddress
      ) {
        history.replace(Routes.RESEND_EMAIL_CUSTOM);
      }
    }
  }, [
    dispatch,
    isActive,
    isAuthenticated,
    history,
    unauthenticatedEmailAddress,
    isUnauthenticatedEmailAddressLoading,
  ]);

  const emailToUse = email || unauthenticatedEmailAddress;

  const handleResend = useCallback(() => {
    if (emailToUse) {
      dispatch(resendActivationRequest({ email: emailToUse }));
    }
  }, [dispatch, emailToUse]);

  const handleChangeEmail = useCallback(() => {
    history.replace(Routes.RESEND_EMAIL_CUSTOM);
  }, [history]);

  return (
    <Page>
      <QuestionnaireHeader />
      <VerifyEmail
        firstName={firstName}
        email={emailToUse}
        handleResend={handleResend}
        handleChangeEmail={handleChangeEmail}
        isAuthenticated={isAuthenticated}
        isOrderSuccessFlow={!!signupOrderUuid}
      />
    </Page>
  );
};
export default VerifyEmailPage;
