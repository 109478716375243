import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountDataRequest,
  getIsActive as fetchIsActive,
} from 'modules/Account/actions';
import { useAnalytics } from 'utils/analytics';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import QuestionLayout from '../components/QuestionLayout';
import {
  getCurrentQuestionRequest,
  getFeedbackRequest,
  getPrevQuestionRequest,
} from '../actions';
import { getFactsSlugs, getIsCompleted, getQuestionData } from '../selectors';
import { getEntries } from 'modules/Contentful/actions';
import { ContentTypeNames } from 'modules/Contentful/constants';
import { ContentKeys } from '../constants/contentfulHeaderSection';

const QuestionPage: React.FC = () => {
  useReportStatusRouter();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const [animation, setAnimation] = useState('forward');
  const [isHelperBoxOpen, setIsHelperBoxOpen] = useState(false);

  const isCompleted = useSelector(getIsCompleted);

  const questionData = useSelector(getQuestionData);

  const factsSlugs = useSelector(getFactsSlugs);

  useEffect(() => {
    dispatch(getCurrentQuestionRequest(0));
    dispatch(fetchIsActive());
    dispatch(getFeedbackRequest());

    dispatch(
      getEntries({
        reqId: ContentKeys[ContentTypeNames.ISSUE_ICON].reqId,
        slugs: [],
        all: true,
        contentType: ContentKeys[ContentTypeNames.ISSUE_ICON].contentType,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isCompleted) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, isCompleted]);

  const prevQId = useRef(questionData.current);
  const handleGoBack = useCallback(() => {
    prevQId.current = questionData.current;
    dispatch(getPrevQuestionRequest((questionData.current as number) - 1));
  }, [dispatch, questionData]);

  useEffect(() => {
    if (questionData.current && prevQId.current) {
      if (questionData.current >= prevQId.current) {
        setAnimation('forward');
      } else {
        setAnimation('back');
      }
    }
  }, [questionData]);

  const progress = useMemo(() => {
    if (
      !questionData.current ||
      !questionData.currentSection ||
      !questionData.sections
    )
      return;
    return {
      current: questionData.current,
      currentSection: questionData.currentSection,
      sections: questionData.sections,
    };
  }, [questionData]);

  const handleToggleHelperBox = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        analytics.track(ANALYTICS_EVENT.QUESTION_HELP, {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          questionId: factsSlugs,
        });
      }
      setIsHelperBoxOpen(isOpen);
    },
    [analytics, factsSlugs],
  );

  return (
    <QuestionLayout
      handleGoBack={handleGoBack}
      isGoBackHidden={questionData.current === 1}
      questionData={questionData}
      progress={progress}
      animation={animation}
      handleToggleHelperBox={handleToggleHelperBox}
      isHelperBoxOpen={isHelperBoxOpen}
    />
  );
};

export default QuestionPage;
