import styled from 'styled-components';
import { GridItem } from '@tuunetech/tuune-components';

export const RecommendationTableContainer = styled(GridItem)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-left: 0;
  }
`;

export const BaseDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.common.black};
  opacity: 0.1;
  height: 1px;
  width: 100%;
`;

export const CheckoutBadge = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  font-size: 12px !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;
