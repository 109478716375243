import React, { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  BODY1,
  Container,
  ContentBlock,
  GridContainer,
  GridItem,
  H2,
  H3,
  H5,
} from '@tuunetech/tuune-components';
import { ClinicianLauraBio, Link } from 'shared';
import { useWebpImage } from 'utils/image';
import { REVIEW_STEPS, getReviewStepsClinic } from './data';
import { Indicator } from 'shared/Indicator';
import { StepsWrapper, Step, Divider, Banner } from './styles';
import { useSelector } from 'react-redux';
import { getClinicInfo } from 'modules/Account/selectors';

const ReviewResults: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [step1, step2, step3, stepClinic] = useWebpImage(
    'preview_next_step1.png',
    'preview_next_step2.png',
    'preview_next_step3.png',
    'preview_next_step_clinic.png',
  );

  const images = useMemo((): Record<string, string> => {
    return {
      step1,
      step2,
      step3,
      stepClinic,
    };
  }, [step1, step2, step3, stepClinic]);

  const HeaderComponent = isXs ? H3 : H2;

  const clinicInfo = useSelector(getClinicInfo);

  return (
    <Container>
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={10} lg={7}>
          <Banner>
            {clinicInfo ? (
              <H5 align="center">
                Your clinician at {clinicInfo?.name} is reviewing your results.
              </H5>
            ) : (
              <ClinicianLauraBio isReviewed={false} />
            )}
          </Banner>
        </GridItem>

        <GridItem sm={11}>
          <HeaderComponent
            $marginBottom={56}
            $marginTop={isXs ? 40 : 64}
            align={isXs ? 'left' : 'center'}
          >
            Your next steps
          </HeaderComponent>
          <StepsWrapper>
            {(clinicInfo
              ? getReviewStepsClinic(clinicInfo.shortName)
              : REVIEW_STEPS
            ).map(({ image, description }, index) => (
              <Step key={image}>
                <img src={images[image]} alt="" />
                <ContentBlock
                  startAdornment={<Indicator>{index + 1}</Indicator>}
                  content={description}
                />
              </Step>
            ))}
          </StepsWrapper>
        </GridItem>

        {!clinicInfo && (
          <GridItem xs={12} md={10} lg={7}>
            <Divider />
            <BODY1 $marginBottom={isXs ? 80 : 40}>
              If you haven&#39;t received your report within 2 business days,
              please get in touch at{' '}
              <Link
                variant="body1"
                href="mailto:help@tuune.com"
                target="_blank"
              >
                help@tuune.com
              </Link>
              .
            </BODY1>
          </GridItem>
        )}
      </GridContainer>
    </Container>
  );
};

export default ReviewResults;
