import styled from 'styled-components';
import { Button } from '@tuunetech/tuune-components';

export const Container = styled.div<{
  $align: 'left' | 'center' | 'right';
}>`
  display: flex;
  justify-content: ${({ $align }) =>
    $align === 'left'
      ? 'flex-start'
      : $align === 'right'
      ? 'flex-end'
      : 'center'};

  column-gap: 8px;
  margin-top: 40px;
`;

export const HAControlButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;
