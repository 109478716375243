import React from 'react';
import { Container, ContentBlock, Divider } from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';
import { AccountInfoFormContainer } from 'modules/Questionnaire/containers/AccountInfoFormContainer';
import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import {
  ClinicLogoImg,
  Indicator,
  IntroBoldParagraph,
  IntroParagraph,
  Title,
} from './styles';

export type LandingProps = {
  handleSubmit: () => void;
  clinicAuthConfig: ClinicAuthConfig;
};

export const ClinicLanding: React.FC<LandingProps> = ({
  handleSubmit,
  clinicAuthConfig,
}) => {
  return (
    <Container>
      <Box
        width="630px"
        maxWidth="100%"
        marginX="auto"
        display="flex"
        flexDirection="column"
        gridRowGap="40px"
      >
        <ClinicLogoImg src={clinicAuthConfig.logoImg} />
        <Title>
          Find the birth control that fits <i>you</i> best
        </Title>

        {clinicAuthConfig.introParagraphsCopy.map((introText, index) => (
          <IntroParagraph key={index}>{introText}</IntroParagraph>
        ))}

        <Box display="flex" flexDirection="column" gridRowGap="16px">
          {clinicAuthConfig.introStepsCopy.map((step, index) => (
            <ContentBlock
              key={index}
              alignItems="center"
              startAdornment={<Indicator>{index + 1}</Indicator>}
              content={step}
            />
          ))}
        </Box>

        {!!clinicAuthConfig.introParagraphsDisclaimerCopy && (
          <IntroBoldParagraph>
            {clinicAuthConfig.introParagraphsDisclaimerCopy}
          </IntroBoldParagraph>
        )}

        <Divider $marginTop={16} />
        <AccountInfoFormContainer
          handleSubmit={handleSubmit}
          clinicAuthConfig={clinicAuthConfig}
        />
      </Box>
    </Container>
  );
};
