import React, { useEffect } from 'react';
import { PrescriptionConfirmation } from '../components/PrescriptionConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { getPharmacyDetailsRequest } from '../actions';
import { getPharmacyDetails, getPrescriptionOrderUuid } from '../selectors';

export const PrescriptionConfirmationContainer: React.FC = () => {
  const dispatch = useDispatch();
  const prescriptionOrderUuid = useSelector(getPrescriptionOrderUuid);

  useEffect(() => {
    if (prescriptionOrderUuid) {
      dispatch(getPharmacyDetailsRequest({ uuid: prescriptionOrderUuid }));
    }
  }, [dispatch, prescriptionOrderUuid]);

  const address = useSelector(getPharmacyDetails);

  return <PrescriptionConfirmation address={address} />;
};
