import React from 'react';
import {
  Container,
  theme,
  REPORT_CONTAINER_WIDTH_LG,
} from '@tuunetech/tuune-components';
import styled from 'styled-components';
import { MarginProps } from '@tuunetech/tuune-components/dist/helpers';
import { useMediaQuery } from '@material-ui/core';

const WidthWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up(REPORT_CONTAINER_WIDTH_LG + 48)} {
    width: ${REPORT_CONTAINER_WIDTH_LG}px;
  }
`;

const BaseDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.common.black};
  opacity: 0.1;
  height: 1px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

type ReportContentWrapperProps = MarginProps & {
  addBottomDivider?: boolean;
  addTopDivider?: boolean;
};

export const ReportContentWrapper: React.FC<ReportContentWrapperProps> = ({
  children,
  addBottomDivider = false,
  addTopDivider = false,
  ...marginProps
}) => {
  const isDesktop = useMediaQuery(() => theme.breakpoints.up('sm'));

  return (
    <Container
      fixed={false}
      $verticalMargin={isDesktop ? 48 : 24}
      {...marginProps}
    >
      <WidthWrapper>
        {addTopDivider && <BaseDivider />}
        {children}
        {addBottomDivider && <BaseDivider />}
      </WidthWrapper>
    </Container>
  );
};
