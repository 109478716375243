import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import Routes from 'routes';
import {
  GridContainer,
  GridItem,
  Button,
  H4,
  BODY1,
  Container,
  BODY2,
} from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import {
  PrescriptionConfirmationContainer,
  ListItem,
  ListItemIndicator,
} from './styles';

export type PharmacyConfirmationProps = {
  address?: string;
};

export const PrescriptionConfirmation: React.FC<PharmacyConfirmationProps> = ({
  address,
}) => {
  const [pharmacyImg] = useWebpImage('pharmacy.jpg');

  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PrescriptionConfirmationContainer>
      <GridContainer justify="center">
        <GridItem xs={12} md={10} lg={6}>
          <img src={pharmacyImg} alt="pharmacy image" />
          <H4 $marginTop={isMobile ? 16 : 40}>
            A physician is writing your prescription!
          </H4>
          <H4 $marginBottom={40} $marginTop={24}>
            What’s next?
          </H4>
          <ListItem
            startAdornment={<ListItemIndicator>1</ListItemIndicator>}
            content={
              <BODY1 color="textSecondahry">
                You&apos;ll receive an email from our physician, outlining next
                steps
              </BODY1>
            }
          />
          <ListItem
            startAdornment={<ListItemIndicator>2</ListItemIndicator>}
            content={
              <Container disableGutters>
                <BODY1 $marginBottom={12}>
                  Once that’s done, we&apos;ll send the prescription to your
                  pharmacy of choice:
                </BODY1>
                {address?.split(',').map((addressLine, idx) => (
                  <BODY2 key={idx}>{addressLine}</BODY2>
                ))}
              </Container>
            }
          />
          <ListItem
            startAdornment={<ListItemIndicator>3</ListItemIndicator>}
            content={
              <BODY1>
                Please get in touch with your pharmacy to collect your
                prescription.
              </BODY1>
            }
          />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => history.replace(Routes.RESULTS)}
          >
            Back to account
          </Button>
        </GridItem>
      </GridContainer>
    </PrescriptionConfirmationContainer>
  );
};
