import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Container } from '@tuunetech/tuune-components';
import { SpinnerContainer } from 'shared/Spinner/styles';
import { useReportStatusRouter } from 'utils/hooks/useReportStatusRouter';
import { Page } from 'shared';

const Home: React.FC = () => {
  useReportStatusRouter();

  return (
    <Page>
      <Container $marginTop={100}>
        <SpinnerContainer>
          <CircularProgress color="primary" />
        </SpinnerContainer>
      </Container>
    </Page>
  );
};

export default Home;
