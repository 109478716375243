import React from 'react';
import { Button, H2 } from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { StartContainer } from './styles';
import { QuestionnaireHeader } from '../QuestionnaireHeader';
import { Page } from 'shared';
import { Box } from '@material-ui/core';

type StartLayoutProps = {
  handleNext: () => void;
};

export const StartLayout: React.FC<StartLayoutProps> = ({ handleNext }) => {
  const [startHA] = useWebpImage('start_ha.jpg');

  return (
    <Page>
      <QuestionnaireHeader />
      <StartContainer fixed={false}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          width="630px"
          maxWidth="100%"
          marginX="auto"
          gridRowGap="40px"
        >
          <img alt="start health assessment" src={startHA} />
          <H2 paragraph>Account activated! Let’s get started...</H2>
          <Button onClick={handleNext}>Next</Button>
        </Box>
      </StartContainer>
    </Page>
  );
};
