import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BODY2, IconPlaceHolder } from '@tuunetech/tuune-components';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import {
  getArrayContentMapped,
  getAssetsMapped,
} from 'modules/Contentful/selectors';
import {
  documentToPlainTextRecords,
  documentToReactNodeRecords,
} from 'modules/Contentful/helpers';
import { DrawerModal } from 'shared';

import {
  SymptomSection,
  SymptomSectionProps,
} from '../components/SymptomSection';
import {
  CONTENT_BLOCK_NAMES,
  getImpact,
  getResultsContentfulOptions,
  IMPACT_SCORE_SLUGS,
} from '../constants';
import { Assessment } from 'shared/Assessment/types/state';
import {
  SCK,
  SYMPTOM_SECTION_STATIC_DATA_SLUGS,
} from '../hooks/useLoadSymptoms';

type SymptomSectionContainerProps = {
  assessmentData: {
    conditions: Assessment[];
    symptomsHormonal: Assessment[];
    symptomsUnknown: Assessment[];
  };
};

export const SymptomSectionContainer: React.FC<
  SymptomSectionContainerProps
> = ({ assessmentData }) => {
  const staticData = useSelector(
    getArrayContentMapped(SCK[CTN.STATIC_CONTENT].reqId),
  );
  // const impactScore = useSelector(
  //   getArrayContentMapped(SCK[CTN.IMPACT_SCORE].reqId),
  // );
  const issueSnippetGeneric = useSelector(
    getArrayContentMapped(SCK[CTN.ISSUE_SNIPPET_GENERIC].reqId),
  );
  const assets = useSelector(getAssetsMapped(SCK[CTN.ISSUE_ICON].reqId));

  const staticDataRemapped = documentToPlainTextRecords(staticData);
  // const impactScoreRemapped = documentToPlainTextRecords(impactScore);
  const issueSnippetGenericRemapped = documentToReactNodeRecords(
    issueSnippetGeneric,
    getResultsContentfulOptions(CONTENT_BLOCK_NAMES.SYMPTOMS_SNIPPET),
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<string>('');

  const handleOpenModal = useCallback(
    (sectionMoreKey?: string) => () => {
      setSelectedSection(sectionMoreKey || '');
      setIsModalOpen(true);
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    setSelectedSection('');
    setIsModalOpen(false);
  }, []);

  const remapIssues = useCallback(
    (issues: Assessment[]): SymptomSectionProps['issues'] => {
      return issues.map(({ slug, name, scores: { wellbeing } }) => {
        const wellbeingImpact = getImpact(wellbeing);
        const wiSlug = IMPACT_SCORE_SLUGS.wellbeing[wellbeingImpact];
        const SymptomIcon = assets[slug] ? (
          <svg data-src={assets[slug].asset} />
        ) : (
          <IconPlaceHolder />
        );

        return {
          id: slug,
          icon: SymptomIcon,
          name,
          scales: [
            {
              id: 'wellbeing',
              title: wiSlug,
              value: wellbeingImpact,
              onClick: null,
            },
          ],
          snippet: issueSnippetGenericRemapped[slug],
        };
      });
    },
    [assets, issueSnippetGenericRemapped],
  );

  const sections = useMemo(() => {
    const sect: SymptomSectionProps[] = [];
    if (assessmentData.conditions.length) {
      sect.push({
        title:
          staticDataRemapped[
            SYMPTOM_SECTION_STATIC_DATA_SLUGS.HORMONE_CONDITION_TITLE
          ],
        sectionMoreKey:
          SYMPTOM_SECTION_STATIC_DATA_SLUGS.HORMONE_CONDITION_CONTENT,
        issues: remapIssues(assessmentData.conditions),
      });
    }
    if (assessmentData.symptomsHormonal.length) {
      sect.push({
        title:
          staticDataRemapped[
            SYMPTOM_SECTION_STATIC_DATA_SLUGS.HORMONE_SYMPTOMS_TITLE
          ],
        sectionMoreKey:
          SYMPTOM_SECTION_STATIC_DATA_SLUGS.HORMONE_SYMPTOMS_CONTENT,
        issues: remapIssues(assessmentData.symptomsHormonal),
      });
    }

    if (assessmentData.symptomsUnknown.length) {
      sect.push({
        title:
          staticDataRemapped[
            SYMPTOM_SECTION_STATIC_DATA_SLUGS.UNKNOWN_SYMPTOMS_TITLE
          ],
        sectionMoreKey:
          SYMPTOM_SECTION_STATIC_DATA_SLUGS.UNKNOWN_SYMPTOMS_CONTENT,
        issues: remapIssues(assessmentData.symptomsUnknown),
      });
    }

    return sect;
  }, [
    assessmentData.conditions,
    assessmentData.symptomsHormonal,
    assessmentData.symptomsUnknown,
    staticDataRemapped,
    remapIssues,
  ]);

  return (
    <>
      <DrawerModal
        onClose={handleCloseModal}
        onOpen={() => handleOpenModal()}
        open={isModalOpen}
      >
        {selectedSection && (
          <BODY2 color="textSecondary">
            {staticDataRemapped[selectedSection]}
          </BODY2>
        )}
      </DrawerModal>
      {sections.map((section) => (
        <SymptomSection
          key={section.title}
          {...section}
          handleOpenMore={handleOpenModal}
        />
      ))}
    </>
  );
};
