import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import React from 'react';

export type ClinicsContextObj = {
  isLoading?: boolean;
  clinicsConfig?: Record<string, ClinicAuthConfig>;
};

export const CLINICS_CONTEXT_DEFAULT_VALUE: ClinicsContextObj = {
  isLoading: undefined,
  clinicsConfig: undefined,
};

export const ClinicsConfigContext = React.createContext(
  CLINICS_CONTEXT_DEFAULT_VALUE,
);
