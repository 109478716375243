import styled from 'styled-components';
import {
  BODY1,
  Button,
  H2,
  Container as BaseContainer,
} from '@tuunetech/tuune-components';

export const Container = styled(BaseContainer)`
  background-color: ${({ theme }) => theme.palette.common.purple[700]};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  overflow: hidden;

  ${H2} + ${BODY1} {
    margin-top: 24px;
  }
`;

export const TopMoonImage = styled.img`
  margin-bottom: auto;
`;

export const BottomMoonImage = styled.img`
  margin-top: auto;
`;

export const LoadingButton = styled(Button)`
  margin-top: 24px;
`;
