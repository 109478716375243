import React, { useCallback } from 'react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { BODY1, Button, Option } from '@tuunetech/tuune-components';
import { DragHandle } from '@material-ui/icons';
import { Box, CircularProgress } from '@material-ui/core';
import { OptionWrapper, Rank } from './styles';
import { Symptom } from '../../types';
import { AppFixedFooter } from 'shared/AppFixedFooter';

export type SymptomRankingProps = {
  symptoms: Symptom[];
  handleUpdateRank: (symptoms: Symptom[]) => void;
  handleBack: () => void;
  handleContinue: () => void;
  isLoading: boolean;
};

export const SymptomRanking: React.FC<SymptomRankingProps> = ({
  symptoms,
  handleUpdateRank,
  handleBack,
  handleContinue,
  isLoading,
}) => {
  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const items = Array.from(symptoms);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      handleUpdateRank(items);
    },
    [handleUpdateRank, symptoms],
  );

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="symptoms">
          {(provided) => (
            <Rank
              {...provided.droppableProps}
              ref={provided.innerRef}
              key="rank"
            >
              {symptoms.map(({ name, slug }, index) => (
                <Draggable key={slug} draggableId={slug} index={index}>
                  {(provided) => (
                    <OptionWrapper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Option
                        startAdornment={<DragHandle color="disabled" />}
                        handleOptionClick={() => {
                          return;
                        }}
                      >
                        <BODY1>{name}</BODY1>
                      </Option>
                    </OptionWrapper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Rank>
          )}
        </Droppable>
      </DragDropContext>
      <AppFixedFooter>
        <Button variant="outlined" onClick={handleBack}>
          back
        </Button>
        <Box width="8px" />
        <Button
          fullWidth
          onClick={handleContinue}
          disabled={isLoading}
          startIcon={
            isLoading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          continue
        </Button>
      </AppFixedFooter>
    </>
  );
};
